import React, { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Image, Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { handleNextRouteLastPathConversion } from '../../helpers/routes-helpers';
import { useDocumentsTempData } from '../../hooks/useDocumentsTempData';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { DOCUMENTS_FORM_TYPE, OCR_DOCUMENT_TYPE } from '../../constants/typeConstants/documentsFormType';
import { PATHS } from '../../services/router/app-routes/routes-constants';
import { DOCUMENTS_TYPES } from '../../helpers/handle-documents-update';
import { FileUpload } from '../../shared/components/FileUpload';
import { ocrProcessingResponse, useOcrProcessing } from '../../hooks/useOcrProcessing';
import { checkOcrResponseIntegrity } from '../../helpers/ocr-processing/ocrProcessingHelper';
import { FILE_MODE } from '../../constants/typeConstants/cameraType';
import { IStatementBackResponse } from '../../@types/current-statement/currentStatementTypes';
import { stopRequests } from '../../services/api';

export const DocumentsPhoto = ({ nextRoute, history, location, parentRoute }: IScreenComponentProps): JSX.Element => {
  const [fileMode, setFileMode] = useState(FILE_MODE.CAMERA);
  const { t } = useTranslation();
  const { defaultPicture, title, putStatementDocuments } = useDocumentsTempData();
  const amicableFindingOrPoliceReportTitle = history?.location?.state?.amicableFindingOrPoliceReportTitle;
  const { setCurrentStatementData, currentStatement } = useCurrentStatement();
  const [initiated, initiate] = useState<boolean>(false);
  const [isImageReady, setIsImageReady] = useState<boolean>(false);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const { formType } = useDocumentsTempData();
  const { sendImageToOcr } = useOcrProcessing();
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [ocrResponse, setOcrResponse] = useState<ocrProcessingResponse | null | any>(null);
  const [emptyImage, setEmptyImage] = useState<boolean>(false);

  const [cancel, setCancel] = useState<boolean>(false);

  const cancelRef = useRef();
  cancelRef.current = cancel as any;

  history.listen(() => {
    setCancel(true);
    stopRequests();
  });

  const getHelperText = () => {
    return !amicableFindingOrPoliceReportTitle &&
      title !== 'Notă de constatare' &&
      title !== 'Ofertă de despăgubire' &&
      title !== 'Constatare amiabilă'
      ? t('cameraText.dataComplete')
      : '';
  };

  const handleAccidentDocumentsUpdate = (documentIdKey: string, formTypeProp: string, image: File | null) => {
    if (image) {
      const componentDestroyed = cancelRef?.current;

      try {
        const formData = new FormData();

        formData.append('file', image);
        formData.append('id', currentStatement?.statementID as any);
        formData.append('type', formTypeProp);

        putStatementDocuments(formData, (data: Partial<IStatementBackResponse>) => {
          if (!componentDestroyed) {
            const documentID = data?.[documentIdKey as keyof Partial<IStatementBackResponse>];
            setCurrentStatementData({ [documentIdKey]: documentID });
            history.push(handleNextRouteLastPathConversion(parentRoute));
          }
        });
      } catch (e) {
        if (!componentDestroyed) {
          setOcrResponse(null);
          setModalActive(true);
        }
      }
    }
  };

  const handleSendImageToOcr = async (documentType: OCR_DOCUMENT_TYPE, image: File | null) => {
    const componentDestroyed = cancelRef?.current;

    if (image) {
      try {
        const ocrResponse = await sendImageToOcr(documentType, image);

        if (!componentDestroyed) {
          if (ocrResponse.ocrData && checkOcrResponseIntegrity(documentType, ocrResponse.ocrData)) {
            return history.push(handleNextRouteLastPathConversion(parentRoute, nextRoute), {
              ocrData: ocrResponse.ocrData,
              image: ocrResponse.newImage,
            });
          }

          setOcrResponse(ocrResponse);
          setModalActive(true);
        }
      } catch (e: any) {
        if (!componentDestroyed) {
          setOcrResponse({ newImage: e?.newImage });
          setModalActive(true);
        }
      }
    }
  };

  const saveFile = async (image: File | null) => {
    if (!image) return;

    if (image?.size === 0) setEmptyImage(true);

    switch (formType) {
      case DOCUMENTS_FORM_TYPE.AMICABLE_FINDING:
        return history.push(handleNextRouteLastPathConversion(parentRoute, PATHS.chooseVehicle.toString()), { newImage: image });
      case DOCUMENTS_FORM_TYPE.POLICE_REPORT:
        handleAccidentDocumentsUpdate('policeReportId', DOCUMENTS_TYPES.POLICE_REPORT, image);
        break;
      case DOCUMENTS_FORM_TYPE.OBSERVATION_NOTE:
        handleAccidentDocumentsUpdate('observationNoteId', DOCUMENTS_TYPES.OBSERVATION_NOTE, image);
        break;
      case DOCUMENTS_FORM_TYPE.COMPENSATION_OFFER:
        handleAccidentDocumentsUpdate('compensationOfferId', DOCUMENTS_TYPES.COMPENSATION_OFFER, image);
        break;
      case DOCUMENTS_FORM_TYPE.ID_CARD:
        await handleSendImageToOcr(OCR_DOCUMENT_TYPE.ID_CARD, image);
        break;
      case DOCUMENTS_FORM_TYPE.CAR_IDENTITY:
        await handleSendImageToOcr(OCR_DOCUMENT_TYPE.CAR_IDENTITY, image);
        break;
      case DOCUMENTS_FORM_TYPE.INSURANCE:
        await handleSendImageToOcr(OCR_DOCUMENT_TYPE.INSURANCE, image);
        break;
      case DOCUMENTS_FORM_TYPE.DRIVING_LICENSE:
        await handleSendImageToOcr(OCR_DOCUMENT_TYPE.DRIVING_LICENSE, image);
        break;
      default:
        break;
    }
  };

  const handleManualOCR = () => {
    return history.push(handleNextRouteLastPathConversion(parentRoute, nextRoute), {
      ocrData: ocrResponse?.ocrData,
      image: ocrResponse?.newImage,
    });
  };

  const renderManualOCRButton = () => {
    return (
      <Button margin='15px 0 0 0' mode='primary' onClick={() => handleManualOCR()}>
        <Typography align='center' color='white' variant='h5'>
          {t('commonText.insertDataManual')}
        </Typography>
      </Button>
    );
  };

  const retakePhotoModal = (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div>
          <Typography align='center' commonStyles={{ margin: '14px 0 0 0' }} fontWeight='700' variant='h2'>
            {t('commonText.retakePhoto')}
          </Typography>
          {emptyImage && (
            <Typography align='center' color='red' commonStyles={{ margin: '100px 0 0 0' }} fontSize='18px' variant='h4'>
              {t('commonText.emptyPhotoMessage')}
            </Typography>
          )}
          {formType === DOCUMENTS_FORM_TYPE.INSURANCE && !emptyImage && (
            <Typography align='center' color='red' commonStyles={{ margin: '100px 0 0 0' }} fontSize='18px' variant='h4'>
              {t('commonText.attentionMessage')}
            </Typography>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Button margin='15px 0 0 0' mode='primary' onClick={() => setModalActive(false)}>
            <Typography align='center' color='white' variant='h5'>
              {t('commonText.ok')}
            </Typography>
          </Button>
          {renderManualOCRButton()}
        </div>
      </div>
    </>
  );

  return (
    <ScreenContainer
      headerProps={{
        title: amicableFindingOrPoliceReportTitle ?? title,
        hideLogo: true,
        customParentBack: true,
        onCustomParentBack: () => {
          if (initiated) initiate(false);
          else {
            history.goBack();
          }
        },
      }}
      isModalOpen={modalActive}
      modal={retakePhotoModal}
      modalProps={{ show: modalActive, onClose: () => setModalActive(false) }}
    >
      <Wrapper alignItems='center' flexDirection='column' height='100%' justifyContent='space-between' width='100%'>
        {!isImageReady && (
          <Wrapper alignItems='center' display='flex' flexDirection='column' height='85%' justifyContent='center'>
            <Image src={location.state?.image || defaultPicture} style={{ width: '95%', height: '100%', objectFit: 'contain' }} />
          </Wrapper>
        )}

        <FileUpload
          ref={fileInput}
          editable={false}
          helperText={isImageReady ? getHelperText() : ''}
          hideControls={!isImageReady}
          mode={fileMode}
          saveFile={saveFile}
          type='image'
          onFileChange={val => setIsImageReady(!!val)}
        />

        {!isImageReady && (
          <>
            <Button
              mode='primary'
              width='100%'
              onClick={() => {
                initiate(true);
                setFileMode(FILE_MODE.CAMERA);
                setTimeout(() => {
                  (fileInput as MutableRefObject<HTMLInputElement>)?.current.click();
                }, 300);
              }}
            >
              {t('cameraText.takePicture')}
            </Button>
            <Button
              boxShadow='unset'
              color='#512AD8'
              mode='default'
              style={{ background: 'none', border: 0 }}
              onClick={() => {
                initiate(true);
                setFileMode(FILE_MODE.UPLOAD);
                setTimeout(() => {
                  (fileInput as MutableRefObject<HTMLInputElement>)?.current.click();
                }, 300);
              }}
            >
              {t('cameraText.uploadPicture')}
            </Button>
          </>
        )}
      </Wrapper>
    </ScreenContainer>
  );
};
