import { DefaultTheme } from 'styled-components';

import { palette, IPaletteTypes } from './pallete';

declare module 'styled-components' {
  export interface DefaultTheme {
    primaryColor: string;
    secondaryColor: string;
    dark: string;
    backgrounds: IBackgroundTypes<string>;
    actionColors: IActionColors;
    palette: IPaletteTypes<string>;
    typography: ITypography;
    buttonSizes: IButtonSizes;
  }
}

/*
  ACTION COLORS
*/

export interface IActionColors {
  error: string;
  info: string;
  success: string;
  primary: string;
  warning: string;
  disabled: string;
}

export const actionColors: IActionColors = {
  error: '#FF3B30',
  info: '#007AFF',
  success: '#34C759',
  primary: '#0066FF',
  warning: '#FF9F0A',
  disabled: '#999999',
};

export interface IButtonSizes {
  small: string;
  medium: string;
  large: string;
  height: string;
}

const buttonSizes: IButtonSizes = {
  small: '156px',
  medium: '216px',
  large: '320px',
  height: '52px',
};

export interface ITypography {
  textFontSize: string;
  headerTitle: string;
  title: string;
  h3FontSize: string;
  fontFamily: string;
  textPrimaryColor: string;
  headingColor: string;
  titleColor: string;
}

export const typography: ITypography = {
  textFontSize: '16px',
  headerTitle: '24px',
  title: '20px',
  h3FontSize: '17px',
  fontFamily: 'Roboto, Montserrat, sans-serif',
  textPrimaryColor: '#1E1E29',
  headingColor: '#1E1E29',
  titleColor: '#9A9A9A',
};

/*
 BACKGROUNDS
*/

type BackgroundTypes = 'light' | 'base' | 'elevated';
type BackgroundItemTypes = 'primary' | 'secondary' | 'tertiary' | 'dark';

export type IBackgroundTypes<T> = {
  [k in BackgroundTypes]: {
    [i in BackgroundItemTypes]: T;
  };
};

const backgrounds: IBackgroundTypes<string> = {
  light: {
    primary: '#FFFFFF',
    secondary: '#F2F2F7',
    tertiary: '#FFFFFF',
    dark: '#1E1E29',
  },
  base: {
    primary: '#2E3946',
    secondary: '#1C1C1E',
    tertiary: '#2C2C2E',
    dark: '#1E1E29',
  },
  elevated: {
    primary: '#1C1C1E',
    secondary: '#2C2C2E',
    tertiary: '#3A3A3C',
    dark: '#1E1E29',
  },
};

export const lightTheme: DefaultTheme = {
  primaryColor: palette.yellow.light,
  secondaryColor: '#666',
  dark: '#1E1E29',
  backgrounds,
  actionColors,
  palette,
  typography,
  buttonSizes,
};

export const darkTheme: DefaultTheme = {
  primaryColor: '#2E3946',
  secondaryColor: '#cacaca',
  dark: '#1E1E29',
  backgrounds,
  actionColors,
  palette,
  typography,
  buttonSizes,
};
