import React from 'react';
import { useTranslation } from 'react-i18next';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { useStatements } from '../../hooks/useStatements';
import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';

export type SelectionType = 'checkbox' | 'radio' | 'text';

export const InformAssured = ({ history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { fetchStatements, statementsState } = useStatements();
  const { currentStatement } = useCurrentStatement();

  const continueToLogin = () => {
    history.push({ pathname: PRE_LOGIN_ROUTES.login });
  };

  return (
    <ScreenContainer headerType='none' showBottomNavbar={false}>
      <Wrapper flexDirection='column' height='100%' justifyContent='space-between'>
        <Wrapper flexDirection='column'>
          <Wrapper alignItems='center' display='flex' flexDirection='column' justifyContent='flex-start'>
            <Typography
              align='center'
              commonStyles={{ margin: '20% auto 0 auto' }}
              extendedStyles={{ lineHeight: '40px' }}
              fontWeight='700'
              variant='h3'
            >
              {t('information.intro')}
              {'XX XX XXX '}
              {t('information.phrase1')}
              {'NUME NUME NUME '}
              {t('information.phrase2')}
            </Typography>
          </Wrapper>
          <Wrapper alignItems='center' display='flex' flexDirection='column' justifyContent='flex-start'>
            <Typography align='center' color='#8e8e89' commonStyles={{ margin: '20px 0 0 0' }} letterSpacing='0.02em' variant='body'>
              {t('information.phrase3')}
              {'NUME NUME NUME '}
              {t('information.phrase4')}
            </Typography>
          </Wrapper>
          <Wrapper alignItems='center' display='flex' flexDirection='column' justifyContent='flex-start'>
            <Typography align='center' color='#8e8e89' commonStyles={{ margin: '20px 0 0 0' }} letterSpacing='0.02em' variant='body'>
              {t('information.phrase5')}
            </Typography>
          </Wrapper>
        </Wrapper>
        <Wrapper style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Button borderRadius='25px' margin='10% auto 0 auto' mode='primary' onClick={() => continueToLogin()}>
            {t('commonText.continue')}
          </Button>
        </Wrapper>
      </Wrapper>
    </ScreenContainer>
  );
};
