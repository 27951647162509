import { CAR_PARTS } from '../constants/typeConstants/damageType';

interface CarPartsI {
  name: string;
  value: number;
}

const useDamageSurfaceConstants = (isLeftSide: () => boolean) => {
  const CAR_PARTS_ARR: CarPartsI[] = [
    {
      name: isLeftSide() ? CAR_PARTS.FRONT + CAR_PARTS.LEFT : CAR_PARTS.BACK + CAR_PARTS.RIGHT,
      value: 25,
    },
    {
      name: isLeftSide() ? CAR_PARTS.FRONT_DOOR + CAR_PARTS.LEFT : CAR_PARTS.BACK_DOOR + CAR_PARTS.RIGHT,
      value: 50,
    },
    {
      name: isLeftSide() ? CAR_PARTS.BACK_DOOR + CAR_PARTS.LEFT : CAR_PARTS.FRONT_DOOR + CAR_PARTS.RIGHT,
      value: 75,
    },
    {
      name: isLeftSide() ? CAR_PARTS.BACK + CAR_PARTS.LEFT : CAR_PARTS.FRONT + CAR_PARTS.RIGHT,
      value: 100,
    },
  ];

  return { CAR_PARTS, CAR_PARTS_ARR };
};

export { useDamageSurfaceConstants };
