import React from 'react';

import { IIconProps } from './icon.model';

export const StatementIcon = ({ width, height, selected, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg height={height || 30} width={width || 30} {...otherProps}>
      <rect fill={selected ? '#4123D7' : '#FFF'} height={30} rx={15} width={30} />
      <path
        d='M22 24H8a1 1 0 01-1-1v-9.586a1 1 0 01.293-.707l7-7a1 1 0 011.415 0l7 7a.994.994 0 01.292.707V23a1 1 0 01-1 1zm-9-7h4v5h4v-8.172l-6-6-6 6V22h4v-5z'
        fill={selected ? '#FFF' : '#8E8D8D'}
      />
    </svg>
  );
};
