import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from '../../components/button';
import { FileUpload } from '../../shared/components/FileUpload';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { IScreenComponentProps } from '../component-props.model';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { fetchStart, fetchSuccess } from '../../store/statement/statement.slice';
import { initFaceRecognitionRequest } from '../../services/api/faceRecognition/initFaceRecognitionRequest';

export const FacialRecognitionCamera = ({ history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const fileCameraRef = useRef<HTMLInputElement | null>(null);
  const [picture, setPicture] = useState<File | null | undefined>(null);
  const { currentStatementId } = useParams<{ currentStatementId: string | undefined }>();
  const { currentStatement } = useCurrentStatement();
  const dispatch = useDispatch();

  const handleFaceRecognition = useCallback(() => {
    const formData = new FormData();
    formData.append('file', picture as File);
    formData.append('personId', currentStatement?.injured?.id?.toString() as string);
    dispatch(fetchStart());
    initFaceRecognitionRequest(formData)
      .then(() => {
        dispatch(fetchSuccess());

        if (currentStatement.insuredId === currentStatement.injured.id)
          return history.push(generatePath(PROTECTED_ROUTES.statementSignature, { currentStatementId }));

        return history.push(generatePath(PROTECTED_ROUTES.statementFinalSignature, { currentStatementId }));
      })
      .catch(e => {
        dispatch(fetchSuccess());
        console.log(e);
      });
  }, [currentStatement.injured.id, currentStatement.insuredId, currentStatementId, history, picture]);

  const handleCamera = () => {
    if (picture) handleFaceRecognition();

    if (picture === null) return fileCameraRef.current?.click();
  };

  return (
    <ScreenContainer headerProps={{ hideLogo: true, title: t('facialRecognition.title'), showButton: false }} showBottomNavbar={false}>
      <FileUpload
        ref={fileCameraRef}
        mode='facial-recognition-camera'
        type='image'
        hideControls
        onFileChange={(file: File | null | undefined) => setPicture(file)}
      />
      <Button margin='auto 0 0 0' mode='primary' onClick={() => handleCamera()}>
        {!picture ? t('facialRecognition.scanPictureButton') : t('commonText.save')}
      </Button>
    </ScreenContainer>
  );
};
