import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Image } from '../../styled-components';
import BlackLogo from '../../assets/images/BlackLogo.png';

import { AuthBody } from './AuthBody';
import { AuthModal } from './AuthModal';

export const Register = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [authMethod, setAuthMethod] = useState<string | null>(null);

  const toggleModal = (value: boolean, method: string) => {
    setAuthMethod(method);
    setShowModal(value);
  };

  const authModalComponent = (
    <AuthModal
      authMethod={authMethod as string}
      headerType='dark'
      history={history}
      nextRoute={nextRoute}
      showModal={showModal}
      toggleModal={toggleModal}
    />
  );
  const pageTitle = t('authentication.createAccountToContinue');
  const modalPrompt = {
    question: t('authentication.doYouHaveAccount'),
    buttonName: t('authentication.login'),
  };

  return (
    <ScreenContainer
      backgroundColor='white'
      headerProps={{
        height: '0',
      }}
      headerType='dark'
      isModalOpen={showModal}
      modal={authModalComponent}
      modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
      showBottomNavbar={false}
    >
      <Image margin='0 0 30px 0' src={BlackLogo} />
      <AuthBody
        history={history}
        modalPrompt={modalPrompt}
        nextRoute={nextRoute}
        pageTitle={pageTitle}
        showModal={showModal}
        toggleModal={toggleModal}
      />
    </ScreenContainer>
  );
};
