import React from 'react';

import { IIconProps } from './icon.model';

export const LocationPinIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 59} viewBox='0 0 46 59' width={width || 46} {...otherProps}>
      <g filter='url(#filter0_d)'>
        <path
          d='M22.7 0C18.443 0 15 3.443 15 7.7C15 12.3831 20.0635 18.8746 21.9783 21.1619C22.3585 21.6162 23.0415 21.6162 23.4217 21.1619C25.3365 18.8746 30.4 12.3831 30.4 7.7C30.4 3.443 26.957 0 22.7 0ZM22.7 10.45C21.182 10.45 19.95 9.218 19.95 7.7C19.95 6.182 21.182 4.95 22.7 4.95C24.218 4.95 25.45 6.182 25.45 7.7C25.45 9.218 24.218 10.45 22.7 10.45Z'
          fill={fill || '#0066ff'}
        />
      </g>
      <defs>
        <filter colorInterpolationFilters='sRGB' filterUnits='userSpaceOnUse' height='58.5026' id='filter0_d' width='45.4' x='0' y='0'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy='22' />
          <feGaussianBlur stdDeviation='7.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' mode='normal' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
