import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { ContactDetailsForm } from '../../components/contact-details/ContactDetailsForm';
import { IContactDetails } from '../../@types/current-statement/currentStatementTypes';
import { contactDetailsInitialState } from '../../store/statement/initialState';
import { usePreLogin } from '../../hooks/use-preLogin';
import { handleContactDetailsDisplay } from '../../helpers/contact-details-helper';
import { logUserAction } from '../../store/auth-user/auth.action';
import { RootState } from '../../store/rootReducer';
import { IAuthUserModel } from '../../store/auth-user/auth.model';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { createStatement } from '../../services/api/createStatement/createStatement.api';
import { STATEMENT_TYPES } from '../../store/types';
import { EVALUATION_STATUS, NOTIFICATION_STATUS, STATEMENT_STATUS } from '../../constants/typeConstants/statementStatusType';
import { preLoginInitialState } from '../../store/pre-login/initialState';
import { useStatements } from '../../hooks/useStatements';

export const ContactDetails = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);
  const { multipleContactInformation, legalPerson, myself } = history?.location?.state || {};

  const { preLoginState, setPreLoginData } = usePreLogin();
  const authData = useSelector((state: RootState) => state.authUser);
  const { questionsFlowHandlerState } = useQuestionsFlowHandler();
  const dispatch = useDispatch();

  const { fetchStatements } = useStatements();

  useEffect(() => {
    if (questionsFlowHandlerState.damageTypeQuestion === '') {
      history.push(generatePath(`${PRE_LOGIN_ROUTES.insuranceType}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialStatusByType = (type: STATEMENT_TYPES) => {
    switch (type) {
      case STATEMENT_TYPES.EVALUATION:
        return EVALUATION_STATUS.EVALUATION_INCOMPLETE;
      case STATEMENT_TYPES.NOTIFICATION:
        return NOTIFICATION_STATUS.NOTIFICATION_INCOMPLETE;
      case STATEMENT_TYPES.STATEMENT:
        return STATEMENT_STATUS.STATEMENT_INCOMPLETE;
      default:
        return;
    }
  };

  const onSubmitValues = (values: Omit<IContactDetails, 'type'>) => {
    handleContactDetailsDisplay(values, multipleContactInformation, legalPerson, setPreLoginData);

    const formValues = Object.entries(values)
      .map(([key, value]) => (value !== '' ? { key, value } : undefined))
      .reduce((obj, item) => {
        if (item && item.key && item.value) return { ...obj, [item.key]: item.value };

        return obj;
      }, {} as IContactDetails);

    // TODO: quick fix faulty hook for today's call, to refactor using corrected hook
    const personDetails: IContactDetails = {
      companyName: formValues.companyName,
      type: preLoginState.carOwner,
      cui: formValues.cui,
      address: formValues.address,
      county: formValues.county,
      city: formValues.city,
      phone: formValues.phone,
      email: formValues.email,
      insuredPhone: formValues.insuredPhone,
      insuredEmail: formValues.insuredEmail,
    };

    if (authData.data) {
      const payload: IAuthUserModel = {
        id: Number(authData.data.id),
        name: authData.data.name,
        email: authData.data.email,
        sessionToken: authData.data.sessionToken,
      };

      const addPhone = {
        ...payload,
        phone: preLoginState.contactData.phone,
      };

      dispatch(logUserAction(addPhone));
      createStatement({
        injured: personDetails,
        type: questionsFlowHandlerState.type, // TODO: change with preLogin.statementType
        status: getInitialStatusByType(questionsFlowHandlerState.type),
        damageTypeQuestion: questionsFlowHandlerState.damageTypeQuestion,
        observationNoteBool: questionsFlowHandlerState.observationNote,
        compensationOfferBool: questionsFlowHandlerState.compensationOffer,
      }).then(async statementFromBackRes => {
        // resetting the preLoginState since the data was sent to backend
        setPreLoginData(preLoginInitialState);

        try {
          await fetchStatements();
          history.push(generatePath((nextRoute as any).toString(), { currentStatementId: statementFromBackRes.data.id }));
        } catch (e) {
          console.log('Error: ', e?.message);
        }
      });
    }
  };

  const initialValues = {
    ...contactDetailsInitialState,
    ...(myself && { email: authData?.data?.email || '', phone: authData?.data?.phone || '' }),
  };

  return (
    <>
      <ScreenContainer
        headerProps={{
          title: t('commonText.contactDetails'),
          hideLogo: true,
          titleSize: 'h5',
        }}
        showBottomNavbar={isLoggedIn}
      >
        <ContactDetailsForm
          initialValues={initialValues}
          legalPerson={legalPerson}
          multipleContactInformation={multipleContactInformation}
          onSubmit={values => onSubmitValues(values)}
        />
      </ScreenContainer>
    </>
  );
};
