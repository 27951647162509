import styled from 'styled-components';

import { ITextAreaProps } from './types';

const StyledTextArea = styled.textarea<ITextAreaProps>`
  font-family: 'Lato';
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;
  border: 1px solid ${({ borderColor }) => borderColor || '#dcdcdc'};
  color: #1e1e29;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  border-radius: 25px;
  padding: 10px 19px;
  &::placeholder {
    color: #9a9a9a;
    font-size: 14px;
    font-style: italic;
    font-family: 'Lato';
  }
`;

export { StyledTextArea };
