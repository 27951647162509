import React from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../styled-components';
import { Typography } from '../../styled-components';
import { $lineHeightXL } from '../../styles/variables';
import { Button } from '../button';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { STATEMENT_TYPES } from '../../store/types';

interface IFirstAmicableFindingModalProps {
  closeModal: () => void;
}

export const SwitchToEvaluationModal: React.FC<IFirstAmicableFindingModalProps> = props => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();

  const onContinueWithEvaluationClick = () => {
    props.closeModal();
    setQuestionsFlowHandler({
      type: STATEMENT_TYPES.EVALUATION,
    });
    history.push(generatePath(PRE_LOGIN_ROUTES.insuranceType));
  };

  const onDiscard = () => {
    history.push(PRE_LOGIN_ROUTES.root);
  };

  return (
    <>
      <Wrapper display='flex' flexDirection='column' height='100%'>
        <Wrapper alignItems='left' display='flex' flexDirection='column' justifyContent='flex-start'>
          <Typography commonStyles={{ padding: '0 15px' }} extendedStyles={{ lineHeight: $lineHeightXL }} fontWeight='700' variant='h3'>
            {t('switchToEvaluationModal.helperText')}
          </Typography>
          <Typography align='left' color='#8e8e8e' commonStyles={{ padding: '15px' }} variant='body'>
            {t('switchToEvaluationModal.subtitle')}
          </Typography>
        </Wrapper>

        <Wrapper display='flex' flexDirection='column' justifyContent='flex-start' width='100%'>
          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            width='100%'
            onClick={() => onContinueWithEvaluationClick()}
          >
            {t('switchToEvaluationModal.continueWithEvaluation')}
          </Button>
          <Button margin='20px 0' mode='link' onClick={() => onDiscard()}>
            <Typography color='#512AD8' variant='h5'>
              {t('switchToEvaluationModal.discard')}
            </Typography>
          </Button>
        </Wrapper>
      </Wrapper>
    </>
  );
};
