import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { RootState } from '../store/rootReducer';
import { setPreLoginDataAction } from '../store/pre-login/preLogin.slice';
import { IPreLogin } from '../@types/pre-login/preLoginTypes';
import { IStatementBackResponse } from '../@types/current-statement/currentStatementTypes';
import { updatePerson } from '../helpers/current-statement';
import { LEASING_COMPANY, LEGAL_PERSON, MYSELF, OTHER_INDIVIDUAL } from '../services/router/routeHandles';
import { getUserById, postUserPhoneNumber } from '../store/statement/currentStatement.action';
import { IAuthUserModel } from '../store/auth-user/auth.model';

import { useCurrentStatement } from './useCurrentStatement';

const usePreLogin = () => {
  const dispatch = useDispatch();
  const preLoginState = useSelector((state: RootState) => state.preLogin.data);
  const { currentStatement, setCurrentStatementData } = useCurrentStatement();
  const setPreLoginData = useCallback(
    (newPreLoginData: Partial<IPreLogin>) => {
      dispatch(setPreLoginDataAction({ ...preLoginState, ...newPreLoginData }));
    },
    [dispatch, preLoginState],
  );

  const handleAfterLoginStatementUpdate = (statementFromBackRes: IStatementBackResponse) => {
    const { guiltyId, id, injuredId } = statementFromBackRes;
    const { guiltyInsurance, injuredInsurance } = preLoginState.insurances;
    const { guilty, injured } = currentStatement;

    setCurrentStatementData({
      ...currentStatement,
      statementID: id,
      guilty: updatePerson(guilty, guiltyId, guiltyInsurance, guilty?.documents?.insurance?.data, 'guilty'),
      injured: updatePerson(injured, injuredId, injuredInsurance, injured?.documents?.insurance?.data, 'injured', statementFromBackRes),
    });
  };

  const handleDifferentTypeOfOwners = () => {
    const { carOwner, contactData } = preLoginState;
    const { email, phone, insuredPhone, insuredEmail, address, city, companyName, county, cui } = contactData;

    switch (preLoginState.carOwner) {
      case MYSELF:
        return {
          type: carOwner,
          email,
          phone,
        };
      case OTHER_INDIVIDUAL:
        return {
          type: carOwner,
          email,
          phone,
          insuredPhone,
          insuredEmail,
        };
      case LEGAL_PERSON:
      case LEASING_COMPANY:
        return {
          type: carOwner,
          email,
          phone,
          companyName,
          county,
          address,
          city,
          cui,
        };
    }
  };

  const postUserPhone = useCallback(
    (payload: { phone: string | undefined }, onSuccess?: (payload: Partial<IAuthUserModel>) => void) => {
      dispatch(postUserPhoneNumber(payload, onSuccess));
    },
    [dispatch],
  );

  const getUser = useCallback(
    (payload: number, onSuccess?: (payload: Partial<IAuthUserModel>) => void) => {
      dispatch(getUserById(payload, onSuccess));
    },
    [dispatch],
  );

  return { setPreLoginData, preLoginState, handleAfterLoginStatementUpdate, handleDifferentTypeOfOwners, postUserPhone, getUser };
};

export { usePreLogin };
