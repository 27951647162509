import { IRoute } from '../../interfaces/route.model';
import { ContactDetails } from '../../../../screens/insurance/ContactDetails';
import { InsurancePrompt } from '../../../../screens/insurance/InsurancePrompt';
import { PATHS, PROTECTED_ROUTES } from '../routes-constants';
import { PhotoEditor } from '../../../../screens/global/camera/PhotoEditor';

export const insuranceRoutes: IRoute = {
  baseUrl: '/',
  children: [
    {
      path: PROTECTED_ROUTES.insurancePhotoEdit,
      component: PhotoEditor,
      nextRoute: PROTECTED_ROUTES.photoEditor,
    },
    {
      path: PROTECTED_ROUTES.insuranceContact,
      component: ContactDetails,
      nextRoute: PATHS.currentStatement,
    },
    {
      path: PROTECTED_ROUTES.insurancePrompt,
      component: InsurancePrompt,
      nextRoute: PROTECTED_ROUTES.insurancePhoto,
    },
  ],
};
