import { useTranslation } from 'react-i18next';

import { useCurrentStatement } from './useCurrentStatement';

const useStepsStatusFunctions = () => {
  const { t } = useTranslation();
  const { currentStatement } = useCurrentStatement();
  const STEPS_CONSTANTS = {
    DOCUMENTS: t('statements.documents'),
    DAMAGE: t('statements.damage'),
    CIRCUMSTANCES: t('statements.circumstances'),
    WITNESSES: t('statements.witnesses'),
    GUILTY_DOCUMENTS: t('statements.guiltyDocuments'),
    CHECK_IDENTITY: t('statements.checkIdentity'),
  };

  const personInitialState = {
    identityCardId: null,
    insuranceId: null,
    drivingLicenseId: null,
    carIdentityId: null,
  };

  const { identityCardId, insuranceId, drivingLicenseId, carIdentityId } = currentStatement?.injured
    ? currentStatement?.injured
    : personInitialState;

  const isEvaluationDocumentsReady = () => {
    const { insuranceId, drivingLicenseId, carIdentityId, identityCardId } = currentStatement?.injured;

    const validInjuredDocuments = insuranceId && drivingLicenseId && carIdentityId && identityCardId;
    const validAccidentDocuments = currentStatement?.amicableFindingId || currentStatement?.policeReportId;

    const guiltyInsuranceID = currentStatement?.guilty?.insuranceId;

    return validInjuredDocuments && validAccidentDocuments && guiltyInsuranceID;
  };

  const handleDocuments = () => {
    return identityCardId && insuranceId && drivingLicenseId && carIdentityId;
  };

  const handleDamage = () => {
    const { damage } = currentStatement?.injured;

    if (damage?.photoProofs) return damage?.photoProofs?.length >= 8;

    return false;
  };

  const handleGuiltyDocuments = () => {
    const { identityCardId, insuranceId, drivingLicenseId, carIdentityId } = currentStatement?.injured
      ? currentStatement?.injured
      : personInitialState;

    return !!(identityCardId && insuranceId && drivingLicenseId && carIdentityId);
  };

  const handleCircumstances = () => {
    return !!(
      currentStatement?.accidentDate &&
      currentStatement?.accidentTime &&
      currentStatement?.accidentLocation &&
      currentStatement?.weatherCondition &&
      currentStatement?.roadCondition &&
      currentStatement?.description
    );
  };

  const handleWitnesses = () => {
    if (currentStatement?.injured?.witnesses) {
      if (currentStatement?.injured?.witnesses.length > 0) return true;
    }

    return false;
  };

  const handleIdentityCheck = (): boolean => {
    return !!currentStatement?.injured?.finalSignatureId && !!currentStatement.injured?.verifiedIdentity;
  };

  return {
    handleDocuments,
    STEPS_CONSTANTS,
    handleGuiltyDocuments,
    handleDamage,
    handleCircumstances,
    handleWitnesses,
    handleIdentityCheck,
    isEvaluationDocumentsReady,
  };
};

export { useStepsStatusFunctions };
