import React from 'react';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import { createStyles, makeStyles } from '@material-ui/core';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { DateType } from '@date-io/type';

const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    year: {
      background: 'linear-gradient(270deg, #813EDA 0%, #4123D7 100%)',
      padding: '2% 5%',
      borderRadius: '25px',
      cursor: 'pointer',
    },
    yearTypography: {
      color: 'white !important',
    },
  }),
);

export const CustomDatePickerToolbar = (props: ToolbarComponentProps): JSX.Element => {
  const classes = useStyles();

  const { date, isLandscape, openView, setOpenView } = props;

  const handleChangeViewClick = (view: any) => () => {
    setOpenView(view);
  };

  const getDayAndMonthLabel = (date: DateType) => {
    const weekDay = date.format('ddd');
    const month = date.format('MMM');
    const day = date.format('D');

    const dayOfWeek = weekDay.charAt(0).toUpperCase() + weekDay.substr(1);

    return `${dayOfWeek}, ${month} ${day}`;
  };

  return (
    <PickerToolbar className={classes.toolbar} isLandscape={isLandscape}>
      <ToolbarButton
        className={classes.year}
        label={date ? date.format('YYYY') : ''}
        selected={openView === 'year'}
        typographyClassName={classes.yearTypography}
        variant='subtitle1'
        onClick={handleChangeViewClick('year')}
      />
      <ToolbarButton
        label={date ? getDayAndMonthLabel(date) : ''}
        selected={openView === 'date'}
        variant='h4'
        onClick={handleChangeViewClick('date')}
      />
    </PickerToolbar>
  );
};
