import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Card, Paragraph, Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { USER_SITUATION, VEHICLE_OWNERSHIP } from '../../services/router/routeHandles';
import { IScreenComponentProps } from '../component-props.model';
import { $fontSizeS, $fontWeightBold, $lineHeightXL } from '../../styles/variables';
import { Close } from '../../assets/icons';
import { ScreenContainer } from '../../components/containers/ScreenContainer';

interface PropertyItem {
  title: string;
  cardTitle: string;
  subTitle: string;
}

type IProprietyContent = {
  [value in VEHICLE_OWNERSHIP]: PropertyItem;
};

export const OwnerNotIndividual = ({ location, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const legalPerson = location?.state?.legalPerson;
  const ownership = location?.state?.flowHandle as VEHICLE_OWNERSHIP;
  const propertyContent: IProprietyContent = {
    [VEHICLE_OWNERSHIP.LEGAL_PERSON]: {
      title: t('preLoginVehicleOwner.legalPerson'),
      cardTitle: t('preLoginVehicleOwner.carOwnedByCompany'),
      subTitle: t('preLoginVehicleOwner.powerAttorney'),
    },
    [VEHICLE_OWNERSHIP.LEASING_COMPANY]: {
      title: t('preLoginVehicleOwner.leasingCompany'),
      cardTitle: t('preLoginVehicleOwner.allowSelfCompensation'),
      subTitle: t('preLoginVehicleOwner.repairCar'),
    },
  };

  const onClick = () => {
    history.push(nextRoute.toString(), { flowHandle: USER_SITUATION.INJURED_STATUS, legalPerson });
  };

  return (
    <ScreenContainer headerProps={{ hideLogo: true, title: propertyContent[ownership]?.title }} showBottomNavbar={false}>
      <Card
        alignItems='center'
        borderRadius='10px'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='space-around'
        padding='10px 15px 15px 15px'
      >
        <Wrapper
          alignItems='center'
          backgroundColor='#E6E7F2'
          borderRadius='38px'
          display='flex'
          flexDirection='column'
          height='40px'
          justifyContent='center'
          position='absolute'
          right='8px'
          top='10px'
          width='41px'
          onClick={() => history.goBack()}
        >
          <Close fill='#7B66E3' height='1.125rem' width='1.125rem' />
        </Wrapper>

        <Wrapper
          alignItems='center'
          display='flex'
          flexDirection='column'
          height='45%'
          justifyContent='flex-start'
          marginTop='-20px 0 0 0'
          width='100%'
        >
          <Typography commonStyles={{ margin: '0 0 20px 0' }} fontWeight={$fontWeightBold} lineHeight={$lineHeightXL} variant='h4'>
            {propertyContent[ownership]?.cardTitle}
          </Typography>

          <Typography color='#8E8E8E' fontSize={$fontSizeS} variant='body'>
            {propertyContent[ownership]?.subTitle}
          </Typography>
        </Wrapper>

        <Wrapper display='flex' flexDirection='column' justifyContent='flex-end' width='100%'>
          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            width='100%'
            onClick={() => onClick()}
          >
            {t('commonText.continue')}
          </Button>

          <Button margin='15px 0 0 0' mode='link' onClick={() => history.goBack()}>
            <Paragraph color='#512AD8' fontSize={$fontSizeS} fontWeight={$fontWeightBold}>
              {t('commonText.goBack')}
            </Paragraph>
          </Button>
        </Wrapper>
      </Card>
    </ScreenContainer>
  );
};
