import { IRoute } from '../interfaces/route.model';
import {
  CheckInsuranceType,
  CheckOwnerStatus,
  ChooseInsurance,
  InitialInformationScreen,
  Login,
  OwnerNotIndividual,
  Register,
  Welcome,
} from '../../../screens';
import { AmicableFindings } from '../../../screens/home/AmicableFindings';
import { AuthSuccess } from '../../../screens/auth/AuthSuccess';
import { ImportantInformationsScreen } from '../../../screens/home/ImportantInformations';
import { ContactDetails } from '../../../screens/insurance/ContactDetails';
import { InformAssured } from '../../../screens/informAssured/InformAssured';

import { PATHS, PRE_LOGIN_ROUTES, PROTECTED_ROUTES } from './routes-constants';

export const preLoginRoutes: IRoute = {
  baseUrl: '/',
  children: [
    {
      path: PRE_LOGIN_ROUTES.root,
      component: Welcome, //FIRST SCREEN OF THE APPLICATION WELCOME SCREEN
      nextRoute: PRE_LOGIN_ROUTES.initialInformText, //SECOND SCREEN TO INFORM YOU ABOUT PP POLICY
      exact: true,
    },
    {
      path: PRE_LOGIN_ROUTES.initialInformText,
      component: InitialInformationScreen, //INFORMATIONAL SCREEN
      nextRoute: PRE_LOGIN_ROUTES.importantInformation,
    },
    {
      path: PRE_LOGIN_ROUTES.initialInformNotificationText,
      component: InitialInformationScreen, //INFORMATIONAL SCREEN
      // nextRoute: PRE_LOGIN_ROUTES.importantInformation, //THIRD SCREEN WITH IMPORTANT INFO ABOUT PP
    },
    {
      path: PRE_LOGIN_ROUTES.initialInformEvaluationText,
      component: InitialInformationScreen, //INFORMATIONAL SCREEN
      // nextRoute: PRE_LOGIN_ROUTES.importantInformation, //THIRD SCREEN WITH IMPORTANT INFO ABOUT PP
    },
    {
      path: PRE_LOGIN_ROUTES.importantInformation,
      component: ImportantInformationsScreen, //THIRD SCREEN WITH IMPORTANT INFO ABOUT PP
      nextRoute: PRE_LOGIN_ROUTES.insuranceType, //PROMPT SCREEN TO CHOOSE INSURANCE TYPE RCA/CASCO
    },
    {
      path: PRE_LOGIN_ROUTES.insuranceType,
      component: CheckInsuranceType, //PROMPT SCREEN TO CHOOSE INSURANCE TYPE RCA/CASCO
      nextRoute: PRE_LOGIN_ROUTES.amicableFindings, //DYNAMIC PROMPT SCREEN THAT INFLUENCES THE FLOW OF THE DOCUMENTS OBSERVATION NOTE/COMPENSATION OFFER
    },
    {
      path: PRE_LOGIN_ROUTES.amicableFindings,
      component: AmicableFindings, //DYNAMIC PROMPT SCREEN THAT INFLUENCES THE FLOW OF THE DOCUMENTS OBSERVATION NOTE/COMPENSATION OFFER
      //DOESN'T HAVE NEXT ROUTE BECAUSE ITS A COMPONENT WITH THE DATA SEPARATED BY THE MAIN COMPONENT WITH A MODAL AND WE PASS DATA IN A SWITCH
    },
    {
      path: PROTECTED_ROUTES.ownerStatus,
      component: CheckOwnerStatus, //PROMPT SCREEN TO CHOOSE THE OWNER STATUS (MYSELF/OTHER INDIVIDUAL/COMPANY/LEASING)
      nextRoute: {
        individual: PROTECTED_ROUTES.checkContactDetails, //IF YOU CHOOSE MYSELF OR INDIVIDUAL YOU GO HERE ***INFLUENCES THE FLOW
        company: PRE_LOGIN_ROUTES.ownerNotIndividualScreen, //IF YOU CHOOSE COMPANY OR LEASING YOU GO HERE ***INFLUENCES THE FLOW
      },
    },
    {
      path: PRE_LOGIN_ROUTES.ownerNotIndividualScreen,
      component: OwnerNotIndividual, //PROMPT SCREEN TO INFORM YOU ABOUT THE POLICY FOR NOT INDIVIDUAL PERSONS
      nextRoute: PROTECTED_ROUTES.checkContactDetails, //TAKES YOU TO THE CONTACT DETAILS FORM
    },
    {
      path: PROTECTED_ROUTES.checkContactDetails,
      component: ContactDetails, //CONTACT DETAILS FORM FOR ANY KIND OF OWNER
      nextRoute: PATHS.currentStatement, //SCREEN TO PICK THE INSURANCE COMPANY FOR BOTH PERSONS INVOLVED INTO THE ACCIDENT
    },
    {
      path: PRE_LOGIN_ROUTES.informAssured,
      component: InformAssured, //SCREEN TO PICK THE INSURANCE COMPANY FOR BOTH PERSONS INVOLVED INTO THE ACCIDENT
      nextRoute: PRE_LOGIN_ROUTES.login, //LOGIN
    },
    {
      path: PRE_LOGIN_ROUTES.chooseInsuranceCompany,
      component: ChooseInsurance, //SCREEN TO PICK THE INSURANCE COMPANY FOR BOTH PERSONS INVOLVED INTO THE ACCIDENT
      nextRoute: PRE_LOGIN_ROUTES.login, //LOGIN
    },
    {
      path: PRE_LOGIN_ROUTES.login,
      component: Login, //LOGIN
      nextRoute: PRE_LOGIN_ROUTES.loginSuccess, //INTERMEDIATE SCREEN FOR THE REQUEST BETWEEN FRONT AND BACK
    },
    {
      path: PRE_LOGIN_ROUTES.register,
      component: Register, //REGISTER
      nextRoute: PRE_LOGIN_ROUTES.loginSuccess, //INTERMEDIATE SCREEN FOR THE REQUEST BETWEEN FRONT AND BACK
    },
    {
      path: PRE_LOGIN_ROUTES.loginSuccess,
      component: AuthSuccess, //INTERMEDIATE SCREEN FOR THE REQUEST BETWEEN FRONT AND BACK
    },
  ],
};
