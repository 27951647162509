import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CardButton, TabContent } from '../../styled-components';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { CheckedRoundFilledIcon } from '../../assets/icons';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { useProgressEvaluationAndStatement } from '../../constants/dataConstants/evaluationSteps';
import { useStatements } from '../../hooks/useStatements';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PaymentModal } from '../paymentModal';
import { useCardManagement } from '../../hooks/useCardManagement';
import { CARD_TYPE, StatementOrEvaluationStep, STATUS_CARD } from '../../@types/statement-evaluation-cards/statementOrEvaluationCardsTypes';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { userFinishNotificationRequest } from '../../services/api/createStatement/createStatement.api';
import { MOBILPAY_STATUSES } from '../global/constants';
import { STATEMENT_TYPES } from '../../store/types';
import { NOTIFICATION_STATUS } from '../../constants/typeConstants/statementStatusType';
import { PATHS, PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { fetchError, fetchStart, fetchSuccess } from '../../store/statement/statement.slice';
import { COMPLETED_DOCUMENTS_TYPE } from '../../@types/questions-flow-handler/questionsFlowHandler';

export const StatementAndEvaluationsDetails = ({ history, parentRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { getStepsByType } = useProgressEvaluationAndStatement();
  const { getStatementById } = useStatements();
  const { currentStatementId } = useParams<{ currentStatementId: string | undefined }>();
  const { currentStatement } = useCurrentStatement();
  const { setQuestionsFlowHandler, questionsFlowHandlerState } = useQuestionsFlowHandler();
  const dispatch = useDispatch();

  const { getButtonLabelByStatus } = useCardManagement(currentStatement.status);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const steps = getStepsByType(currentStatement.type);
  const allStepsAreCompleted = steps.filter(step => step.status !== STATUS_CARD.COMPLETE).length === 0;
  const paymentIsDone = currentStatement?.paymentOrder?.status === MOBILPAY_STATUSES.CONFIRMED;
  const isNotification = currentStatement.type === STATEMENT_TYPES.NOTIFICATION;
  const notificationIsIncomplete = currentStatement.status === NOTIFICATION_STATUS.NOTIFICATION_INCOMPLETE;

  const headerTextByType = {
    [STATEMENT_TYPES.NOTIFICATION]: t('statements.notificationSteps'),
    [STATEMENT_TYPES.STATEMENT]: t('statements.statementSteps'),
    [STATEMENT_TYPES.EVALUATION]: t('statements.evaluationSteps'),
  };

  useEffect(() => {
    const preLoginAnswers = {
      damageTypeQuestion: currentStatement.damageTypeQuestion,
      observationNote: currentStatement.observationNote,
      compensationOffer: currentStatement.compensationOffer,
      completedDocumentsAfterAccident: null,
    };

    if (currentStatement.statementID) {
      getStatementById(currentStatement.statementID);
      setQuestionsFlowHandler(preLoginAnswers);
    } else if (currentStatementId) {
      getStatementById(Number(currentStatementId));
      setQuestionsFlowHandler(preLoginAnswers);
    } else {
      getStatementById(Number(currentStatementId));
      setQuestionsFlowHandler(preLoginAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const evaluationStepsCondition = (equal?: boolean) => {
    return getStepsByType(currentStatement.type).find((evaluation: StatementOrEvaluationStep): boolean => {
      if (equal) return evaluation.status === STATUS_CARD.INCOMPLETE;

      return evaluation.status !== STATUS_CARD.INCOMPLETE;
    });
  };

  const navigateTo = (path?: string): void => {
    history.push(`${parentRoute}${path}`);
  };

  const handleIncompleteNavigation = (step: StatementOrEvaluationStep) => {
    /** In case if user select to add documents, verify if accident documents is already selected */
    if (step.type === CARD_TYPE.INJURED_DOCUMENTS) {
      const accidentDocument = questionsFlowHandlerState.completedDocumentsAfterAccident;

      const alreadySelected =
        (accidentDocument === COMPLETED_DOCUMENTS_TYPE.AMICABLE_FINDING && currentStatement?.amicableFindingId) ||
        (accidentDocument === COMPLETED_DOCUMENTS_TYPE.POLICE_REPORT && currentStatement?.policeReportId);

      if (alreadySelected) return navigateTo(PATHS.documents);
    }

    navigateTo(step.navigateTo.new);
  };

  const onStatementContinue = async () => {
    if (!currentStatement.statementID) {
      return;
    }

    const currentEvaluation = evaluationStepsCondition(true);

    if (currentEvaluation?.status !== STATUS_CARD.COMPLETE && currentEvaluation !== undefined) {
      return handleIncompleteNavigation(currentEvaluation);
    }

    // Redirect on "information sent successfully" prompt
    if (!currentEvaluation && isNotification) {
      try {
        dispatch(fetchStart());
        await userFinishNotificationRequest(currentStatement?.statementID);
        dispatch(fetchSuccess());
        history.push(
          generatePath(PROTECTED_ROUTES.informationSendSuccessfully, {
            currentStatementId: currentStatement.statementID,
          }),
        );
      } catch (e) {
        dispatch(fetchError(e.message));
      }

      return;
    }

    setShowPaymentModal(true);
  };

  const cardNavigation = (card: StatementOrEvaluationStep): void => {
    if (STATUS_CARD.COMPLETE === card.status) navigateTo(card.navigateTo.list);
    else handleIncompleteNavigation(card);
  };

  const getAttachmentUrl = (filePath: string) => {
    const getBaseName = (path: string) => path.substring(path.lastIndexOf('/') + 1);
    const fileName = getBaseName(filePath);

    return `${process.env.REACT_APP_ATTACHMENTS_DOWNLOAD_PATH}/${fileName}`;
  };

  const openEvaluationDocument = () => {
    if (currentStatement?.evaluationDocument) {
      window.open(getAttachmentUrl(currentStatement?.evaluationDocument?.path), '_blank');
    }
  };

  const showContinueButton = !allStepsAreCompleted || (!isNotification && !paymentIsDone) || notificationIsIncomplete;
  const showPreviewEvaluationButton = currentStatement?.evaluationDocument;
  const headerText = headerTextByType[currentStatement.type];

  const getBackURL = () => {
    if (currentStatement?.type === STATEMENT_TYPES.NOTIFICATION) return PATHS.notifications;
    else if (currentStatement?.type === STATEMENT_TYPES.EVALUATION) return PATHS.evaluations;

    return undefined;
  };

  const backUrl = getBackURL();

  return (
    <>
      <ScreenContainer
        headerProps={{ title: headerText, hideLogo: true, backUrl }}
        isModalOpen={showPaymentModal}
        modal={<PaymentModal setShowModal={setShowPaymentModal} />}
        modalProps={{ margin: 'auto 20px', show: showPaymentModal, onClose: () => setShowPaymentModal(false) }}
      >
        <TabContent className='active'>
          {steps.map((card: StatementOrEvaluationStep, index: number) => {
            const isNotLast = index + 1 !== steps.length;

            return (
              <CardButton
                key={`injured-card-${card.id}`}
                backgroundColor='transparent'
                border='none'
                iconLeft={<CheckedRoundFilledIcon fill={STATUS_CARD.COMPLETE === card.status ? '#2da455' : '#aeaeae'} />}
                minHeight='5rem'
                status={card.status}
                title={card.title}
                verticalLine={isNotLast}
                onClick={() => {
                  localStorage.setItem('documentsFlowHandle', card.type);
                  cardNavigation(card);
                }}
              />
            );
          })}
        </TabContent>

        {showContinueButton && (
          <Button mode='primary' style={{ marginTop: 'auto' }} onClick={onStatementContinue}>
            {getButtonLabelByStatus()}
          </Button>
        )}

        {showPreviewEvaluationButton && (
          <Button mode='primary' style={{ marginTop: 'auto' }} onClick={openEvaluationDocument}>
            Vizualizează evaluarea
          </Button>
        )}
      </ScreenContainer>
    </>
  );
};
