import styled from 'styled-components';
import React from 'react';

export interface ITabsProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode | Element[];
  backgroundColor?: string;
  boxShadow?: string;
  tabsContainerPadding?: string;
  heightTab?: string;
  fontSize?: string;
  borderRadiusTab?: string;
  activeTabBgColor?: string;
  activeTabColor?: string;
  tabColor?: string;
}

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 76px;
  position: relative;
  background: ${({ backgroundColor }: ITabsProps) => backgroundColor || '#fff'};
  box-shadow: ${({ boxShadow }: ITabsProps) => boxShadow || '0 4px 4px rgba(0, 0, 0, 0.1)'};
  padding: ${({ tabsContainerPadding }: ITabsProps) => tabsContainerPadding || '0'};
  z-index: 10;
  width: 100%;
`;

const StyledTabContent = styled.div`
  position: relative;
  width: 100%;
  display: none;
  color: red;
  &.active {
    display: block;
  }
`;
const StyledTab = styled.div`
  cursor: pointer;
  height: ${({ heightTab }: ITabsProps) => heightTab || '100%'};
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }: ITabsProps) => fontSize || '16px'};
  line-height: 19.5px;
  font-weight: 700;
  text-align: center;
  color: ${({ tabColor }: ITabsProps) => tabColor || '#9a9a9a'};
  border-radius: ${({ borderRadiusTab }: ITabsProps) => borderRadiusTab || 'none'};

  &.active {
    color: ${({ activeTabColor }: ITabsProps) => activeTabColor || '#512ad8'};
    background-color: ${({ activeTabBgColor }: ITabsProps) => activeTabBgColor || 'unset'};
  }
`;

export const TabContent = (props: ITabsProps): JSX.Element => <StyledTabContent {...props}>{props.children}</StyledTabContent>;

export const Tabs = (props: ITabsProps): JSX.Element => {
  return (
    <StyledContainer backgroundColor={props.backgroundColor} boxShadow={props.boxShadow} tabsContainerPadding={props.tabsContainerPadding}>
      {props.children}
    </StyledContainer>
  );
};

export const Tab = (props: ITabsProps): JSX.Element => {
  return <StyledTab {...props}>{props.children}</StyledTab>;
};
