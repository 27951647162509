import React, { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';

import { ArrowLeftIcon, Close } from '../../assets/icons';

const StyledSearchBar = styled.div`
  height: 56px;
  width: 100%;
  background: #ffffff;
  position: relative;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

export const StyledTextField = styled.input`
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 53px;
  width: 75%;
  border: none;
  outline: none;

  border-bottom: 1px solid rgb(81, 42, 216);
  height: 30px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(81, 42, 216);
    opacity: 1; /* Firefox */
    font-weight: normal;
  }
`;

const StyledButton = styled.button`
  height: 56px;
  width: 56px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
`;

interface ISearchBar {
  value: string;
  onChange: (text: string) => void;
  onBackButtonPress: () => void;
  onCloseButtonPress: () => void;
}

export const SearchBar: React.FC<ISearchBar> = props => {
  const { value, onChange, onBackButtonPress, onCloseButtonPress } = props;

  const inputRef = useRef(null);

  return (
    <StyledSearchBar>
      <StyledButton onClick={onBackButtonPress}>
        <ArrowLeftIcon />
      </StyledButton>
      <StyledTextField
        ref={inputRef}
        placeholder='Cauta...'
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      />
      <StyledButton style={{ position: 'absolute', right: '0' }} onClick={onCloseButtonPress}>
        <Close fill='#512AD8' />
      </StyledButton>
    </StyledSearchBar>
  );
};
