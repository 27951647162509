import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Paragraph, Title } from '../../styled-components';
import { TextField } from '../../components/text_field';
import { Button } from '../../components/button';
import { $fontSizeL, $fontWeightBase, $fontWeightBold, $lineHeightL, $lineHeightM } from '../../styles/variables';
import { PROFILE_TEXTS } from '../../translations/ro/home';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { RootState } from '../../store/rootReducer';
import { usePreLogin } from '../../hooks/use-preLogin';
import { IAuthUserModel } from '../../store/auth-user/auth.model';
import { logUserAction } from '../../store/auth-user/auth.action';
import { logOutAction } from '../../store/app/app.action';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { IScreenComponentProps } from '../component-props.model';
import { PHONE_REGEX } from '../../shared/constants';

export const Profile = ({ history }: IScreenComponentProps): JSX.Element => {
  const authData = useSelector((state: RootState) => state.authUser);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(authData?.data?.phone);
  const { postUserPhone, getUser } = usePreLogin();
  const [editPressed, setEditPressed] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const hasMissingData = !authData?.data?.hasOwnProperty('phone') || !authData?.data?.hasOwnProperty('picture');

    if (!!authData?.data && hasMissingData) {
      getUser(authData?.data?.id, data => {
        if (authData.data) {
          const payload: IAuthUserModel = {
            id: Number(authData?.data?.id),
            name: authData.data.name,
            email: authData.data.email,
            sessionToken: authData.data.sessionToken,
            phone: data.phone,
            picture: data.picture,
          };
          dispatch(logUserAction(payload));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getUser]);

  const onInputChange = (element: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(element.target.value.replace(/[^+|0-9]/g, ''));
  };

  const logOut = () => {
    if (authData?.data) {
      dispatch(logOutAction());
      history.push(generatePath(PRE_LOGIN_ROUTES.login));
    }
  };

  const onSubmit = () => {
    if (phoneNumber) {
      if (authData.data) {
        const payload: IAuthUserModel = {
          id: Number(authData?.data?.id),
          name: authData?.data?.name,
          email: authData?.data?.email,
          sessionToken: authData?.data?.sessionToken,
          phone: phoneNumber,
          picture: authData?.data?.picture,
        };
        postUserPhone({ phone: payload.phone });
        dispatch(logUserAction(payload));
      }
      setEditPressed(false);
    }
  };

  const isCorrectPhoneNumber = () => (phoneNumber && phoneNumber.match(PHONE_REGEX) ? false : true);

  return (
    <ScreenContainer
      backgroundColor='#fff'
      bodyProps={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0 20px',
      }}
      headerProps={{
        showProfilePicture: true,
        hideLogo: true,
        height: '135px',
        backgroundColor: 'none',
        gradient: 'linear-gradient(to left, #563add , #8c51df)',
      }}
      headerType='dark'
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <Title
          color='#1E1E29'
          fontSize={$fontSizeL}
          fontWeight={$fontWeightBase}
          lineHeight={$lineHeightL}
          padding='20px 0 0 0'
          textAlign='left'
          textTransform='initial'
        >
          {authData?.data?.name}
        </Title>
        <div style={{ margin: '20px 0 0 0' }}>
          <TextField
            label={PROFILE_TEXTS.email}
            style={{ borderRadius: 26, padding: '0 10px', borderColor: '#E7E7E7' }}
            type='text'
            value={authData?.data?.email || ''}
            variant='outlined'
            disabled
          />
        </div>
        <div style={{ margin: '20px 0 0 0' }}>
          <TextField
            label={PROFILE_TEXTS.telephone}
            style={{ borderRadius: 26, padding: '0 10px', borderColor: '#E7E7E7' }}
            type='text'
            value={phoneNumber || ''}
            variant='outlined'
            onChange={onInputChange}
          />

          <Button disabled={isCorrectPhoneNumber()} margin='50px 0' mode='primary' type='button' onClick={() => onSubmit()}>
            <Paragraph
              color={isCorrectPhoneNumber() ? 'grey' : 'white'}
              fontFamily='Roboto'
              fontSize='15px'
              fontWeight={$fontWeightBold}
              lineHeight={$lineHeightM}
              textAlign='center'
            >
              {t('commonText.save')}
            </Paragraph>
          </Button>
        </div>
      </div>
      <Button mode='link' style={{ marginTop: '0', marginBottom: '70px' }} onClick={() => logOut()}>
        <Paragraph
          color='#512AD8'
          fontFamily='Roboto'
          fontSize='15px'
          fontWeight={$fontWeightBold}
          lineHeight={$lineHeightM}
          margin='10px 0 20px 0'
        >
          {PROFILE_TEXTS.logOut}
        </Paragraph>
      </Button>
    </ScreenContainer>
  );
};
