import React, { useEffect, useState } from 'react';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Container, Typography } from '../../styled-components';
import { Button } from '../../components/button';
import { HOME_TEXTS } from '../../translations/ro/home';
import { IScreenComponentProps } from '../component-props.model';
import { TermsModal } from '../../components/terms-modal';
import { useStatements } from '../../hooks/useStatements';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';

export const EvaluationMethod = ({ history }: IScreenComponentProps): JSX.Element => {
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | null>(null);
  const { getStatementById } = useStatements();
  const { currentStatement } = useCurrentStatement();

  useEffect(() => {
    if (currentStatement.statementID) {
      getStatementById(currentStatement.statementID);
    }

    if (currentStatement?.paymentDetails?.amount) setAmount(currentStatement?.paymentDetails?.amount);
  }, [currentStatement?.paymentDetails?.amount]);

  return (
    <ScreenContainer
      headerType='none'
      isModalOpen={modalActive}
      modal={<TermsModal history={history} />}
      modalProps={{ show: modalActive, onClose: () => setModalActive(false) }}
    >
      <Container
        style={{
          backgroundColor: '#fff',
          borderRadius: 10,
          padding: '20px 15px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '0px 2px 15px rgba(134, 112, 251, 0.2)',
          marginBottom: 20,
        }}
      >
        <Typography align='center' fontSize='18px' fontWeight='700' variant='h4'>
          {HOME_TEXTS.getPayPactMoney}
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
          <Typography color='#8e8e8e' extendedStyles={{ lineHeight: '20px' }} fontSize='12px' variant='body'>
            {HOME_TEXTS.damageValue}:
          </Typography>

          <Typography color='#8e8e8e' extendedStyles={{ lineHeight: '20px' }} fontSize='12px' variant='body'>
            {`${amount} ${HOME_TEXTS.currency}`}
          </Typography>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography color='#8e8e8e' extendedStyles={{ lineHeight: '20px' }} fontSize='12px' variant='body'>
            {HOME_TEXTS.paypactProcessing}:
          </Typography>

          <Typography color='#8e8e8e' extendedStyles={{ lineHeight: '20px' }} fontSize='12px' variant='body'>
            {`${amount && amount / 10} ${HOME_TEXTS.currency}`} {HOME_TEXTS.currency}
          </Typography>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 20 }}>
          <Typography extendedStyles={{ lineHeight: '20px' }} fontSize='18px' variant='h5'>
            {HOME_TEXTS.totalTransfer}:
          </Typography>

          <Typography extendedStyles={{ lineHeight: '20px' }} fontSize='18px' variant='h5'>
            {`${amount} ${HOME_TEXTS.currency}`}
          </Typography>
        </div>

        <Button margin='0 0 25px 0' mode='primary' onClick={() => setModalActive(true)}>
          {HOME_TEXTS.accept}
        </Button>

        <Typography align='center' color='#a1a4b2' fontSize='12px' variant='body'>
          {HOME_TEXTS.whatIs}{' '}
          <Button color='#fabe0b' mode='link' style={{ fontSize: '12px', paddingLeft: 0 }}>
            {HOME_TEXTS.thePaypactProcessing}
          </Button>
        </Typography>
      </Container>

      <Container
        style={{
          backgroundColor: '#fff',
          borderRadius: 10,
          padding: '20px 15px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '0px 2px 15px rgba(134, 112, 251, 0.2)',
        }}
      >
        <Typography align='center' commonStyles={{ margin: '0 0 20px 0' }} fontSize='18px' fontWeight='700' variant='h4'>
          {HOME_TEXTS.getBrokerMoney}
        </Typography>

        <Typography color='#8e8e8e' commonStyles={{ margin: '0 0 20px 0' }} fontSize='12px' variant='body'>
          {HOME_TEXTS.brokerMoneyNotice}
        </Typography>
      </Container>
    </ScreenContainer>
  );
};
