import React from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useSelector } from 'react-redux';

import { Image, Typography, Wrapper } from '../../styled-components';
import CarsCollision from '../../assets/images/cars-collision.jpg';
import { IScreenComponentProps } from '../component-props.model';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { ButtonCard } from '../../styled-components/button-card';
import { EvaluateIcon } from '../../assets/icons/EvaluateIcon';
import { currentNotificationInitialState, currentStatementInitialState } from '../../store/statement/initialState';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { RootState } from '../../store/rootReducer';
import { Button } from '../../components/button';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { STATEMENT_TYPES } from '../../store/types';

export const Welcome = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);
  const { t } = useTranslation();
  const { setCurrentStatementData } = useCurrentStatement();
  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();

  const goNext = () => {
    history.push(generatePath(nextRoute.toString()));
  };

  return (
    <>
      <ScreenContainer
        backgroundColor='#F7F8FA'
        bodyProps={{
          padding: '0px',
          justifyContent: 'flex-start',
        }}
        headerType='none'
        showBottomNavbar={isLoggedIn}
      >
        <Wrapper padding='0' width='100%'>
          <Image src={CarsCollision} width='100%' />
        </Wrapper>

        <Typography align='center' commonStyles={{ margin: '30px 0 0 0' }} fontWeight='700' variant='h3'>
          {t('welcome.didYouHadAnAccident')}
        </Typography>
        <Wrapper display='flex' flexDirection='column' height='100%' justifyContent='space-between' padding='20px'>
          <div>
            <ButtonCard
              description={t('welcome.notificationDescription')}
              discountPrice='GRATUIT'
              icon={<NotificationsNoneIcon />}
              normalPrice='50 RON'
              title={t('welcome.notificationTitle')}
              onClick={() => {
                goNext();
                setCurrentStatementData(currentNotificationInitialState);
                setQuestionsFlowHandler({
                  type: STATEMENT_TYPES.NOTIFICATION,
                });
              }}
            />
            <ButtonCard
              description={t('welcome.evaluationDescription')}
              discountPrice='150 RON'
              icon={<EvaluateIcon />}
              normalPrice='200 RON'
              title={t('welcome.evaluationTitle')}
              onClick={() => {
                goNext();
                setCurrentStatementData(currentStatementInitialState);
                setQuestionsFlowHandler({
                  type: STATEMENT_TYPES.EVALUATION,
                });
              }}
            />
          </div>
          <div>
            <Button
              style={{
                background: 'linear-gradient(#7433ff,#ffa3fd)',
                color: 'white',
                padding: '0 30px',
              }}
              onClick={() => history.push({ pathname: PRE_LOGIN_ROUTES.login })}
            >
              {isLoggedIn ? 'Mergi spre Dashboard' : 'Logheaza-te'}
            </Button>
          </div>
          <Wrapper backgroundColor='#F7F8FA' marginBottom='10px' marginTop='10px'>
            <a href='https://www.paypact.ro'>www.paypact.ro</a>
          </Wrapper>
        </Wrapper>
      </ScreenContainer>
    </>
  );
};
