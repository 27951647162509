import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { SelectionType } from '../../../screens/statement/CircumstancesSelection';
import { Typography, Wrapper } from '../../../styled-components';
import { RadioFields } from '../../forms-selection/RadioFields';
import { Button } from '../../button';
import { CheckboxGroup } from '../../forms-selection/CheckboxGroup';
import { ICheckOption } from '../../../constants/dataConstants/circumstances';

interface IFormProps {
  onSubmit: (values: ICheckOption[]) => void;
  formValues: ICheckOption[];
  title: string;
  type: SelectionType;
  onChangeCheckbox?: (element: ICheckOption, checked: boolean, index: number) => void;
  onChangeRadioField?: (element: number | string | undefined, index: number) => void;
  onChangeChildValue?: (element: ICheckOption, value: string, index: number) => void;
}

export const CircumstancesSelectionForm = ({
  onSubmit,
  formValues,
  title,
  type,
  onChangeCheckbox,
  onChangeChildValue,
}: IFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [invalid, setInvalid] = useState(true);

  const isItemsListInValid = () => {
    const isSelectedAtLeastOneItem = !formValues.some((item: ICheckOption) => item.checked);
    const childElementsCompleted = !!formValues.find((item: ICheckOption) => item.checked && item.hasChild && !item.childValue);

    return isSelectedAtLeastOneItem || childElementsCompleted;
  };

  useEffect(() => {
    setInvalid(isItemsListInValid());
  }, [formValues]);

  const handleChangeCheckboxChild = (event: React.ChangeEvent<HTMLTextAreaElement>, parentEl: ICheckOption, index: number) => {
    const value = event.target.value;

    if (onChangeChildValue) onChangeChildValue(parentEl, value, index);

    if (parentEl.checked && !value) setInvalid(true);
    else setInvalid(false);
  };

  return (
    <Formik initialValues={formValues} style={{ width: '100%' }} enableReinitialize validateOnMount onSubmit={onSubmit}>
      {({ handleChange, handleBlur, isValid, setFieldValue }) => (
        <Form
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Wrapper display='flex' flexDirection='column'>
            <Typography fontWeight='700' variant='h4'>
              {title}
            </Typography>
            {type === 'radio' && (
              <Wrapper
                alignItems='flex-start'
                display='flex'
                flexDirection='column'
                justifyContent='initial'
                margin='10px 0 0 0'
                width='100%'
              >
                <RadioFields
                  childName='accidentCircumstanceDescription'
                  childPlaceholder={t('circumstances.circumstanceDescriptionText')}
                  fields={formValues}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleValidation={(value: boolean) => setInvalid(!value)}
                  name='accidentCircumstance'
                />
              </Wrapper>
            )}

            {type === 'checkbox' && (
              <Wrapper alignItems='flex-start' display='flex' flexDirection='column' justifyContent='initial' margin='10px 0' width='100%'>
                <CheckboxGroup
                  checkboxes={formValues}
                  childName='accidentCircumstanceDescription'
                  childPlaceholder={t('circumstances.circumstanceDescriptionText')}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleChangeChild={handleChangeCheckboxChild}
                  onChange={(el: ICheckOption, value: boolean, index: number) => {
                    if (onChangeCheckbox) {
                      onChangeCheckbox(el, value, index);
                      setInvalid(isItemsListInValid);
                    }
                  }}
                />
              </Wrapper>
            )}
          </Wrapper>

          <Button borderRadius='25px' disabled={type !== 'text' && invalid} mode='primary' type='submit'>
            {t('commonText.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
