import styled from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';

export const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: scroll;
  z-index: 1000;

  .MyLoader_overlay {
    background: rgba(19, 18, 18, 0.5);
  }

  &.MyLoader_wrapper--active {
    overflow: hidden;
    pointer-events: none;
  }
`;
