import React from 'react';

import { IIconProps } from './icon.model';

export const FacebookIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 21} viewBox='0 0 11 21' width={width || 21} {...otherProps}>
      <path
        d='M10.5 7.35H6.983v-2.1c0-1.084.084-1.766 1.56-1.766h1.863V.145A24.718 24.718 0 007.675 0C4.968 0 2.993 1.74 2.993 4.934V7.35H0v4.2l2.993-.001V21h3.99v-9.453l3.06-.001.457-4.196z'
        fill={fill || '#fff'}
      />
    </svg>
  );
};
