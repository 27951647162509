import { useTranslation } from 'react-i18next';

import { USER_SITUATION } from '../../services/router/routeHandles';
import { preLoginInsuranceInitialState } from '../../store/pre-login/initialState';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';

interface PropertyItem {
  pageTitle: string;
  title: string;
  name?: string;
  props?: Record<string, string | boolean>;
}

type IProprietyContent = {
  [value in USER_SITUATION]: PropertyItem;
};

const ChooseInsuranceConstants = () => {
  const { t } = useTranslation();
  const { questionsFlowHandlerState } = useQuestionsFlowHandler();
  const initialInsuranceState = {
    injuredInsurance: preLoginInsuranceInitialState,
    guiltyInsurance: preLoginInsuranceInitialState,
  };
  const handleDamageTypeChange = () => {
    if (questionsFlowHandlerState.damageTypeQuestion !== null) return questionsFlowHandlerState.damageTypeQuestion.toString();

    return 'N/A';
  };

  const INSURANCE_SWITCH_RESPONSE = {
    CAN_HELP: 'CAN HELP INJURED',
    CANT_HELP: "CAN'T HELP INJURED",
    CANT_HELP_WITH_FILE: "CAN'T HELP WITH FILE",
  };

  const digitalStatementFlow: IProprietyContent = {
    [USER_SITUATION.INJURED_STATUS]: {
      pageTitle: t('chooseInsurance.injuredInsurer'),
      title: t('chooseInsurance.chooseInjuredInsurer').replace('#DAMAGE_TYPE#', handleDamageTypeChange()),
      name: t('chooseInsurance.injuredName'),
      props: { color: '#2EA454' },
    },
    [USER_SITUATION.GUILTY_STATUS]: {
      pageTitle: t('chooseInsurance.guiltyInsurer'),
      title: t('chooseInsurance.chooseGuiltyInsurer').replace('#DAMAGE_TYPE#', handleDamageTypeChange()),
      name: t('chooseInsurance.guiltyName'),
      props: { color: '#EB7A0C' },
    },
  };

  return { initialInsuranceState, INSURANCE_SWITCH_RESPONSE, digitalStatementFlow };
};

export { ChooseInsuranceConstants };
