import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { generatePath } from 'react-router-dom';

import { Image } from '../../styled-components';
import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../button';
import { putStatementDocuments } from '../../store/statement/currentStatement.api';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

import { PdfDocument } from './pdf-document';

interface IProps {
  setShowModal?: (showModal: boolean) => void;
  history: H.History<any>;
}

export const PowerOfAttorneyModal = ({ history, setShowModal }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [openImage, setOpenImage] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadedPdf, setUploadedPdf] = useState<string>('');
  const [uploadedPicture, setUploadedPicture] = useState<string>('');
  const { currentStatement } = useCurrentStatement();

  const [isFileSelected, setIsFileSelected] = useState<string | null>(null);

  const handleDocumentUpload = useCallback(
    event => {
      setIsFileSelected(event.currentTarget.value);
      const converted = URL.createObjectURL(event.target.files[0]);

      if (event.target.files[0].type === 'application/pdf') {
        setOpenPdf(true);
        setUploadedPdf(converted);
      } else {
        setOpenImage(true);
        setUploadedPicture(converted);
      }
    },
    [inputRef],
  );

  const handleClose = () => {
    setOpenPdf(false);
    history.push(
      generatePath(`${PROTECTED_ROUTES.congratulations}`, {
        currentStatementId: String(currentStatement.statementID),
      }),
    );
  };

  const uploadDocument = () => {
    inputRef?.current?.click();
    setOpenPdf(false);
    setOpenImage(false);
  };

  const createFileFromBlob = async (document: any): Promise<any> => {
    return await fetch(document).then(response =>
      response.blob().then(myBlob => {
        return new File([myBlob], 'pdf' as string, {
          type: 'image/jpeg',
        });
      }),
    );
  };

  const updatePicture = async () => {
    return createFileFromBlob(uploadedPdf).then(res => {
      const formData = new FormData();
      formData.append('file', res);
      formData.append('id', currentStatement?.statementID?.toString() as string);
      formData.append('type', 'powerOfAttorney');
      putStatementDocuments(formData)
        .then(() => {
          history.push(
            generatePath(`${PROTECTED_ROUTES.congratulations}`, {
              currentStatementId: String(currentStatement.statementID),
            }),
          );
        })
        .catch(e => e);
      setOpenImage(false);
    });
  };

  return (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='flex-start'>
        <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='space-between'>
          <Typography commonStyles={{ margin: 'auto' }} fontWeight='700' variant='h2'>
            {t('commonText.uploadPowerOfAttorney')}
          </Typography>
          <Typography color='#8e8e8e' commonStyles={{ margin: '0 0 20px 0' }} fontSize='12px' variant='body'>
            {t('commonText.uploadPowerOfAttorney2')}
          </Typography>
        </Wrapper>
        <Wrapper display='flex' flexDirection='column' justifyContent='center' padding='0'>
          {openImage && (
            <Wrapper display='flex' flexDirection='column' justifyContent='center' padding='0'>
              <Image height='auto' src={uploadedPicture} width='100%' />
              <Button
                style={{ margin: '20px auto', padding: '0 10px', display: 'block', borderRadius: '25px', height: '30px', width: 'auto' }}
                onClick={() => {
                  updatePicture();
                }}
              >
                Confirmă document
              </Button>
            </Wrapper>
          )}
          {openPdf && <PdfDocument handleClose={handleClose} uploadedPdf={uploadedPdf} />}
          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            width='100%'
            onClick={() => uploadDocument()}
          >
            + {t('commonText.uploadPowerOfAttorney')}
          </Button>

          <Button
            margin='15px 0 0 0'
            mode='link'
            onClick={() => {
              if (setShowModal) {
                setShowModal(false);
              }
            }}
          >
            <Typography color='#512AD8' variant='h5'>
              {t('commonText.goBack')}
            </Typography>
          </Button>
        </Wrapper>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start' />
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type='file'
        value={isFileSelected ? '' : ''}
        onChange={event => handleDocumentUpload(event)}
      />
    </>
  );
};
