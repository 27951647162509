import { PayloadAction } from '@reduxjs/toolkit';

import { createGenericSlice } from '../store.constants';
import { QuestionsFlowHandler } from '../../@types/questions-flow-handler/questionsFlowHandler';

type IQuestionsFlowHandler = {
  data: QuestionsFlowHandler;
  isLoading: boolean;
  error: string | null;
};

export const initialState: IQuestionsFlowHandler = {
  data: {} as QuestionsFlowHandler,
  isLoading: false,
  error: null,
};

const questionsFlowHandlerSlice = createGenericSlice({
  name: 'questionsFlowHandler',
  initialState,
  reducers: {
    setQuestionsFlowHandlerAction(state: IQuestionsFlowHandler, action: PayloadAction<QuestionsFlowHandler>) {
      state.data = action.payload;
    },
  },
});

export default questionsFlowHandlerSlice.reducer;
export const { setQuestionsFlowHandlerAction } = questionsFlowHandlerSlice.actions;
