import { AxiosResponse } from 'axios';
import { Omit } from 'react-redux';

import {
  ICurrentStatement,
  IPayment,
  IPaymentDTO,
  IStatementBackResponse,
  IStatementDbFormat,
} from '../../@types/current-statement/currentStatementTypes';
import { IWitness } from '../../@types/witnesses/witnessesTypes';
import { IPhotoProofs, IStatementDamage } from '../../@types/damage/damageType';
import { authorizedRequest, requestMedia } from '../../services/api';

export function getStatementByIdRequest(id: number): Promise<AxiosResponse<IStatementDbFormat>> {
  return authorizedRequest().get(`/statements/${id}`);
}

export function postStatementByIdRequest(statement: ICurrentStatement): Promise<ICurrentStatement> {
  return authorizedRequest().post(`/statements`, statement);
}

export function getWitnessesByStatementId(id: number): Promise<AxiosResponse<IWitness[]>> {
  return authorizedRequest().get(`/witnesses/${id}`);
}

export function postWitnessesByStatementId(statement: IWitness[] | Omit<IWitness, 'id'>[]): Promise<AxiosResponse<IWitness[]>> {
  return authorizedRequest().post(`/witnesses/multiple`, statement);
}

export function postDamages(payload: {
  description: string | undefined;
  zone: string | undefined;
  statementId: number | null;
  personId: number | null;
}): Promise<AxiosResponse<IStatementBackResponse>> {
  console.log('FROM POST DAMAGES WITH PAYLOAD: ', payload);

  return authorizedRequest().post(`/damages`, payload);
}

export async function postDamagesMedia(payload: FormData, type: boolean): Promise<AxiosResponse<IPhotoProofs>> {
  return requestMedia().post(`/photo-proofs${type ? '/video' : ''}`, payload);
}

export function getDamagesById(id: number): Promise<AxiosResponse<IStatementDamage>> {
  return authorizedRequest().get(`/damages/${id}`);
}

export function deleteDamagesMedia(id: number): Promise<AxiosResponse<IPhotoProofs>> {
  return authorizedRequest().delete(`/photo-proofs/${id}`);
}

export function putDocuments(payload: FormData): Promise<AxiosResponse<IStatementBackResponse>> {
  return requestMedia().put(`/persons/document`, payload);
}

export function postOcrDataCall(payload: FormData, document: string): Promise<AxiosResponse> {
  return requestMedia().post(`/ocr/${document}`, payload);
}

export function putStatementDocuments(payload: FormData): Promise<AxiosResponse<IStatementBackResponse>> {
  return requestMedia().post(`/statements/document`, payload);
}

export async function putPaymentDetails(payload: IPaymentDTO): Promise<AxiosResponse<IPayment>> {
  return authorizedRequest().put(`/payment-details`, payload);
}

export async function postSignature(payload: FormData, id: number): Promise<AxiosResponse<IStatementBackResponse>> {
  return requestMedia().post(`/persons/sign/${id}`, payload);
}

export async function insuredAssignAndRespond(payload: { id: number; response: boolean }): Promise<AxiosResponse<IStatementBackResponse>> {
  return authorizedRequest().put(`/statements/assign-insured`, payload);
}

export async function getSignature(id: number): Promise<AxiosResponse<string>> {
  return authorizedRequest().get(`/attachments/file/${id}`, {
    responseType: 'blob',
  });
}
