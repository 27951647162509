import React, { FC, useMemo, useState } from 'react';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { Wrapper } from '../../../styled-components';
import { Button } from '../../../components/button';
import { CloseOutlinedIcon, AcceptOutlinedIcon } from '../../../assets/icons';

interface IPhotoEditorProps {
  setFileReady: (value: boolean) => void;
  onSaveFile: (file?: File) => void;
  file: File | null;
}

const useStyle = () => ({
  button: {
    height: 30,
    width: 30,
    borderRadius: 17,
    backgroundColor: '#DB4437',
    padding: 0,
    minHeight: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const PhotoEditor: FC<IPhotoEditorProps> = props => {
  const { onSaveFile, file } = props;
  const style = useStyle();

  const [crop, setCrop] = useState<ReactCrop.Crop>({
    unit: '%',
    x: 10,
    y: 10,
    width: 80,
    height: 80,
  });

  const getCroppedImg = (): Promise<File> => {
    const imageToCrop = document.getElementById('preview') as HTMLImageElement;

    const fileName = file?.name || `attachment${new Date().getTime().toString()}`;
    const canvas = document.createElement('canvas');
    const scaleX = imageToCrop.naturalWidth / imageToCrop.width;
    const scaleY = imageToCrop.naturalHeight / imageToCrop.height;

    const pixelRatio = window.devicePixelRatio;

    canvas.width = (crop?.width || imageToCrop.width) * pixelRatio;
    canvas.height = (crop?.height || imageToCrop.height) * pixelRatio;

    const ctx = canvas.getContext('2d');
    const cropX = crop.x || 1;
    const cropY = crop.y || 1;
    const cropWidth = crop.width || imageToCrop.naturalWidth;
    const cropHeight = crop.height || imageToCrop.naturalHeight;

    ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    if (ctx) ctx.imageSmoothingQuality = 'high';
    ctx?.drawImage(imageToCrop, cropX * scaleX, cropY * scaleY, cropWidth * scaleX, cropHeight * scaleY, 0, 0, cropWidth, cropHeight);

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (blob) {
            const croppedFile = new File([blob], fileName) as File;
            resolve(croppedFile);
          }
        },
        'image/jpeg',
        1,
      );
    });
  };

  const imageUrl = useMemo(() => (file ? URL.createObjectURL(file) : ''), [file]);

  const saveFile = async (): Promise<void> => {
    if (crop.unit !== '%') {
      const newImage = await getCroppedImg();
      onSaveFile(newImage);
      setCrop({});
    } else {
      setCrop({});

      return;
    }
  };

  const cropSelectionControls = () => {
    return (
      <Wrapper display='flex' justifyContent='flex-start'>
        <Button
          style={{
            ...style.button,
            backgroundColor: '#DB4437',
          }}
          onClick={() => setCrop({})}
        >
          <CloseOutlinedIcon fill='white' />
        </Button>
        <Button
          style={{
            ...style.button,
            backgroundColor: '#2DA455',
          }}
          onClick={saveFile}
        >
          <AcceptOutlinedIcon fill='white' />
        </Button>
      </Wrapper>
    );
  };

  return (
    <Wrapper display='flex' justifyContent='center' width='100%'>
      <ReactCrop
        crop={crop}
        imageStyle={{ width: '100%' }}
        renderComponent={<img alt='' height='auto' id='preview' src={imageUrl} width='100%' />}
        renderSelectionAddon={cropSelectionControls}
        src={imageUrl}
        style={{ width: '100%' }}
        onChange={setCrop}
      />
    </Wrapper>
  );
};
