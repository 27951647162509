import React from 'react';

import { Tab, Tabs } from '../../styled-components';
import { Typography } from '../../styled-components';
import { $fontWeightBold } from '../../styles/variables';

import { INavCreateProps } from './PageTopNav';

export interface ITab {
  id: number;
  label: string;
}

export const TabsNav = (props: INavCreateProps): JSX.Element => {
  return (
    <div style={{ backgroundColor: '#8875e6' } as React.CSSProperties}>
      {props.title && (
        <Typography align='left' color='white' commonStyles={{ padding: '30px 0 0 20px' }} fontWeight={$fontWeightBold} variant='h4'>
          {props.title}
        </Typography>
      )}

      <Tabs {...props}>
        {props.tabs?.map((tab: ITab) => (
          <Tab
            key={tab.id}
            activeTabBgColor={props.activeTabBgColor}
            activeTabColor={props.activeTabColor}
            borderRadiusTab={props.borderRadiusTab}
            className={tab.id === props.selectedTab ? 'active' : ''}
            fontSize={props.fontSize}
            heightTab={props.heightTab}
            tabColor={props.tabColor}
            onClick={() => props?.onTabClick?.(tab.id)}
          >
            {tab.label}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};
