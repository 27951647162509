import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';

import { TextField } from '../text_field';
import { Button } from '../button';
import { Column, Wrapper } from '../../styled-components';
import { Typography } from '../../styled-components';
import { IDrivingLicense } from '../../@types/document/documentTypes';
import { CustomDatePickerToolbar } from '../date-picker/custom-date-picker-toolbar';
import { LETTERS_AND_NUMBERS_REGEX } from '../../shared/constants';
import { validateCuiOrCNP } from '../../utils/form-validators';

interface IDetailsFormProps {
  initialValues: IDrivingLicense['data'];
  onSubmit: (value: IDrivingLicense['data']) => void;
  history: H.History<any>;
  parentRoute: string;
}

export const DrivingLicenseDetailsForm = ({ onSubmit, initialValues }: IDetailsFormProps): JSX.Element => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    number: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false))
      .matches(LETTERS_AND_NUMBERS_REGEX, t('ocrForms.invalidFieldOnlyLettersAndDigits')),
    nameSurname: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false))
      .nullable(),
    availabilityStart: Yup.date().required(t('commonText.requiredFieldError')).typeError(t('commonText.invalidFormat')).nullable(),
    availabilityEnd: Yup.date()
      .required(t('commonText.requiredFieldError'))
      .min(Yup.ref('availabilityStart'), t('commonText.endDateError'))
      .typeError(t('commonText.invalidFormat'))
      .nullable()
      .default(null),
    cnp: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidFormat'), val => (val ? val.length < 14 : false))
      .test('validate-cnp', t('commonText.invalidCnpOrCuiError'), validateCuiOrCNP),
    birthLocation: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : false)),
    categories: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    dateOfBirth: Yup.date().required(t('commonText.requiredFieldError')).typeError(t('commonText.invalidFormat')).nullable(),
  });

  const eighteenYearsAgo = () => {
    const d = new Date();

    return d.setFullYear(d.getFullYear() - 18);
  };

  return (
    <Formik initialValues={initialValues || {}} validationSchema={validationSchema} validateOnMount onSubmit={onSubmit}>
      {({ handleChange, handleBlur, isValid, values, errors, setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <TextField
            errorMessage={errors.nameSurname}
            label={t('ocrForms.nameSurname')}
            name='nameSurname'
            value={values?.nameSurname}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextField
            errorMessage={errors.birthLocation}
            label={t('ocrForms.birthLocation')}
            name='birthLocation'
            value={values?.birthLocation}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextField
            errorMessage={errors.cnp}
            label={t('ocrForms.cnp')}
            name='cnp'
            value={values?.cnp}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />
          <TextField
            errorMessage={errors.number}
            label={t('ocrForms.number')}
            name='number'
            value={values?.number}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />
          <TextField
            errorMessage={errors.categories}
            label={t('ocrForms.categories')}
            name='categories'
            value={values?.categories}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Typography color='#9a9a9a' variant='body-xs'>
            {t('ocrForms.dateOfBirth')}
          </Typography>
          <DatePicker
            ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
            cancelLabel={t('commonText.cancel')}
            className='date-picker'
            error={Boolean(errors.dateOfBirth)}
            format='DD.MM.YYYY'
            helperText={errors.dateOfBirth}
            maxDate={eighteenYearsAgo()}
            name='dateOfBirth'
            okLabel={t('commonText.save')}
            value={values?.dateOfBirth || null}
            onBlur={handleBlur}
            onChange={value => setFieldValue('dateOfBirth', value)}
          />
          <Typography color='#9a9a9a' variant='body-xs'>
            {t('ocrForms.drivingLicenseAvailability')}
          </Typography>
          <Wrapper alignItems='flex-start' justifyContent='space-between'>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <DatePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
                cancelLabel={t('commonText.cancel')}
                className='date-picker'
                error={Boolean(errors.availabilityStart)}
                format='DD.MM.YYYY'
                helperText={errors.availabilityStart}
                maxDate={new Date()}
                name='availabilityStart'
                okLabel={t('commonText.save')}
                value={values?.availabilityStart || null}
                onBlur={handleBlur}
                onChange={value => setFieldValue('availabilityStart', value)}
              />
            </Column>
            <Column maxWidth='4%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='24px' textAlign='center'>
              -
            </Column>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <DatePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
                cancelLabel={t('commonText.cancel')}
                className='date-picker'
                error={Boolean(errors.availabilityEnd)}
                format='DD.MM.YYYY'
                helperText={errors.availabilityEnd}
                minDate={new Date()}
                name='availabilityEnd'
                okLabel={t('commonText.save')}
                value={values?.availabilityEnd || null}
                onBlur={handleBlur}
                onChange={value => setFieldValue('availabilityEnd', value)}
              />
            </Column>
          </Wrapper>
          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            border='none'
            borderRadius='25px'
            color='#FFFF'
            disabled={!isValid}
            disabledColor='#DADADA'
            margin='15px 0 10px 0'
            type='submit'
            onClick={() => onSubmit(values)}
          >
            {t('commonText.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
