import { initializePaymentAction } from '../services/api/payment/payment.api';

const usePayment = () => {
  const initializePayment = async (statementId: number) => {
    initializePaymentAction({ statementId }).then(paymentData => {
      const { url, env_key, data } = paymentData?.data;
      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', url);
      const inputEnv = document.createElement('input');
      inputEnv.setAttribute('type', 'hidden');
      inputEnv.setAttribute('name', 'env_key');
      inputEnv.setAttribute('value', env_key);
      form.appendChild(inputEnv);

      const inputData = document.createElement('input');
      inputData.setAttribute('type', 'hidden');
      inputData.setAttribute('name', 'data');
      inputData.setAttribute('value', data);
      form.appendChild(inputData);

      document.body.appendChild(form);
      form.submit();
    });
  };

  return { initializePayment };
};

export { usePayment };
