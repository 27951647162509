import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS, PROFILE_TEXTS } from '../../translations/ro/home';
import { Typography } from '../../styled-components';
import { TextField } from '../../components/text_field';
import { PHONE_REGEX } from '../../shared/constants';
import { Button } from '../../components/button';

export const StatementDamagedInsurerData = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const [insurerPhone, setInsurerPhone] = useState<string>('');
  const [insurerEmail] = useState<string>('');

  const ValidationSchema = Yup.object().shape({
    phone: Yup.string().matches(new RegExp(PHONE_REGEX)).required(),
    email: Yup.string().email(),
  });

  const onSubmit = (data: { phone: string; email: string }) => {
    // TODO: rename route when screen logic is done.
    history.push('/statement/1/success');
  };

  const chooseFromContacts = async (): Promise<void> => {
    // Implementation: https://glitch.com/edit/#!/contact-picker
    if ('contacts' in navigator && 'ContactsManager' in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const contact = await navigator.contacts.select(['tel']);

      setInsurerPhone(contact[0]?.tel?.[0] || '');
    }
  };

  return (
    <ScreenContainer headerProps={{ hideLogo: true, title: HOME_TEXTS.theDamagedInsurer }}>
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {HOME_TEXTS.typeDamageInsurer}
      </Typography>

      <Formik
        initialValues={{ phone: insurerPhone, email: insurerEmail }}
        validationSchema={ValidationSchema}
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, values, errors, isValid, touched }) => {
          return (
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <div>
                <TextField
                  errorMessage={errors.phone}
                  label={`* ${HOME_TEXTS.thePhoneNumber}`}
                  name='phone'
                  value={values.phone}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Button
                  color='#512ad8'
                  margin='15px 0 10px 0'
                  mode='link'
                  style={{ fontWeight: 700 }}
                  type='button'
                  onClick={chooseFromContacts}
                >
                  {HOME_TEXTS.chooseFromContactList}
                </Button>
                <br />

                <TextField
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  errorMessage={errors.email}
                  label={PROFILE_TEXTS.email}
                  name='email'
                  value={values.email}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <Button disabled={!isValid} margin='15px 0 10px 0' mode='primary' type='submit'>
                Ok
              </Button>
            </Form>
          );
        }}
      </Formik>
    </ScreenContainer>
  );
};
