import { PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { createGenericSlice, GenericState, initializeState } from '../store.constants';
import { ICurrentStatement, ICurrentPerson } from '../../@types/current-statement/currentStatementTypes';

import { currentStatementInitialState } from './initialState';
export type IStatementState = GenericState<ICurrentStatement>;

const initialState: IStatementState = initializeState(currentStatementInitialState);

const statementInsuranceSlice = createGenericSlice({
  name: 'currentStatement',
  initialState,
  reducers: {
    setCurrentStatement(state: IStatementState, action: PayloadAction<ICurrentStatement>) {
      state.data = action.payload;
    },
    setGuiltyDetails(state: IStatementState, action: PayloadAction<Partial<ICurrentPerson>>) {
      state.data = {
        ...state.data,
        guilty: { ...state.data?.guilty, ...action.payload },
      };
    },
    setInjuredDetails(state: IStatementState, action: PayloadAction<Partial<ICurrentPerson>>) {
      state.data = {
        ...state.data,
        injured: { ...state.data?.injured, ...action.payload },
      };
    },
    setInjuredWitnesses(state: IStatementState, action: PayloadAction<Partial<ICurrentPerson>>) {
      if (action.payload.witnesses?.length === 0 && state.data.injured.witnesses !== null) {
        state.data = {
          ...state.data,
          injured: { ...state.data?.injured, ...action.payload },
        };
      }

      if (action.payload.witnesses?.length !== 0)
        state.data = {
          ...state.data,
          injured: { ...state.data?.injured, ...action.payload },
        };
    },
  },
});

export default statementInsuranceSlice.reducer;

export const {
  setCurrentStatement,
  fetchStart,
  fetchError,
  fetchSuccess,
  setGuiltyDetails,
  setInjuredDetails,
  setInjuredWitnesses,
} = statementInsuranceSlice.actions;

// TODO: resolve any
export const setCurrentStatementData = (payload: any): AppThunk => async (dispatch: (arg0: any) => void) => {
  dispatch(setCurrentStatement(payload));
};
