import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardActionButton, CardContent, CardHeader, StyledCard } from '../../styled-components/cards/ElevatedCard';
import { Wrapper } from '../../styled-components';
import { useCardManagement } from '../../hooks/useCardManagement';
import { EVALUATION_STATUS, NOTIFICATION_STATUS, STATEMENT_STATUS } from '../../constants/typeConstants/statementStatusType';

import { CardsCommonContent } from './CardsCommonContent';

export interface Props {
  id: number;
  currentPlace: number | null;
  onContinue: () => void;
  createdAt: string;
  insuranceNo?: string | null;
  registrationNumber?: string | null;
  status: EVALUATION_STATUS | STATEMENT_STATUS | NOTIFICATION_STATUS;
}

const styles = {
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
  },
};

export const NotificationCardTemplate = ({
  registrationNumber,
  insuranceNo,
  currentPlace,
  onContinue,
  createdAt,
  status,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const { getMainColor, IconStatus, getLabel } = useCardManagement(status);

  const isFinished = [NOTIFICATION_STATUS.NOTIFICATION_FINISHED].includes(status as NOTIFICATION_STATUS);

  const color = getMainColor();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const getAttachmentUrl = (filePath: string) => {
    const getBaseName = (path: string) => path.substring(path.lastIndexOf('/') + 1);
    const fileName = getBaseName(filePath);

    return `${process.env.REACT_APP_ATTACHMENTS_DOWNLOAD_PATH}/${fileName}`;
  };

  const headerText = `${t('statementStatus.notification')} #${currentPlace}`;
  const actionButtonLabel = isFinished ? t('commonText.seeDetails') : t('commonText.continue');

  return (
    <>
      <StyledCard style={{ padding: 0, border: `1px solid ${color}` }}>
        <CardHeader color={getMainColor()} header={headerText} label={getLabel()}>
          {IconStatus()}
        </CardHeader>

        <CardContent>
          <Wrapper style={{ ...(styles.cardWrapper as React.CSSProperties) }}>
            <CardsCommonContent createdAt={createdAt} insuranceNo={insuranceNo} registrationNumber={registrationNumber} />
          </Wrapper>
        </CardContent>
        <CardActionButton
          color={!isFinished ? getMainColor() : 'black'}
          label={actionButtonLabel}
          separatorColor={getMainColor()}
          onClick={onContinue}
        />
      </StyledCard>
    </>
  );
};
