import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { ArrowLeftIcon } from '../../assets/icons';
import { Card, TabContent } from '../../styled-components';

type IInfoProps = {
  content: Array<any>;
  uniqueKey: string;
};

const StyledButton = styled.button`
  height: 56px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

interface ITopics {
  items: ITopicItem[];
}

interface ITopicListItem {
  title: string;
  text: string;
}
interface ITopicItem {
  title: string;
  text?: string;
  list?: Array<ITopicListItem>;
}

const renderTabContent = (
  topic: ITopics,
  selectTopic: any,
  selectedTopic: ITopicItem | null,
  selectItem: any,
  selectedItem: ITopicListItem | null,
  uniqueKey: string,
): JSX.Element => {
  if (selectedItem) {
    return (
      <>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'black' }}
          onClick={() => {
            selectItem(null);
          }}
        >
          <StyledButton>
            <ArrowLeftIcon height='20px' width='20px' />
          </StyledButton>
          <span style={{ fontWeight: 'bold', color: '#5F2EEA' }}>{selectedItem?.title}</span>
        </div>
        <p style={{ color: 'black', lineHeight: '1.8', width: '100%' }}>{ReactHtmlParser(selectedItem.text)}</p>
      </>
    );
  }

  if (selectedTopic) {
    return (
      <>
        <div
          style={{ color: '#5F2EEA', display: 'flex', alignItems: 'center', fontWeight: 'bold', cursor: 'pointer', width: '100%' }}
          onClick={() => {
            selectTopic(null);
          }}
        >
          <StyledButton>
            <ArrowLeftIcon height='20px' width='20px' />
          </StyledButton>
          <span>{selectedTopic?.title}</span>
        </div>
        {selectedTopic.list &&
          selectedTopic.list.map((item: ITopicListItem, index) => (
            <Card
              key={`topic-${uniqueKey}-${index}`}
              alignItems='center'
              backgroundColor='#f3f3703d'
              borderRadius='10px'
              boxShadow='0px 2px 15px rgba(134, 112, 251, 0.2)'
              style={{
                fontSize: 14,
                flexDirection: 'column',
                width: 'calc(50% - 10px)',
                margin: '10px 0',
                height: '150px',
                textAlign: 'center',
              }}
              onClick={() => selectItem(item)}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontWeight: 700,
                }}
              >
                {item.title}
              </span>
            </Card>
          ))}
        {selectedTopic.text && <p style={{ color: 'black', lineHeight: '1.8', width: '100%' }}>{ReactHtmlParser(selectedTopic.text)}</p>}
      </>
    );
  }

  return (
    <>
      {topic.items.map((item: ITopicItem, index) => (
        <Card
          key={`item-${uniqueKey}-${index}`}
          alignItems='center'
          backgroundColor='#f3ecff'
          borderRadius='10px'
          boxShadow='0px 2px 15px rgba(134, 112, 251, 0.2)'
          style={{
            fontSize: 14,
            flexDirection: 'column',
            width: 'calc(50% - 10px)',
            margin: '10px 0',
            height: '150px',
            textAlign: 'center',
          }}
          onClick={() => selectTopic(item)}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontWeight: 700,
            }}
          >
            {item.title}
          </span>
        </Card>
      ))}
    </>
  );
};

export const Info: React.FC<IInfoProps> = props => {
  const { content, uniqueKey } = props;
  const [selectedTopic, selectTopic] = useState<ITopicItem | null>(null);
  const [selectedTopicItem, selectTopicListItem] = useState<ITopicListItem | null>(null);
  const selectTopicList = (topic: ITopicItem) => {
    selectTopic(topic);
  };

  const selectTopicItem = (item: ITopicListItem) => {
    selectTopicListItem(item);
  };

  return (
    <TabContent style={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between' }}>
      {content.map((topic: ITopics) =>
        renderTabContent(topic, selectTopicList, selectedTopic, selectTopicItem, selectedTopicItem, uniqueKey),
      )}
    </TabContent>
  );
};
