import React, { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EditableCard, Paragraph, Switch, Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS } from '../../translations/ro/home';
import { PlusIcon } from '../../assets/icons';
import { $fontWeightBold, $lineHeightM } from '../../styles/variables';
import { IWitness } from '../../@types/witnesses/witnessesTypes';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { useWitnesses } from '../../hooks/useWitnesses';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { useStatements } from '../../hooks/useStatements';
import { ICurrentStatement } from '../../@types/current-statement/currentStatementTypes';

export const Witnesses = ({ history, state }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { witnesses } = state?.currentStatement.data.injured || {};
  const [showModal, setShowModal] = useState<boolean>(false);
  // 0 will be for delete modal
  const [chooseModal, setChooseModal] = useState<number>(-1);
  const [witnessID, setWitnessID] = useState<number>(-1);
  const [hasWitnesses, setHasWitnesses] = useState<boolean>(false);
  const { currentStatement } = useCurrentStatement();
  const { deleteWitnessByID, postWitnessesByStatementId } = useWitnesses();
  const { fetchStatements, statementsState } = useStatements();
  const { currentStatementId } = useParams<{ currentStatementId: string | undefined }>();

  const verifyAlreadyCompleted = (statement: ICurrentStatement) => {
    if (statement?.injured?.witnesses && statement?.injured?.witnesses.length > 0) return true;
  };

  useEffect(() => {
    fetchStatements();
    statementsState.forEach(statement => {
      if (statement.statementID === Number(currentStatementId) && verifyAlreadyCompleted(statement)) {
        return history.push(generatePath(`${PROTECTED_ROUTES.statements}`));
      }
    });
  }, []);

  const onEdit = (witness: IWitness): void => {
    history.push(
      generatePath(`${PROTECTED_ROUTES.statementWitnesses$WitnessIdEdit}`, {
        currentStatementId: String(currentStatement.statementID),
        witnessId: witness.id,
      }),
      { addWitnesses: true, witness },
    );
  };

  const onDelete = () => {
    deleteWitnessByID(witnessID);
    setShowModal(false);
  };

  const deleteModalComponent = (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '14px 0 0 0' }} fontWeight='700' variant='h2'>
          {HOME_TEXTS.deleteWitnesses}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button
          background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
          borderRadius='25px'
          color='#FFFFFF'
          width='100%'
          onClick={() => onDelete()}
        >
          {t('commonText.yes')}
        </Button>

        <Button margin='15px 0 0 0' mode='link' onClick={() => setShowModal(false)}>
          <Typography color='#512AD8' variant='h5'>
            {t('commonText.no')}
          </Typography>
        </Button>
      </Wrapper>
    </>
  );

  const confirmBackModalComponent = (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '14px 0 0 0' }} fontWeight='700' variant='h2'>
          {t('commonText.confirmBack')}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button
          background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
          borderRadius='25px'
          color='#FFFFFF'
          width='100%'
          onClick={() => {
            setShowModal(false);

            if (currentStatement.statementID !== null) {
              history.push(generatePath(`${PROTECTED_ROUTES.statement}`, { currentStatementId: currentStatement.statementID }));
            }
          }}
        >
          {t('commonText.yes')}
        </Button>

        <Button margin='15px 0 0 0' mode='link' onClick={() => setShowModal(false)}>
          <Typography color='#512AD8' variant='h5'>
            {t('commonText.no')}
          </Typography>
        </Button>
      </Wrapper>
    </>
  );

  const onAddWitness = () => {
    history.push(
      generatePath(`${PROTECTED_ROUTES.statementWitnessesAdd}`, {
        currentStatementId: String(currentStatement.statementID),
      }),
      { addWitnesses: true },
    );
  };

  const hanldleSwitchChange = () => {
    setHasWitnesses(prevState => !prevState);
  };

  const submitWitnesses = () => {
    if (witnesses && witnesses?.length === 0) {
      postWitnessesByStatementId(
        [
          {
            name: '',
            address: '',
            phone: '',
            email: '',
            statementId: currentStatement.statementID,
          },
        ],
        () =>
          history.push(
            generatePath(`${PROTECTED_ROUTES.statement$IdDetails}`, {
              currentStatementId: String(currentStatement.statementID),
            }),
          ),
      );
    }

    if (witnesses && witnesses?.length > 0) {
      postWitnessesByStatementId(witnesses, () =>
        history.push(
          generatePath(`${PROTECTED_ROUTES.statement$IdDetails}`, {
            currentStatementId: String(currentStatement.statementID),
          }),
        ),
      );
    }
  };

  return (
    <>
      <ScreenContainer
        bodyProps={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
        headerProps={{
          title: HOME_TEXTS.witnesses,
          hideLogo: true,
          customParentBack: true,
          onCustomParentBack: () => {
            setChooseModal(1);
            setShowModal(true);
          },
        }}
        isModalOpen={showModal}
        modal={chooseModal === 0 ? deleteModalComponent : confirmBackModalComponent}
        modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
      >
        <Wrapper display='flex' flexDirection='column' justifyContent='center' style={{ fontFamily: 'Roboto' }} width='100%'>
          {witnesses &&
            witnesses.map((witness: IWitness, index: number) => {
              return witness ? (
                <EditableCard
                  key={index.toString()}
                  fields={witness}
                  showCrudButton
                  onDelete={() => {
                    setChooseModal(0);
                    setShowModal(true);
                    setWitnessID(witness.id);
                  }}
                  onEdit={() => onEdit(witness)}
                />
              ) : (
                <></>
              );
            })}
          {witnesses?.length === 0 && (
            <Wrapper display='flex' justifyContent='space-between' margin='0 0 10px 0' width='100%'>
              <Typography fontWeight='700' variant='h5'>
                {t('statements.noWitnesses')}
              </Typography>
              <Switch defaultChecked={hasWitnesses} onChange={hanldleSwitchChange} />
            </Wrapper>
          )}

          <br />
          <br />
          {!hasWitnesses && (
            <Button display='flex' mode='link' onClick={onAddWitness}>
              <PlusIcon />
              <Paragraph color='#512AD8' fontWeight={$fontWeightBold} lineHeight={$lineHeightM} margin='0 0 0 10px'>
                {HOME_TEXTS.addWitnesses}
              </Paragraph>
            </Button>
          )}
        </Wrapper>
        <Button
          background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
          border='none'
          borderRadius='25px'
          color='#FFFF'
          disabled={!(witnesses?.length || hasWitnesses)}
          disabledColor='#DADADA'
          onClick={() => submitWitnesses()}
        >
          {t('commonText.save')}
        </Button>
      </ScreenContainer>
    </>
  );
};
