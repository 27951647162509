export const PHONE_REGEX = /^(\+4|)?(07[0-8]{1}[0-9]{1}){1}?(\s|\-)?([0-9]{3}(\s|\-|)){2}$/;
export const STRING_REGEX = /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/;
export const API_PREFIX = '/api/v1';
export const PLATE_NUMBER_REGEX = /(^B\d{2}\d?[A-Z]{3}$)|(^B-\d{2}\d?-[A-Z]{3}$)|(^B\s\d{2}\d?\s[A-Z]{3}$)|(^[A-Z]{2}\d{2}[A-Z]{3}$)|(^[A-Z]{2}-\d{2}-[A-Z]{3}$)|(^[A-Z]{2}\s\d{2}\s[A-Z]{3}$)|(^[A-Z]\s\d{3}\s[A-Z]{3}$)|(^[A-Z]\d{3}[A-Z]{3}$)|(^[A-Z]{2}\s\d{3}\s[A-Z]{3}$)|(^[A-Z]{2}\d{6}$)|(^[A-Z]{2}\s\d{6}$)|(^[A-Z]\s\d{6}$)|(^[A-Z]\d{6}$)/i;
export const CIV_NUMBER_REGEX = /^[A-HJ-NO-Z]\d{6}$/i;
export const VIN_NUMBER_REGEX = /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/i;
export const LETTERS_AND_NUMBERS_REGEX = /^[A-Za-z0-9\s]+$/;
export const INSURANCE_SERIES_REGEX = /^[A-Za-z0-9/]+$/;
export const OWNER_NAME_REGEX = /^[A-Za-zÀ-ž\-\s]+$/;
export const ISSUED_BY_REGEX = /^[A-Za-zÀ-ž0-9\-\s]+$/;
export const ADDRESS_REGEX = /^[A-Za-zÀ-ž0-9'.'','\-\s]+$/;
export const DIGITS_REGEX = /^[0-9]+$/;
