import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../button';
import { Typography } from '../../styled-components';
import { $lineHeightS } from '../../styles/variables';
import { authMethodSwitch } from '../../utils/authMethodSwitch';

interface PropsI {
  toggleModal: (value: boolean, method: string) => void;
  methodType: string;
  login?: boolean;
}

export const AuthentificationMethods = ({ toggleModal, methodType, login }: PropsI): JSX.Element => {
  const { t } = useTranslation();
  const value = authMethodSwitch(methodType);

  return (
    <Button background={value?.color} borderRadius='26px' margin='10px 0' onClick={() => toggleModal(true, methodType)}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0 26px', textAlign: 'center' }}>
        <div style={{ marginRight: '20px', position: 'absolute' }}>{value?.logo}</div>
        <div style={{ padding: '0 20px', width: '100%' }}>
          <Typography
            align='center'
            color='#fff'
            commonStyles={{ width: '100%' }}
            fontSize='14px'
            fontWeight='700'
            lineHeight={$lineHeightS}
            variant='body'
          >
            {login ? `${t('commonText.continue')} ${value?.label}` : `${t('commonText.createAccountWith')} ${value?.label}`}
          </Typography>
        </div>
      </div>
    </Button>
  );
};
