import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UIModel, STATEMENT_FILTERS } from './ui.model';

const initialState: UIModel = {
  statements: {
    filter: STATEMENT_FILTERS.ALL,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setStatementFilter(state: UIModel, action: PayloadAction<STATEMENT_FILTERS>) {
      state.statements.filter = action.payload;
    },
  },
});

export default uiSlice.reducer;

export const { setStatementFilter } = uiSlice.actions;
