import React from 'react';

import { Button } from '../../components/button';
import { Close } from '../../assets/icons';
import { Typography } from '../index';

import { IModalProps } from './types';
import { StyledModal, StyledModalHeader, useStyles } from './modal.styled';

export const Modal: React.FC<IModalProps> = props => {
  const classes = useStyles();

  return (
    <>
      {props.show && (
        <StyledModal
          backgroundColor={props.backgroundColor}
          border={props.border}
          height={props.height}
          left={props.left}
          margin={props.margin}
          right={props.right}
          showTitle={props.showTitle}
          width={props.width}
        >
          <StyledModalHeader>
            {props.showCloseButton && (
              <Button style={classes.closeButton} onClick={props.onClose}>
                <Close fill='#7B66E3' />
              </Button>
            )}
            {props.showTitle && (
              <Typography align='center' commonStyles={{ width: '100%', margin: '10px 0' }} fontWeight='700' variant='h3'>
                {props.title}
              </Typography>
            )}
          </StyledModalHeader>
          <div style={{ height: 'calc(100% - 50px)', overflow: props.scroll }}>{props.children}</div>
        </StyledModal>
      )}
    </>
  );
};

Modal.defaultProps = {
  showCloseButton: true,
  scroll: 'scroll',
};
