export const INJURED_STATUS = 'injured';
export const GUILTY_STATUS = 'guilty';

export enum VEHICLE_OWNERSHIP {
  LEGAL_PERSON = 'LEGAL_PERSON',
  LEASING_COMPANY = 'LEASING_COMPANY',
}
export enum USER_SITUATION {
  INJURED_STATUS = 'injured',
  GUILTY_STATUS = 'guilty',
}

export const MYSELF = 'MYSELF';
export const OTHER_INDIVIDUAL = 'OTHER_INDIVIDUAL';
export const LEGAL_PERSON = 'LEGAL_PERSON';
export const LEASING_COMPANY = 'LEASING_COMPANY';
