import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import { Image, Typography } from '../../styled-components';
import PaperPlane from '../../assets/svgs/paper-plane.svg';
import { Button } from '../../components/button';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { IScreenComponentProps } from '../component-props.model';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

export const Congratulations = ({ history, nextRoute }: IScreenComponentProps) => {
  const { t } = useTranslation();
  const { currentStatementId } = useParams<{ currentStatementId: string | undefined }>();

  return (
    <ScreenContainer headerType='none'>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
        <Image margin='0 auto' src={PaperPlane} />

        <Typography align='center' commonStyles={{ margin: '40px 0 20px 0' }} fontWeight='700' variant='h3'>
          {t('congratulations.header')}
        </Typography>
        <Typography align='center' color='#9a9a9a' commonStyles={{ padding: '0 30px' }} variant='body'>
          {t('congratulations.forMoreInformations')}
        </Typography>
      </div>
      <Button mode='primary' onClick={() => history.push(generatePath(PROTECTED_ROUTES.statements, { currentStatementId }))}>
        {t('commonText.ok')}
      </Button>
    </ScreenContainer>
  );
};
