import { AxiosResponse } from 'axios';

import { authorizedRequest } from '../../services/api';

export function getLocations(): Promise<AxiosResponse<any>> {
  return authorizedRequest().get(`/location`);
}

export function getCitiesByCounty(county: string): Promise<AxiosResponse<any>> {
  return authorizedRequest().get(`/location/${county}`);
}
