import { DOCUMENTS_FORM_TYPE } from '../../constants/typeConstants/documentsFormType';
import { IFormOcrMocks } from '../../screens/documentsOCR/documentsOcrDataForm';

export const DOCUMENTS_TYPES = {
  INSURANCE: 'insurance',
  DRIVER_LICENCE: 'driverLicence',
  IDENTITY_CARD: 'identityCard',
  VEHICLE_REGISTRATION_CERTIFICATE: 'vehicleRegistrationCertificate',
  AMICABLE_FINDING: 'amicableFinding',
  POLICE_REPORT: 'policeReport',
  OBSERVATION_NOTE: 'observationNote',
  COMPENSATION_OFFER: 'compensationOffer',
  EVALUATION_DOCUMENT: 'evaluationDocument',
};

export const updateDocuments = (formValue: IFormOcrMocks, formType: string, documentImage: string) => {
  if (formValue) {
    switch (formType) {
      case DOCUMENTS_FORM_TYPE.DRIVING_LICENSE:
        return { drivingLicense: { image: documentImage, data: { ...formValue }, type: DOCUMENTS_TYPES.DRIVER_LICENCE } };
      case DOCUMENTS_FORM_TYPE.ID_CARD:
        return { identityCard: { image: documentImage, data: { ...formValue }, type: DOCUMENTS_TYPES.IDENTITY_CARD } };
      case DOCUMENTS_FORM_TYPE.INSURANCE:
        return { insurance: { image: documentImage, data: { ...formValue }, type: DOCUMENTS_TYPES.INSURANCE } };
      case DOCUMENTS_FORM_TYPE.CAR_IDENTITY:
        return { carIdentity: { image: documentImage, data: { ...formValue }, type: DOCUMENTS_TYPES.VEHICLE_REGISTRATION_CERTIFICATE } };
    }
  }
};
