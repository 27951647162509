import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { DatePicker } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import moment from 'moment';

import { TextField } from '../text_field';
import { Button } from '../button';
import { ICarIdentity } from '../../@types/document/documentTypes';
import { Typography } from '../../styled-components';
import { momentDateFormat } from '../../shared/helpers';
import { LETTERS_AND_NUMBERS_REGEX, PLATE_NUMBER_REGEX } from '../../shared/constants';
import { CustomDatePickerToolbar } from '../date-picker/custom-date-picker-toolbar';

interface IDetailsFormProps {
  initialValues: ICarIdentity['data'];
  onSubmit: (value: ICarIdentity['data']) => void;
  history: H.History<any>;
  parentRoute: string;
}

export const CarIdentityDetailsForm = ({ onSubmit, initialValues }: IDetailsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    carBrand: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .matches(LETTERS_AND_NUMBERS_REGEX, t('ocrForms.invalidFieldOnlyLettersAndDigits'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    carModel: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .matches(LETTERS_AND_NUMBERS_REGEX, t('ocrForms.invalidFieldOnlyLettersAndDigits'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    matriculationNumber: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false))
      .matches(PLATE_NUMBER_REGEX, t('ocrForms.invalidFieldCarPlate')),
    ownerName: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
    registrationNumber: Yup.string()
      .min(17, t('commonText.invalidFormat'))
      .max(17, t('commonText.invalidMaxLimit'))
      .required(t('commonText.requiredFieldError'))
      .matches(LETTERS_AND_NUMBERS_REGEX, t('ocrForms.invalidFieldOnlyLettersAndDigits')),
    firstMatriculationYear: Yup.number()
      .required(t('commonText.requiredFieldError'))
      .min(1982, t('ocrForms.invalidMatriculationYear'))
      .max(moment().year(), t('ocrForms.invalidMatriculationYear')),
    itpValidity: Yup.date().required(t('commonText.requiredFieldError')).nullable(),
  });

  return (
    <Formik initialValues={initialValues || {}} validationSchema={validationSchema} validateOnMount onSubmit={onSubmit}>
      {({ handleChange, handleBlur, isValid, values, errors, setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <TextField
            errorMessage={errors.carBrand}
            label={t('ocrForms.carBrand')}
            name='carBrand'
            position='relative'
            value={values?.carBrand}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.carModel}
            label={t('ocrForms.carModel')}
            name='carModel'
            value={values?.carModel}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.matriculationNumber}
            label={t('ocrForms.matriculationNumber')}
            name='matriculationNumber'
            value={values?.matriculationNumber}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <TextField
            errorMessage={errors.firstMatriculationYear}
            label={t('ocrForms.firstMatriculationYear')}
            name='firstMatriculationYear'
            type='number'
            value={values?.firstMatriculationYear}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <TextField
            errorMessage={errors.ownerName}
            label={t('ocrForms.ownerName')}
            name='ownerName'
            value={values?.ownerName}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.registrationNumber}
            label={t('ocrForms.registrationNumber')}
            name='registrationNumber'
            value={values?.registrationNumber}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <Typography color='#9a9a9a' variant='body-xs'>
            {t('ocrForms.itpValidity')}
          </Typography>
          <DatePicker
            ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
            cancelLabel={t('commonText.cancel')}
            className='date-picker'
            error={Boolean(errors.itpValidity)}
            format='DD.MM.YYYY'
            helperText={errors.itpValidity}
            minDate={new Date()}
            name='itpValidity'
            okLabel={t('commonText.save')}
            value={values?.itpValidity || null}
            onBlur={handleBlur}
            onChange={value => setFieldValue('itpValidity', momentDateFormat(value))}
          />

          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            border='none'
            borderRadius='25px'
            color='#FFFF'
            disabled={!isValid}
            disabledColor='#DADADA'
            margin='15px 0 10px 0'
            type='submit'
            onClick={() => onSubmit(values)}
          >
            {t('commonText.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
