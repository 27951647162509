import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store/rootReducer';
import { setQuestionsFlowHandlerAction } from '../store/questions-flow-handler/questions-flow-handler.slice';
import { QuestionsFlowHandler } from '../@types/questions-flow-handler/questionsFlowHandler';

interface IUseQuestionsFlowHandler {
  setQuestionsFlowHandler: (questionsFlowHandler: Partial<QuestionsFlowHandler>) => void;
  questionsFlowHandlerState: QuestionsFlowHandler;
}

const useQuestionsFlowHandler = (): IUseQuestionsFlowHandler => {
  const dispatch = useDispatch();
  const questionsFlowHandlerState = useSelector((state: RootState) => state.questionsFlowHandler.data);

  const setQuestionsFlowHandler = (questionsFlowHandler: Partial<QuestionsFlowHandler>) => {
    dispatch(setQuestionsFlowHandlerAction({ ...questionsFlowHandlerState, ...questionsFlowHandler }));
  };

  return { setQuestionsFlowHandler, questionsFlowHandlerState };
};

export { useQuestionsFlowHandler };
