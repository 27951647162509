import React from 'react';
import styled from 'styled-components';

export interface IImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  backgroundColor?: string;
  height?: string;
  width?: string;
  position?: string;
  padding?: string;
  margin?: string;
  borderRadius?: string;
}

const StyledImage = styled.img<IImageProps>`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  padding: ${({ padding }) => padding || '0'};
  position: ${({ position }) => position || 'relative'};
  margin: ${({ margin }) => margin || '0'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  background-repeat: no-repeat;
  background-position: center;
  & * {
    /* Block your circles from triggering 'add circle' */
    pointer-events: none;
  }
`;

export const Image = (props: IImageProps): JSX.Element => {
  return <StyledImage {...props} />;
};
