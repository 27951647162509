import React from 'react';

import { IIconProps } from './icon.model';

export const LocationFlatIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 22} viewBox='0 0 16 22' width={width || 16} {...otherProps}>
      <path
        d='M7.7 0C3.443 0 0 3.443 0 7.7C0 12.3831 5.06352 18.8746 6.97829 21.1619C7.35854 21.6162 8.04146 21.6162 8.42171 21.1619C10.3365 18.8746 15.4 12.3831 15.4 7.7C15.4 3.443 11.957 0 7.7 0ZM7.7 10.45C6.182 10.45 4.95 9.218 4.95 7.7C4.95 6.182 6.182 4.95 7.7 4.95C9.218 4.95 10.45 6.182 10.45 7.7C10.45 9.218 9.218 10.45 7.7 10.45Z'
        fill={fill || '#512AD8'}
      />
    </svg>
  );
};
