import React from 'react';

import { IIconProps } from './icon.model';

export const PencilIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 35} viewBox='0 0 35 35' width={width || 35} {...otherProps}>
      <rect height={34} rx={7.5} stroke='#4123D7' width={34} x={0.5} y={0.5} />
      <path
        d='M26.71 13.043c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75 1.84-1.83zM9 23.253v3.75h3.75l11.06-11.07-3.75-3.75L9 23.252z'
        fill={fill || '#4123d7'}
      />
    </svg>
  );
};
