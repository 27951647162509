import React from 'react';

import { IIconProps } from './icon.model';

export const DeleteIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 33} viewBox='0 0 33 33' width={width || 33} {...otherProps}>
      <rect fill='#DADADA' height={33} rx={8} width={33} />
      <path d='M24 8h-3.5l-1-1h-5l-1 1H10v2h14V8zM11 23a2 2 0 002 2h8a2 2 0 002-2V11H11v12z' fill={fill || '#DB4437'} />
    </svg>
  );
};
