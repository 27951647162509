import { IRoute } from '../interfaces/route.model';
import { StatementDetails, Evaluations, FindingNote, ReFinding, ReFindingInfo } from '../../../screens';
import { StatementAndEvaluationsDetails } from '../../../screens/statement/StatementAndEvaluationsDetails';
import { CompensationOffer } from '../../../screens/compensation/CompensationOffer';
import { CompensationClaim } from '../../../screens/compensation/CompensationClaim';
import { Warrant } from '../../../screens/warrant/Warrant';
import { ReceivableAssignment } from '../../../screens/receivableAssignment/ReceivableAssignment';
import { SelectAccidentDocument } from '../../../screens/documentsOCR/select-accident-document';
import { VehicleSelection } from '../../../screens/statement/VehicleSelection';
import { DocumentsList } from '../../../screens/statement/DocumentsList';
import { PaymentReturn } from '../../../screens/statement/PaymentReturn';
import { ObservationNote } from '../../../screens/observationNote/ObservationNote';
import { DocumentsPhoto } from '../../../screens/documentsOCR/documentsPhoto';
import { DocumentsOcrDataForm } from '../../../screens/documentsOCR/documentsOcrDataForm';
import { DamagePictureList } from '../../../screens/statement-damage/DamagePictureList';
import { EvaluationMethod } from '../../../screens/evaluations/EvaluationMethod';
import { Notifications } from '../../../screens/notifications/Notifications';
import { DocumentsDetailsView } from '../../../screens/statement-documents/DocumentsDetailsView1';

import { PATHS, PROTECTED_ROUTES } from './routes-constants';

export const evaluationRoutes: IRoute = {
  children: [
    {
      exact: true,
      path: PROTECTED_ROUTES.notifications,
      component: Notifications, //MAIN EVALUATION SCREEN DISPLAYS EVERY EVALUATION
      nextRoute: PROTECTED_ROUTES.statement$IdDetails, //IF YOU CLICK ON ANY EVALUATION YOU GO TO THE SPECIFIC EVALUATION STEP
    },
    {
      exact: true,
      path: PROTECTED_ROUTES.evaluations,
      component: Evaluations, //MAIN EVALUATION SCREEN DISPLAYS EVERY EVALUATION
      nextRoute: PROTECTED_ROUTES.statement$IdDetails, //IF YOU CLICK ON ANY EVALUATION YOU GO TO THE SPECIFIC EVALUATION STEP
    },
    {
      path: PROTECTED_ROUTES.statement$IdDetails,
      component: StatementAndEvaluationsDetails, //SECONDS EVALUATION SCREEN SHOWS YOU THE EVALUATION STEPS (DOCUMENTS AND DAMAGE)
      exact: true,
      nextRoute: {
        documents: PATHS.chooseDocuments, //EVALUATION DOCUMENTS FLOW
        damage: PATHS.damage, //EVALUATION DAMAGE FLOW
      },
    },
    {
      path: PROTECTED_ROUTES.chooseDocuments,
      component: SelectAccidentDocument, //FIRST SCREEN OF THE DOCUMENTS FLOW (CHOOSE BETWEEN AMICABLE FINDING OR POLICE REPORT)
      exact: true,
      nextRoute: PATHS.photo, //THE OCR INFORM SCREEN
    },
    {
      path: PROTECTED_ROUTES.chooseDamagedVehicle,
      component: VehicleSelection, //SCREEN FOR CHOOSING THE INJURED VEHICLE ON AMICABLE FINDING
      nextRoute: PATHS.documents,
    },
    {
      path: PROTECTED_ROUTES.statementDocuments,
      component: DocumentsDetailsView, //EVALUATION DOCUMENTS MAIN SCREEN
      exact: true,
      nextRoute: {
        photo: PATHS.photo, //FIRST SCREEN TO INFORM ABOUT THE OCR
        documentsList: PATHS.list, //AFTER EVERY DOCUMENT IS COMPLETED YOU SEE THE LIST OF DOCUMENTS
      },
    },
    {
      path: PROTECTED_ROUTES.documentsPhotoStatements,
      component: DocumentsPhoto, //INFORMATIONAL SCREEN ABOUT THE OCR
      nextRoute: PATHS.documentsForm, //THE SCREEN WHERE YOU UPLOAD DOCUMENTS
      exact: true,
    },
    // {
    //   path: PROTECTED_ROUTES.documentsCameraEvaluation,
    //   component: DocumentsCamera, //THE SCREEN WHERE YOU UPLOAD DOCUMENTS
    //   nextRoute: PATHS.documentsForm, //THE SCREEN THAT DISPLAYS THE OCR DATA ***YOU CAN MODIFY THE FORM DATA
    //   exact: true,
    // },
    {
      path: PROTECTED_ROUTES.documentsFormStatements,
      component: DocumentsOcrDataForm, //THE SCREEN THAT DISPLAYS THE OCR DATA
      nextRoute: PATHS.documents, //TAKES YOU BACK TO THE DOCUMENTS MAIN SCREEN
    },
    {
      path: PROTECTED_ROUTES.statementDocumentsList, //AFTER YOU COMPLETE THE DOCUMENTS THIS SCREEN WILL BE SHOWN CONTAINING ALL THE DOCUMENTS PICTURE
      component: DocumentsList,
      //DOESN'T HAVE A NEXT ROUTE IT ONLY DISPLAYS THE DOCUMENTS LIST
    },
    {
      path: PROTECTED_ROUTES.statementDocumentsList, //AFTER YOU COMPLETE THE DOCUMENTS THIS SCREEN WILL BE SHOWN CONTAINING ALL THE DOCUMENTS PICTURE
      component: DocumentsList,
      //DOESN'T HAVE A NEXT ROUTE IT ONLY DISPLAYS THE DOCUMENTS LIST
    },
    {
      path: PROTECTED_ROUTES.statementDamageList,
      component: DamagePictureList, // LIST DAMAGE PICTURES SCREEN
      //DOESN'T HAVE A NEXT ROUTE IT ONLY DISPLAYS THE DOCUMENTS LIST
    },
    {
      path: PROTECTED_ROUTES.paymentReturn, //THE SCREEN AFTER A SUCCESS PAYMENT
      component: PaymentReturn,
      nextRoute: PROTECTED_ROUTES.evaluations, // AFTER PAYMENT YOU ARE TAKEN TO EVALUATIONS SCREEN WITH CHANGES STATUS OF EVALUATION
    },
    {
      path: PROTECTED_ROUTES.statementMethodDetailsOffer, // PHASE 3 FROM FLOW, INFO SCREEN WITH AMOUNT
      component: EvaluationMethod,
    },
    {
      path: PROTECTED_ROUTES.statementMethodDetails, // PHASE 2 FROM FLOW, AFTER THE EVALUATION HAS BECOME AN INCOMPLETE STATEMENT
      component: StatementDetails,
      nextRoute: PROTECTED_ROUTES.statementObservationNote,
    },
    {
      path: PROTECTED_ROUTES.statementObservationNote, // ACCESS WHEN observationNode NOT IN questionHandler
      component: ObservationNote,
      nextRoute: PROTECTED_ROUTES.statementWarrant, // FROM HERE WE GO TO WARRANT
    },
    {
      path: PROTECTED_ROUTES.statementWarrant, // WARRANT SCREEN
      component: Warrant,
      nextRoute: PROTECTED_ROUTES.receivableAssignment, // FROM HERE WE GO TO RECEIVABLE ASSIGNMENT
    },
    {
      path: PROTECTED_ROUTES.receivableAssignment, // RECEIVABLE ASSIGNMENT SCREEN
      component: ReceivableAssignment,
      nextRoute: PROTECTED_ROUTES.statementSignature, // FROM HERE WE GO TO SIGNATURE SCREEN
    },
    {
      path: PROTECTED_ROUTES.statementFinding,
      component: FindingNote,
      nextRoute: PROTECTED_ROUTES.statementCompensation,
      exact: true,
    },
    {
      path: PROTECTED_ROUTES.statementRefinding,
      component: ReFinding,
      nextRoute: PROTECTED_ROUTES.statementRefindingInfo,
      exact: true,
    },
    {
      path: PROTECTED_ROUTES.statementRefindingInfo,
      component: ReFindingInfo,
    },
    {
      path: PROTECTED_ROUTES.statementCompensation,
      component: CompensationOffer,
      nextRoute: PROTECTED_ROUTES.statement$IdDetails,
    },
    {
      path: PROTECTED_ROUTES.statementCompensation,
      component: CompensationClaim,
      nextRoute: PROTECTED_ROUTES.statementSignature,
    },
  ],
};
