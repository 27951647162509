import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { getSignatureApi, postSignatureApi } from '../store/statement/currentStatement.action';
import { IStatementBackResponse } from '../@types/current-statement/currentStatementTypes';

const useSignature = () => {
  const dispatch = useDispatch();

  const postSignature = useCallback(
    (payload: FormData, id: number, onSuccess?: (payload: Partial<IStatementBackResponse>) => void) => {
      dispatch(postSignatureApi(payload, id, onSuccess));
    },
    [dispatch],
  );

  const getSignature = useCallback(
    (signatureId: number, onSuccess?: (payload: string) => void) => {
      dispatch(getSignatureApi(signatureId, onSuccess));
    },
    [dispatch],
  );

  return { postSignature, getSignature };
};

export { useSignature };
