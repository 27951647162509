import React from 'react';

import { Profile, UrlNotFound, Utils } from '../../screens';
import { Statements } from '../../screens/statements/Statements';
import { StatementHome } from '../../screens/statement/Home';
import { AscertainmentInfo } from '../../screens/contact-details/AscertainmentInfo';
import { CircumstancesSelection } from '../../screens/statement/CircumstancesSelection';
import { StatementDamagedInsurerData } from '../../screens/statement-damage/StatementDamagedInsurerData';

import { IRoute } from './interfaces/route.model';
import { insuranceRoutes } from './app-routes/insurance';
import { evaluationDetailsDamageRoutes, statementDetailsRoutes, statementsRoutes } from './app-routes/statements-routes';
import { preLoginRoutes } from './app-routes/pre-login-routes';
import { evaluationRoutes } from './app-routes/evaluations-routes';
import { PRE_LOGIN_ROUTES, PROTECTED_ROUTES } from './app-routes/routes-constants';

//TODO use this interface and refactor the code accordingly
/*
export interface IComponentRoute extends RouteProps {
  component: React.FC<any>;
  handle?: string;
  nextRoute?: string | { [key: string]: string };
  childrenRoute?: IRoute[];
  }
  */

export interface IComponentRoute {
  component: React.FC<any>;
  handle?: string;
  path?: string;
  exact?: boolean;
  nextRoute?: string | { guilty?: string; injured?: string } | any;
  children?: IRoute[];
}

export const mainRoutes: IRoute = {
  baseUrl: '/',
  children: [
    ...preLoginRoutes.children,
    ...evaluationRoutes.children,
    ...insuranceRoutes.children,
    ...statementsRoutes.children,
    ...statementDetailsRoutes.children,
    ...evaluationDetailsDamageRoutes.children,

    {
      component: UrlNotFound, //URL NOT FOUND SCREEN
      nextRoute: PRE_LOGIN_ROUTES.root, //WELCOME SCREEN
      exact: true,
    },
    {
      path: '/success2',
      component: StatementDamagedInsurerData,
      // TODO: Fix route when screen logic is done.
      nextRoute: '/statement/1/success',
    },
    {
      path: PROTECTED_ROUTES.whoCompleteAscertainment,
      component: AscertainmentInfo,
    },
    {
      path: PROTECTED_ROUTES.circumstances$Id,
      component: CircumstancesSelection,
      nextRoute: {
        injured: '/hello',
        guilty: '/not hello',
      },
    },
    {
      path: PROTECTED_ROUTES.profile,
      component: Profile,
    },
    {
      path: PROTECTED_ROUTES.utils,
      component: Utils,
    },

    {
      // Has children
      path: PROTECTED_ROUTES.statements,
      component: Statements,
      nextRoute: PROTECTED_ROUTES.statement,
    },
    {
      // Has children
      path: PROTECTED_ROUTES.statement$IdDetails,
      component: StatementHome,
    },
  ],
};
