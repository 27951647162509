import React from 'react';
import styled from 'styled-components';

import { LocationFlatIcon, CircleIcon } from '../../assets/icons';

const StyledListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 15px;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: Roboto
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #1e1e29;
`;

const Subtitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #9a9a9a;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

type IListItemProps = {
  title: string;
  subtitle?: string;
  isMain?: boolean;
  onClick: () => void;
};

export const ListItem: React.FC<IListItemProps> = props => {
  const { title, subtitle, isMain, onClick } = props;

  return (
    <StyledListItem>
      {isMain ? <LocationFlatIcon /> : <CircleIcon />}
      <Content onClick={onClick}>
        <Title style={{ color: isMain ? '#512AD8' : '#1E1E29' }}>{title}</Title>
        {!isMain && <Subtitle>{subtitle}</Subtitle>}
      </Content>
    </StyledListItem>
  );
};
