import * as H from 'history';
import React from 'react';

import { OCR_DOCUMENT_TYPE } from '../../constants/typeConstants/documentsFormType';
import { InsuranceDetailsForm } from '../../components/ocrForms/InsuranceDetailsForm';
import { CarIdentityDetailsForm } from '../../components/ocrForms/CarIdentityDetailsForm';
import { DrivingLicenseDetailsForm } from '../../components/ocrForms/DrivingLicenseDetailsForm';
import { IdCardDetailsForm } from '../../components/ocrForms/IdCardDetailsForm';

const HandleOcrFormsData = () => {
  const handleFormTypesForOcr = (
    documentType: OCR_DOCUMENT_TYPE,
    history: H.History<any>,
    ocrData: any,
    parentRoute: string,
    onSubmitValues: (value: any) => void,
  ) => {
    switch (documentType) {
      case OCR_DOCUMENT_TYPE.ID_CARD:
        return (
          <IdCardDetailsForm
            history={history}
            initialValues={ocrData}
            parentRoute={parentRoute}
            onSubmit={value => onSubmitValues(value)}
          />
        );
      case OCR_DOCUMENT_TYPE.INSURANCE:
        return (
          <InsuranceDetailsForm
            history={history}
            initialValues={ocrData}
            parentRoute={parentRoute}
            onSubmit={value => onSubmitValues(value)}
          />
        );
      case OCR_DOCUMENT_TYPE.CAR_IDENTITY:
        return (
          <CarIdentityDetailsForm
            history={history}
            initialValues={ocrData}
            parentRoute={parentRoute}
            onSubmit={value => onSubmitValues(value)}
          />
        );
      case OCR_DOCUMENT_TYPE.DRIVING_LICENSE:
        return (
          <DrivingLicenseDetailsForm
            history={history}
            initialValues={ocrData}
            parentRoute={parentRoute}
            onSubmit={value => onSubmitValues(value)}
          />
        );
    }
  };

  return { handleFormTypesForOcr };
};

export { HandleOcrFormsData };
