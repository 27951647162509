import React from 'react';

import { IIconProps } from './icon.model';

export const PhoneIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg height={height || 31.533} width={width || 31.467} {...otherProps}>
      <path
        d='M30.642 23.142l-4.4-4.4a2.927 2.927 0 00-4.872 1.1 3 3 0 01-3.458 1.886c-3.143-.786-7.387-4.872-8.172-8.172a2.848 2.848 0 011.885-3.456 2.927 2.927 0 001.1-4.872l-4.4-4.4a3.139 3.139 0 00-4.243 0L1.095 3.811c-2.986 3.143.314 11.473 7.7 18.859s15.716 10.844 18.859 7.7l2.986-2.986a3.139 3.139 0 00.002-4.242z'
        fill={fill || '#fff'}
      />
    </svg>
  );
};
