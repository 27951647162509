export enum MOBILPAY_STATUSES {
  // cand action este `confirmed` avem certitudinea ca banii au plecat din contul posesorului de card si facem update al starii comenzii si livrarea produsului
  // update DB, SET status = "confirmed/captured"
  CONFIRMED = 'confirmed',

  // cand action este `paid` inseamna ca tranzactia este in curs de procesare. Nu facem livrare/expediere. In urma trecerii de aceasta procesare se va primi o noua notificare pentru o actiune de confirmare sau anulare.
  // update DB, SET status = "open/preauthorized"
  PAID = 'paid',

  // cand action este `confirmed_pending`/`paid_pending` inseamna ca tranzactia este in curs de verificare antifrauda. Nu facem livrare/expediere. In urma trecerii de aceasta verificare se va primi o noua notificare pentru o actiune de confirmare sau anulare.
  // update DB, SET status = "pending"
  PAID_PENDING = 'paid_pending',
  CONFIRMED_PENDING = 'confirmed_pending',

  // cand action este `canceled` inseamna ca tranzactia este anulata. Nu facem livrare/expediere.
  // update DB, SET status = "canceled"
  CANCELED = 'canceled',

  // cand action este `credit` inseamna ca banii sunt returnati posesorului de card. Daca s-a facut deja livrare, aceasta trebuie oprita sau facut un reverse.
  // update DB, SET status = "refunded"
  CREDIT = 'credit',
}

export enum PAYMENT_STATUSES {
  SUCCESS = 'success',
  FAIL = 'fail',
  WARNING = 'warning',
}

export enum MOBILPAY_ERROR_CODES_ENUM {
  APPROVED = 0, // approved
  STOLEN_CARD = 16, // card has a risk (e.g. stolen card)
  INVALID_CARD_NUMBER = 17, // card number is incorrect
  CLOSED_CARD = 18, // closed card
  EXPIRED_CARD = 19, // card is expired
  INSUFFICIENT_FUNDS = 20, // insufficient funds
  INVALID_CVV = 21, // cVV2 code incorrect
  UNAVAILABLE_ISSUER = 22, // issuer is unavailable
  INVALID_AMOUNT = 32, // amount is incorrect
  INVALID_CURRENCY = 33, // currency is incorrect
  TRANSACTION_NOT_PERMITTED = 34, // transaction not permitted to cardholder
  TRANSACTION_DECLINED = 35, // transaction declined
  TRANSACTION_REJECTED = 36, // transaction rejected by antifraud filters
  TRANSACTION_DECLINED_1 = 37, // transaction declined (breaking the law)
  TRANSACTION_DECLINED_2 = 38, // transaction declined
  INVALID_REQUEST = 48, // invalid request
  DUPLICATE_PREAUTH = 49, // duplicate PREAUTH
  DUPLICATE_AUTH = 50, // duplicate AUTH
  ONLY_CANCEL_PREAUTH_PERMITTED = 51, // you can only CANCEL a preauth order
  ONLY_CONFIRM_PREAUTH_PERMITTED = 52, // you can only CONFIRM a preauth order
  ONLY_CREDIT_PREAUTH_PERMITTED = 53, // you can only CREDIT a confirmed order
  INVALID_CREDIT_AMOUNT = 54, // credit amount is higher than auth amount
  INVALID_CAPTURE_AMOUNT = 55, // capture amount is higher than preauth amount
  DUPLICATE_REQUEST = 56, // duplicate request
  GENERIC_ERROR = 99, // generic error
}
