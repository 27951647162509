import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS } from '../../translations/ro/home';
import { Button } from '../../components/button';
import { Typography } from '../../styled-components';
import { IScreenComponentProps } from '../component-props.model';

export const StatementOverview = ({ history, nextRoute, flowHandle, parentRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const navigateTo = (event?: React.MouseEvent<HTMLDivElement>): void => {
    event && event.stopPropagation();
    setModalOpen(false);
    history.push(`${parentRoute}${nextRoute}`, { flowHandle });
  };

  const modal = (
    <>
      <Typography variant='h4'>{HOME_TEXTS.damageStatementConfirmModal}</Typography>
      <div style={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: 'space-between', overflow: 'scroll' }}>
        <Typography color='#8e8e89' commonStyles={{ margin: '40px 0 0 0' }} variant='body'>
          {HOME_TEXTS.damageStatementConfirmModalNotice}
        </Typography>

        <div>
          <Button mode='primary' onClick={() => navigateTo()}>
            {HOME_TEXTS.iConfirm}
          </Button>
          <Button margin='25px 0 0 0' mode='link' width='100%' onClick={() => setModalOpen(false)}>
            <Typography align='center' color='#512ad8' variant='h5'>
              {HOME_TEXTS.iDontConfirm}
            </Typography>
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <ScreenContainer
      headerProps={{ hideLogo: true, title: HOME_TEXTS.damageStatement }}
      isModalOpen={modalOpen}
      modal={modal}
      modalProps={{ show: modalOpen, onClose: () => setModalOpen(false) }}
      showBottomNavbar={false}
    >
      <Typography variant='h4'>{HOME_TEXTS.damageStatement}</Typography>
      <Button mode='primary' style={{ marginTop: 'auto' }} onClick={() => setModalOpen(true)}>
        {t('commonText.continue')}
      </Button>
    </ScreenContainer>
  );
};
