import React from 'react';

import { IIconProps } from './icon.model';

export const UtilsIcon = ({ width, height, fill, selected, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg height={height || 30} width={width || 30} {...otherProps}>
      <rect fill={selected ? '#4123d7' : '#fff'} height={30} rx={15} width={30} />
      <path
        d='M15 25C9.477 25 5 20.523 5 15S9.477 5 15 5s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10zm-8-9.828A8 8 0 107 15v.172zM17 20h-3v-4h-1v-2h3v4h1v2zm-1-8h-2v-2h2v2z'
        fill={selected ? '#fff' : '#8e8d8d'}
      />
    </svg>
  );
};
