import React from 'react';

import { IIconProps } from './icon.model';

export const AddSquareIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg height={height || 20.333} width={width || 20.333} {...otherProps}>
      <path
        d='M16.7 0H3.631A3.635 3.635 0 000 3.631V16.7a3.635 3.635 0 003.631 3.631H16.7a3.635 3.635 0 003.633-3.631V3.631A3.635 3.635 0 0016.7 0zm-2.9 10.893h-2.9v2.9a.726.726 0 11-1.452 0v-2.9h-2.9a.726.726 0 110-1.452h2.9v-2.9a.726.726 0 111.452 0v2.9h2.9a.726.726 0 110 1.452z'
        fill={fill || '#fc0'}
      />
    </svg>
  );
};
