import React from 'react';
import { useTranslation } from 'react-i18next';
import * as H from 'history';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../button';

interface IConfirmNavigationModal {
  setShowModal: (showModal: boolean) => void;
  lastLocation?: string | null | undefined;
  history?: H.History;
  setConfirmNavigation?: (confirmNavigation: boolean) => void;
  toEvaluations?: boolean;
  toStatements?: boolean;
  onConfirm?: () => void;
}

export const ConfirmNavigationModal = ({
  setConfirmNavigation,
  setShowModal,
  history,
  onConfirm,
}: IConfirmNavigationModal): JSX.Element => {
  const { t } = useTranslation();

  const stayOnPage = () => {
    if (setConfirmNavigation) {
      setConfirmNavigation(false);
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  const leavePage = () => {
    if (history) {
      if (setConfirmNavigation) {
        setConfirmNavigation(true);
        setShowModal(false);
      } else {
        setShowModal(false);
        onConfirm && onConfirm();
        history.goBack();
      }
    }
  };

  return (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 100px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '14px 0 0 0' }} fontWeight='700' variant='h2'>
          {t('commonText.confirmBack')}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button
          background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
          borderRadius='25px'
          color='#FFFFFF'
          width='100%'
          onClick={() => {
            leavePage();
          }}
        >
          {t('commonText.yes')}
        </Button>

        <Button margin='15px 0 0 0' mode='link' onClick={() => stayOnPage()}>
          <Typography color='#512AD8' variant='h5'>
            {t('commonText.no')}
          </Typography>
        </Button>
      </Wrapper>
    </>
  );
};
