import { AppDispatch, AppThunk } from '../store';
import { removeAuthUser } from '../auth-user/auth.slice';

import { logIn, logOut } from './app.slice';

export const logInAction = (payload: string, onSuccess?: () => void): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(logIn(payload));
    localStorage.setItem('access_token', payload);

    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    console.log('Error', e.message);
  }
};

export const logOutAction = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(logOut());
    dispatch(removeAuthUser());
  } catch (e) {
    console.log('Error', e.message);
  }
};
