import React from 'react';

import { IIconProps } from './icon.model';

export const AppleIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 20} viewBox='0 0 17 20' width={width || 17} {...otherProps}>
      <path
        d='M16.633 15.586a10.853 10.853 0 01-1.084 1.933c-.57.807-1.037 1.365-1.397 1.674-.558.509-1.155.77-1.795.784-.46 0-1.014-.13-1.659-.392-.647-.262-1.241-.392-1.785-.392-.57 0-1.182.13-1.836.392-.655.263-1.183.4-1.586.413-.614.026-1.226-.242-1.836-.805-.39-.337-.877-.914-1.461-1.733-.627-.874-1.142-1.888-1.545-3.043C.217 13.169 0 11.96 0 10.79c0-1.34.292-2.496.877-3.465a5.123 5.123 0 011.837-1.842 4.973 4.973 0 012.484-.695c.487 0 1.126.15 1.92.443.793.295 1.301.444 1.524.444.167 0 .732-.175 1.69-.523.905-.323 1.67-.457 2.296-.404 1.697.135 2.972.799 3.82 1.994-1.518.912-2.269 2.189-2.254 3.827.014 1.276.48 2.338 1.398 3.181.416.392.88.694 1.397.909-.112.322-.23.63-.356.927zM12.741.4c0 1-.368 1.934-1.103 2.799-.886 1.027-1.958 1.621-3.121 1.527a3.087 3.087 0 01-.024-.379c0-.96.422-1.987 1.17-2.828.374-.425.85-.779 1.426-1.061C11.664.18 12.21.026 12.72 0c.015.134.021.267.021.4z'
        fill={fill || 'white'}
      />
    </svg>
  );
};
