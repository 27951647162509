import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Typography } from '@material-ui/core';
import throttle from 'lodash.throttle';
import Pagination from '@material-ui/lab/Pagination';

import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { putStatementDocuments } from '../../store/statement/currentStatement.api';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  uploadedPdf: string | number;
  handleClose: (modalValue: boolean) => void;
}

export const PdfDocument = ({ uploadedPdf, handleClose }: IProps) => {
  const documentRef = useRef<HTMLDivElement | null>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const { currentStatement } = useCurrentStatement();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [nrOfPages, setNrOfPages] = useState(1);
  const [pdfWrapperWidth, setPdfWrapperWidth] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const onDocumentLoadSuccess = useCallback((data: any) => {
    setNumPages(data.numPages as number);
    setNrOfPages(data.numPages as number);
    setRefresh(prev => prev + 1);
  }, []);

  const createFileFromBlob = async (document: any): Promise<any> => {
    return await fetch(document).then(response =>
      response.blob().then(myBlob => {
        return new File([myBlob], 'pdf' as string, {
          type: 'application/pdf',
        });
      }),
    );
  };

  const updateDocument = async () => {
    return createFileFromBlob(uploadedPdf)
      .then(res => {
        const formData = new FormData();
        formData.append('file', res);
        formData.append('id', currentStatement?.statementID?.toString() as string);
        formData.append('type', 'powerOfAttorney');
        putStatementDocuments(formData)
          .then(() => {
            handleClose(false);
          })
          .catch(e => e);
      })
      .then(() => setPageNumber(1));
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setDivSize, 500));

    return () => window.removeEventListener('resize', throttle(setDivSize, 500));
  }, [documentRef]);

  useEffect(() => {
    setDivSize();
  }, [refresh]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(Number(value));
  };

  const setDivSize = () => {
    if (documentRef.current) setPdfWrapperWidth(documentRef.current.getBoundingClientRect().width);
  };

  return (
    <div ref={documentRef}>
      <Document
        file={uploadedPdf}
        options={{ workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js` }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={pdfWrapperWidth} />
      </Document>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', flexDirection: 'column' }}>
        <Typography align='center'>Pagina: {pageNumber}</Typography>
        <Pagination count={numPages} page={pageNumber} size='small' onChange={handleChange} />
      </div>
      <Button
        style={{ margin: '20px auto', padding: '0 10px', display: 'block', borderRadius: '25px', height: '30px', width: 'auto' }}
        variant='contained'
        onClick={() => {
          updateDocument();
        }}
      >
        Confirmă document
      </Button>
    </div>
  );
};
