import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Image, Typography, Wrapper } from '../../styled-components';
import ReceivableAssignmentPhoto from '../../assets/images/receivableAssignment.png';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { ASSESSMENTS_TEXT } from '../../translations/ro/home';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

export const ReceivableAssignment = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentStatement } = useCurrentStatement();
  const [showModal, setShowModal] = useState<boolean>(false);

  const onConfirm = () => {
    if (currentStatement.statementID !== null) {
      history.push(generatePath(`${PROTECTED_ROUTES.statementSignature}`, { currentStatementId: currentStatement.statementID }), {
        modalActive: false,
      });
    }
  };

  const confirmModalFinding = (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '15px 0 15px 0' }} variant='h4'>
          {t('compensation.confirmReceivableAssignment')}
        </Typography>
        <Typography color='#8E8E8E' variant='body'>
          {t('compensation.confirmFindingContent')}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button mode='primary' onClick={() => onConfirm()}>
          {t('compensation.buttonConfirm')}
        </Button>
        <Button
          margin='15px 0 0 0'
          mode='link'
          onClick={() => {
            setShowModal(false);

            if (currentStatement.statementID !== null) {
              history.push(generatePath(`${PROTECTED_ROUTES.receivableAssignment}`, { currentStatementId: currentStatement.statementID }), {
                modalActive: false,
              });
            }
          }}
        >
          <Typography color='#512AD8' variant='h5'>
            {t('compensation.buttonBack')}
          </Typography>
        </Button>
      </Wrapper>
    </>
  );

  return (
    <ScreenContainer
      headerProps={{ title: ASSESSMENTS_TEXT.receivableAssignment, hideLogo: true }}
      isModalOpen={showModal}
      modal={confirmModalFinding}
      modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
      showBottomNavbar={false}
    >
      <Wrapper style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Image height='428px' src={ReceivableAssignmentPhoto} width='315px' />
        <Button mode='primary' onClick={() => setShowModal(true)}>
          {t('commonText.continue')}
        </Button>
      </Wrapper>
    </ScreenContainer>
  );
};
