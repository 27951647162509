import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card, Column, Image, Row, Typography, Wrapper } from '../../styled-components';
import { IScreenComponentProps } from '../component-props.model';
import { USER_SITUATION } from '../../services/router/routeHandles';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { INSURANCE_MOCK } from '../../mock/insurance-mock';
import { useInsurance } from '../../hooks/useInsurance';
import { PreLoginInsuranceData } from '../../@types/pre-login/preLoginTypes';
import { preLoginInsuranceInitialState } from '../../store/pre-login/initialState';
import { ChooseInsuranceConstants } from '../../constants/dataConstants/ChooseInsuranceConstants';
import { RootState } from '../../store/rootReducer';

import { DirectSettlement } from './DirectSettlement';

export const ChooseInsurance = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { digitalStatementFlow } = ChooseInsuranceConstants();
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);

  const [flowHandle, setFlowHandle] = useState<USER_SITUATION>(USER_SITUATION.INJURED_STATUS);
  const [currentInsurance, setCurrentInsurance] = useState<PreLoginInsuranceData>(preLoginInsuranceInitialState);
  const {
    selectInsurance,
    showModalDirectSettlement,
    setShowModalDirectSettlement,
    handleDirectSettlement,
    setHandleNextRoute,
  } = useInsurance(flowHandle, history, nextRoute);

  const getInsurance = (insurance: PreLoginInsuranceData) => {
    setCurrentInsurance(insurance);
  };

  useEffect(() => {
    setHandleNextRoute(nextRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {flowHandle && (
        <>
          <ScreenContainer
            headerProps={{ hideLogo: true, title: digitalStatementFlow[flowHandle]?.pageTitle }}
            isModalOpen={showModalDirectSettlement}
            modal={
              <DirectSettlement
                handleDirectSettlement={handleDirectSettlement}
                insurance={currentInsurance}
                setFlowHandle={setFlowHandle}
              />
            }
            modalProps={{
              show: showModalDirectSettlement,
              onClose: () => {
                setShowModalDirectSettlement(false);
              },
            }}
            showBottomNavbar={isLoggedIn}
          >
            {/*MOCK MAP*/}
            <Wrapper display='flex' flexDirection='column' justifyContent='flex-start' width='100%'>
              <Typography commonStyles={{ margin: '0 0 1rem 0', width: '100%' }} fontWeight='700' variant='h4'>
                {digitalStatementFlow[flowHandle]?.title}
                {digitalStatementFlow[flowHandle]?.name && (
                  <Typography fontSize='20px' fontWeight='700' variant='body' {...digitalStatementFlow[flowHandle]?.props}>
                    {digitalStatementFlow[flowHandle]?.name}
                  </Typography>
                )}
              </Typography>
              <Row display='grid' gridTemplateColumns='repeat(2, 1fr)'>
                {INSURANCE_MOCK.map((insurance: PreLoginInsuranceData) => {
                  return (
                    <Column key={insurance.insurerName} maxWidth='100%' paddingLeft='12px' paddingRight='12px' paddingTop='8px'>
                      <Card
                        alignItems='center'
                        borderRadius='10px'
                        boxShadow='0px 2px 15px rgba(134, 112, 251, 0.2)'
                        onClick={() => {
                          getInsurance(insurance);
                          selectInsurance(insurance);
                        }}
                      >
                        <Image src={insurance?.image} width='100%' />
                      </Card>
                    </Column>
                  );
                })}
              </Row>
            </Wrapper>
          </ScreenContainer>
        </>
      )}
    </>
  );
};
