import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { TextField } from '../text_field';
import { IWitness } from '../../@types/witnesses/witnessesTypes';
import { Typography } from '../../styled-components';
import { HOME_TEXTS, PROFILE_TEXTS } from '../../translations/ro/home';
import { PHONE_REGEX } from '../../shared/constants';
import { Button } from '../button';

interface IWitnessFormProps {
  initialValues: IWitness;
  onSubmit: (formData: IWitness) => void;
  onSkip: () => void;
  hasWitnesses: boolean;
}

export const WitnessAddEditForm = ({ initialValues, onSubmit, onSkip, hasWitnesses }: IWitnessFormProps): JSX.Element => {
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    address: Yup.string().required(),
    phone: Yup.string().matches(new RegExp(PHONE_REGEX)).required(),
    email: Yup.string().email().required(),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={ValidationSchema} enableReinitialize validateOnMount onSubmit={onSubmit}>
      {({ handleChange, handleBlur, values, errors, isValid }) => {
        return (
          <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
            {hasWitnesses && (
              <div>
                <Typography
                  color='#8E8E8E'
                  commonStyles={{ margin: '10px 0 10px 0' }}
                  extendedStyles={{ fontStyle: 'italic', lineHeight: '14px' }}
                  variant='body-xs'
                >
                  {HOME_TEXTS.dateWitnesses}
                </Typography>

                <TextField
                  errorMessage={errors.name}
                  label={HOME_TEXTS.name}
                  name='name'
                  value={values.name}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  errorMessage={errors.address}
                  label={HOME_TEXTS.address}
                  name='address'
                  value={values.address}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  errorMessage={errors.phone}
                  label={PROFILE_TEXTS.telephone}
                  name='phone'
                  value={values.phone}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  errorMessage={errors.email}
                  label={PROFILE_TEXTS.email}
                  name='email'
                  value={values.email}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            )}
            <br /> <br />
            <Button
              background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
              border='none'
              borderRadius='25px'
              color='#FFFF'
              disabled={hasWitnesses && !isValid}
              disabledColor='#DADADA'
              type='submit'
              onClick={() => {
                if (hasWitnesses) {
                  return values;
                }
                onSkip();
              }}
            >
              {HOME_TEXTS.save}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
