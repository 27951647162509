import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../containers';
import { STATUS_CARD } from '../../@types/statement-evaluation-cards/statementOrEvaluationCardsTypes';

export interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  position?: string;
  display?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  padding?: string;
  margin?: string;
  marginBottom?: string;
  width?: string;
  height?: string;
  minHeight?: string;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  backgroundColor?: string;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

const StyledCard = styled.div`
  padding: ${({ padding }: ICardProps) => padding || 0};
  margin: ${({ margin }: ICardProps) => margin || '0'};
  min-height: ${({ minHeight }: ICardProps) => minHeight || '5rem'};
  position: ${({ position }: ICardProps) => position || 'relative'};
  display: ${({ display }: ICardProps) => display || 'flex'};
  flex-direction: ${({ flexDirection }: ICardProps) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }: ICardProps) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }: ICardProps) => alignItems || 'start'};
  color: #20344c;
  width: ${({ width }: ICardProps) => width || '100%'};
  background-color: ${({ backgroundColor }: ICardProps) => backgroundColor || '#ffffff'};
  border: ${({ border }: ICardProps) => border || '1px solid #e7e7e7'};
  border-radius: ${({ borderRadius }: ICardProps) => borderRadius || '3px'};
  ${({ boxShadow }: ICardProps) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
  ${({ height }: ICardProps) =>
    height &&
    css`
      height: ${height};
    `}
  ${({ marginBottom }: ICardProps) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;

const CardText = styled.div`
  max-width: 80%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const CardTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
  margin: 0;
`;
const CardStatus = styled.p`
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #fff;
  background-color: ${({ color }) => color || '#aeaeae'};
  border-radius: 50px;
  padding: 5px 10px;
`;

const VerticalLine = styled.div`
  position: absolute;
  width: 32px;
  top: 76px;
  transform: rotate(90deg);
  border: 2px solid ${({ status }: ICardButtonProps) => (STATUS_CARD.COMPLETE === status ? '#2da455' : '#aeaeae')};
`;

const WelcomeVerticalLine = styled.div`
  position: absolute;
  width: 1px;
  height: 70%;
  top: 1.95rem;
  left: 7px;
  background: rgba(65, 35, 215, 0.5);
`;

const WelcomeCardTitle = styled.p`
  font-weight: 400;
  margin-left: 10px;
  font-size: 12px;
  line-height: 14px;
`;

export interface ICardButtonProps extends ICardProps {
  active?: boolean;
  iconLeft?: React.ReactNode;
  minHeight?: string;
  status?: STATUS_CARD;
  title?: string;
  verticalLine?: boolean;
  welcomeCard?: boolean;
}

const StyledCardButton = styled(StyledCard)`
  height: ${({ height }: ICardButtonProps) => height || 'initial'};
  margin-bottom: ${({ marginBottom }: ICardButtonProps) => marginBottom || 0};
  display: flex;
  min-height: ${({ minHeight }: ICardButtonProps) => minHeight || '3rem'};
  align-items: center;
  position: relative;
  justify-content: space-between;
`;
export const CardButton = (props: ICardButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { welcomeCard, iconLeft, status, verticalLine, title, ...otherProps } = props;

  const STATEMENT_STATUS_TEXTS = {
    COMPLETE: t('statements.statusComplete'),
    INCOMPLETE: t('statements.statusIncomplete'),
  };

  return (
    <StyledCardButton {...otherProps}>
      {!welcomeCard ? (
        <>
          {iconLeft}
          {verticalLine && <VerticalLine status={status} />}
          <CardText>
            <CardTitle color={STATUS_CARD.COMPLETE === status ? '#00160a' : '#ababad'}>{title}</CardTitle>
            {status && (
              <CardStatus color={STATUS_CARD.COMPLETE === status ? '#2da455' : '#ababad'}>{STATEMENT_STATUS_TEXTS[status]}</CardStatus>
            )}
          </CardText>
        </>
      ) : (
        <div>
          <Wrapper>
            <div style={{ borderRadius: '50%', background: 'rgba(65, 35, 215, 0.5)', width: '15px', height: '15px' }} />
            <WelcomeCardTitle>{title}</WelcomeCardTitle>
          </Wrapper>
          {verticalLine && <WelcomeVerticalLine />}
        </div>
      )}
    </StyledCardButton>
  );
};

export const Card = (props: ICardProps): JSX.Element => {
  return <StyledCard {...props} />;
};
