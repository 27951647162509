import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { ASSESSMENTS_TEXT } from '../../translations/ro/home';
import { Image, Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import AddPictureBackground from '../../assets/images/add-picture.png';
import { TextArea } from '../../styled-components/textarea';
import { CloseIcon } from '../../assets/icons';

export const ReFindingInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const [pictures, setPictures] = useState<{ id: number; data: string | ArrayBuffer | null }[]>([
    {
      id: 1,
      data: '',
    },
    {
      id: 2,
      data: '',
    },
  ]);
  const [damageDescription, setDamageDescription] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    const imageFound = pictures.find(picture => picture.data);
    setButtonDisabled(!imageFound || damageDescription.length < 20);
  }, [pictures, damageDescription]);

  const addPicture = () => {
    const lastPictureId = pictures[pictures.length - 1].id;
    setPictures(prevState => [
      ...prevState,
      {
        id: lastPictureId + 1,
        data: '',
      },
    ]);
  };

  const setPictureData = (target: EventTarget & HTMLInputElement) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const pictureId = target.id;

        const reader = new FileReader();
        reader.onloadend = () => {
          const newPictures = pictures.map(picture => (picture.id === parseInt(pictureId) ? { ...picture, data: reader.result } : picture));
          setPictures(newPictures);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const removePicture = (e: React.MouseEvent) => {
    const currentPictureId = e.currentTarget.id;

    const newPictures = pictures.map(picture => (picture.id === parseInt(currentPictureId) ? { ...picture, data: '' } : picture));
    setPictures(newPictures);
  };

  const sendData = () => {
    // TODO: Filter pictures and send only those with data !== (null & '')
  };

  return (
    <ScreenContainer headerProps={{ title: ASSESSMENTS_TEXT.reFindingTitle, hideLogo: true, titleSize: 'h5' }}>
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {ASSESSMENTS_TEXT.addMissingPictures}
      </Typography>
      <Wrapper flexWrap='wrap' width='100%'>
        {pictures.map(picture => (
          <div key={picture.id.toString()} style={{ position: 'relative', cursor: 'pointer' }}>
            {pictures[picture.id - 1].data && (
              <div
                id={picture.id.toString()}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  backgroundColor: '#db4437',
                  borderRadius: 50,
                  padding: 7,
                  zIndex: 1,
                  top: -10,
                  right: -10,
                  cursor: 'pointer',
                }}
                onClick={removePicture}
              >
                <CloseIcon fill='#fff' />
              </div>
            )}

            <input
              accept='image/*'
              id={picture.id.toString()}
              style={{ display: 'none' }}
              type='file'
              onChange={e => setPictureData(e.target)}
            />
            <Image
              key={picture.id.toString()}
              backgroundColor='white'
              borderRadius='10px'
              height='145px'
              id={picture.id.toString()}
              margin='0 0 20px 0'
              src={picture.data !== '' ? picture.data?.toString() : AddPictureBackground}
              width='145px'
              onClick={() => (pictures[picture.id - 1].data ? null : document.getElementById(picture.id.toString())?.click())}
            />
          </div>
        ))}
        <Button color='#512ad8' mode='link' style={{ fontSize: 15, fontWeight: 700 }} onClick={addPicture}>
          {ASSESSMENTS_TEXT.addPicture}
        </Button>
      </Wrapper>

      <Typography commonStyles={{ margin: '28px 0 20px 0' }} fontWeight='700' variant='h4'>
        {ASSESSMENTS_TEXT.addAdditionalDamageDescription}
      </Typography>

      <TextArea
        borderColor={damageDescription.length < 20 ? '#C54554' : '#E7E7E7'}
        error={damageDescription.length < 20}
        placeholder={ASSESSMENTS_TEXT.additionalDamageDescription}
        rows={4}
        style={{
          minHeight: 100,
        }}
        value={damageDescription}
        onChange={e => setDamageDescription(e.currentTarget.value)}
      />
      <div style={{ padding: 10 }} />
      <Button disabled={buttonDisabled} mode='primary' style={{ marginTop: 'auto' }} onClick={sendData}>
        {t('commonText.continue')}
      </Button>
    </ScreenContainer>
  );
};
