import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { ASSESSMENTS_TEXT } from '../../translations/ro/home';
import { Image, Typography, Wrapper } from '../../styled-components';
import Finding from '../../assets/images/notaconstatare.png';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

export const Warrant = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { currentStatement } = useCurrentStatement();

  const onConfirm = () => {
    if (currentStatement.statementID !== null) {
      if (!currentStatement.insuredId) {
        if (!currentStatement.observationNoteId)
          history.push(generatePath(PROTECTED_ROUTES.statementObservationNote, { currentStatementId: currentStatement.statementID }));
        else if (!currentStatement.compensationOfferId)
          history.push(generatePath(PROTECTED_ROUTES.statementCompensation, { currentStatementId: currentStatement.statementID }));
        else history.push(generatePath(`${PROTECTED_ROUTES.receivableAssignment}`, { currentStatementId: currentStatement.statementID }));
      } else {
        history.push(generatePath(`${PROTECTED_ROUTES.receivableAssignment}`, { currentStatementId: currentStatement.statementID }));
      }
    }
  };

  const goBack = () => {
    setShowModal(false);

    if (currentStatement.statementID !== null) {
      history.push(generatePath(`${PROTECTED_ROUTES.statementWarrant}`, { currentStatementId: currentStatement.statementID }), {
        modalActive: false,
      });
    }
  };

  const confirmModalFinding = (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '15px 0 15px 0' }} fontWeight='700' variant='h4'>
          {ASSESSMENTS_TEXT.confirmWarrant}
        </Typography>
        <Typography color='#8E8E8E' variant='body'>
          {ASSESSMENTS_TEXT.confirmFindingContent}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button mode='primary' onClick={() => onConfirm()}>
          {ASSESSMENTS_TEXT.confirm}
        </Button>
        <Button margin='15px 0 0 0' mode='link' onClick={() => goBack()}>
          <Typography color='#512AD8' variant='h5'>
            {t('compensation.buttonBack')}
          </Typography>
        </Button>
      </Wrapper>
    </>
  );

  return (
    <ScreenContainer
      headerProps={{ title: ASSESSMENTS_TEXT.warrant, hideLogo: true }}
      isModalOpen={showModal}
      modal={confirmModalFinding}
      modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
      showBottomNavbar={false}
    >
      <Wrapper style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Image src={Finding} style={{ maxWidth: '100%' }} />
        <Button margin='15px 0 0 0' mode='primary' onClick={() => setShowModal(true)}>
          {t('commonText.continue')}
        </Button>
      </Wrapper>
    </ScreenContainer>
  );
};
