import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../styled-components/typography';
import { Image } from '../../styled-components/image';
import { Wrapper } from '../../styled-components';
import { useDamageSurfaceConstants } from '../../hooks/useDamageSurfaceConstants';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { USER_SITUATION } from '../../services/router/routeHandles';
import { CoordsSetI, IStatementDamage } from '../../@types/damage/damageType';

import { DamageIndicator } from './DamageIndicator';

interface PropsI {
  sideRef: any;
  text: string;
  sideAuto: any;
  image: string;
  allowIfSelected: any;
  side: string;
}

const damageSet: Set<string> = new Set();
const leftSideCoordsSet: Set<any> = new Set();
const rightSideCoordsSet: Set<any> = new Set();

export const DamageSurface = React.memo(({ allowIfSelected, sideRef, text, sideAuto, image, side }: PropsI) => {
  const { t } = useTranslation();
  const isLeftSide = () => text === t('carDamage.leftSideCar');
  const { CAR_PARTS_ARR } = useDamageSurfaceConstants(isLeftSide);
  const [damageArray, setDamageArray] = useState<string[]>([]);
  const { currentStatement, getDamage } = useCurrentStatement();
  const { injured } = currentStatement;
  const { damage } = injured;
  const [damageIndicatorCoords, setDamageIndicatorCoords] = useState<{ leftSide: CoordsSetI[]; rightSide: CoordsSetI[] }>({
    leftSide: damage?.zone?.leftSide || [],
    rightSide: damage?.zone?.rightSide || [],
  });
  const getCoords = () => {
    const partOfTheCar = CAR_PARTS_ARR.find((index: { name: string; value: number }) => {
      return sideAuto?.x < (index.value / 100) * sideAuto?.elementWidth;
    });

    const part = partOfTheCar?.name;

    if (part != null && !damageSet.has(part)) {
      const coords = {
        leftSide: () => {
          if (isLeftSide() && sideAuto?.x !== null) return { x: sideAuto?.x, y: sideAuto?.y, part };
        },
        rightSide: () => {
          if (!isLeftSide() && sideAuto?.x !== null) return { x: sideAuto?.x, y: sideAuto?.y, part };
        },
      };
      leftSideCoordsSet.add(coords.leftSide());
      rightSideCoordsSet.add(coords.rightSide());
      damageSet.add(part);
    }
    setDamageArray(Array.from(damageSet));

    leftSideCoordsSet.delete(undefined);
    rightSideCoordsSet.delete(undefined);
    setDamageIndicatorCoords({
      leftSide: Array.from(leftSideCoordsSet),
      rightSide: Array.from(rightSideCoordsSet),
    });
    const payload: IStatementDamage = {
      ...damage,
      zone: {
        leftSide: Array.from(leftSideCoordsSet),
        rightSide: Array.from(rightSideCoordsSet),
      },
    };

    getDamage(USER_SITUATION.INJURED_STATUS, payload);

    if (damageArray.length < 1) allowIfSelected(true);
  };

  return (
    <Wrapper display='block' margin='20px auto'>
      <Typography color='#8E8E89' fontWeight='bold' variant='body-xs'>
        {text}
      </Typography>
      <div
        ref={sideRef}
        style={{ position: 'relative', margin: '30px auto', height: '110px', display: 'flex', width: '310px' }}
        onClick={() => getCoords()}
        onTouchEnd={e => {
          e.preventDefault();
          getCoords();
        }}
      >
        <Image height='auto' margin='0 auto' src={image} width='100%' />
        {(side === 'left' ? damageIndicatorCoords.leftSide : damageIndicatorCoords.rightSide).map((index, key: number) => {
          return (
            <DamageIndicator
              key={key}
              damagePartsSet={damageSet}
              isLeftSide={isLeftSide}
              leftSideCoordsSet={leftSideCoordsSet}
              part={index?.part}
              rightSideCoordsSet={rightSideCoordsSet}
              x={index?.x}
              y={index?.y}
            />
          );
        })}
      </div>
    </Wrapper>
  );
});
