import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *:focus {
    outline: none !important;
  }
  body {
    -webkit-tap-highlight-color: transparent;
    color: #1E1E29;
  }
`;

export default GlobalStyle;
