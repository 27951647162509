import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IAppInitialState = {
  isLoggedIn: boolean;
  timestamp: number;
  authenticationToken: string | null;
  isLoading?: boolean;
};

// initialize the state of the slice by passing data
const initialState: IAppInitialState = {
  isLoggedIn: false,
  timestamp: new Date().getTime(),
  authenticationToken: null,
  isLoading: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    logIn(state, action: PayloadAction<string>) {
      const appStatus: IAppInitialState = {
        isLoggedIn: true,
        authenticationToken: action.payload,
        timestamp: new Date().getTime(),
      };
      state.isLoggedIn = appStatus.isLoggedIn;
      state.authenticationToken = appStatus.authenticationToken;
      state.timestamp = appStatus.timestamp;
      localStorage.setItem('app-status', JSON.stringify(appStatus));
    },
    logOut(state: IAppInitialState) {
      state.isLoggedIn = false;
      state.authenticationToken = '';
      state.timestamp = 0;
      //localStorage.removeItem('app-status');
      localStorage.removeItem('access_token');
    },
  },
});

export default appSlice.reducer;

export const { logIn, logOut, setLoading } = appSlice.actions;
