import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Image, Typography } from '../../styled-components';
import { Button } from '../../components/button';
import PaperPlane from '../../assets/svgs/paper-plane.svg';
import Error from '../../assets/svgs/error.svg';
import Alert from '../../assets/svgs/alert.svg';
import CircleWarningIcon from '../../assets/svgs/circle-warning.svg';
import SmallAlert from '../../assets/svgs/small-alert.svg';
import Warning from '../../assets/svgs/info.svg';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { MOBILPAY_ERROR_CODES_ENUM, MOBILPAY_STATUSES } from '../global/constants';
import { getPaymentOrderRequest } from '../../services/api/paymentOrder/paymentOrder.api';
import { setLoading } from '../../store/app/app.slice';
import { usePayment } from '../../hooks/usePayment';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { PaymentConfig } from '../../services/api/paymentOrder/paymentOrder.types';

export const PAYMENT_RETURN_SWR = {
  getPaymentOrder: 'getPaymentOrder',
};

export const PaymentReturn = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const payment = usePayment();
  const { currentStatement } = useCurrentStatement();

  const [paymentConfig, setPaymentConfig] = useState<PaymentConfig | null>(null);

  const searchParams = new URLSearchParams(location.search);
  const mobilPayOrderId = Number(searchParams.get('orderId'));

  const { data: paymentOrder, error: requestError } = useSWR(PAYMENT_RETURN_SWR.getPaymentOrder, () =>
    getPaymentOrderRequest(mobilPayOrderId),
  );

  const preparePaymentConfig = () => {
    const isSuccess = paymentOrder?.status === MOBILPAY_STATUSES.CONFIRMED;
    const isPending = paymentOrder?.status === MOBILPAY_STATUSES.CONFIRMED_PENDING;

    const errorCode = paymentOrder?.errorCode;

    const config = {
      isError: false,
      icon: PaperPlane,
      title: t('paymentModal.informationSent'),
      subtitle: t('paymentModal.receiveInfoAboutPayment'),
    };

    if (isPending) {
      config.icon = CircleWarningIcon;
      config.title = t('payment.errorMessages.status');
      config.subtitle = t('payment.errorDetails.detailsStatus');
    } else if (!isSuccess && errorCode !== MOBILPAY_ERROR_CODES_ENUM.APPROVED) {
      config.isError = true;

      switch (errorCode) {
        case MOBILPAY_ERROR_CODES_ENUM.INVALID_CVV:
          config.icon = Alert;
          config.title = t('payment.errorMessages.invalidCVC');
          config.subtitle = t('payment.errorDetails.invalidCVC');
          break;
        case MOBILPAY_ERROR_CODES_ENUM.INSUFFICIENT_FUNDS:
          config.icon = Error;
          config.title = t('payment.errorMessages.insufficientFunds');
          config.subtitle = t('payment.errorDetails.insufficientFunds');
          break;
        case MOBILPAY_ERROR_CODES_ENUM.EXPIRED_CARD:
          config.icon = Alert;
          config.title = t('payment.errorMessages.expiredCard');
          config.subtitle = t('payment.errorDetails.expiredCard');
          break;
        case MOBILPAY_ERROR_CODES_ENUM.TRANSACTION_NOT_PERMITTED:
          config.icon = Alert;
          config.title = t('payment.errorMessages.transactionNotPermitted');
          config.subtitle = t('payment.errorDetails.transactionNotPermitted');
          break;
        default:
          config.icon = Warning;
          config.title = t('payment.errorMessages.genericError');
          config.subtitle = t('payment.errorDetails.genericError');
      }
    }

    setPaymentConfig(config);
  };

  useEffect(() => {
    dispatch(setLoading(true));
  }, []);

  useEffect(() => {
    // handle loading
    if (requestError) {
      dispatch(setLoading(false));
      // TODO: log the backend error. Send it to the developers emails.
    }

    if (paymentOrder !== undefined) {
      dispatch(setLoading(false));

      preparePaymentConfig();
    }
  }, [requestError, paymentOrder]);

  const handleRepeatPayment = async () => {
    if (currentStatement.statementID) {
      await payment.initializePayment(currentStatement.statementID);
    }
  };

  const renderActionButtons = () => {
    if (paymentConfig?.isError) {
      return (
        <>
          <Button mode='primary' onClick={() => handleRepeatPayment()}>
            {t('commonText.retryPayment')}
          </Button>
          <Button margin='15px 0' mode='link' onClick={() => history.push(PROTECTED_ROUTES.evaluations)}>
            <Typography align='center' color='#512AD8' fontSize='15px' fontWeight='bold' lineHeight='18px' variant='h5'>
              {t('commonText.goBack')}
            </Typography>
          </Button>
        </>
      );
    }

    return (
      <Button mode='primary' onClick={() => history.push(nextRoute)}>
        {t('commonText.ok')}
      </Button>
    );
  };

  return (
    <ScreenContainer headerType='none'>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
        {paymentConfig?.icon && <Image margin='0 auto' src={paymentConfig?.icon as string} width='50%' />}
        {paymentConfig?.title && (
          <Typography align='center' commonStyles={{ margin: '40px 0 20px 0' }} fontWeight='700' variant='h4'>
            {paymentConfig.title}
          </Typography>
        )}
        {paymentConfig?.isError && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Image margin='0 auto' src={SmallAlert} width='10%' />
            <Typography align='left' color='#E84346' commonStyles={{ padding: '0 2%' }} fontStyle='oblique' variant='body'>
              {paymentConfig.subtitle}
            </Typography>
          </div>
        )}
        {!paymentConfig?.isError && paymentConfig?.subtitle && (
          <Typography align='left' color='#E84346' commonStyles={{ padding: '0 2%' }} fontStyle='oblique' variant='body'>
            {paymentConfig.subtitle}
          </Typography>
        )}
      </div>

      {renderActionButtons()}
    </ScreenContainer>
  );
};
