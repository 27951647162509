import React, { ChangeEvent, useEffect, useState } from 'react';

import { Checkbox } from '../../styled-components';
import { $fontSizeS } from '../../styles/variables';
import { ICheckOption } from '../../constants/dataConstants/circumstances';

export interface ICheckboxStyles {
  backgroundColor?: string;
  checkboxBackground?: string;
  checkboxColor?: string;
  padding?: string;
  margin?: string;
}

export interface ICheckboxGroup {
  checkboxes: ICheckOption[];
  styles?: ICheckboxStyles;
  onChange: (element: ICheckOption, value: boolean, index: number) => void;
  childName: string;
  childPlaceholder: string;
  handleBlur: { (e: FocusEvent): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleChangeChild: (e: React.ChangeEvent<HTMLTextAreaElement>, parentEl: ICheckOption, index: number) => void;
}

export const CheckboxGroup = (props: ICheckboxGroup): JSX.Element => {
  const { onChange, checkboxes, styles, childName, childPlaceholder, handleBlur, handleChangeChild } = props;
  const [elements, setElements] = useState<ICheckOption[]>([]);

  useEffect(() => {
    const tempCheckboxes = JSON.parse(JSON.stringify(checkboxes));
    setElements(tempCheckboxes);
  }, [checkboxes]);

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    elements[index].checked = event.target.checked;
    setElements([...elements]);
    onChange(elements[index], event.target.checked, index);
  };

  return (
    <>
      {elements &&
        elements.map((element, index) => (
          <div key={element.label} style={{ width: '100%' }}>
            <Checkbox
              key={element.value}
              checkboxBackground={styles?.checkboxBackground}
              checkboxColor={styles?.checkboxColor}
              checked={element.checked || false}
              style={{ fontSize: $fontSizeS }}
              value={element.value}
              onChange={event => onCheckboxChange(event, index)}
            >
              {element.label}
            </Checkbox>
            {element.hasChild && (
              <>
                <textarea
                  className='form-field'
                  disabled={!element?.checked}
                  name={childName}
                  placeholder={childPlaceholder}
                  rows={3}
                  style={{
                    marginTop: '15px',
                    marginBottom: '6px',
                    borderRadius: '25px',
                    padding: '10px',
                    fontSize: $fontSizeS,
                    fontStyle: 'italic',
                    minHeight: '95px',
                  }}
                  onBlur={handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleChangeChild(event, element, index)}
                />
              </>
            )}
          </div>
        ))}
    </>
  );
};
