import * as H from 'history';
import { useTranslation } from 'react-i18next';

import { USER_SITUATION } from '../../services/router/routeHandles';
import { PreLoginInsuranceData } from '../../@types/pre-login/preLoginTypes';
import { ChooseInsuranceConstants } from '../../constants/dataConstants/ChooseInsuranceConstants';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';

export interface Insurances {
  injuredInsurance: Partial<PreLoginInsuranceData>;
  guiltyInsurance: Partial<PreLoginInsuranceData>;
}

const ChooseInsuranceHelpers = () => {
  const { t } = useTranslation();
  const { INSURANCE_SWITCH_RESPONSE } = ChooseInsuranceConstants();
  const changeUserScreen = (history: H.History<any>, userSituation: USER_SITUATION) => {
    const nextHandle = userSituation === USER_SITUATION.GUILTY_STATUS ? USER_SITUATION.INJURED_STATUS : USER_SITUATION.GUILTY_STATUS;
    history.push({ pathname: PRE_LOGIN_ROUTES.chooseInsuranceCompany, state: { flowHandle: nextHandle } });
  };

  const situationSwitcher = (injured: Partial<PreLoginInsuranceData>, guilty: Partial<PreLoginInsuranceData>) => {
    if (injured !== null && guilty !== null) {
      if (injured?.available && !guilty?.available && injured?.directSettlement) {
        return INSURANCE_SWITCH_RESPONSE.CAN_HELP;
      } else if (injured?.available && !guilty?.available && !injured?.directSettlement) {
        return INSURANCE_SWITCH_RESPONSE.CANT_HELP;
      } else if (injured?.available && guilty?.available) {
        return INSURANCE_SWITCH_RESPONSE.CAN_HELP;
      } else if (!injured?.available && guilty?.available) {
        return INSURANCE_SWITCH_RESPONSE.CAN_HELP;
      } else if (!injured?.available && !guilty?.available) {
        return INSURANCE_SWITCH_RESPONSE.CANT_HELP_WITH_FILE;
      }
    }
  };

  const handleInsurerSituation = (personInsurer: Insurances) => {
    const injuredInsurer = personInsurer?.injuredInsurance;
    const guiltyInsurer = personInsurer?.guiltyInsurance;

    return situationSwitcher(injuredInsurer, guiltyInsurer);
  };

  const convertInsuranceData = (insurance: PreLoginInsuranceData) => {
    return {
      ...insurance,
      insurerID: insurance?.insurerID,
      insurerName: insurance?.insurerName,
      available: insurance?.available,
      directSettlement: insurance?.directSettlement,
    };
  };

  return { convertInsuranceData, situationSwitcher, handleInsurerSituation, changeUserScreen };
};

export { ChooseInsuranceHelpers };
