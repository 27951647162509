import React from 'react';

import { IIconProps } from './icon.model';

export const CircleIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 15} viewBox='0 0 15 15' width={width || 15} {...otherProps}>
      <circle cx='7.5' cy='7.5' fill={fill || '#512AD8'} r='7.5' />
    </svg>
  );
};
