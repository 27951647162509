import { IContactDetails } from '../../@types/current-statement/currentStatementTypes';

export const handleContactDetailsDisplay = (
  values: Omit<IContactDetails, 'type'>,
  multipleContactInformation: boolean,
  legalPerson: boolean,
  setPreLoginData: any,
) => {
  if (multipleContactInformation) {
    setPreLoginData({
      contactData: {
        phone: values.phone,
        email: values.email,
        insuredPhone: values.insuredPhone,
        insuredEmail: values.insuredEmail,
      },
    });
  } else if (legalPerson) {
    setPreLoginData({
      contactData: {
        phone: values.phone,
        email: values.email,
        companyName: values.companyName,
        cui: values.cui,
        address: values.address,
        county: values.county,
        city: values.city,
      },
    });
  } else {
    setPreLoginData({
      contactData: {
        phone: values.phone,
        email: values.email,
      },
    });
  }
};
