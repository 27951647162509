import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-perspective-cropper';
import { useDispatch } from 'react-redux';

import { Wrapper } from '../../styled-components';
import { Typography } from '../../styled-components';
import { Button } from '../../components/button';
import VideoDefault from '../../assets/images/videoDefault.png';
import { INPUT_TYPE } from '../../constants/typeConstants/cameraType';
import DefaultFacialRecognition from '../../assets/images/faceRecognitionDefault.png';
import { $fontSizeS } from '../../styles/variables';
import { fetchStart, fetchSuccess } from '../../store/statement/statement.slice';

const MODE_UPLOAD = 'upload';
const MODE_CAMERA = 'camera';
const FACIAL_RECOGNITION_CAMERA = 'facial-recognition-camera';
const FILE_TYPE_IMAGE = 'image';
const FILE_TYPE_VIDEO = 'video';

interface IPhoneCameraProps {
  saveFile?: (image: File | null) => void; // when user click continue
  mode: string | null;
  type?: string;
  editable?: boolean; // for photos, enable edit component
  onFileChange?: (file?: File | null) => void; // triggered of file select
  initialFile?: File | null;
  hideControls?: boolean;
  helperText?: string;
  preview?: boolean;
  initialImageB64?: string; // base64
}

export const FileUpload = React.forwardRef<HTMLInputElement, IPhoneCameraProps>((props, ref) => {
  const { saveFile, mode, type = 'image', editable, onFileChange, hideControls, initialFile, helperText, preview, initialImageB64 } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFileReady, setFileReady] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [croppedFile, setCroppedFile] = useState<File | null>(null);
  const cropperRef = useRef<React.ElementRef<typeof Cropper> | null>(null);
  const [cropLoading, setCropLoading] = useState<boolean | null>(null);
  const cropperH = window.innerHeight - 300;
  const cropperW = window.innerWidth - 40;

  const onChange = useCallback(s => {
    setCropLoading(s?.loading);
  }, []);

  // set the initial file if provided
  useEffect(() => {
    if (initialFile) {
      setFile(initialFile);
      !editable && setCroppedFile(initialFile);

      if (onFileChange) onFileChange(initialFile);
    }
  }, [initialFile, onFileChange]);

  // set new file
  const onSaveFile = (file?: File): void => {
    if (!file) {
      setFile(null);

      return;
    }
    setFile(file);
    !editable && setCroppedFile(file);
  };

  useEffect(() => {
    if (onFileChange) {
      onFileChange(file);
    }
  }, [file, onFileChange]);

  useEffect(() => {
    if (cropLoading) dispatch(fetchStart());
    else if (!cropLoading) dispatch(fetchSuccess());
  }, [cropLoading]);

  const onLoadImageFile = (e: ProgressEvent<FileReader>): void => {
    if (e.target && typeof e.target.result === 'string') {
      const preview = document.querySelector('#preview');
      preview && preview.setAttribute('src', e.target.result);
    }
  };

  const onLoadVideoFile = (e: ProgressEvent<FileReader>): void => {
    if (e.target && e.target.result) {
      // The file reader gives us an ArrayBuffer:
      const buffer = e.target.result;
      // We have to convert the buffer to a blob:
      const videoBlob = new Blob([new Uint8Array(buffer as ArrayBuffer)], { type: 'video/mp4' });
      // The blob gives us a URL to the video file:
      const url = window.URL.createObjectURL(videoBlob);
      const preview = document.querySelector('#preview');
      preview && preview.setAttribute('src', url);
    }
  };

  const handleFileChange = (input: React.ChangeEvent<HTMLInputElement>): void => {
    input.stopPropagation();
    input.preventDefault();

    if (!input.target.files || !input.target.files[0]) {
      setFileReady(false);

      return;
    }

    const selectedFile = input.target.files[0];
    setFile(selectedFile);
    !editable && setCroppedFile(selectedFile);

    if (mode === MODE_CAMERA) {
      setFileReady(true);
      const reader = new FileReader();

      if (type === FILE_TYPE_IMAGE) {
        reader.onload = onLoadImageFile;
        reader.readAsDataURL(selectedFile);
      }

      if (type === FILE_TYPE_VIDEO) {
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = onLoadVideoFile;
      }
    }

    if (mode === MODE_UPLOAD) {
      onSaveFile(selectedFile);
    }
    setFileReady(true);
  };

  const resetFile = (val: boolean) => {
    if (val) setFileReady(true);
    else {
      setFileReady(false);
      setFile(null);
      (ref as MutableRefObject<HTMLInputElement>)?.current.click();
    }
  };

  const imageUrl = useMemo(() => {
    // if there is a fresh uploaded file, display this,
    if (file) {
      return URL.createObjectURL(file);
    }

    // otherwise, display the initial image uploaded
    if (initialImageB64) {
      return initialImageB64;
    }

    // otherwise, let the default placeholder
    return '';
  }, [file, initialImageB64]);

  const getImageUrl = useCallback(
    (imageUrl: any) => {
      if (imageUrl) {
        return imageUrl;
      } else if (type === INPUT_TYPE.VIDEO) {
        return VideoDefault;
      } else if (mode === FACIAL_RECOGNITION_CAMERA) {
        return DefaultFacialRecognition;
      }

      return initialImageB64;
    },
    [type, mode],
  );

  const crop = async () => {
    if (cropperRef?.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const res = await cropperRef?.current?.done({
        preview: true,
        filterCvParams: {
          blur: false,
          th: false,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          thMode: window.cv.ADAPTIVE_THRESH_MEAN_C,
          thMeanCorrection: 15,
          thBlockSize: 25,
          thMax: 255,
          grayScale: false,
        },
      });
      setCroppedFile(res);
    }
  };

  const resetCrop = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cropperRef?.current?.backToCrop();
    setCroppedFile(null);
  };

  const getRemakeButtonText = useMemo(() => {
    let take = mode === MODE_CAMERA ? t('cameraText.remake') : t('cameraText.upload');
    const fileType = type === 'image' ? t('cameraText.picture') : t('cameraText.video');

    if (file === null) {
      take = mode === MODE_UPLOAD ? t('cameraText.upload') : t('cameraText.make');
    }

    return `${take} ${fileType}`;
  }, [file, mode, type]);

  return (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='100%' overflowY='auto' width='100%'>
        <input
          ref={ref}
          accept={`${type}/*`}
          capture={mode === MODE_CAMERA}
          id='file-upload'
          style={{
            visibility: 'hidden',
            position: 'absolute',
          }}
          type='file'
          onChange={handleFileChange}
          onClick={event => {
            setFileReady(false);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event.target.value = null;
          }}
        />
        <Wrapper
          display='flex'
          flexDirection='column'
          height='80%'
          justifyContent='flex-start'
          margin='0 0 20px 0'
          maxHeight='80%'
          width='95%'
        >
          {type === FILE_TYPE_VIDEO && file && <video height='auto' id='preview' src={imageUrl} width='100%' controls />}
          {type === FILE_TYPE_IMAGE && file && editable && (
            <Wrapper display='flex' flexDirection='column' style={{ padding: 20 }}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Cropper ref={cropperRef} image={file} maxHeight={cropperH} maxWidth={cropperW} onChange={onChange} />
              <Wrapper display='flex' flexDirection='row' justifyContent='space-evenly' margin='20px 0 0 20px' width='100%'>
                <Button disabled={!!croppedFile} display='flex' mode='primary' width='40%' onClick={crop}>
                  Done
                </Button>
                <Button disabled={!croppedFile} display='flex' mode='primary' width='40%' onClick={resetCrop}>
                  Reset
                </Button>
              </Wrapper>
            </Wrapper>
          )}
          {!editable && !file && preview && (
            <img alt='' id='preview' src={getImageUrl(imageUrl)} style={{ width: '95%', height: '80%', objectFit: 'contain' }} />
          )}
          {!editable && file && type === FILE_TYPE_IMAGE && (
            <img alt='' id='preview' src={getImageUrl(imageUrl)} style={{ width: '95%', height: '80%', objectFit: 'contain' }} />
          )}
          {helperText && (
            <Typography align='center' color='#8E8E8E' commonStyles={{ margin: '12px 0' }} fontSize={$fontSizeS} variant='tab'>
              {helperText}
            </Typography>
          )}
        </Wrapper>
        {!hideControls && (
          <Wrapper display='flex' flexDirection='column' width='100%'>
            <Button
              disabled={!isFileReady || !croppedFile}
              mode='primary'
              width='100%'
              onClick={() => {
                if (saveFile) {
                  saveFile(croppedFile);
                }
              }}
            >
              {t('commonText.continue')}
            </Button>
            <Button margin='10px 0' mode='primary' onClick={() => resetFile(false)}>
              <Typography align='center' color='#FFF' variant='h5'>
                {getRemakeButtonText}
              </Typography>
            </Button>
          </Wrapper>
        )}
      </Wrapper>
    </>
  );
});

FileUpload.defaultProps = {
  type: 'image',
  editable: false,
  mode: 'upload',
  hideControls: false,
  preview: false,
};
