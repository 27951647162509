import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Image, Typography, Wrapper } from '../../styled-components';
import Finding from '../../assets/images/notaconstatare.png';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';

export const CompensationClaim = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const onConfirm = () => {
    history.push(nextRoute);
  };

  const confirmModal = (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '15px 0 15px 0' }} variant='h4'>
          {t('compensation.confirmCompensation')}
        </Typography>
        <Typography color='#8E8E8E' variant='body'>
          {t('compensation.confirmFindingContent')}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button mode='primary' onClick={() => onConfirm()}>
          {t('compensation.buttonConfirm')}
        </Button>
        <Button margin='15px 0 0 0' mode='link' onClick={() => setShowModal(false)}>
          <Typography color='#512AD8' variant='h5'>
            {t('compensation.buttonBack')}
          </Typography>
        </Button>
      </Wrapper>
    </>
  );

  return (
    <ScreenContainer
      headerProps={{ title: t('compensation.claim'), hideLogo: true }}
      isModalOpen={showModal}
      modal={confirmModal}
      modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
      showBottomNavbar
    >
      <Wrapper style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Image src={Finding} />
        <Button mode='primary' onClick={() => setShowModal(true)}>
          {t('commonText.continue')}
        </Button>
      </Wrapper>
    </ScreenContainer>
  );
};
