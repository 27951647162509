import { AxiosResponse } from 'axios';

import { authorizedRequest } from '../../services/api';

import { IAuthUserModel } from './auth.model';

export function getExampleApi(id: number): Promise<AxiosResponse<IAuthUserModel>> {
  return authorizedRequest().post('/auth', { id });
}

export function postUserPhoneNumberApi(payload: { phone: string | undefined }): Promise<AxiosResponse<IAuthUserModel>> {
  return authorizedRequest().post('/users/phone', payload);
}

export function getUserByIdApi(id: number): Promise<AxiosResponse<IAuthUserModel>> {
  return authorizedRequest().get(`/users/${id}`);
}
