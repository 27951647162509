import { SelectionType } from '../../screens/statement/CircumstancesSelection';
import { HOME_TEXTS } from '../../translations/ro/home';

export interface ICheckOption {
  label: string;
  value: string | number | undefined;
  checked?: boolean;
  hasChild?: boolean;
  childValue?: string;
}

export interface CircumstanceData {
  index: number;
  title: string;
  type: SelectionType;
  values: ICheckOption[];
}

export const circumstancesData: CircumstanceData[] = [
  // TODO: this will be refactore/removed as soon as redux/store is in place
  {
    index: 1,
    title: HOME_TEXTS.howWasTheAccident,
    type: 'checkbox',
    values: [
      {
        label: HOME_TEXTS.standOnCircumstance,
        value: HOME_TEXTS.standOnCircumstance,
        checked: false,
      },
      {
        label: HOME_TEXTS.departureTime,
        value: HOME_TEXTS.departureTime,
        checked: false,
      },
      {
        label: HOME_TEXTS.comingOutOfParking,
        value: HOME_TEXTS.comingOutOfParking,
        checked: false,
      },
      {
        label: HOME_TEXTS.comingInOfParking,
        value: HOME_TEXTS.comingInOfParking,
        checked: false,
      },
      {
        label: HOME_TEXTS.enteringTheRoundabout,
        value: HOME_TEXTS.enteringTheRoundabout,
        checked: false,
      },
      {
        label: HOME_TEXTS.runningTheRoundabout,
        value: HOME_TEXTS.runningTheRoundabout,
        checked: false,
      },
      {
        label: HOME_TEXTS.hitFromBehindSameDirection,
        value: HOME_TEXTS.hitFromBehindSameDirection,
        checked: false,
      },
      {
        label: HOME_TEXTS.sameDirectionDifferentLane,
        value: HOME_TEXTS.sameDirectionDifferentLane,
        checked: false,
      },
      {
        label: HOME_TEXTS.changingLane,
        value: HOME_TEXTS.changingLane,
        checked: false,
      },
      {
        label: HOME_TEXTS.overtaking,
        value: HOME_TEXTS.overtaking,
        checked: false,
      },
      {
        label: HOME_TEXTS.steeringToTheLeft,
        value: HOME_TEXTS.steeringToTheLeft,
        checked: false,
      },
      {
        label: HOME_TEXTS.steeringToTheRight,
        value: HOME_TEXTS.steeringToTheRight,
        checked: false,
      },
      {
        label: HOME_TEXTS.goingBackwords,
        value: HOME_TEXTS.goingBackwords,
        checked: false,
      },
      {
        label: HOME_TEXTS.goingOnOppositeSide,
        value: HOME_TEXTS.goingOnOppositeSide,
        checked: false,
      },
      {
        label: HOME_TEXTS.comingFromRight,
        value: HOME_TEXTS.comingFromRight,
        checked: false,
      },
      {
        label: HOME_TEXTS.notGivingPriority,
        value: HOME_TEXTS.notGivingPriority,
        checked: false,
      },
      {
        label: HOME_TEXTS.otherCircumstance,
        value: HOME_TEXTS.otherCircumstance,
        checked: false,
        hasChild: true,
        childValue: '',
      },
    ],
  },
  {
    index: 2,
    title: HOME_TEXTS.visibilityConditions,
    type: 'radio',
    values: [
      {
        label: HOME_TEXTS.clearSky,
        value: HOME_TEXTS.clearSky,
        checked: false,
      },
      {
        label: HOME_TEXTS.cloudy,
        value: HOME_TEXTS.cloudy,
        checked: false,
      },
      {
        label: HOME_TEXTS.rainy,
        value: HOME_TEXTS.rainy,
        checked: false,
      },
      {
        label: HOME_TEXTS.snow,
        value: HOME_TEXTS.snow,
        checked: false,
      },
    ],
  },
  {
    index: 3,
    title: HOME_TEXTS.roadCondition,
    type: 'radio',
    values: [
      {
        label: HOME_TEXTS.dryRoad,
        value: HOME_TEXTS.dryRoad,
        checked: false,
      },
      {
        label: HOME_TEXTS.wetRoad,
        value: HOME_TEXTS.wetRoad,
        checked: false,
      },
      {
        label: HOME_TEXTS.frozenRoad,
        value: HOME_TEXTS.frozenRoad,
        checked: false,
      },
      {
        label: HOME_TEXTS.anotherState,
        value: HOME_TEXTS.anotherState,
        checked: false,
        hasChild: true,
        childValue: '',
      },
    ],
  },
];
