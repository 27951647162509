import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS } from '../../translations/ro/home';
import { Typography, Wrapper, Image } from '../../styled-components';
import { StatementDocumentData, useStatementDocuments } from '../../hooks/useStatementDocuments';

export const DocumentsList = (): JSX.Element => {
  const { t } = useTranslation();
  const { getStatementDocumentsListByFlowCondition } = useStatementDocuments();

  return (
    <ScreenContainer headerProps={{ hideLogo: true, title: HOME_TEXTS.documents, titleSize: 'h5' }}>
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {t('documents.title')}
      </Typography>
      <Wrapper flexWrap='wrap' width='100%'>
        {getStatementDocumentsListByFlowCondition()?.map((document: StatementDocumentData) => (
          <div key={document.id} style={{ alignSelf: 'flex-end', margin: '16px auto 0 auto', width: '150px', position: 'relative' }}>
            <Typography color='#3F414E' commonStyles={{ margin: '0 0 7px 0', width: '95%' }} fontWeight='normal' variant='body'>
              {document.label}
            </Typography>
            <Image
              backgroundColor='white'
              borderRadius='10px'
              src={document.src}
              style={{
                objectFit: 'cover',
                width: 145,
                height: 145,
              }}
            />
          </div>
        ))}
      </Wrapper>
    </ScreenContainer>
  );
};
