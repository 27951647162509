import { useTranslation } from 'react-i18next';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { EVALUATION_STATUS, NOTIFICATION_STATUS, STATEMENT_STATUS } from '../constants/typeConstants/statementStatusType';
import { StyledIconButton } from '../styled-components/bottom-nav';
import { EvaluationCardTemplate } from '../components/card/EvaluationCardTemplate';
import { StatementCardTemplate } from '../components/card/StatementCardTemplate';
import { IDocuments } from '../@types/document/documentTypes';
import { Typography, Wrapper } from '../styled-components';
import { NotificationCardTemplate } from '../components/card/NotificationCardTemplate';

const useCardManagement = (status: STATEMENT_STATUS | EVALUATION_STATUS | NOTIFICATION_STATUS) => {
  const { t } = useTranslation();
  const getMainColor = () => {
    const greenColor = [
      EVALUATION_STATUS.EVALUATION_FINISHED,
      STATEMENT_STATUS.STATEMENT_FINISHED,
      NOTIFICATION_STATUS.NOTIFICATION_FINISHED,
    ];

    if (greenColor.includes(status as EVALUATION_STATUS)) {
      return '#2DA455';
    }

    return '#F0861F';
  };

  const isFinished = [
    EVALUATION_STATUS.EVALUATION_FINISHED,
    STATEMENT_STATUS.STATEMENT_FINISHED,
    NOTIFICATION_STATUS.NOTIFICATION_FINISHED,
  ].includes(status as EVALUATION_STATUS | STATEMENT_STATUS | NOTIFICATION_STATUS);

  const getLabel = () => {
    switch (status) {
      // EVALUATIONS
      case EVALUATION_STATUS.EVALUATION_INCOMPLETE:
        return t('statementStatus.completeWithNecessaryData');
      case EVALUATION_STATUS.EVALUATION_AWAITING_USER_PAYMENT:
        return t('statementStatus.pendingPayment');
      case EVALUATION_STATUS.EVALUATION_AWAITING_ADMIN_REVIEW:
        return t('statementStatus.awaitingReview');

      // STATEMENTS
      case STATEMENT_STATUS.STATEMENT_INCOMPLETE:
        return t('statementStatus.incomplete');
      case STATEMENT_STATUS.STATEMENT_AWAITING_ADMIN_PAYMENT:
        return t('statementStatus.awaiting');
      case STATEMENT_STATUS.STATEMENT_FINISHED:
        return t('statementStatus.paid');

      // Notifications
      case NOTIFICATION_STATUS.NOTIFICATION_INCOMPLETE:
        return t('statementStatus.completeWithNecessaryData');
      case NOTIFICATION_STATUS.NOTIFICATION_AWAITING_ADMIN:
        return t('statementStatus.awaiting');
    }
  };

  const IconStatus = () => {
    if (isFinished) {
      const color = getMainColor();

      return (
        <Wrapper paddingRight='15px'>
          <Typography
            color={color}
            commonStyles={{ margin: '0 5px 0 0' }}
            extendedStyles={{ fontSize: '13px', lineHeight: '30px' }}
            fontWeight='500'
            variant='h5'
          >
            {t('statementStatus.finished')}
          </Typography>
          <StyledIconButton>
            <CheckCircleIcon style={{ color: getMainColor() }} />
          </StyledIconButton>
        </Wrapper>
      );
    }

    return null;
  };

  const getButtonLabelByStatus = () => {
    switch (status) {
      case EVALUATION_STATUS.EVALUATION_AWAITING_USER_PAYMENT:
        return t('status.continueToPayment');
      default:
        return t('commonText.continue');
    }
  };

  const handleStatusCardTemplatesByStatus = (
    id: number,
    createdAt: string,
    currentPlace: number | null,
    insuranceId: number | null,
    documentsData: Partial<IDocuments['insurance']['data']> | null,
    onContinue: () => void,
    evaluation = false,
  ) => {
    const evaluationCard = (): JSX.Element => {
      return (
        <EvaluationCardTemplate
          createdAt={createdAt}
          currentPlace={currentPlace}
          id={id}
          insuranceNo={insuranceId !== null ? documentsData?.insuranceNo : 'N/A'}
          registrationNumber={insuranceId !== null ? documentsData?.carPlate : 'N/A'}
          // TODO handle this status in the below switch case
          status={NOTIFICATION_STATUS.NOTIFICATION_INCOMPLETE}
          onContinue={onContinue}
        />
      );
    };

    switch (status) {
      case EVALUATION_STATUS.EVALUATION_INCOMPLETE:
        return (
          <EvaluationCardTemplate
            createdAt={createdAt}
            currentPlace={currentPlace}
            id={id}
            insuranceNo={insuranceId !== null ? documentsData?.insuranceNo : 'N/A'}
            registrationNumber={insuranceId !== null ? documentsData?.carPlate : 'N/A'}
            status={status !== null ? status : EVALUATION_STATUS.EVALUATION_INCOMPLETE}
            onContinue={onContinue}
          />
        );
      case EVALUATION_STATUS.EVALUATION_AWAITING_USER_PAYMENT:
      case EVALUATION_STATUS.EVALUATION_AWAITING_ADMIN_REVIEW:
        return (
          <EvaluationCardTemplate
            createdAt={createdAt}
            currentPlace={currentPlace}
            id={id}
            insuranceNo={insuranceId !== null ? documentsData?.insuranceNo : 'N/A'}
            registrationNumber={insuranceId !== null ? documentsData?.carPlate : 'N/A'}
            status={status !== null ? status : EVALUATION_STATUS.EVALUATION_INCOMPLETE}
            onContinue={onContinue}
          />
        );
      case EVALUATION_STATUS.EVALUATION_FINISHED:
        return (
          <EvaluationCardTemplate
            createdAt={createdAt}
            currentPlace={currentPlace}
            id={id}
            insuranceNo={insuranceId !== null ? documentsData?.insuranceNo : 'N/A'}
            registrationNumber={insuranceId !== null ? documentsData?.carPlate : 'N/A'}
            status={status !== null ? status : EVALUATION_STATUS.EVALUATION_INCOMPLETE}
            onContinue={onContinue}
          />
        );
      case STATEMENT_STATUS.STATEMENT_INCOMPLETE:
        if (evaluation) {
          return evaluationCard();
        }

        return (
          <StatementCardTemplate
            createdAt={createdAt}
            currentPlace={currentPlace}
            insuranceNo={insuranceId !== null ? documentsData?.insuranceNo : 'N/A'}
            registrationNumber={insuranceId !== null ? documentsData?.carPlate : 'N/A'}
            status={status !== null ? status : EVALUATION_STATUS.EVALUATION_INCOMPLETE}
            onContinue={onContinue}
          />
        );
      case STATEMENT_STATUS.STATEMENT_AWAITING_ADMIN_PAYMENT:
        if (evaluation) {
          return evaluationCard();
        }

        return (
          <StatementCardTemplate
            createdAt={createdAt}
            currentPlace={currentPlace}
            insuranceNo={insuranceId !== null ? documentsData?.insuranceNo : 'N/A'}
            registrationNumber={insuranceId !== null ? documentsData?.carPlate : 'N/A'}
            status={status !== null ? status : EVALUATION_STATUS.EVALUATION_INCOMPLETE}
            onContinue={onContinue}
          />
        );
      case NOTIFICATION_STATUS.NOTIFICATION_INCOMPLETE:
      case STATEMENT_STATUS.STATEMENT_FINISHED:
      // TODO: define custom cards for each of this type, if needed
      case NOTIFICATION_STATUS.NOTIFICATION_AWAITING_ADMIN:
      case NOTIFICATION_STATUS.NOTIFICATION_FINISHED:
        return (
          <NotificationCardTemplate
            createdAt={createdAt}
            currentPlace={currentPlace}
            id={id}
            insuranceNo={insuranceId !== null ? documentsData?.insuranceNo : 'N/A'}
            registrationNumber={insuranceId !== null ? documentsData?.carPlate : 'N/A'}
            status={status !== null ? status : EVALUATION_STATUS.EVALUATION_INCOMPLETE}
            onContinue={onContinue}
          />
        );
      default:
        return <div>N/A</div>;
    }
  };

  return { getMainColor, getLabel, IconStatus, getButtonLabelByStatus, handleStatusCardTemplatesByStatus };
};

export { useCardManagement };
