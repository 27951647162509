import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../styled-components/containers';
import { Checkbox } from '../../styled-components/form';
import { Typography } from '../../styled-components/typography';
import { Button } from '../../components/button';
import { $lineHeightL } from '../../styles/variables';
import { IScreenCommonProps } from '../component-props.model';
import { TERMS_AND_CONDITIONS } from '../../translations/ro/terms-and-conditions';
import { DATA_PRIVACY } from '../../translations/ro/data-privacy';

interface PropsI extends IScreenCommonProps {
  toggleModal: (value: boolean, method: string) => void;
  showModal: boolean;
  headerType: string;
  authMethod: string;
}

export const AuthModal = ({ authMethod }: PropsI): JSX.Element => {
  const { t } = useTranslation();

  const [checkboxes, setCheckboxes] = useState<{ terms: boolean; data: boolean }>({ terms: false, data: false });
  const [showTC, setShowTC] = useState<boolean>(false);
  const [showDataPrivacy, setShowDataPrivacy] = useState<boolean>(false);

  const onCreateAccount = (): void => {
    window.location.assign(`${process.env.REACT_APP_API_URL}/auth/${authMethod}`);
  };

  const renderModalContent = () => {
    if (showTC) {
      return renderTC();
    }

    if (showDataPrivacy) {
      return renderDataPrivacy();
    }

    return renderCheckboxes();
  };

  const renderTC = () => {
    return (
      <>
        <div style={{ marginTop: '20px', height: 'calc(100% - 100px)', overflow: 'scroll' }}>{ReactHtmlParser(TERMS_AND_CONDITIONS)}</div>

        <Button
          mode='primary'
          style={{ marginTop: '0' }}
          onClick={() => {
            setShowTC(false);
            setCheckboxes({ ...checkboxes, terms: true });
          }}
        >
          {t('commonText.accept')}
        </Button>
      </>
    );
  };

  const renderDataPrivacy = () => {
    return (
      <>
        <div style={{ marginTop: '20px', height: 'calc(100% - 100px)', overflow: 'scroll' }}> {ReactHtmlParser(DATA_PRIVACY)} </div>
        <Button
          mode='primary'
          style={{ marginTop: '0' }}
          onClick={() => {
            setShowDataPrivacy(false);
            setCheckboxes({ ...checkboxes, data: true });
          }}
        >
          {t('commonText.accept')}
        </Button>
      </>
    );
  };

  const renderCheckboxes = () => {
    return (
      <>
        <Typography align='left' commonStyles={{ margin: '0 0 45px 0' }} fontWeight='700' lineHeight={$lineHeightL} variant='h3'>
          {t('authentication.checkToContinue')}
        </Typography>
        <Wrapper display='block'>
          <Wrapper alignItems='flex-start' display='flex'>
            <Checkbox
              checkboxBackground='red'
              checked={checkboxes.terms}
              onChange={event => setCheckboxes({ ...checkboxes, terms: event.target.checked })}
            />
            <Typography align='left' commonStyles={{ margin: '10px 0 0', width: '100%' }} variant='body'>
              {t('authentication.agreeWith')}
              <span
                style={{ textDecoration: 'underline', color: 'blue', marginLeft: '3px', cursor: 'pointer' }}
                onClick={() => setShowTC(true)}
              >
                {t('authentication.termsAndConditions')}
              </span>
            </Typography>
          </Wrapper>
          <Wrapper alignItems='unset'>
            <Checkbox checked={checkboxes.data} onChange={event => setCheckboxes({ ...checkboxes, data: event.target.checked })} />
            <Typography align='left' commonStyles={{ margin: '10px 0 0', width: '100%' }} variant='body'>
              {t('authentication.agreeWith')}
              <span
                style={{ textDecoration: 'underline', color: 'blue', marginLeft: '3px', cursor: 'pointer' }}
                onClick={() => setShowDataPrivacy(true)}
              >
                {t('authentication.dataProcessing')}
              </span>
            </Typography>
          </Wrapper>
        </Wrapper>
        <Wrapper alignItems='center' display='flex' justifyContent='center' marginTop='auto' width='100%'>
          <Button borderRadius='25px' disabled={!checkboxes.terms || !checkboxes.data} mode='primary' onClick={onCreateAccount}>
            {t('commonText.continue')}
          </Button>
        </Wrapper>
      </>
    );
  };

  return (
    <Wrapper display='flex' flexDirection='column' height='calc(100%)' width='100%'>
      {renderModalContent()}
    </Wrapper>
  );
};
