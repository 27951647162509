import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../button';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

export const ContinueModal = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentStatement } = useCurrentStatement();

  return (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 160px)' justifyContent='flex-start'>
        <Typography align='left' commonStyles={{ margin: '15px 0 15px 0' }} fontWeight='700' lineHeight='10px' variant='h4'>
          {t('compensation.continueFile')}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button
          mode='primary'
          type='button'
          onClick={() => {
            if (currentStatement.statementID !== null) {
              history.push(generatePath(PROTECTED_ROUTES.statements));
            }
          }}
        >
          {t('commonText.continue')}
        </Button>
      </Wrapper>
    </>
  );
};
