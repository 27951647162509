import { FORMATTED_OCR_DOCUMENTS_TYPE, OCR_DOCUMENT_TYPE } from '../constants/typeConstants/documentsFormType';
import { formatOcrResponse } from '../helpers/ocr-processing/ocrProcessingHelper';
import { convertFileToBase64 } from '../helpers/convert-image';

import { useDocumentsTempData } from './useDocumentsTempData';

interface processedOcrData {
  [key: string]: string | null;
}

export type ocrProcessingResponse = {
  ocrData: processedOcrData | null;
  newImage: FileReader['result'];
};

interface IUseOcrProcessing {
  sendImageToOcr: (documentType: OCR_DOCUMENT_TYPE, image: File) => Promise<ocrProcessingResponse>;
}

export const useOcrProcessing = (): IUseOcrProcessing => {
  const { postOcrData } = useDocumentsTempData();

  const sendImageToOcr = async (documentType: OCR_DOCUMENT_TYPE, image: File): Promise<ocrProcessingResponse> => {
    const formData = new FormData();

    if (image) formData.append('image', image);

    const newImage = await convertFileToBase64(image);

    return new Promise((resolve, reject) => {
      postOcrData(
        formData,
        FORMATTED_OCR_DOCUMENTS_TYPE[documentType],
        response => {
          resolve({ ocrData: formatOcrResponse(documentType, response), newImage });
        },
        () => {
          reject({ newImage });
        },
      );
    });
  };

  return { sendImageToOcr };
};
