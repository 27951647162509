import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Image } from '../../styled-components';
import BlackLogo from '../../assets/images/BlackLogo.png';
import { RootState } from '../../store/rootReducer';
import { AuthRoutes } from '../../services/router/routeAccessRights';

import { AuthBody } from './AuthBody';
import { AuthModal } from './AuthModal';

export const Login = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [authMethod, setAuthMethod] = useState<string | null>(null);
  const search = useLocation().search;
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);

  useEffect(() => {
    const statement = new URLSearchParams(search).get('statement');
    // TODO: check this flow
    const userAcceptsOffer = new URLSearchParams(search).get('userAcceptsOffer');

    if (statement) {
      localStorage.setItem('statement', statement);
    }

    if (userAcceptsOffer) {
      localStorage.setItem('response', userAcceptsOffer);
    }

    if (isLoggedIn) {
      history.push(AuthRoutes.evaluations);
    }
  }, [isLoggedIn]);

  const toggleModal = (value: boolean, method: string) => {
    setAuthMethod(method);
    setShowModal(value);
  };

  const authModalComponent = (
    <AuthModal
      authMethod={authMethod as string}
      headerType='dark'
      history={history}
      nextRoute={nextRoute}
      showModal={showModal}
      toggleModal={toggleModal}
    />
  );
  const pageTitle = t('authentication.logInAccount');
  const modalPrompt = {
    question: t('authentication.noAccount'),
    buttonName: t('authentication.createAccount'),
  };

  return (
    <>
      <ScreenContainer
        backgroundColor='white'
        headerType='none'
        isModalOpen={showModal}
        modal={authModalComponent}
        modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
        showBottomNavbar={false}
      >
        <Image margin='0 0 30px 0' src={BlackLogo} />
        <AuthBody
          history={history}
          modalPrompt={modalPrompt}
          nextRoute={nextRoute}
          pageTitle={pageTitle}
          showModal={showModal}
          toggleModal={toggleModal}
          isLogin
        />
      </ScreenContainer>
    </>
  );
};
