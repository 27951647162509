import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GoogleMapLocation, GoogleMapProps } from '../../@types/google-map/GoogleMap.types';
import { Container } from '../../styled-components';
import { ListItem } from '../../components';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '../../constants/google-map';
import { GoogleMapActions } from '../../components/google-map/google-map-actions';
import { GoogleMapSearch } from '../../components/google-map/google-map-search';
import { Button } from '../../components/button';
import { setLoading } from '../../store/app/app.slice';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || '';

const useStyles = makeStyles(() =>
  createStyles({
    search: {
      backgroundColor: 'white',
      padding: 3,
    },
    searchInput: {
      padding: 10,
      '&::placeholder': {
        fontStyle: 'oblique',
      },
    },
    searchField: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#F0F2FC !important',
          borderRadius: '0 !important',
          borderWidth: '1px !important',
        },
      },
    },
    suggestionList: {
      width: '100%',
      height: '30%',
      overflow: 'scroll',
    },
  }),
);

export const GoogleMap = (props: GoogleMapProps): JSX.Element => {
  const { onSave, address } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [zoom, setZoom] = useState<number>(DEFAULT_MAP_ZOOM);
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [currentAddress, setCurrentAddress] = useState<string>('');
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[] | null>(null);

  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const geocodeService = useRef<google.maps.Geocoder>();

  const onMapLoad = (map: any) => {
    autocompleteService.current = new google.maps.places.AutocompleteService();
    geocodeService.current = new google.maps.Geocoder();

    setMap(map);
  };

  const handleSuggestionsList = (suggestions: any[] | null, status: google.maps.places.PlacesServiceStatus, select = false) => {
    if (status !== google.maps.places.PlacesServiceStatus.OK || !suggestions) return;

    setSuggestions(suggestions);

    if (select) {
      const firstSuggestion = suggestions?.[0]?.place_id;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSelectSuggestion(firstSuggestion);
    }
  };

  const handleSearch = (value: string) => {
    const currentSearchService = autocompleteService?.current;

    if (currentSearchService && value) {
      const queryParams = {
        input: value,
      };

      currentSearchService.getQueryPredictions(queryParams, (suggestions: any[] | null, status: google.maps.places.PlacesServiceStatus) =>
        handleSuggestionsList(suggestions, status),
      );
    } else setSuggestions(null);

    setSearchValue(value);
  };

  const selectLocation = (location: GoogleMapLocation, address: string, search = false) => {
    if (!map) return;

    if (marker) marker?.setMap(null);

    if (search) handleSearch(address);
    else setSearchValue(address);

    const newMarker = new google.maps.Marker({
      position: new google.maps.LatLng(location),
      map,
    });

    map.setCenter(location);
    setZoom(18);

    setMarker(newMarker);
    setCurrentAddress(address);
  };

  const onSelectSuggestion = (placeID: string) => {
    const currentGeocodeService = geocodeService.current;

    if (!currentGeocodeService) return;
    currentGeocodeService.geocode({ placeId: placeID }, function (results, status) {
      const location = results?.[0]?.geometry?.location;
      const address = results?.[0]?.formatted_address || '';

      if (status !== google.maps.GeocoderStatus.OK || !location || !address) return;

      const position = {
        lat: location.lat(),
        lng: location.lng(),
      };

      selectLocation(position, address);
    });
  };

  const getSuggestionsFromLatLng = (position: GoogleMapLocation) => {
    const currentGeocodeService = geocodeService.current;

    if (!position.lat || !position.lng || !currentGeocodeService || !map) return;

    const location = { lat: position.lat, lng: position.lng };

    currentGeocodeService
      .geocode({ location })
      .then(response => {
        const firstItem = response?.results?.[0];

        if (firstItem) {
          const address = firstItem?.formatted_address || '';
          selectLocation(location, address, true);
        }
      })
      .catch(error => console.log('Error: ', error));
  };

  const getCurrentPosition = () => {
    dispatch(setLoading(true));

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          dispatch(setLoading(false));

          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          getSuggestionsFromLatLng(pos);
        },
        (e: GeolocationPositionError) => {
          dispatch(setLoading(false));

          console.log("Location services can't be accessed: ", e);

          alert("Location services can't be accessed");
        },
      );
    }
  };

  useEffect(() => {
    const currentSearchService = autocompleteService?.current;

    if (!address || !map || !currentSearchService) return;

    const queryParams = {
      input: address,
    };

    currentSearchService.getQueryPredictions(queryParams, (suggestions: any[] | null, status: google.maps.places.PlacesServiceStatus) =>
      handleSuggestionsList(suggestions, status, true),
    );
  }, [address, map, autocompleteService]);

  const renderSuggestions = () => {
    if (suggestions?.length) {
      return (
        <div className={classes.suggestionList}>
          {suggestions.map((item: google.maps.places.AutocompletePrediction) => {
            const formatResult = item?.structured_formatting;

            const title = formatResult?.main_text || '';
            const subtitle = formatResult?.secondary_text || '';

            return <ListItem key={Math.random()} subtitle={subtitle} title={title} onClick={() => onSelectSuggestion(item?.place_id)} />;
          })}
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <div style={{ height: '90%' }}>
        <Container height='100%' padding='0' width='100%'>
          <GoogleMapSearch placeholder={t('googleMap.searchLabel')} value={searchValue} onChange={handleSearch} />

          <Container height={searchValue ? '50%' : '80%'} padding='0'>
            <div style={{ position: 'relative', height: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: API_KEY,
                  language: 'ro',
                  libraries: ['places'],
                }}
                center={DEFAULT_MAP_CENTER}
                defaultCenter={DEFAULT_MAP_CENTER}
                options={{
                  disableDefaultUI: true,
                }}
                zoom={zoom}
                onClick={(value: ClickEventValue) => getSuggestionsFromLatLng({ lat: value.lat, lng: value.lng })}
                onGoogleApiLoaded={(maps: { map: any; maps: any; ref: Element | null }) => onMapLoad(maps?.map)}
                onZoomAnimationEnd={(value: number) => {
                  if (value !== zoom) setZoom(value);
                }}
              />
              <GoogleMapActions
                onCurrentLocation={getCurrentPosition}
                onZoomIn={() => setZoom(prevState => prevState + 1)}
                onZoomOut={() => setZoom(prevState => prevState - 1)}
              />
            </div>
          </Container>

          <ListItem key='current-position' title={t('googleMap.currentLocation')} isMain onClick={() => getCurrentPosition()} />
          {renderSuggestions()}
        </Container>
      </div>
      <Button disabled={!currentAddress} margin='auto 0 20px 0' mode='primary' onClick={() => onSave(currentAddress)}>
        {t('commonText.save')}
      </Button>
    </>
  );
};
