import React, { useEffect, useRef, useState } from 'react';
import useMouse from '@react-hook/mouse-position';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Typography } from '../../styled-components';
import CarLeftSide from '../../assets/svgs/car-left-side.svg';
import CarRightSide from '../../assets/svgs/car-right-side.svg';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { useStatements } from '../../hooks/useStatements';
import { ConfirmNavigationModal } from '../../components/confirm-navigation-modal';
import { setDamageAction } from '../../store/statement/currentStatement.action';

import { DamageSurface } from './DamageSurface';

export const CarDamageSelect = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { currentStatement } = useCurrentStatement();
  const [selected, setSelected] = useState<boolean>(false);
  const { currentStatementId } = useParams<{ currentStatementId: string | undefined }>();
  const { fetchStatements, statementsState } = useStatements();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!statementsState) fetchStatements();
    statementsState.forEach(statement => {
      if (
        statement?.injured?.damage?.photoProofs &&
        statement.statementID === Number(currentStatementId) &&
        statement?.injured?.damage?.photoProofs.length >= 10
      ) {
        history.push(generatePath(`${PROTECTED_ROUTES.evaluations}`));
      }
    });

    if (currentStatement?.injured?.damage?.zone?.leftSide && currentStatement?.injured?.damage?.zone?.rightSide)
      if (currentStatement?.injured?.damage?.zone?.leftSide.length > 0 || currentStatement?.injured?.damage?.zone?.rightSide.length > 0) {
        setSelected(true);
      } else {
        setSelected(false);
      }
  }, [currentStatement.injured.damage?.zone?.leftSide, currentStatement.injured.damage?.zone?.rightSide]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const leftSideRef = useRef<HTMLDivElement | null>(null);
  const rightSideRef = useRef<HTMLDivElement | null>(null);
  const leftSideAuto = useMouse(leftSideRef);
  const rightSideAuto = useMouse(rightSideRef);

  const onContinue = (): void => {
    if (currentStatement.statementID !== null) {
      history.push(generatePath(nextRoute.toString(), { currentStatementId: currentStatement.statementID }));
    }
  };

  const onConfirmGoBack = (): void => {
    const { injured } = currentStatement;
    const { damage } = injured;

    if (currentStatement.userSituation) {
      dispatch(setDamageAction(currentStatement.userSituation, { zone: { leftSide: [], rightSide: [] } }));
    }
  };

  return (
    <ScreenContainer
      headerProps={{
        title: t('carDamage.title'),
        hideLogo: true,
        titleSize: 'h5',
        customParentBack: true,
        onCustomParentBack: () => {
          if (selected) setShowModal(true);
          else {
            history.goBack();
          }
        },
      }}
      isModalOpen={showModal}
      modal={<ConfirmNavigationModal history={history} setShowModal={setShowModal} onConfirm={onConfirmGoBack} />}
      modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
    >
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {t('carDamage.selectDamagedAreas')}
      </Typography>

      <div ref={leftSideRef}>
        <DamageSurface
          allowIfSelected={setSelected}
          image={CarLeftSide}
          side='left'
          sideAuto={leftSideAuto}
          sideRef={leftSideRef}
          text={t('carDamage.leftSideCar')}
        />
      </div>

      <div ref={rightSideRef} style={{ height: 'auto' }}>
        <DamageSurface
          allowIfSelected={setSelected}
          image={CarRightSide}
          side='right'
          sideAuto={rightSideAuto}
          sideRef={rightSideRef}
          text={t('carDamage.rightSideCar')}
        />
      </div>

      <Button disabled={!selected} margin='auto 0 0 0' mode='primary' onClick={onContinue}>
        {t('commonText.continue')}
      </Button>
    </ScreenContainer>
  );
};
