import React from 'react';
import styled from 'styled-components';

export interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'> {
  onChange(value: boolean): void;
  checked?: boolean;
}

const SwitchInputUI = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
`;

const SwitchSliderUI = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dadada;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 1ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 40px;

  &:before {
    content: '';
    position: absolute;
    height: 25px;
    width: 25px;
    left: -4px;
    bottom: -4px;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
  }
`;
const SwitchUI = styled.label`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 17px;
  margin-bottom: 0;
  vertical-align: middle;
  ${SwitchInputUI}:checked + ${SwitchSliderUI} {
    background-color: rgba(123, 102, 227, 0.6);
  }
  ${SwitchInputUI}:checked + ${SwitchSliderUI}:before {
    transform: translateX(24px);
    background-color: #592dd9;
  }
`;

export const Switch: React.FC<IProps> = ({ children, onChange, ...shared }) => {
  return (
    <SwitchUI>
      <SwitchInputUI onChange={e => onChange(e.target.checked)} {...shared} />
      <SwitchSliderUI />
    </SwitchUI>
  );
};

SwitchInputUI.defaultProps = {
  type: 'checkbox',
};
