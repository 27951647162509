export const HOME_TEXTS = {
  payPact: 'PayPact',
  didYouHaveAnAccident: 'Ai avut un accident?',
  completeTheFinding: 'Completează constatarea direct pe telefon',
  acceptTheCompensation: 'Acceptă oferta de despagubire',
  offer: 'Ofertă',
  accept: 'Acceptă',
  readMoreAbout: 'CITEȘTE MAI MULTE DESPRE',
  theWarranty: 'Garanția',
  whatIs: 'CE ESTE',
  thePaypactProcessing: 'PROCESAREA PAYPACT?',
  damageValue: 'Valoare daună',
  paypactProcessing: 'Procesare PayPact',
  totalTransfer: 'Total transfer',
  getPayPactMoney: 'Primește banii de la PayPact',
  getBrokerMoney: 'Primește banii în termenii asiguratorului',
  initialInformText1:
    'Evaluarea PayPact este realizată și aprobată de inspectori autorizați cu peste 20 de ani de experiență în domeniu, utilizând soluții software recunoscute  ' +
    'de companiile de asigurări din România. Tarifele în ceea ce privește costul pieselor, precum și ora de manoperă sunt calculate luând ca bază de calcul standardele' +
    'aplicate în reprezentanțele autorizate ale fiecărui producător.',
  initialInformText2:
    'Nu putem garanta suma oferită de asigurator, fiind posibil să existe anumite variații la momentul primirii ofertei de la asigurator.',
  InitialInformText3:
    'Dacă dauna ta se încadrează pentru plata rapida PayPact, atunci poți alege să primești banii în maximum 48 de ore de la noi conform evaluării, iar evaluarea e din partea casei :).',
  brokerMoneyNotice:
    'Nu putem garanta suma primită. Timpul de așteptare diferă de la o societate la alta, media depășind deseori o lună. Dacă nu ' +
    'dorești să folosești serviciile PayPact, accesează linkul de mai jos pentru a primi pe email documentele necesare depunerii ' +
    'dosarului la asigurator.',
  paymentDetails: 'DETALIILE PLĂȚII',
  getTheMoney: 'Primeste banii în 48 de ore!',
  continueFile: 'Pentru a primi banii, finalizează dosarul digitalal daunei',
  injuredBrokerName: 'Nume asigurat păgubit',
  bankName: 'Nume bancă',
  IBAN: 'IBAN',
  hours48: '48 de ore',
  provideYourBankInfo: 'Introdu IBAN-ul tău pentru a primii banii direct în cont',
  provideInjuredBrokerName: 'Introdu numele asiguratului',
  provideInjuredBankName: 'Introdu numele băncii',
  provideInjuredIBAN: 'Introdu IBAN-ul',
  checkTerms: 'Trebuie să accepți termenii și condițiile de plată.',
  ibanMinLength: 'Lungimea minimă a IBAN-ului trebuie să fie de 16 caractere.',
  currency: 'RON',
  start: 'Start',
  yes: 'Da',
  no: 'Nu',
  question: 'Întrebarea',
  situation: 'Situația',
  injured: 'Păgubit',
  goToTodayDatePicker: 'mergi la ziua de azi',
  goToNowTimePicker: 'ora actuală',
  guilty: 'Vinovat',
  chooseVehicle: 'Alege vehiculul',
  injuredVehicle: 'Care este vehiculul păgubit din constatarea amiabilă?',
  aVehicle: 'Vehiculul A',
  bVehicle: 'Vehiculul B',
  contact: 'Contactează',
  continueAnyway: 'Continuă oricum',
  continue: 'Continuă',
  informationByEmail: '* La final vei primi toate informațiile pe email.',
  insurerNotExist: 'Ne pare rău. Asiguratorul tău nu este în listă.',
  insuranceDetailsTitle: 'Verifică datele preluate',
  insuranceNameDetected: 'Asigurare #NAME# detectată!',
  retrievedDataFromBroker: 'Datele asigurării au fost preluate de la asigurator.',
  insuranceSeries: 'Seria Poliței',
  insuranceNo: 'Nr. poliță',
  insuranceOwnerName: 'Nume/Denumire Asigurat: PROPRIETAR',
  cnp: 'C.U.I / C.N.P. Proprietar',
  insuranceOwnerAddress: 'Adresa asigurat',
  carMakeAndModel: 'Fel, tip, marcă, model automobil',
  carPlate: 'Nr. de inmatriculare',
  registrationNumber: 'Nr de inregistrare',
  insuranceDateTo: 'Polita incheiată pe data de',
  insuranceValidity: 'Valabilitate poliță',
  cancel: 'Anulează',
  save: 'Salvează',
  statements: 'Declarații',
  newStatement: '+ Declarație nouă',
  statementSent: 'Declaratia a fost trimisa cu success!',
  onlyInsurerCanReceive: 'Conform legislației, doar Asiguratul poate primi oferta de despăgubire.',
  onlyInsurerCanReceiveNotice:
    'Șoferul este diferit de asiguratul din poliță. Continuă la introducerea datelor asiguratului pentru a primi oferta de despăgubire.',
  theDamagedInsurer: 'Asiguratul păgubit',
  typeDamageInsurer: 'Introdu numărul de telefon al Asiguratului Păgubit',
  thePhoneNumber: 'Numărul de telefon',
  chooseFromContactList: '+ Alege din agenda telefonului',
  statementPaymentDetails: 'Vei primi în curând un email cu detalii legate de plată.',
  damageStatement: 'Declarație de daună',
  damageStatementConfirmModal: 'Confirmă informațiile din Declarația de daună',
  damageStatementConfirmModalNotice:
    'Prin apăsarea butonului „Confirm” ești de acord cu toate informațiile și pozele preluate de către PayPact pentru trimiterea lor către Asigurator.',
  iConfirm: 'Confirm',
  agreeWithTheNext: 'Prin a face transferul de bani cât mai rapid către tine, este necesar să aprobi următoarele documente:',
  iDontConfirm: 'Nu confirm',
  insurerList: 'Listă asiguratori',
  circumstances: 'Circumstanțe',
  accidentDate: 'Data accidentului',
  accidentTime: 'Ora accidentului',
  accidentLocation: 'Locația accidentului',
  damage: 'Poze daune',
  witnesses: 'Martori',
  documents: 'Documente',
  injuredInsurance: 'Polița Păgubitului',
  guiltyInsurance: 'Polița Vinovatului',
  agreeWith: 'Sunt de acord cu',
  termsAndConditions: 'termenii și condițiile',
  ofPayment: 'de plată.',
  dataIsSaved: '* toate datele introduse până acum vor rămâne salvate',
  date: 'Dată',
  insurancePicture: 'Fotografierea Polițelor',
  continueWithInsurancePicture: 'Continuă cu realizarea fotografiilor asigurărilor RCA ale celor două autovehicule implicate',
  createAccountWith: 'Creează cont',
  injuredPhoneNo: '*Telefon păgubit',
  guiltyPhoneNo: '*Telefon vinovat',
  injuredEmail: 'Email păgubit',
  guiltyEmail: 'Email vinovat',
  injuredDetails: 'Datele păgubitului',
  guiltyDetails: 'Datele vinovatului',
  contactDetails: 'Date de contact',
  contactDetailsInfo: 'Detalii contact',
  whoCompleteAscertainment: 'Cine completează constatarea pentru partea vinovată?',
  injuredMe: 'Eu, păgubitul',
  sendLinkToGuilty: 'Trimite link vinovatului',
  with: 'cu',
  address: 'Adresă',
  name: 'Nume și prenume',
  addWitnesses: 'Adaugă martor',
  notWitnesses: 'Nu există martori',
  dateWitnesses: 'Datele martorului vor fi utilizate exclusiv pentru soluționarea dosarului de daună.',
  continueToRegister: 'Felicitări!\nPentru a continua procesul, creează-ți un cont.',
  noDirectSettlment: 'Ne pare rău. Nu te putem ajuta cu dosarul de daună.\n\nPutem să te ajutăm cu completarea constatării amiabilei!',
  deleteWitnesses: 'Ești sigur că vrei să ștergi martorul?',
  confirmBack: 'Ești sigur că vrei să părăsești pagina? Datele nesalvate vor fi șterse.',
  onlyInjured: 'Doar în calitate de păgubit poți continua.',
  community: 'Comunitate',
  legislation: 'Legislație',
  howWasTheAccident: 'Cum a avut loc accidentul?',
  standOnCircumstance: 'În staționare, oprit',
  departureTime: 'La momentul plecării, deschizând portiera',
  comingOutOfParking: 'Ieșind dintr-o parcare, loc privat',
  comingInOfParking: 'Intrând într-o parcare, loc privat',
  enteringTheRoundabout: 'Intrând în sensul giratoriu',
  runningTheRoundabout: 'Circulând în sensul giratoriu',
  hitFromBehindSameDirection: 'Lovind spatele altui vehicul care rula în acelasi sens si pe aceeasi banda de circulatie',
  sameDirectionDifferentLane: 'Ruland in acelasi sens, dar pe alta banda de circulatie',
  changingLane: 'Schimband banda de circulatie',
  overtaking: 'Depasind',
  steeringToTheLeft: 'Virand la stanga',
  steeringToTheRight: 'Virand la dreapta',
  goingBackwords: 'Efectuand manevra de mers inapoi',
  goingOnOppositeSide: 'Patrunzand pe banda rezervata circulatiei in sens invers',
  comingFromRight: 'Venind din dreapta, intr-o intersectie',
  notGivingPriority: 'Nerespectand indicatorul de prioritate',
  otherCircumstance: 'Altă circumstanță',
  visibilityConditions: 'Care au fost condițiile de vizibilitate la momentul producerii accidentului?',
  clearSky: 'Cer senin',
  cloudy: 'Înnorat',
  rainy: 'Ploaie',
  snow: 'Ninsoare',
  roadCondition: 'Care era starea drumului la momentul producerii accidentului?',
  dryRoad: 'Uscat',
  wetRoad: 'Umed',
  frozenRoad: 'Înghețat',
  anotherState: 'Altă stare',
  circumstanceDescriptionText: 'Descrie circumstanța',
  selectDamagedAreas: 'Selectează zonele cu daune',
  leftSideCar: 'Partea stângă auto',
  rightSideCar: 'Partea dreaptă auto',
  mentionAllVisibleDamage: 'Menționează toate daunele vizibile ale mașinii tale',
  describeDamage: 'Descrie daunele mașinii păgubitului\n',
  describeDamageError: '*descrie daunele mașinii păgubitului cu min 20 de caractere pentru a continua',
  affectedZones: 'Zone afectate',
  picture: 'Poză',
  sketchTitleDescription: 'Realizează o schiță cu circumstanțele în care a avut loc accidentul',
  sketchBodyDescription:
    'Asigură-te că redai cele mai importante elemente: așezarea străzilor, semne de circulație și poziționările corecte ale autovehiculelor implicate.',
  takeAmicableFindingPicture: 'Realizează o poză la Constatarea amiabilă',
  injuredSignature: 'Semnătură păgubit',
  guiltySignature: 'Semnătură vinovat',
  signature: 'Semnătură',
  injuredSignatureText: 'Păgubitul trebuie să semneze pe ecran folosind vârful degetului',
  guiltySignatureText: 'Vinovatul trebuie să semneze pe ecran folosind vârful degetului',
  signatureText: 'Semnează pentru a confirma',
  pressForSignature: 'Apasă pentru a semna',
  currentLocation: 'Trimite locația actuală',
  noLocationTitle: 'Locație negasită',
  payment: 'Plata',
  utils: 'Utile',
};

export const PROFILE_TEXTS = {
  addEmail: 'Adaugă email',
  email: 'Email',
  logOut: 'Deconectare',
  contactPP: 'CONTACTEAZĂ ECHIPA PAYPACT',
  telephone: 'Telefon',
};

export const VALIDATION_TEXTS = {
  accidentDateRequired: 'Câmpul „Dată accident” este obligatoriu.',
  accidentTimeRequired: 'Câmpul „Oră accident” este obligatoriu.',
  accidentLocationRequired: 'Câmpul „Locație accident” este obligatoriu.',
};

export const ASSESSMENTS_TEXT = {
  observationNote: 'Notă de constatare',
  assessment: 'Evaluare',
  numberPolicy: 'Nr. Poliță:',
  date: 'Data:',
  finding: 'Nota de constatare',
  warrant: 'Mandat',
  receivableAssignment: 'Cesiune creanță',
  compensation: 'Oferta de despăgubire',
  confirmWarrant: 'Confirmă informațiile din mandat',
  confirmReceivableAssignment: 'Confirmă informațiile din Cesiunea creanței',
  confirmFinding: 'Confirmă informațiile din Nota de constatare',
  confirmCompensation: 'Confirmă informațiile din Oferta de despăgubire',
  confirmObservationNote: 'Confirmă informațiile din Nota de constatare',
  notConfirm: 'Nu confirm',
  confirm: 'Confirm',
  reFinding: 'Doresc Re-constatare',
  reFindingTitle: 'Re-constatare',
  confirmFindingContent:
    'Prin apăsarea butonului “Confirm” ești de acord cu toate informațiile și pozele preluate de către PayPact pentru trimiterea lor către Asigurator.',
  iWantMoneyInMyAccount: 'Doresc banii în cont',
  iWantMoneyInMyAccountNotice:
    'Prin apăsarea butonului „Continuă”, accepți informațiile și pozele preluate de către PayPact pentru trimiterea lor către Asigurator.',
  fixMyCarInService: 'Doresc reparația mașinii în service autorizat',
  fixMyCarInServiceNotice:
    'În acest caz vei primi toate documentele necesare pe email, împreună cu lista service-urilor autorizate partenere cu compania de asigurare.',
  addMissingPictures: 'Adaugă pozele lipsă',
  addPicture: '+ Adaugă poză',
  addAdditionalDamageDescription: 'Adaugă descrierea daunelor suplimentare',
  additionalDamageDescription: 'Daune suplimentare',
};
