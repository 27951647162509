import { OCR_DOCUMENT_TYPE } from '../../constants/typeConstants/documentsFormType';

interface carDriver {
  cuiCNP: string | null;
  name: string | null;
}

export interface ocrGenericResponseType {
  [key: string]: string | null;
}

export type orcResponse = {
  carDriver: carDriver;
} & ocrGenericResponseType;

export const isDataPopulatedEnough = (data: { [key: string]: string | null }): boolean => {
  const countOfMissingFields = Object.values(data).reduce((count, item) => {
    if (!item) {
      return count + 1;
    }

    return count;
  }, 0);

  return countOfMissingFields <= Object.keys(data).length / 2;
};

export const checkOcrResponseIntegrity = (documentType: OCR_DOCUMENT_TYPE, ocrResponse: { [key: string]: string | null }): boolean => {
  switch (documentType) {
    case OCR_DOCUMENT_TYPE.CAR_IDENTITY:
    case OCR_DOCUMENT_TYPE.DRIVING_LICENSE:
    case OCR_DOCUMENT_TYPE.ID_CARD:
    case OCR_DOCUMENT_TYPE.INSURANCE:
      return isDataPopulatedEnough(ocrResponse);
  }
};

const checkOcrFieldIntegrity = (field?: string | null) => {
  if (!field || field === '-' || field === 'NA') return '';

  return field;
};

export const formatOcrResponse = (
  documentType: OCR_DOCUMENT_TYPE,
  response: { data: orcResponse },
): null | { [key: string]: string | null } => {
  switch (documentType) {
    case OCR_DOCUMENT_TYPE.ID_CARD:
      return {
        nameSurname: checkOcrFieldIntegrity(response.data.fullName),
        cnp: checkOcrFieldIntegrity(response.data.identityNumber),
        serial: checkOcrFieldIntegrity(response.data.serial),
        serialNumber: checkOcrFieldIntegrity(response.data.serialNumber),
        dateOfBirth: checkOcrFieldIntegrity(response.data.birthday),
        placeOfBirth: checkOcrFieldIntegrity(response.data.placeOfBirth),
        address: checkOcrFieldIntegrity(response.data.address),
        issuedBy: checkOcrFieldIntegrity(response.data.issuedBy),
        issuedOn: checkOcrFieldIntegrity(response.data.issuedOn),
        expiresOn: checkOcrFieldIntegrity(response.data.expiresOn),
      };
    case OCR_DOCUMENT_TYPE.CAR_IDENTITY:
      return {
        carType: checkOcrFieldIntegrity(response.data.category),
        carBrand: checkOcrFieldIntegrity(response.data.brand),
        carModel: checkOcrFieldIntegrity(response.data.model),
        matriculationNumber: checkOcrFieldIntegrity(response.data.registerNumber),
        firstMatriculationYear: checkOcrFieldIntegrity(response.data.manufacturingYear),
        ownerName: checkOcrFieldIntegrity(response.data.owner),
        registrationNumber: checkOcrFieldIntegrity(response.data.vinNumber),
        itpValidity: checkOcrFieldIntegrity(response.data.itpValidity),
      };
    case OCR_DOCUMENT_TYPE.INSURANCE:
      return {
        insurerName: checkOcrFieldIntegrity(response.data.fullName),
        insuranceSeries: checkOcrFieldIntegrity(response.data.series),
        insuranceNo: checkOcrFieldIntegrity(response.data.number),
        insuranceOwnerName: checkOcrFieldIntegrity(response.data.fullName),
        cnp: checkOcrFieldIntegrity(response.data.cnp),
        insuranceOwnerAddress: checkOcrFieldIntegrity(response.data.address),
        carMakeAndModel: checkOcrFieldIntegrity(response.data.markTypeModel),
        carPlate: checkOcrFieldIntegrity(response.data.registerNumber),
        registrationNumber: checkOcrFieldIntegrity(response.data.numberIdentificare),
        insuranceCreatedAt: checkOcrFieldIntegrity(response.data.issuedOn),
        insuranceDateFrom: checkOcrFieldIntegrity(response.data.start),
        insuranceDateTo: checkOcrFieldIntegrity(response.data.end),
        seats: checkOcrFieldIntegrity(response.data.seats),
        engineCapacity: checkOcrFieldIntegrity(response.data.engineCapacity),
        civNumber: checkOcrFieldIntegrity(response.data.civNumber),
        carDriverCnp: checkOcrFieldIntegrity(response.data.carDriver.cuiCNP),
        carDriverFullName: checkOcrFieldIntegrity(response.data.carDriver.name),
      };
    case OCR_DOCUMENT_TYPE.DRIVING_LICENSE:
      return {
        nameSurname:
          checkOcrFieldIntegrity(response.data.lastName) && checkOcrFieldIntegrity(response.data.firstName)
            ? response.data.lastName + ' ' + response.data.firstName
            : null,
        birthLocation: checkOcrFieldIntegrity(response.data.birthLocation),
        dateOfBirth: checkOcrFieldIntegrity(response.data.dateOfBirth),
        cnp: checkOcrFieldIntegrity(response.data.cnp),
        availabilityStart: checkOcrFieldIntegrity(response.data.dateOfIssue),
        availabilityEnd: checkOcrFieldIntegrity(response.data.dateOfExpiry),
        categories: checkOcrFieldIntegrity(response.data.categories),
        number: checkOcrFieldIntegrity(response.data.number),
      };
  }

  return null;
};
