import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { StyledScreenContainer, Wrapper } from '../../styled-components/containers';
import { Modal, WelcomeHeader } from '../../styled-components';
import { BottomNavigation, INavCreateProps, PageTopNav } from '..';
import { TabsNav } from '../layout/TabsNav';
import { IModalProps } from '../../styled-components/modals/types';
import { IHeaderProps } from '../../styled-components/headers';
import { useLastLocation } from '../../hooks/useLastLocation';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { ILastRoutes } from '../../store/last-routes/last-routes.slice';
import { style } from '../../screens/evaluations/style';

export type IHeaderType = 'dark' | 'light' | 'tabs' | 'none';

interface IBodyProps {
  display?: string;
  flexDirection?: string;
  justifyContent?: string;
  padding?: string;
}

interface IScreenContainer {
  headerType?: IHeaderType; //'dark' | 'light' | 'tabs' | 'none, defaults to light
  header?: JSX.Element;
  headerProps?: INavCreateProps | IHeaderProps; // props passed to header component
  showBottomNavbar?: boolean; //show/hide bottomNav, defaults to true
  bodyProps?: IBodyProps;
  isModalOpen?: boolean; // this prop apply modal effect(grayscale)
  modal?: JSX.Element | null;
  specialScreen?: boolean;
  backgroundColor?: string; // BG of content wrapper
  modalProps?: Omit<IModalProps, 'headerType'>;
}

export interface IHeaderComponentProps extends Pick<IScreenContainer, 'headerProps' | 'backgroundColor'> {
  type?: IHeaderType;
  style?: React.CSSProperties;
}

export const HeaderComponent = (props: IHeaderComponentProps): JSX.Element | null => {
  const { type, headerProps, style } = props;

  switch (type) {
    case 'light':
      return <PageTopNav style={style} {...headerProps} />;
    case 'dark':
      return <WelcomeHeader backgroundColor='#1E1E29' height='155px' {...headerProps} />;
    case 'tabs':
      return <TabsNav {...headerProps} />;
    default:
      return null;
  }
};

export const ScreenContainer: FC<IScreenContainer> = props => {
  const { specialScreen, headerProps, headerType, showBottomNavbar, bodyProps, children, isModalOpen, backgroundColor, header } = props;

  const contentHeight = (): string => {
    let headerHeight;
    const bottomNavHeight = showBottomNavbar && !isModalOpen ? 62 : 0;

    switch (headerType) {
      case 'light':
      case 'tabs':
        headerHeight = 77;
        break;
      case 'dark':
        headerHeight = 155;
        break;
      default:
        headerHeight = 0;
    }

    if (header) {
      headerHeight += 64;
    }

    return `calc(100% - ${headerHeight + bottomNavHeight}px)`;
  };

  const modalContentHeight = (): string => {
    const bottomNavHeight = showBottomNavbar && !isModalOpen ? 62 : 0;

    return `calc(100% - ${bottomNavHeight}px)`;
  };

  const initialState: ILastRoutes = {
    data: [],
    isLoading: false,
    error: null,
  };

  const contentStyle = isModalOpen && { filter: 'grayscale(100%)', opacity: 0.4 };
  const { setLastLocationByStatementID, resetRoutes } = useLastLocation();
  const { currentStatement } = useCurrentStatement();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [navigation, setNavigation] = useState<string | null>(null);
  const [confirmNavigation, setConfirmNavigation] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (currentStatement.statementID !== null) {
      setLastLocationByStatementID(currentStatement.statementID, window.location.href);
    } else {
      resetRoutes(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatement.statementID]);

  useEffect(() => {
    if (confirmNavigation && navigation) {
      history.push(`/${navigation}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmNavigation]);

  return (
    <StyledScreenContainer backgroundColor={isModalOpen ? (!specialScreen ? '#F0F2FC' : 'white') : backgroundColor || '#F0F2FC'}>
      {header ?? (
        <HeaderComponent headerProps={headerProps} style={{ ...contentStyle, backgroundColor: 'transparent' }} type={headerType} />
      )}

      <Wrapper
        alignItems='center'
        color={isModalOpen ? '#cacaca!important' : 'inherit'}
        display={bodyProps?.display ?? 'flex'}
        flexDirection={bodyProps?.flexDirection ?? 'column'}
        height={contentHeight()}
        justifyContent={bodyProps?.justifyContent}
        overflowY='auto'
        padding={bodyProps?.padding ?? '25px 20px'}
        style={{ overflow: 'auto', ...contentStyle }}
      >
        {children}
      </Wrapper>

      {isModalOpen && (
        <div style={{ ...style.modalWrapper, height: modalContentHeight() }}>
          <Modal {...props.modalProps} headerType={headerType}>
            {props.modal}
          </Modal>
        </div>
      )}

      {showBottomNavbar && !isModalOpen && (
        <BottomNavigation confirmNavigation={confirmNavigation} setNavigation={setNavigation} setShowModal={setShowModal} />
      )}
    </StyledScreenContainer>
  );
};

ScreenContainer.defaultProps = {
  showBottomNavbar: true,
  headerType: 'light',
  headerProps: {
    iconButton: 'back',
  },
  bodyProps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '25px 20px',
  },
};
