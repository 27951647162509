import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

import { GoogleMapActionsProps } from '../../@types/google-map/GoogleMap.types';
import { LocationIcon, PlusIcon } from '../../assets/icons';
import { MinusIcon } from '../../assets/icons/MinusIcon';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 155,
      height: 48,
      position: 'absolute',
      bottom: 20,
      right: 5,
    },
    actionButton: {
      width: '45px',
      height: '45px',
      background: 'rgba(255, 255, 255, 0.95)',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.28)',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export const GoogleMapActions = (props: GoogleMapActionsProps): JSX.Element => {
  const { onCurrentLocation, onZoomIn, onZoomOut } = props;

  const classes = useStyles();

  return (
    <div className={classes.content}>
      <button className={classes.actionButton} onClick={() => onZoomIn()}>
        <PlusIcon stroke='#0066FF' strokeWidth='2' />
      </button>
      <button className={classes.actionButton} onClick={() => onZoomOut()}>
        <MinusIcon stroke='#0066FF' strokeWidth='2' />
      </button>
      <button className={classes.actionButton} onClick={() => onCurrentLocation()}>
        <LocationIcon />
      </button>
    </div>
  );
};
