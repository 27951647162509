import { PreLoginInsuranceData } from '../../@types/pre-login/preLoginTypes';
import { ICurrentPerson, ICurrentStatementInsurance, IStatementBackResponse } from '../../@types/current-statement/currentStatementTypes';

export const updatePerson = (
  personCurrentStatement: ICurrentPerson,
  personId: number,
  personInsurance: PreLoginInsuranceData,
  personStatementCurrentInsurance: ICurrentStatementInsurance['data'],
  personObjectType: string,
  injuredBackResponse?: IStatementBackResponse,
) => {
  const { insurerID, insurerName, directSettlement, available } = personInsurance;

  return {
    ...personCurrentStatement,
    userID: personId,
    [personObjectType]: {
      type: personObjectType === 'injured' ? injuredBackResponse?.injured?.type : null,
      documents: {
        insurance: {
          ...personStatementCurrentInsurance,
          insurerID,
          insurerName,
          directSettlement,
          available,
        },
      },
    },
  };
};
