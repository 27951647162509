import React, { useState } from 'react';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { ITab } from '../../components/layout/TabsNav';
import { Info } from '../../components/info';
import { PaypactContact } from '../../components/paypact-contact';
import { HOME_TEXTS } from '../../translations/ro/home';
import { INFO_TAB } from '../../translations/ro/info-tab';

interface ITopics {
  items: ITopicItem[];
}

interface ITopicListItem {
  title: string;
  text: string;
}
interface ITopicItem {
  title: string;
  link?: string;
  list?: Array<ITopicListItem>;
}

const tab1Content: ITopics[] = INFO_TAB.utils;
const tab2Content: ITopics[] = INFO_TAB.legislation;

const tabs: ITab[] = [
  { id: 1, label: HOME_TEXTS.utils },
  { id: 2, label: HOME_TEXTS.legislation },
  { id: 3, label: HOME_TEXTS.payPact },
];

export const Utils = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<number>(1);

  return (
    <ScreenContainer
      headerProps={{
        title: 'Informații',
        tabs,
        backgroundColor: 'none',
        gradient: 'linear-gradient(to left, #563add , #8c51df)',
        boxShadow: 'none',
        onTabClick: setActiveTab,
        selectedTab: activeTab,
        heightTab: '30px',
        borderRadiusTab: '50px',
        activeTabBgColor: '#4123d7',
        activeTabColor: '#fff',
        tabColor: 'white',
        fontSize: '14px',
        tabsContainerPadding: '0 20px',
      }}
      headerType='tabs'
    >
      {' '}
      <div style={{ paddingBottom: '100px', width: '100%' }}>
        {activeTab === 1 ? (
          <>
            <Info content={tab1Content} uniqueKey='tab1' />
          </>
        ) : activeTab === 2 ? (
          <Info content={tab2Content} uniqueKey='tab2' />
        ) : (
          <PaypactContact />
        )}{' '}
      </div>
    </ScreenContainer>
  );
};
