import axios, { AxiosInstance } from 'axios';

import store from '../../store/store';
import { logOut } from '../../store/app/app.slice';
const BASE_URL = process.env.REACT_APP_API_URL;
const AXIOS_TIMEOUT_MEDIA = process.env.REACT_APP_AXIOS_REQUEST_TIMEOUT_MEDIA;
const AXIOS_TIMEOUT = process.env.REACT_APP_AXIOS_REQUEST_TIMEOUT;

let cancelTokenSource = axios.CancelToken.source();

// TODO: Append "Request" to all request methods names

export const stopRequests = (): void => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();
};

const getAuthenticationToken = () => {
  try {
    return localStorage.getItem('access_token');
  } catch {
    return null;
  }
};
export function authorizedRequest(returnOnlyData = false): AxiosInstance {
  const token = getAuthenticationToken();
  axios.defaults.timeout = Number(AXIOS_TIMEOUT);

  if (!token) {
    return axios.create({
      baseURL: BASE_URL,
    });
  }

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelTokenSource.token,
  });

  axiosInstance.interceptors.response.use(
    response => (returnOnlyData ? response.data : response),
    error => {
      if (error?.response?.status === 401) {
        store.dispatch(logOut());
      }

      return Promise.reject(error);
    },
  );

  console.log('AXIOS INSTANCE', axiosInstance);

  return axiosInstance;
}
export function requestMedia(): AxiosInstance {
  const token = getAuthenticationToken();
  axios.defaults.timeout = Number(AXIOS_TIMEOUT_MEDIA);

  if (!token) {
    return axios.create({
      baseURL: BASE_URL,
      cancelToken: cancelTokenSource.token,
    });
  }

  return axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    },
    cancelToken: cancelTokenSource.token,
  });
}
