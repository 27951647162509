export enum STATUS_CARD {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export enum CARD_TYPE {
  INJURED_DOCUMENTS = 'INJURED_DOCUMENTS',
  DAMAGE = 'DAMAGE',
  CIRCUMSTANCES = 'CIRCUMSTANCES',
  WITNESSES = 'WITNESSES',
  GUILTY_DOCUMENTS = 'GUILTY_DOCUMENTS',
  CHECK_IDENTITY = 'CHECK_IDENTITY',
}

export interface StatementOrEvaluationStep {
  id: number;
  status: STATUS_CARD;
  title: string;
  type: CARD_TYPE;
  navigateTo: {
    new: string;
    list?: string;
  };
}
