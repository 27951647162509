export const PATHS = {
  //SINGLE PATHS USED FOR CUSTOM ROUTES AS CONSTANTS
  auth: '/auth',
  notifications: '/notifications',
  evaluations: '/evaluations',
  payment: '/payment',
  damage: '/damage',
  currentStatement: '/currentStatement/:currentStatementId',
  currentStatementDocuments: '/currentStatement/:currentStatementId/documents',
  statements: '/statements',
  insurance: '/insurance',
  shared: 'shared',
  photo: '/photo',
  list: '/list',
  ocrDocumentsForm: '/ocr-document-form',
  documentsCamera: '/document-camera',
  documentsForm: '/ocr-document-form',
  chooseDocuments: '/documents/choose-documents',
  signature: '/signature',
  chooseVehicle: '/choose-vehicle',
  documents: '/documents',
  facialRecognitionCamera: '/facial-recognition/camera',
  congratulations: '/congratulations',
};

export const PRE_LOGIN_ROUTES = {
  //PRE-LOGIN ROUTES CAN BE ACCESSED ANYTIME
  root: '/', //MAIN APPLICATION ROUTE WELCOME SCREEN (FIRST SCREEN)
  initialInformText: '/initial-inform',
  initialInformNotificationText: '/initial-inform-notification',
  initialInformEvaluationText: '/initial-inform-evaluation', //INFORMATIONAL TEXT AFTER FLOW START(SECOND SCREEN)
  importantInformation: '/important-information', //ATTENTION SCREEN WITH IMPORTANT DATA (THIRD SCREEN)
  insuranceType: '/check-insurance-type', //PROMPT SCREEN TO CHOOSE RCA OR CASCO
  amicableFindings: '/amicable-findings/:id', //PROMPT ABOUT THE OBSERVATION NOTE OR COMPENSATION OFFER (2 SCREENS WITH TWO ID'S PASSED AS ARGUMENT) THE ANSWERS WILL AFFECT THE FLOW
  ownerNotIndividualScreen: '/owner-not-individual', //IF THE OWNER STATUS IS DIFFERENT OF MYSELF OR OTHER INDIVIDUAL THIS IS THE MODAL SCREEN CONFIRMATION
  informAssured: '/inform-assured', //THE SCREEN WHICH INFORM THE ASSURED THAT HIS CAR WAS INVOLVED IN AN ACCIDENT
  chooseInsuranceCompany: `${PATHS.insurance}/choose`, //PICK THE INSURER COMPANY FOR BOTH PEOPLE INVOLVED INTO THE ACCIDENT
  //THIS IS THE END OF THE PRE-LOGIN PHASE

  //LOGIN AND REGISTER -> SUCCESS LOGIN OR REGISTER
  login: '/login', //LOGIN SCREEN *YOU CAN GO TO THIS SCREEN AUTOMATICALLY ONLY IF YOU ARE NOT LOGGED IN. IF YOU ARE LOGGED IN YOU WILL RECEIVE A CONFIRMATION MODAL THAT YOU ARE ALREADY LOGGED IN AND YOU CAN SKIP THIS PART
  register: '/register', //IF YOU DON'T HAVE AN ACCOUNT YOU CAN GO TO THE REGISTER SCREEN
  loginSuccess: '/login-success', //INTERMEDIATE SCREEN BETWEEN THE FRONT AND BACK WHERE THE DATA IS SENT AND BROUGHT BACK
};

export const PROTECTED_ROUTES = {
  //PROTECTED ROUTES ARE THE ROUTES USED AFTER LOGIN THIS MEANS YOU CAN'T ACCESS THEM BEFORE YOU ARE LOGGED
  ownerStatus: '/check-owner-status', //PROMPT SCREEN TO SELECT THE TYPE OF OWNER (COMPANY, MYSELF , LEASING SOCIETY , OTHER INDIVIDUAL)
  checkContactDetails: '/contact-details', //AFTER YOU CHOOSE THE TYPE OF OWNER YOU COMPLETE THE CONTACT DETAILS

  // PAYMENT
  payment: '/payment', //PAYMENT SCREEN FIRST PROTECTED ROUTE AFTER LOGIN
  paymentReturn: `${PATHS.payment}/return`, // after the user is redirected back to our application with a payment status

  // GLOBAL
  whoCompleteAscertainment: '/complete-ascertainment',
  utils: '/utils',
  profile: '/profile',

  // HEADER MENU Tabs
  notifications: `${PATHS.notifications}`,
  evaluations: `${PATHS.evaluations}`,
  statements: PATHS.statements,

  //DOCUMENTS
  chooseDocuments: `${PATHS.currentStatementDocuments}/choose-documents`, //AFTER YOU CLICK ON THE DOCUMENTS CARD THIS IS THE FIRST SCREEN OF THE DOCUMENTS FLOW YOU HAVE TO CHOOSE BETWEEN AMICABLE FINDING OR POLICE REPORT
  chooseDamagedVehicle: `${PATHS.currentStatementDocuments}${PATHS.chooseVehicle}`, //IF  YOU CHOOSE THE AMICABLE FINDING ON THE PREVIOUS PROMPT YOU WILL FIND YOURSELF ON THIS SCREEN TO CHOOSE WHICH VEHICLE IS THE INJURED ONE

  /**
   * Insurance.
   */
  insurancePhoto: `${PATHS.insurance}/photo`,
  insuranceCamera: `${PATHS.insurance}/camera`,
  insurancePrompt: `${PATHS.insurance}/prompt`,
  insurancePhotoEdit: `${PATHS.insurance}/photo-edit`,
  insuranceDetected: `${PATHS.insurance}/detected`,
  insuranceForm: `${PATHS.insurance}/form`,
  insuranceContact: `${PATHS.insurance}/contact`,
  insuranceDataComplete: `${PATHS.insurance}/data-complete`,
  insuranceSmsSent: `${PATHS.insurance}/sms-sent`,

  /**
   * Statements
   */
  statement: PATHS.currentStatement,
  statement$IdDetails: `${PATHS.currentStatement}`,
  documentsCameraStatements: `${PATHS.currentStatementDocuments}${PATHS.documentsCamera}`,
  currentStatementDocumentsList: `${PATHS.currentStatementDocuments}${PATHS.list}`,
  documentsFormStatements: `${PATHS.currentStatementDocuments}${PATHS.ocrDocumentsForm}`, //THIRD SCREEN WHERE YOU SEE THE OCR DATA FROM THE SCAN ***YOU CAN MODIFY DATA INSIDE THE FORM AND YOU GO BACK TO THE DOCUMENTS SCREEN
  documentsPhotoStatements: `${PATHS.currentStatement}/documents/photo`,
  statementMethodDetailsOffer: `${PATHS.currentStatement}/details/offer`,
  statementMethodDetails: `${PATHS.currentStatement}/details`,
  statementDocuments: `${PATHS.currentStatementDocuments}`, //AFTER YOU PASS THE PREVIOUS PROMPT YOU ARE ON THE MAIN DOCUMENTS SCREEN WHERE YOU SEE EVERY DOCUMENT YOU HAVE TO UPLOAD ***THE DOCUMENTS REQUIRED ARE DYNAMIC SO THE PREVIOUS SCREENS MAY AFFECT THE DOCUMENTS THE USER HAS TO COMPLETE
  statementObservationNote: `${PATHS.currentStatement}/observation-note`,
  statementWarrant: `${PATHS.currentStatement}/warrant`,
  statementFinding: `${PATHS.currentStatement}/finding`,
  statementCompensation: `${PATHS.currentStatement}/compensation`,
  statementCompensationClaim: `${PATHS.currentStatement}/compensation-claim`,
  receivableAssignment: `${PATHS.currentStatement}/receivable-assignment`,
  statement$IdCircumstances: `${PATHS.currentStatement}/circumstances`,
  statementCircumstanceList: `${PATHS.currentStatement}/circumstances/list`,
  circumstances: `${PATHS.currentStatement}/circumstances`,
  circumstances$Id: `${PATHS.currentStatement}/circumstances/:id`,
  statementRefinding: `${PATHS.currentStatement}/finding/refinding`,
  statementRefindingInfo: `${PATHS.currentStatement}/finding/refinding/info`,
  statementView: `${PATHS.currentStatement}/view`,
  statementDocumentsList: `${PATHS.currentStatement}/documents/list`,
  statementDamage: `${PATHS.currentStatement}/damage`,
  statementWitnesses: `${PATHS.currentStatement}/witnesses`,
  statementSignature: `${PATHS.currentStatement}/signature`,
  statementFinalSignature: `${PATHS.currentStatement}/finalSignature`,
  statementSketch: `${PATHS.currentStatement}/sketch`,
  statementGuilty: `${PATHS.currentStatement}/guilty`,
  faceRecognition: `/facial-recognition`,
  faceRecognitionCamera: `${PATHS.currentStatement}${PATHS.facialRecognitionCamera}`,
  congratulations: `${PATHS.currentStatement}/facial-recognition/congratulations`,
  informationSendSuccessfully: `${PATHS.currentStatement}/information-send-successfully`,

  /**
   * Statement details damage routes.
   */
  statement$IdDamage: `${PATHS.currentStatement}/damage`,
  statementDamageDetails: `${PATHS.currentStatement}/damage/details`,
  statementDamageDescription: `${PATHS.currentStatement}/damage/description`,
  statementDamageList: `${PATHS.currentStatement}/damage/list`,

  /**
   * Witnesses
   */
  statement$IdWitnesses: `${PATHS.currentStatement}/witnesses`,
  statementWitnessesAdd: `${PATHS.currentStatement}/witnesses/add`,
  statementWitnessesList: `${PATHS.currentStatement}/witnesses/list`,
  statementWitnesses$WitnessIdEdit: `${PATHS.currentStatement}/witnesses/:witnessId/edit`,

  /**
   * Shared
   */
  photoEditor: `${PATHS.shared}/photo-editor`,
};

export enum BottomNavigationPathUtilities { //USED TO MATCH THE URL TO THE BOTTOM NAVIGATION TABS
  utils = 'utils',
  evaluations = 'evaluations',
  profile = 'profile',
  home = 'home',
}
