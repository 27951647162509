import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

import { Wrapper } from '../../styled-components';
import { Image } from '../../styled-components';
import ExclamationSvg from '../../assets/svgs/exclamation-mark.svg';
import { $darkRed } from '../../styles/variables';

type TextFieldType = 'outlined' | 'default' | 'rounded';

type InputTextTransform = 'capitalize' | 'uppercase' | 'lowercase' | 'none';

export interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  value?: any;
  variant?: TextFieldType;
  label?: string;
  placeholder?: string;
  error?: boolean;
  margin?: string;
  padding?: string;
  height?: string;
  errorMessage?: string;
  borderRadius?: string;
  border?: string;
  fontSize?: string;
  position?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  mode?: string;
  textTransform?: InputTextTransform;
}

interface IThemedTexField extends IProps {
  theme: DefaultTheme;
}

const Input = styled.input`
  padding: ${({ padding }: IThemedTexField) => padding || '10px 16px'};
  height: ${({ height }: IThemedTexField) => height || '48px'};
  font-size: 16px;
  color: #20344c;
  border: none;
  outline: 0;
  width: 100%;
  background-color: transparent;
  text-transform: ${({ textTransform }: IThemedTexField) => textTransform || 'none'};
  &::placeholder {
    font-size: 14px;
    line-height: 17px;
    color: #b6b6b6;
  }
`;

const FilledInput = styled(Input)`
  background: #ffffff;
  border-radius: ${({ borderRadius }: IThemedTexField) => borderRadius || '0px'};
  border: ${({ border }: IThemedTexField) => border || 'none'};
  &:disabled {
    background-color: white;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InputError = styled.p`
  font-size: 12px;
  color: #ff3b30;
  margin-top: 0;
`;

const InputErrorMessage = styled.p`
  font-size: 12px;
  color: #ff3b30;
  margin-top: 0;
`;

const IconWrapper = styled.div`
  max-height: 20px;
  max-width: 20px;
`;

const StyledTextField = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffff;
  border-bottom: ${(props: IThemedTexField) =>
    props.error ? `1px solid ${props.theme.palette.red.dark}` : props.border ? props.border : '1px solid #dcdcdc'};
  ${({ margin }: IThemedTexField) =>
    margin &&
    css`
			margin ${margin};
		`}
`;

const OutlinedInput = styled(StyledTextField)`
  margin: ${(props: IThemedTexField) => (props.errorMessage ? '10px 0' : '10px 0')};
  background: #fff;
  border: 1px solid ${(props: IThemedTexField) => (props.error ? props.theme.palette.red.dark : '#E7E7E7')};
  border-radius: 3px;
`;

const RoundedInput = styled(StyledTextField)`
  margin: ${(props: IThemedTexField) => (props.errorMessage ? '10px 0' : '10px 0')};
  background: #fff;
  border: 1px solid ${(props: IThemedTexField) => (props.error ? props.theme.palette.red.dark : '#E7E7E7')};
  border-radius: 26px;
  position: ${(props: IThemedTexField) => (props.position ? props.position : 'relative')};
`;

export const InputLabel = styled.span`
  color: #9a9a9a;
  font-size: ${({ fontSize }: IThemedTexField) => fontSize || '12px'};
  font-family: Lato, Montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
`;

const InputWithIcon = (props: IProps) => {
  const { iconLeft, iconRight } = props;

  return (
    <InputContainer>
      <IconWrapper>{iconLeft}</IconWrapper>
      <Input {...props} />
      {iconRight}
    </InputContainer>
  );
};

const renderInput = (props: IProps) => {
  const { variant, iconLeft, iconRight } = props;

  switch (variant) {
    case 'default':
      return (
        <StyledTextField {...props}>
          <InputLabel>{props.label}</InputLabel>
          {iconLeft || iconRight ? <InputWithIcon {...props} /> : <Input placeholder={props.placeholder} {...props} />}
        </StyledTextField>
      );
    case 'outlined':
      return (
        <>
          <InputLabel>{props.label}</InputLabel>
          <OutlinedInput {...props}>
            {iconLeft || iconRight ? <InputWithIcon {...props} /> : <FilledInput placeholder={props.placeholder} {...props} />}
          </OutlinedInput>
          {props.errorMessage && <InputError>{props.errorMessage}</InputError>}
        </>
      );
    case 'rounded':
      return (
        <>
          <InputLabel>{props.label}</InputLabel>
          <RoundedInput {...props}>
            <FilledInput
              border={props.border || props.errorMessage ? `1px solid ${$darkRed}` : 'none'}
              borderRadius='24px'
              placeholder={props.placeholder}
              {...props}
            />
            {props.errorMessage && (
              <Wrapper
                alignItems='center'
                background='#DB4437'
                borderRadius='23px'
                display='flex'
                height='24px'
                justifyContent='center'
                position='absolute'
                right='12px'
                top='10px'
                width='24px'
              >
                <Image src={ExclamationSvg} />
              </Wrapper>
            )}
          </RoundedInput>
          <InputErrorMessage>{props.errorMessage}</InputErrorMessage>
        </>
      );
    default:
      return (
        <StyledTextField {...props}>
          <InputLabel>{props.label}</InputLabel>
          {iconLeft || iconRight ? <InputWithIcon {...props} /> : <Input placeholder={props.placeholder} {...props} />}
        </StyledTextField>
      );
  }
};

export const TextField: React.FC<IProps> = props => {
  return renderInput(props);
};
