import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Typography } from '../../styled-components';
import { Button } from '../../components/button';

export const UrlNotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ScreenContainer
      headerProps={{
        showButton: false,
      }}
      showBottomNavbar={false}
    >
      <Typography align='center' commonStyles={{ margin: '50px 0 20px 0' }} variant='h3'>
        {t('urlNotFound.sorryPageNotFound')}
      </Typography>
      <Typography align='center' commonStyles={{ margin: '20px 0 ' }} variant='h4'>
        {t('urlNotFound.clickToGoToMainPage')}
      </Typography>
      <Button margin='auto 0 30px 0' mode='primary' onClick={() => history.push('/')}>
        {t('urlNotFound.mainPage')}
      </Button>
    </ScreenContainer>
  );
};
