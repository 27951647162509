import React from 'react';
import { useTranslation } from 'react-i18next';

import { Image, Typography, Wrapper } from '../../styled-components';
import { Button } from '../button';
import { HOME_TEXTS } from '../../translations/ro/home';
import { InitialInformationI, UseInitialInformationScreen } from '../../constants/dataConstants/initialInformationScreenConstants';
import CircleCheck from '../../assets/svgs/circle-check.svg';

interface IImportantModal {
  setShowTerms?: (showTerms: boolean) => void;
  setShowImportant?: (showImportant: boolean) => void;
  nextRoute?: string;
}

export const ImportantModal = ({ setShowTerms, setShowImportant }: IImportantModal): JSX.Element => {
  const { t } = useTranslation();
  const { TransferInformationData } = UseInitialInformationScreen();

  return (
    <>
      <Typography align='center' commonStyles={{ margin: '15px 0 15px 0' }} fontWeight='700' variant='h4'>
        {t('payPactTerms.important')}
      </Typography>
      <Wrapper display='flex' justifyContent='center' margin='10px 20px 0px 20px'>
        <Typography commonStyles={{ margin: '40px 0 40px 0' }} fontSize='16px' fontWeight='400' variant='body'>
          {HOME_TEXTS.agreeWithTheNext}
        </Typography>
      </Wrapper>
      <Wrapper alignItems='center' display='flex' flexDirection='column' padding='0px' textAlign='justify'>
        <Wrapper display='block' height='100%' margin='0 0 auto 0' overflowY='scroll'>
          {TransferInformationData.map((initialInformation: InitialInformationI) => {
            return (
              <Wrapper key={initialInformation.id} display='flex' justifyContent='center' margin='10px 20px 0px 20px'>
                <Image src={CircleCheck} style={{ marginBottom: '50px' }} />
                <Typography color='#8e8e89' variant='body'>
                  <span style={{ fontSize: '14px', fontWeight: 700, color: 'black', margin: '5px' }}>{initialInformation.title}</span>
                  {initialInformation.paragraph}
                </Typography>
              </Wrapper>
            );
          })}

          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            margin='40px 0 0 0'
            width='100%'
            onClick={() => {
              if (setShowImportant) {
                setShowImportant(false);

                if (setShowTerms) {
                  setShowTerms(true);
                }
              }
            }}
          >
            {t('compensation.buttonConfirm')}
          </Button>
        </Wrapper>
      </Wrapper>
    </>
  );
};
