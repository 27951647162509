import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { usePayment } from '../../hooks/usePayment';

interface PropsI {
  setShowModal: (showModal: boolean) => void;
}

export const PaymentModal = ({ setShowModal }: PropsI) => {
  const { t } = useTranslation();
  const { currentStatement } = useCurrentStatement();
  const payment = usePayment();

  const handleGoToPayment = async () => {
    setShowModal(false);

    if (currentStatement.statementID) {
      payment.initializePayment(currentStatement.statementID).catch(err => err);
    }
  };

  return (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 100px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '14px 0 0 0' }} fontWeight='700' variant='h2'>
          {t('paymentModal.toPayment')}
        </Typography>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start' marginTop='auto'>
        <Button
          background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
          borderRadius='25px'
          color='#FFFFFF'
          width='100%'
          onClick={() => handleGoToPayment()}
        >
          {t('commonText.yes')}
        </Button>

        <Button margin='15px 0 0 0' mode='link' onClick={() => setShowModal(false)}>
          <Typography color='#512AD8' variant='h5'>
            {t('commonText.no')}
          </Typography>
        </Button>
      </Wrapper>
    </>
  );
};
