import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import { Typography, Wrapper } from '../../styled-components';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { CheckedRoundFilledIcon } from '../../assets/icons';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { TermsModal } from '../../components/terms-modal';
import { ContinueModal } from '../../components/continue-modal';
import { ImportantModal } from '../../components/ImportantModal';
import { OTHER_INDIVIDUAL } from '../../services/router/routeHandles';
import { useStatements } from '../../hooks/useStatements';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

export const StatementDetails = ({ history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState<boolean>(false);
  const { currentStatementId } = useParams<{ currentStatementId: string | undefined }>();
  const { currentStatement } = useCurrentStatement();
  const [showImportant, setShowImportant] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showContinue, setShowContinue] = useState<boolean>(false);
  const { getStatementById } = useStatements();

  useEffect(() => {
    if (currentStatement.statementID) {
      getStatementById(currentStatement.statementID);
    } else {
      getStatementById(Number(currentStatementId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatement?.paymentDetails?.amount]);

  const chooseModal = (): JSX.Element => {
    if (showImportant) {
      return <ImportantModal setShowImportant={setShowImportant} setShowTerms={setShowTerms} />;
    } else if (showTerms) {
      return <TermsModal history={history} setModalActive={setModalActive} setShowTerms={setShowTerms} />;
    } else if (showContinue) return <ContinueModal />;

    return <></>;
  };

  return (
    <ScreenContainer
      bodyProps={{ justifyContent: 'center' }}
      headerProps={{ hideLogo: true, title: t('commonText.offer') }}
      headerType='none'
      isModalOpen={modalActive}
      modal={chooseModal()}
      modalProps={{ show: modalActive, onClose: () => setModalActive(false) }}
      showBottomNavbar={false}
    >
      <Wrapper
        background='linear-gradient(146.35deg, #4324d8 19.39%, #813Edb 96.82%)'
        border='1px solid #5E45D8'
        borderRadius='10px'
        boxShadow='0px 4px 4px rgba(218, 218, 218, 0.25)'
        color='#f0f2fc'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='space-evenly'
        maxHeight='400px'
        padding='20px'
        width='100%'
      >
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '50%',
            width: 44,
            height: 44,
            position: 'absolute',
            top: -15,
            right: -15,
          }}
        >
          <CheckedRoundFilledIcon fill='#2da455' height='44px' width='44px' />
        </div>

        <Typography color='#fff' fontSize='20px' variant='body'>
          {t('commonText.theWarranty')} <span style={{ color: '#ffc71c' }}>{t('commonText.payPact')}:</span>
        </Typography>

        <div style={{ margin: '45px 0' }}>
          <Typography align='center' color='#fff' extendedStyles={{ lineHeight: '50px' }} variant='h1'>
            {`${currentStatement?.paymentDetails?.amount} ${t('commonText.currency')}`}
          </Typography>

          <Typography align='center' color='#fff' variant='h4'>
            {t('commonText.in')}
          </Typography>

          <Typography align='center' color='#fff' extendedStyles={{ lineHeight: '50px' }} variant='h1'>
            {t('commonText.hours48')}
          </Typography>
        </div>

        <Button
          background='#fff !important'
          color='#4224d7 !important'
          margin='0 0 20px 0'
          mode='primary'
          onClick={() => {
            setModalActive(true);

            if (currentStatement.carOwner !== OTHER_INDIVIDUAL && !currentStatement.insuredId) {
              setShowImportant(true);
            } else if (currentStatement.carOwner === OTHER_INDIVIDUAL && currentStatement.insuredId) {
              setShowContinue(true);
            } else {
              history.push(generatePath(PROTECTED_ROUTES.statements));
            }
          }}
        >
          {t('commonText.accept')}
        </Button>

        <Typography align='center' color='#fff' fontSize='12px' variant='body'>
          {`${t('commonText.readMoreAbout')} `}
          <Button color='#ffc71c' mode='link' style={{ fontSize: '12px', paddingLeft: 0 }} onClick={() => null}>
            {t('payPactTerms.paymentDetails')}
          </Button>
        </Typography>
      </Wrapper>
    </ScreenContainer>
  );
};
