export enum DOCUMENTS_FORM_TYPE {
  INSURANCE = 'INSURANCE',
  CAR_IDENTITY = 'CAR_IDENTITY',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD',
  AMICABLE_FINDING = 'AMICABLE_FINDING',
  POLICE_REPORT = 'POLICE_REPORT',
  OBSERVATION_NOTE = 'OBSERVATION_NOTE',
  COMPENSATION_OFFER = 'COMPENSATION_OFFER',
}

type PickKey<T, K extends keyof T> = Extract<keyof T, K>;

export type Picked_KeysOfEnum = PickKey<typeof DOCUMENTS_FORM_TYPE, 'INSURANCE' | 'DRIVING_LICENSE' | 'CAR_IDENTITY' | 'ID_CARD'>;

export enum DOCUMENTS_OCR_TYPE {
  INSURANCE = 'insurance',
  DRIVING_LICENSE = 'drivingLicense',
  ID_CARD = 'identityCard',
  CAR_IDENTITY = 'carIdentity',
}

export enum OCR_DOCUMENT_TYPE {
  INSURANCE = 'INSURANCE',
  CAR_IDENTITY = 'CAR_IDENTITY',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD',
}

// use this for mapping Frontend types to backend format
export enum FORMATTED_OCR_DOCUMENTS_TYPE {
  INSURANCE = 'insurance',
  CAR_IDENTITY = 'talon',
  DRIVING_LICENSE = 'driver-license',
  ID_CARD = 'identity-card',
}
