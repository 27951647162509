export const TERMS_AND_CONDITIONS = `<h2><b>TERMENII ȘI CONDIȚIILE DE UTILIZARE ALE APLICAȚIEI „PAYPACT”</b></h2>
<p><b>TERMENI GENERALI</b></p>
<p><span style="font-weight: 400;">Utilizarea serviciilor oferite de aplicația „PayPact” semnifică acordul dvs. tacit asupra conținutului termenilor și condițiilor de mai jos. Dacă utilizați aplicația „PayPact” înseamnă că ați citit, ați înțeles și ați acceptat termenii și condițiile de utilizare.</span></p>
<p><span style="font-weight: 400;">Ne rezervăm dreptul, fără a fi necesar acceptul dvs., de a aduce schimbări sau modificări acestor Termeni și Condiții de utilizare, în orice moment. Orice modificare ce va fi adusă conținutului Termenilor și Condițiilor de utilizare va fi notificată utilizatorilor prin actualizarea datei în câmpul „Ultima actualizare” a Termenilor și Condițiilor de utilizare.</span></p>
<p><span style="font-weight: 400;">Responsabilitatea pentru luarea la cunoștință a modificărilor aduse Termenilor și Condițiilor de utilizare revine utilizatorilor, prin revizuirea periodică a conținutului Termenilor și Condițiilor de utilizare.</span></p>
<p><span style="font-weight: 400;">Continuarea utilizării aplicației după data publicării Termenilor și Condițiilor modificați,  reprezintă că ați luat la cunoștință și ați acceptat toate modificările aduse Termenilor și Condițiilor de utilizare până în acel moment.</span></p>
<p><span style="font-weight: 400;">Informațiile furnizate prin intermediul aplicației „PayPact” vor fi în acord doar cu prevederile legale interne, astfel că nu ne asumăm responsabilitatea pentru respectarea normelor legale ce sunt supuse jurisdicției altor țări. Utilizatorii ce accesează și/sau folosesc aplicația din alte jurisdicții sau țări sunt singurii responsabili pentru respectarea normelor legale locale aplicabile în țara în care se află și/sau în țara a cărei jurisdicție se supun din orice motive.</span></p>
<p><b>DEFINIȚII</b></p>
<p><span style="font-weight: 400;">În cuprinsul prezentului document, termenii de mai jos au următoarea semnificație:</span></p>
<ul style="list-style: circle">

         <li style="font-weight: 400;" aria-level="1"><b>Prestator</b><span style="font-weight: 400;">: administratorul și dezvoltatorul aplicației PayPact &#8211; societatea Bewell Technologies SRL &#8211; cu sediul în România, Cluj-Napoca , str. Ialomița , nr. 11 , bl. , sc. , ap. 5 , Județul Cluj, nr. de înregistrare la Registrul Comerțului J12/1166/2017, cod de identificare fiscală 37273506;</span></li>
         <li style="font-weight: 400;" aria-level="1"><b>Utilizator: </b><span style="font-weight: 400;">persoana fizică cu vârsta de peste 18 ani,  cu capacitate de exercițiu deplină, care folosește aplicația;</span></li>
         <li style="font-weight: 400;" aria-level="1"><b>Persoana păgubită:</b><span style="font-weight: 400;"> persoana îndreptățită să primească despăgubiri pentru prejudiciul suferit ca urmare a producerii unui risc acoperit printr-un contract de asigurare de răspundere civilă;</span></li>
         <li style="font-weight: 400;" aria-level="1"><b>Asigurat: </b><span style="font-weight: 400;">proprietarul sau utilizatorul unui vehicul sau unui tramvai a cărui răspundere civilă delictuală este preluată contractual de un asigurator RCA în baza contractului RCA valabil pentru prejudiciile produse terțelor persoane din accidente de vehicule/tramvaie;</span></li>
         <li style="font-weight: 400;" aria-level="1"><b>Asigurător:</b><span style="font-weight: 400;"> asigurător autorizat să practice asigurare RCA;</span></li>
         <li style="font-weight: 400;" aria-level="1"><b>Aplicație:</b><span style="font-weight: 400;"> programul software dezvoltat de Prestator și prin intermediul căruia Utilizatorii pot efectua în mod online serviciile ce fac obiectul conținutului aplicației „PayPact”;</span></li>
         <li style="font-weight: 400;" aria-level="1"><b>Eveniment rutier:</b><span style="font-weight: 400;"> eveniment în care a fost implicat cel puțin un autovehicul în urma căruia au rezultat prejudicii materiale;</span></li>
         <li style="font-weight: 400;" aria-level="1"><b>Avizare daună: </b><span style="font-weight: 400;">notificarea asigurătorului de către persoana prejudiciată, de către asigurat sau mandatarii acestora, cu privire la producerea evenimentului asigurat;</span></li>
</ul>

<p><b>CONȚINUT</b></p>
<p><span style="font-weight: 400;">În vederea utilizării aplicației ”PayPact”, Utilizatorul trebuie să dispună de un telefon mobil / dispozitiv care să permită transmiterea de fotografii de la locul evenimentului cu vehiculele implicate în accidente, locul accidentului (locația), marcajele rutiere, documentele șoferilor implicați, datele privind locul evenimentului rutier, data și ora,  orice alte informații necesare furnizării serviciilor oferite prin intermediul aplicației.</span></p>
<p><span style="font-weight: 400;">Accesul la serviciile furnizate prin intermediul aplicației ”PayPact” se va realiza în baza unui cont Apple, Facebook, Google sau LinkedIn deja deținut de utilizator. Astfel, contul necesar pentru utilizarea aplicației ”PayPact” se va crea pe baza informațiilor furnizate de către utilizator la momentul creării acestor conturi. Fără logarea în cont și fără acceptarea Termenilor și Condițiilor, precum și a Politicii de Confidențialitate nu vor putea fi parcurși pașii privind accesarea serviciilor.</span></p>
<p><span style="font-weight: 400;">În vederea furnizării serviciilor, Utilizatorul trebuie să introducă în aplicație informații despre:</span></p>
<ol>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Eveniment</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">vehiculul avariat</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Localizare avarii</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">proprietarul vehicului pentru care este notificată daună</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">conducatorul autovehiculului pentru care se notifică dauna/persoana care a constatat avariile la vehiculul pentru care se notifică dauna</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;"> observații și mențiuni</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">persoana de contact</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">documentele deținute: existența formularului de constat amiabil; fotografii cu vehiculul pentru care faceți notificarea; polița de asigurare; procesul-verbal poliție etc.</span></li>
</ol>
<p><span style="font-weight: 400;">Una din metodele de identificare a utilizatorului poate fi identificarea prin metoda Face ID (recunoaștere facială). Această metodă de identificare are la bază  autentificarea securizata cu tehnologii avansate ce permit identificarea precisă de către dispozitivul utilizat a geometriei feței. </span></p>
<p><span style="font-weight: 400;">Prin intermediul aplicației „PayPact”, Utilizatorilor le sunt furnizate următoarele servicii:</span></p>
<ol>
   <li style="font-weight: 400;" aria-level="1"><b>Serviciul de notificare incident auto</b><span style="font-weight: 400;">, prin intermediul căruia Utilizatorii pot declara un incident rutier în care au fost implicați și pentru care se consideră îndreptățiți să primească despăgubiri de asigurător. Serviciul de notificare incident auto este furnizat gratuit Utilizatorilor și operează prin transmiterea informațiilor ce sunt introduse de Utilizator către Asigurător. Notificarea daunei către asigurător se va realiza în intervalul orar 8:00-17:30 pentru solicitările de notificare realizate prin intermediul aplicației   până la 17:00 Pentru solicitările de notificare realizate prin intermediul aplicației după ora 17:00 notificarea asigurătorului se va realiza a doua zi lucratoare în intervalul orar mai sus menționat.</span></li>
</ol>
<p><span style="font-weight: 400;">În procedura de notificare a daunei către asigurător vom indica adresa noastră de contact (e-mail: daune@paypact.ro)astfel încât să putem să vă informăm cu privire la stadiul cererii dvs. precum și cu privire la orice solicitare venită din partea Asigurătorului inclusiv, dar fără a se limita la documente, informații suplimentare de care asigurătorul are nevoie pentru  deschiderea dosarului de daună. </span></p>
<p><span style="font-weight: 400;">Prin utilizarea aplicației ”PayPact” și acceptarea Termenilor și Condițiilor sunteți de acord ca adresa de contact menționată în cererea de notificare a daunei să fie adresa noastră.  Astfel sunteți de acord și înțelegeți că noi vom ține legătură cu asigurătorul și vă vom comunica orice solicitare venită din partea lui în legătură cu incidentul rutier în care ați fost implicați și / sau care este stadiul cererii dvs., inclusiv dar fără a se limita dacă incidentul rutier semnalizat prin intermediul aplicației ”PayPact” este încadrat sau nu  în categoria evenimentului asigurat conform legislației în vigoare și se va deschide astfel, dosarul de daună, dacă sunt necesare documente, informații suplimentare și / sau care este stadiul dosarului dvs. de daună deschis în urma utilizării serviciului de notificare incident auto.</span></p>
<p><span style="font-weight: 400;">Vă vom comunica dacă există solicitări din partea Asigurătorului iar dvs. veți avea posibilitatea de a răspunde acestor solicitări și de a transmite informațiile, documentele suplimentare fie prin intermediul aplicației ”PayPact”, fie în mod direct către Asigurător.</span></p>
<p><span style="font-weight: 400;">Prin intermediul acestui serviciu, vă ajutăm să notificați producerea unui incident rutier către Asigurător pe baza informațiilor introduse de dvs. în aplicație, vă informăm cu privire la stadiul cererii dvs. și totodată cu privire la deschiderea sau nu a dosarului de daună. Prin acceptarea Termenilor și Condițiilor înțelegeți și acceptați că etapele ulterioare deschiderii dosarului de daună, inclusiv acordarea despăgubirilor nu se poate realiza în baza serviciului de notificare incident auto furnizat prin intermediul aplicației ”PayPact”.</span></p>
<ol>
   <li style="font-weight: 400;" aria-level="1"><b>Serviciul de evaluare daună autovehicul,</b><span style="font-weight: 400;"> constă în determinarea cuantumului despăgubirii la care ar fi îndreptățită Persoana păgubită. Evaluarea daunelor materiale aduse autovehiculului implicat în accidentul rutier se realizează prin intermediul unui p sistem de evaluare specializat. În procesul de evaluare al daunelor colaborăm și cu persoane autorizate în activitatea de evaluare a riscului de asigurare și a pagubei.</span></li>
</ol>
<p><span style="font-weight: 400;">Evaluarea daunelor materiale se face pe baza informațiilor și documentelor furnizate de către Utilizator, din care rezultă daunele vizibile ale autovehiculului. Rezultatul evaluării daunei va fi transmis Utilizatorilor prin intermediul  adresei de e-mail furnizată de aceștia, în urma achitării costului aferent acestui serviciu în maxim 24 de ore calculate din momentul achitării serviciului de către Utilizator.</span></p>
<p><span style="font-weight: 400;">Plata serviciului de evaluare a daunelor autovehiculului se va face exclusiv online și va fi intermediată de către un procesator de plăți agreat de Prestator.</span></p>
<p><span style="font-weight: 400;">Tariful aferent serviciului de evaluare daună autovehicul va fi adus la cunoștința Utilizatorului înainte de introducerea detaliilor necesare pentru efectuarea plății.</span></p>
<p><b>DREPTURI DE PROPRIETATE INTELECTUALĂ</b></p>
<p><span style="font-weight: 400;">Utilizatorul declară în mod expres că a luat la cunoștință de faptul că toată creația grafică și conținutul aplicației accesate sunt proprietatea Prestatorului. Fac excepție informațiile, elementele grafice aparținând altor entități, care sunt proprietatea partenerilor Prestatorului.</span></p>
<p><span style="font-weight: 400;">Utilizatorul se obligă să nu reproducă, să nu copieze, să nu vândă sau să exploateze serviciile și informațiile la care are acces ca urmare a utilizării acestei aplicații, în parte sau complet.</span></p>
<p><span style="font-weight: 400;">Utilizatorului îi este interzisă modificarea, copierea, închirierea, cedarea, împrumutul, vânzarea, distribuția sau crearea unor materiale derivate bazate pe serviciile sau programele folosite în cadrul aplicației.</span></p>
<p><b>RĂSPUNDERI</b></p>
<p><span style="font-weight: 400;">Utilizatorii înțeleg și sunt de acord că serviciile pe care le prestăm prin intermediul aplicației sau pe care Utilizatorii le accesează prin intermediul aplicației nu sunt de natură să atragă vreo obligație legală sau contractuală, de orice natură, Prestatorului sau partenerilor/angajaților/colaboratorilor acestuia.</span></p>
<p><span style="font-weight: 400;">Serviciul de evaluare daună furnizat prin intermediul aplicației are drept rol și scop să asigure pentru Utilizator o informare corectă și obiectivă cu privire la valoarea despăgubirilor pe care Persoana păgubită ar putea să le solicite de la Asigurător. </span></p>
<p><span style="font-weight: 400;">Utilizatorii înțeleg și acceptă că valoarea despăgubirii rezultată din furnizarea serviciului de evaluare daună nu obligă Asigurătorul ori Prestatorul la plata acestei sume.</span></p>
<p><span style="font-weight: 400;">Utilizatorii înțeleg și acceptă în egală măsură faptul că valoarea despăgubirii rezultată din serviciul de evaluare daună poate fi diferită de valoarea ce va fi necesară pentru repararea autovehiculului, iar noi nu ne asumăm nicio răspundere pentru situația în care costul reparării autovehiculului depășește valoarea estimată de noi prin serviciul de evaluare daună.</span></p>
<p><span style="font-weight: 400;">Toate serviciile ce sunt furnizate prin intermediul aplicației se vor efectua în baza informațiilor și documentelor transmise de către Utilizator, care își asumă întreaga răspundere față de corectitudinea datelor și informațiilor furnizate. Utilizatorul va răspunde potrivit normelor legale interne pentru furnizarea de informații eronate și/sau greșite, independent de forma vinovăției cu care a acționat.</span></p>
<p><span style="font-weight: 400;">Nu ne asumăm nicio răspundere pentru modul de soluționare al notificării/avizării evenimentului auto sau al dosarului de daună de către Asigurător. Serviciile ce sunt puse la dispoziția Utilizatorului prin intermediul aplicației reprezintă doar  intermedierea raportului dintre Utilizator și Asigurător, pentru a facilita demersul de deschidere și soluționare al dosarului de daună, prin punerea la dispoziția Utilizatorului a unei platforme digitale.  În acest sens, utilizatorul își asumă riscul ca în urma analizării de către Asigurător a documentelor încărcate în aplicație în vederea notificării daunei, Asigurătorul să stabilească că incidentul notificat nu reprezintă eveniment asigurat, fiind exclus de la acordarea despăgubirilor.</span></p>
<p><span style="font-weight: 400;">Prin acceptarea acestor Termeni și Condiții Utilizatorul înțelege și este de acord ca Prestatorul să îl reprezinte în fața Asigurătorului în limitele necesare pentru prestarea serviciilor ce îi sunt puse la dispoziție prin intermediul acestei aplicații.</span></p>
<p><span style="font-weight: 400;">Utilizatorul înțelege și acceptă faptul că Prestatorul nu își asumă o reprezentare generală a Utilizatorului în fața Asigurătorului, ci că va asigura doar intermedierea  comunicării dintre Utilizator și Asigurător, prin transmiterea informațiilor ce îi sunt puse la dispoziție. Prestatorul nu poate fi tras la răspundere și nu pot fi ridicate pretenții față de acesta în baza prevederilor Codului Civil român pentru serviciile furnizate prin intermediul aplicației.</span></p>
<p><span style="font-weight: 400;">Utilizatorii nu pot formula nici un fel de pretenții față de Prestator sau partenerii/salariații/colaboratorii acestuia pentru modul de soluționare al dosarului de daună de către Asigurător, indiferent de serviciul ce a fost furnizat prin intermediul aplicației (exemplificativ însă nu și limitativ: notificarea/avizarea evenimentului auto, evaluarea despăgubirilor materiale ale autovehiculului, depunerea dosarului de daună, etc.).</span></p>
<p><span style="font-weight: 400;">Utilizatorii înțeleg și acceptă că termenele în care se precizează că se vor furniza serviciile oferite prin intermediul aplicației, sunt termene de recomandare și că suntem absolviți de orice răspundere în eventualitatea producerii oricăror întârzieri în furnizarea serviciilor, cu excepția situației în care se constată de către o instanță judiciară că întârzierea se datorează în mod direct și exclusiv culpei Prestatorului.</span></p>
<p><b>JURISDICȚIE</b></p>
<p><span style="font-weight: 400;">Prin accesarea acestei aplicații, Utilizatorul acceptă în mod expres că orice dispută legală legată de/în legătură cu serviciile furnizate de Prestator prin aplicație, vor fi guvernate de legile din România, indiferent de locul în care Utilizatorul accesează aplicația. Dacă Utilizatorul accesează această aplicație din afara României, o face pe riscul său și este singurul responsabil de respectarea legilor teritoriului în care se află.</span></p>
<p><span style="font-weight: 400;">Părțile vor încerca să soluționeze în mod amiabil eventualele divergențe legale intervenite în legătură cu serviciile furnizate prin intermediul aplicației „PayPact”, iar în situația în care soluționarea amiabilă nu este posibilă, litigiul va fi soluționat de instanțele judiciare competente de la sediul Prestatorului. </span></p>
<p><b>UTILIZATORUL ACCEPTĂ ACEȘTI TERMENI ȘI CONDIȚII</b></p>
<p><span style="font-weight: 400;">Utilizatorul confirmă că a citit și acceptat acești termeni și condiții. Folosirea Aplicației de către Utilizator implică acceptarea necondiționată a acestor termeni și condiții. În cazul în care nu sunteți de acord cu acești termeni și condiții, vă rugăm să nu folosiți aplicația.</span></p>
<p><span style="font-weight: 400;">Ultima actualizare a Termenilor și Condițiilor de utilizare a aplicației „PayPact” a avut loc la data de 13:04.2022.</span></p>
<p><b>ASISTENȚĂ</b></p>
<p><span style="font-weight: 400;">Dacă aveți orice problemă legată de funcționarea aplicației „PayPact”, ne puteți contacta la adresa de e-mail: daune@paypact.ro  sau la numerele de telefon: 0744778890.</span></p>
<p style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal;"> </p>
</div>`;
