import React from 'react';

export const EvaluateIcon = (): JSX.Element => {
  return (
    <svg fill='none' height='22' viewBox='0 0 22 22' width='22' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='M10.9987 18.1663C7.04066 18.1663 3.83203 14.9577 3.83203 10.9997C3.83203 7.04163 7.04066 3.83301 10.9987 3.83301C14.9567 3.83301 18.1654 7.04163 18.1654 10.9997C18.1654 14.9577 14.9567 18.1663 10.9987 18.1663ZM1.83203 10.9997C1.83203 5.93706 5.93609 1.83301 10.9987 1.83301C16.0613 1.83301 20.1654 5.93706 20.1654 10.9997C20.1654 16.0623 16.0613 20.1663 10.9987 20.1663C5.93609 20.1663 1.83203 16.0623 1.83203 10.9997ZM10.082 6.41634V8.24967H8.2487V10.083H10.082V11.9163H11.9154V10.083H13.7487V8.24967H11.9154V6.41634H10.082ZM8.2487 13.7497V15.583H13.7487V13.7497H8.2487Z'
        fill='#322761'
        fillRule='evenodd'
      />
    </svg>
  );
};
