import { Omit, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { fetchWitnessesByStatementId, postWitnessesApi, setWitnessesAction } from '../store/statement/currentStatement.action';
import { IWitness } from '../@types/witnesses/witnessesTypes';
import { RootState } from '../store/rootReducer';

const useWitnesses = () => {
  const dispatch = useDispatch();
  const { witnesses } = useSelector((state: RootState) => state.currentStatement.data.injured);
  const setWitnesses = useCallback(
    (newData: IWitness[] | null) => {
      dispatch(setWitnessesAction(newData));
    },
    [dispatch],
  );

  const setWitnessByID = useCallback(
    (id: number, newData: IWitness) => {
      const editedWitnesses = witnesses?.map((item: IWitness) => {
        if (item && item.id === id) {
          return newData;
        }

        return item;
      });
      dispatch(setWitnessesAction(editedWitnesses || [newData]));
    },
    [dispatch, witnesses],
  );

  const deleteWitnessByID = useCallback(
    (id: number) => {
      if (witnesses) {
        const filtered = witnesses.filter((witness: IWitness) => {
          if (witness !== null) return id !== witness.id;

          return false;
        });
        dispatch(setWitnessesAction(filtered));
      }
    },
    [dispatch, witnesses],
  );

  const getWitnessesByStatementId = useCallback(
    (id: number) => {
      dispatch(fetchWitnessesByStatementId(id));
    },
    [dispatch],
  );

  const postWitnessesByStatementId = useCallback(
    (payload: IWitness[] | Omit<IWitness, 'id'>[], onSuccess?: () => void) => {
      dispatch(postWitnessesApi(payload, onSuccess));
    },
    [dispatch],
  );

  return { setWitnesses, setWitnessByID, deleteWitnessByID, getWitnessesByStatementId, postWitnessesByStatementId };
};

export { useWitnesses };
