import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { IScreenComponentProps } from '../component-props.model';
import { useStatements } from '../../hooks/useStatements';
import { ICurrentStatement } from '../../@types/current-statement/currentStatementTypes';
import { currentStatementInitialState } from '../../store/statement/initialState';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { StatusCard } from '../../styled-components/cards/StatusCard';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { initialState } from '../../store/questions-flow-handler/questions-flow-handler.slice';
import DashboardHomeNav from '../../components/layout/DashboardHomeNav';
import { Button } from '../../components/button';
import { Typography } from '../../styled-components';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { STATEMENT_TYPES } from '../../store/types';

export const Evaluations = ({ nextRoute, history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { getStatementsByType, fetchStatements } = useStatements();
  const { setCurrentStatementData, currentStatementsData } = useCurrentStatement();
  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();

  useEffect(() => {
    fetchStatements().catch(e => e);
    setQuestionsFlowHandler(initialState.data);
    setCurrentStatementData(currentStatementInitialState);
  }, [currentStatementsData.length]);

  return (
    <ScreenContainer header={<DashboardHomeNav history={history} />} showBottomNavbar>
      {getStatementsByType(STATEMENT_TYPES.EVALUATION).map(
        (evaluation: ICurrentStatement) =>
          evaluation !== null && (
            <StatusCard
              key={evaluation.statementID}
              currentData={evaluation}
              currentPlace={evaluation.statementID}
              history={history}
              insuranceId={evaluation.injured.insuranceId}
              nextRoute={nextRoute}
              type={STATEMENT_TYPES.EVALUATION}
              isEvaluation
            />
          ),
      )}
      <Button margin='15px 0' mode='link' onClick={() => history.push(PRE_LOGIN_ROUTES.root)}>
        <Typography align='center' color='#512AD8' fontSize='15px' fontWeight='bold' lineHeight='18px' variant='h5'>
          {t('statements.newDeclaration')}
        </Typography>
      </Button>
    </ScreenContainer>
  );
};
