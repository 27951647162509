import React from 'react';

import { AppleIcon, FacebookIcon, GoogleIcon, LinkedinIcon } from '../assets/icons';
import { HOME_TEXTS } from '../translations/ro/home';
import { AUTH_TYPE } from '../constants/typeConstants/authType';

interface StackI {
  color: string;
  label: string;
  logo: JSX.Element;
}

const COLOR = {
  APPLE: 'linear-gradient(0deg, #131313, #131313)',
  FACEBOOK: '#4267B2',
  GOOGLE: 'linear-gradient(0deg, #DB4437, #DB4437)',
  LINKED_IN: '#2867B2',
};

const LOGO = {
  APPLE: <AppleIcon />,
  FACEBOOK: <FacebookIcon />,
  GOOGLE: <GoogleIcon />,
  LINKED_IN: <LinkedinIcon />,
};

const authMethodSwitch = (type: string) => {
  const authStack: StackI = {
    color: '',
    label: '',
    logo: <div />,
  };

  switch (type) {
    case AUTH_TYPE.APPLE:
      authStack.color = COLOR.APPLE;
      authStack.label = `${HOME_TEXTS.with} Apple`;
      authStack.logo = LOGO.APPLE;

      return authStack;
    case AUTH_TYPE.FACEBOOK:
      authStack.color = COLOR.FACEBOOK;
      authStack.label = `${HOME_TEXTS.with} Facebook`;
      authStack.logo = LOGO.FACEBOOK;

      return authStack;
    case AUTH_TYPE.GOOGLE:
      authStack.color = COLOR.GOOGLE;
      authStack.label = `${HOME_TEXTS.with} Google`;
      authStack.logo = LOGO.GOOGLE;

      return authStack;
    case AUTH_TYPE.LINKED_IN:
      authStack.color = COLOR.LINKED_IN;
      authStack.label = `${HOME_TEXTS.with} LinkedIn`;
      authStack.logo = LOGO.LINKED_IN;

      return authStack;
  }
};

export { authMethodSwitch };
