import React from 'react';

import { IIconProps } from './icon.model';

export const PlusIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 16} viewBox='0 0 16 16' width={width || 16} {...otherProps}>
      <path
        d='M14.8571 9.14286H9.14286V14.8571C9.14286 15.4857 8.62857 16 8 16C7.37143 16 6.85714 15.4857 6.85714 14.8571V9.14286H1.14286C0.514286 9.14286 0 8.62857 0 8C0 7.37143 0.514286 6.85714 1.14286 6.85714H6.85714V1.14286C6.85714 0.514286 7.37143 0 8 0C8.62857 0 9.14286 0.514286 9.14286 1.14286V6.85714H14.8571C15.4857 6.85714 16 7.37143 16 8C16 8.62857 15.4857 9.14286 14.8571 9.14286Z'
        fill={fill || '#512ad8'}
      />
    </svg>
  );
};
