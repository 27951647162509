export enum STATEMENT_FILTERS {
  ALL = 'ALL',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface UIModel {
  statements: {
    filter: STATEMENT_FILTERS;
  };
}
