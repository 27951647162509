import React, { useState } from 'react';
import { ErrorMessage } from 'formik';

import { Radio } from '../../styled-components';
import { $fontSizeS } from '../../styles/variables';
import { ICheckOption } from '../../constants/dataConstants/circumstances';

interface IRadioFIeldProps {
  name: string;
  fields: ICheckOption[];
  childName?: string;
  childPlaceholder?: string;
  childClassName?: string;
  handleChange?: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur?: { (e: FocusEvent): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void };
  handleValidation: (value: boolean) => void;
}

export const RadioFields = ({
  fields,
  handleBlur,
  name,
  childName = '',
  childPlaceholder = '',
  handleValidation,
  childClassName,
}: IRadioFIeldProps): JSX.Element => {
  const [selected, setSelected] = useState<number | string | undefined>(undefined);

  const handleFieldChange = (field: ICheckOption) => {
    const isChecked = fields.some(item => item.checked);

    if (isChecked) {
      fields[fields.findIndex(item => item.checked)].checked = false;
    }

    field.checked = true;
    setSelected(field.value);

    if (field.checked && field.hasChild && !field.childValue) handleValidation(false);
    else handleValidation(field.checked);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>, parentEl: ICheckOption) => {
    const value = event.target?.value;
    parentEl.childValue = value;

    handleValidation(!!value);
  };

  return (
    <>
      {fields.map(field => (
        <div key={field.label} style={{ width: '100%' }}>
          <Radio
            checked={field.checked}
            name={name}
            style={{ fontSize: $fontSizeS }}
            value={field.value}
            onChange={() => handleFieldChange(field)}
          >
            {field.label}
          </Radio>
          {field.hasChild && (
            <>
              <textarea
                className='form-field'
                disabled={selected !== field.value}
                name={childName}
                placeholder={childPlaceholder}
                rows={3}
                style={{
                  marginTop: '10px',
                  marginBottom: '6px',
                  borderRadius: '25px',
                  padding: '10px',
                  fontSize: $fontSizeS,
                  fontStyle: 'italic',
                  minHeight: '95px',
                }}
                onBlur={handleBlur}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleTextChange(event, field)}
              />
              <ErrorMessage className={childClassName || 'field-error'} component='p' name={childName} />
            </>
          )}
        </div>
      ))}
      <ErrorMessage className={childClassName || 'field-error'} component='p' name={name} />
    </>
  );
};
