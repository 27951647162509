import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Image } from '../../styled-components';
import { Typography } from '../../styled-components';
import { CloseButton } from '../../components/button';
import { useDocumentsTempData } from '../../hooks/useDocumentsTempData';
import { PICTURE_CONTAINER_TYPE } from '../../constants/pictureContainerType';
import AddPictureBackground from '../../assets/images/add-picture.png';
import { SpecialFormType } from '../../hooks/useStatementDocuments';
import { PATHS } from '../../services/router/app-routes/routes-constants';
import { deleteDocumentApi } from '../../services/api/attachments/attachments.api';

export interface ModalDataI {
  label?: string;
  src?: string;
  id?: number;
  type?: string;
  modalLabel?: string;
}

interface PropsI {
  label: string;
  modalLabel?: string;
  nextRoute?: any;
  parentRoute?: string;
  image?: string;
  setShowModal?: (showModal: boolean) => void;
  setModalData?: ({ label, src, id, modalLabel, type }: ModalDataI) => void;
  id: number;
  type: string | SpecialFormType;
  pictureContainerType: string;
  hasNewPicture?: boolean;
  deletePicture?: (id: number) => void;
  defaultPictureTutorial?: string;
  documentFormType?: string;
  userSituation?: string;
  documentID?: number | null;
  refresh?: () => void;
}

export const AddPictureContainer = ({
  nextRoute,
  defaultPictureTutorial,
  parentRoute,
  deletePicture,
  modalLabel,
  userSituation,
  hasNewPicture,
  id,
  setModalData,
  setShowModal,
  label,
  documentFormType,
  image,
  type,
  pictureContainerType,
  documentID,
  refresh,
}: PropsI): JSX.Element => {
  const history = useHistory();
  const { getTempDocumentsDataFromMainScreen } = useDocumentsTempData();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    if (documentID) {
      setLoading(true);

      try {
        await deleteDocumentApi(documentID);

        setLoading(false);

        if (refresh) refresh();
      } catch (e) {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ margin: '16px auto 0 auto', alignSelf: 'flex-end', width: '150px', position: 'relative' }}>
      <Typography color='#3F414E' commonStyles={{ margin: '0 0 7px 0', width: '95%' }} fontWeight='normal' variant='body'>
        {label}
      </Typography>
      {type === 'image' && (
        <Image
          backgroundColor='white'
          borderRadius='10px'
          src={image}
          style={{
            objectFit: 'cover',
            width: 145,
            height: 145,
          }}
          onClick={() => {
            getTempDocumentsDataFromMainScreen({
              title: label,
              defaultPicture: defaultPictureTutorial,
              formType: documentFormType,
              flowHandle: userSituation,
            });

            const img = hasNewPicture && { image };

            if (typeof documentFormType === 'object') {
              history.push(`${parentRoute}${PATHS.chooseDocuments.toString()}`, img);
            } else if (pictureContainerType === PICTURE_CONTAINER_TYPE.DOCUMENTS) {
              history.push(`${parentRoute}${nextRoute['photo'].toString()}`, img);
            } else if (setModalData) {
              // If the image is default (placeholder) then don't pass any initialImage to the modal (MediaModal)
              if (image === AddPictureBackground) {
                image = '';
              }
              setModalData({ label, src: image, id, modalLabel, type });
            }

            if (setShowModal) {
              setShowModal(true);
            }
          }}
        />
      )}
      {hasNewPicture && <CloseButton onClick={() => handleDelete()} />}
    </div>
  );
};
