import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Image, Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import CircleCheck from '../../assets/svgs/circle-check.svg';
import { InitialInformationI, UseInitialInformationScreen } from '../../constants/dataConstants/initialInformationScreenConstants';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { STATEMENT_TYPES } from '../../store/types';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { RootState } from '../../store/rootReducer';

const Description = styled.div`
  font-size: 14px;
  color: #5b5675;
`;

export const InitialInformationScreen = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);
  const { InitialInformationData } = UseInitialInformationScreen();
  const { currentStatement } = useCurrentStatement();
  const { questionsFlowHandlerState } = useQuestionsFlowHandler();

  let isEvaluation = currentStatement.type === STATEMENT_TYPES.EVALUATION;

  //handle route isEvaluation or notification
  if (history.location.pathname === PRE_LOGIN_ROUTES.initialInformNotificationText) {
    isEvaluation = false;
  }

  if (history.location.pathname === PRE_LOGIN_ROUTES.initialInformEvaluationText) {
    isEvaluation = true;
  }

  const goNext = () => {
    if (questionsFlowHandlerState.type === STATEMENT_TYPES.NOTIFICATION) {
      history.push(PRE_LOGIN_ROUTES.insuranceType);

      return;
    }
    history.push(nextRoute.toString());
  };

  const getInformationModal = () => {
    return (
      <>
        <Wrapper
          alignItems='center'
          display='flex'
          flexDirection='column'
          height='calc(100% - 20px)'
          margin='20px 0 0 0'
          padding='0px'
          paddingTop='20px'
          width='100%'
        >
          <Wrapper display='flex' flexDirection='column' height='100%' justifyContent='flex-start'>
            {InitialInformationData.map((initialInformation: InitialInformationI) => {
              return (
                <Wrapper key={initialInformation.id} display='flex' justifyContent='flex-start' margin='10px' width='100%'>
                  <Image src={CircleCheck} />
                  <Typography align='left' commonStyles={{ margin: '0 10px' }} variant='body'>
                    {initialInformation.title}
                  </Typography>
                </Wrapper>
              );
            })}

            {isEvaluation && (
              <Wrapper display='flex' flexDirection='column' marginBottom='20px' marginLeft='10px' marginRight='10px' marginTop='40px'>
                <Typography align='center' commonStyles={{ width: '100%' }} fontWeight='700' variant='h3'>
                  <p>{t('welcome.evaluationCost')}</p>
                </Typography>
                <Description>{t('welcome.evaluationCostDescription')}</Description>
              </Wrapper>
            )}
          </Wrapper>

          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            marginTop='auto'
            width='100%'
            onClick={() => goNext()}
          >
            {isEvaluation ? t('welcome.beginEvaluation') : t('commonText.continue')}
          </Button>
        </Wrapper>
      </>
    );
  };

  return (
    <>
      <ScreenContainer
        headerType='none'
        modal={getInformationModal()}
        modalProps={{
          show: true,
          showCloseButton: false,
          scroll: 'none',
          border: '1px solid #DADADA',
          height: '90%',
          margin: 'auto 25px',
          display: 'flex',
          justifyContent: 'flex-start',
          showTitle: true,
          title: t('welcome.beforeYouStart'),
        }}
        showBottomNavbar={isLoggedIn}
        isModalOpen
        specialScreen
      />
    </>
  );
};
