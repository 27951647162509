import React from 'react';

import { Typography, Wrapper } from '../../styled-components';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { COMPLETED_DOCUMENTS_TYPE } from '../../@types/questions-flow-handler/questionsFlowHandler';
import { useDocumentsTempData } from '../../hooks/useDocumentsTempData';
import { DOCUMENTS_FORM_TYPE } from '../../constants/typeConstants/documentsFormType';
import AmicableFindingTutorial from '../../assets/images-tutorial/docs/constatare-amiabila.png';
import PoliceReportTutorial from '../../assets/images-tutorial/docs/proces-verbal.png';

interface PropsI {
  title: string;
  id: number;
  setRadioButtonsValue: ({ amicableFinding, policeReport }: { amicableFinding: boolean; policeReport: boolean }) => void;
  active: boolean;
  value: boolean;
}

export const RadioButtonDocuments = ({ title, id, setRadioButtonsValue, active, value }: PropsI): JSX.Element => {
  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();
  const { getTempDocumentsDataFromMainScreen } = useDocumentsTempData();
  const handleRadioButtonClick = () => {
    if (id === 1) {
      setRadioButtonsValue({ amicableFinding: true, policeReport: false });
      setQuestionsFlowHandler({ completedDocumentsAfterAccident: COMPLETED_DOCUMENTS_TYPE.AMICABLE_FINDING });
      getTempDocumentsDataFromMainScreen({
        title,
        formType: DOCUMENTS_FORM_TYPE.AMICABLE_FINDING,
        defaultPicture: AmicableFindingTutorial,
      });
    } else {
      setRadioButtonsValue({ amicableFinding: false, policeReport: true });
      setQuestionsFlowHandler({ completedDocumentsAfterAccident: COMPLETED_DOCUMENTS_TYPE.POLICE_REPORT });
      getTempDocumentsDataFromMainScreen({
        title,
        formType: DOCUMENTS_FORM_TYPE.POLICE_REPORT,
        defaultPicture: PoliceReportTutorial,
      });
    }
  };

  return (
    <Wrapper width='100%'>
      <div
        style={{
          width: '100%',
          height: '60px',
          margin: '10px auto',
          boxShadow: '0px 4px 4px rgba(218, 218, 218, 0.25)',
          background: '#FFFFFF',
          borderRadius: '25px',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleRadioButtonClick()}
      >
        <Typography color={active ? '#512AD8' : '#8E8D8D'} commonStyles={{ margin: '0 0 0 25px' }} fontWeight='700' variant='h5'>
          {title}
        </Typography>
        <input
          checked={value}
          name='radio'
          style={{ marginLeft: 'auto', marginRight: '25px' }}
          type='radio'
          onChange={() => handleRadioButtonClick()}
        />
      </div>
    </Wrapper>
  );
};
