import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Typography, Wrapper } from '../../styled-components';
import { RadioButtonDocuments } from '../../components/RadioButtonDocuments/RadioButtonDocuments';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { handleNextRouteLastPathConversion } from '../../helpers/routes-helpers';

export const SelectAccidentDocument = ({ history, parentRoute, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<{ amicableFinding: boolean; policeReport: boolean }>({
    amicableFinding: false,
    policeReport: false,
  });

  const onContinue = () => {
    if (selection.amicableFinding) {
      return history.push(handleNextRouteLastPathConversion(parentRoute, nextRoute), {
        amicableFindingOrPoliceReportTitle: t('initialQuestions.amicableFinding'),
      });
    } else if (selection.policeReport) {
      return history.push(handleNextRouteLastPathConversion(parentRoute, nextRoute), {
        amicableFindingOrPoliceReportTitle: t('commonText.policeReport'),
      });
    }
  };

  const disabledContinue = () => !selection.amicableFinding && !selection.policeReport;

  return (
    <ScreenContainer
      bodyProps={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}
      headerProps={{
        title: t('documents.title'),
        titleSize: 'h5',
        hideLogo: true,
      }}
    >
      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start' width='100%'>
        <Typography commonStyles={{ margin: '0 0 1rem 0' }} fontWeight='700' variant='h3'>
          {t('documents.whichDocumentsDidYouComplete')}
        </Typography>
        <RadioButtonDocuments
          active={selection.amicableFinding}
          id={1}
          setRadioButtonsValue={setSelection}
          title={t('initialQuestions.amicableFinding')}
          value={selection.amicableFinding}
        />
        <RadioButtonDocuments
          active={selection.policeReport}
          id={2}
          setRadioButtonsValue={setSelection}
          title={t('commonText.policeReport')}
          value={selection.policeReport}
        />
      </Wrapper>
      <Button
        background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
        border='none'
        borderRadius='25px'
        color='#FFFF'
        disabled={disabledContinue()}
        disabledColor='#DADADA'
        margin='10px 0 0 0'
        type='submit'
        onClick={() => onContinue()}
      >
        {t('commonText.continue')}
      </Button>
    </ScreenContainer>
  );
};
