import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';

import { Card, Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { setCurrentStatementData } from '../../store/statement/statement.slice';
import { OTHER_INDIVIDUAL, USER_SITUATION } from '../../services/router/routeHandles';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS } from '../../translations/ro/home';
import { DeleteIcon } from '../../assets/icons';
import { $fontWeightBold } from '../../styles/variables';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { IEvaluationContent, IPaymentDTO } from '../../@types/current-statement/currentStatementTypes';
import { BankModal } from '../../components/bank-modal';
import { ContinueModal } from '../../components/continue-modal';
import { TermsModal } from '../../components/terms-modal';
import { IScreenComponentProps } from '../component-props.model';
import { usePaymentDetails } from '../../hooks/usePaymentDetails';
import { useSignature } from '../../hooks/useSignature';
import { useStatements } from '../../hooks/useStatements';

const initialValues = {
  injuredBrokerName: '',
  bankName: '',
  iban: '',
  terms: false,
};

export const Signature = ({ history, state }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { putPaymentDetails } = usePaymentDetails();
  const { currentStatement } = useCurrentStatement();
  const { postSignature } = useSignature();
  const sigCanvas = useRef<any>();
  const [isSignatureReady, setSignatureReady] = useState<boolean>(false);
  const [showPlaceholder, setPlaceholder] = useState<boolean>(true);
  const [signature, setSignature] = useState<string | null | undefined>('');
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [showBankInfo, setShowBankInfo] = useState<boolean>(false);
  const [showContinue, setShowContinue] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const { getSignature } = useSignature();
  const { getStatementById } = useStatements();

  useEffect(() => {
    if (currentStatement.statementID) getStatementById(currentStatement.statementID);

    if (history.location.pathname.includes(USER_SITUATION.GUILTY_STATUS)) {
      if (currentStatement.guilty.userSignature)
        if (!isNaN(Number(currentStatement?.guilty?.userSignature))) {
          getSignature(Number(currentStatement.guilty.userSignature), file => {
            setSignature(URL.createObjectURL(file));
          });
        }
    } else {
      if (currentStatement.injured.userSignature)
        if (!isNaN(Number(currentStatement?.injured?.userSignature))) {
          getSignature(Number(currentStatement.injured.userSignature), file => {
            setSignature(URL.createObjectURL(file));
          });
        }
    }
  }, []);

  const isCanvasEmpty = (): boolean => {
    return sigCanvas.current && sigCanvas.current.isEmpty();
  };

  const onBeginSignature = (): void => {
    setSignature('');
    setPlaceholder(false);
  };

  const onSignatureReady = (): void => {
    setSignatureReady(!isCanvasEmpty());
  };

  const clearSignature = (): void => {
    sigCanvas.current.clear();
    setSignatureReady(false);
  };

  const base64ToFile = (base64File: string): File => {
    const i = base64File.indexOf('base64,');
    const buffer = Buffer.from(base64File.slice(i + 7), 'base64');
    const name = `${Math.random().toString(36).slice(-5)}.png`;

    return new File([buffer], name, {
      type: 'image/png',
    });
  };

  const saveSignature = (): void => {
    // TODO: this is working pretty fine but we need to refactor/check the signature from the Store when back/forth
    const signatureDataString = sigCanvas.current.toDataURL();
    const updatedStatement = {
      ...currentStatement,
      injured: {
        ...currentStatement['injured'],
        userSignature: signatureDataString,
      },
    };
    dispatch(setCurrentStatementData(updatedStatement));

    const signatureFile = base64ToFile(signatureDataString);

    if (currentStatement.injured.id) {
      const formData = new FormData();
      formData.append('id', currentStatement?.injured?.id.toString());
      formData.append('file', signatureFile);
      postSignature(formData, 1, () => {
        clearSignature();
        setModalActive(true);

        if (currentStatement.carOwner !== OTHER_INDIVIDUAL) setShowBankInfo(true);
      });
    }
  };

  const onSubmit = (formData: IEvaluationContent): void => {
    setShowContinue(true);
    setShowBankInfo(false);

    if (currentStatement.statementID) {
      const paymentDetails: IPaymentDTO = {
        id: currentStatement?.statementID,
        name: formData.injuredBrokerName,
        iban: formData.iban,
        bankName: formData.bankName,
      };
      putPaymentDetails(paymentDetails);
    }
  };

  const chooseModal = (): JSX.Element => {
    if (showBankInfo) {
      return (
        <BankModal
          acceptTerms={acceptTerms}
          initialValues={initialValues}
          setShowBankInfo={setShowBankInfo}
          setShowContinue={setShowContinue}
          setShowTerms={setShowTerms}
          onSubmit={onSubmit}
        />
      );
    } else if (showContinue) {
      return <ContinueModal />;
    } else if (showTerms) {
      return <TermsModal history={history} setAcceptTerms={setAcceptTerms} setShowBankInfo={setShowBankInfo} setShowTerms={setShowTerms} />;
    }

    return <></>;
  };

  return (
    <ScreenContainer
      bodyProps={{
        display: 'flex',
        flexDirection: 'column',
      }}
      headerProps={{
        title: HOME_TEXTS.signature,
        hideLogo: true,
        titleSize: 'h5',
      }}
      isModalOpen={modalActive}
      modal={chooseModal()}
      modalProps={{ show: modalActive, onClose: () => setModalActive(false) }}
      showBottomNavbar={false}
    >
      <Wrapper display='flex' flexDirection='column' width='100%'>
        <Typography commonStyles={{ margin: '0 0 15px 0' }} fontWeight={$fontWeightBold} variant='h4'>
          {HOME_TEXTS.signatureText}
        </Typography>
        <Card
          alignItems='center'
          borderRadius='26px'
          display='flex'
          flexDirection='column'
          height='230px'
          justifyContent='center'
          padding='0'
          position='relative'
          width='100%'
        >
          <ReactSignatureCanvas
            ref={sigCanvas}
            canvasProps={{ className: 'sigCanvas' }}
            onBegin={onBeginSignature}
            onEnd={onSignatureReady}
          />
          {showPlaceholder && !signature && (
            <Typography color='#9A9A9A' position='absolute' variant='body'>
              {HOME_TEXTS.pressForSignature}
            </Typography>
          )}
          {isSignatureReady && (
            <Button mode='link' style={{ position: 'absolute', right: '7px', bottom: '10px' }} type='button' onClick={clearSignature}>
              <DeleteIcon />
            </Button>
          )}
          {signature && (
            <img alt='' height='70px' src={signature} style={{ position: 'absolute' }} width='70px' onClick={() => setSignature('')} />
          )}
        </Card>
      </Wrapper>

      <Button disabled={!isSignatureReady && !signature} mode='primary' type='button' onClick={saveSignature}>
        {t('commonText.continue')}
      </Button>
    </ScreenContainer>
  );
};
