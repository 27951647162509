import { IRoute } from '../../interfaces/route.model';
import { Witnesses } from '../../../../screens/witnesses/Witnesses';
import { WitnessAddEdit } from '../../../../screens/witnesses/WitnessAddEdit';
import { WitnessList } from '../../../../screens/witnesses/WitnessList';
import { PROTECTED_ROUTES } from '../routes-constants';

export const CURRENT_STATEMENT_ROUTE = '/statement/1';

export const witnessesRoutes: IRoute = {
  baseUrl: '/',
  children: [
    {
      path: PROTECTED_ROUTES.statement$IdWitnesses,
      component: Witnesses,
      exact: true,
      nextRoute: PROTECTED_ROUTES.statementWitnessesList,
    },
    {
      path: PROTECTED_ROUTES.statementWitnessesAdd,
      component: WitnessAddEdit,
      nextRoute: PROTECTED_ROUTES.statementWitnesses$WitnessIdEdit,
    },
    {
      path: PROTECTED_ROUTES.statementWitnesses$WitnessIdEdit,
      component: WitnessAddEdit,
      nextRoute: PROTECTED_ROUTES.statementWitnessesAdd,
    },
    {
      path: PROTECTED_ROUTES.statementWitnessesList,
      component: WitnessList,
    },
  ],
};
