import { useTranslation } from 'react-i18next';

export interface IAnswers {
  firstAnswer: string;
  secondAnswer: string;
  secondAnswerProps?: Record<string, string | boolean>;
}

export interface IQuestionScreenType {
  id?: number;
  answers: IAnswers;
  pageTitle: string;
  question: string;
  modal?: JSX.Element;
}

export interface IQuestion {
  DAMAGE_TYPE: IQuestionScreenType;
  AMICABLE_FINDING: IQuestionScreenType[];
}

const useQuestionsContent = () => {
  const { t } = useTranslation();

  const QUESTIONS_SCREENS_CONSTANTS: IQuestion = {
    DAMAGE_TYPE: {
      pageTitle: t('initialQuestions.damage'),
      question: t('initialQuestions.damageTypeQuestion'),
      answers: {
        firstAnswer: t('initialQuestions.rca'),
        secondAnswer: t('initialQuestions.casco'),
        secondAnswerProps: {
          disabled: true,
          background: 'linear-gradient(270deg, #813EDA 0%, #4123D7 100%)',
          color: '#FFFF',
          disabledColor: '#DADADA',
        },
      },
    },
    AMICABLE_FINDING: [
      {
        id: 1,
        pageTitle: t('initialQuestions.observationNote'),
        question: t('initialQuestions.alreadyOpenedFile'),
        answers: {
          firstAnswer: t('commonText.yes'),
          secondAnswer: t('commonText.no'),
        },
      },
      {
        id: 2,
        pageTitle: t('initialQuestions.compensationOffer'),
        question: t('initialQuestions.didYouReceiveAnOffer'),
        answers: {
          firstAnswer: t('commonText.yes'),
          secondAnswer: t('commonText.no'),
        },
      },
    ],
  };

  return { QUESTIONS_SCREENS_CONSTANTS };
};
export { useQuestionsContent };
