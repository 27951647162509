import React from 'react';

import { IIconProps } from './icon.model';

export const CheckedRoundFilledIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 34} viewBox='0 0 35 35' width={width || 34} {...otherProps}>
      <path
        d='M17.5 34.5832C8.06939 34.5728 0.426983 26.9304 0.416626 17.4999V17.1582C0.604426 7.77011 8.33404 0.29346 17.7232 0.418038C27.1123 0.542617 34.6409 8.22171 34.5795 17.6115C34.5182 27.0013 26.8899 34.5813 17.5 34.5832ZM9.65871 16.7995L7.24996 19.2082L14.0833 26.0415L27.75 12.3749L25.3412 9.94903L14.0833 21.207L9.65871 16.7995Z'
        fill={fill || '#aeaeae'}
      />
    </svg>
  );
};
