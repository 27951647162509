import { PayloadAction } from '@reduxjs/toolkit';

import { createGenericSlice, GenericState, initializeState } from '../store.constants';

import { IAuthUserModel } from './auth.model';

// generate the type of the slice
type IAuthUser = GenericState<IAuthUserModel | null>;

const getLoggedUser = (): IAuthUserModel | null => {
  const authUser = localStorage.getItem('auth_user');

  if (authUser) return JSON.parse(authUser);

  return null;
};

// initialize the state of the slice by passing data
const initialState: IAuthUser = initializeState(getLoggedUser());

const authSlice = createGenericSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setAuthUser(state: IAuthUser, action: PayloadAction<IAuthUserModel>) {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    removeAuthUser(state: IAuthUser) {
      state.data = null;
    },
  },
});

export default authSlice.reducer;

export const { setAuthUser, removeAuthUser, fetchStart, fetchError } = authSlice.actions;
