import aliansLogo from '../assets/images/allianz.png';
import graweLogo from '../assets/images/grawe.png';
import eurionsLogo from '../assets/images/euroins.png';
import generaliLogo from '../assets/images/generali.png';
import asiromLogo from '../assets/images/asirom.png';
import groupamaLogo from '../assets/images/groupama.png';
import omniasigLogo from '../assets/images/omniasig.png';
import uniqaLogo from '../assets/images/uniqa.png';
import { PreLoginInsuranceData } from '../@types/pre-login/preLoginTypes';

export const INSURANCE_MOCK: PreLoginInsuranceData[] = [
  {
    insurerID: 1,
    insurerName: 'Alliantz Tiriac',
    image: aliansLogo,
    available: true,
  },
  {
    insurerID: 2,
    insurerName: 'Grawe',
    image: graweLogo,
    available: true,
  },
  {
    insurerID: 4,
    insurerName: 'Euroins',
    image: eurionsLogo,
    available: false,
  },
  {
    insurerID: 5,
    insurerName: 'Generali',
    image: generaliLogo,
    available: false,
  },
  {
    insurerID: 6,
    insurerName: 'Asirom',
    image: asiromLogo,
    available: false,
  },
  {
    insurerID: 7,
    insurerName: 'Grupama',
    image: groupamaLogo,
    available: false,
  },
  {
    insurerID: 8,
    insurerName: 'Omniasig',
    image: omniasigLogo,
    available: false,
  },
  {
    insurerID: 9,
    insurerName: 'Uniqa',
    image: uniqaLogo,
    available: false,
  },
];
