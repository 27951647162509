import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../button';
import { $lineHeightXL } from '../../styles/variables';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { usePreLogin } from '../../hooks/use-preLogin';
import { STATEMENT_TYPES } from '../../store/types';

interface PropsI {
  closeModal: () => void;
}

export const ThirdAmicableFindingModal = ({ closeModal }: PropsI): JSX.Element => {
  const history = useHistory();
  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();
  const { t } = useTranslation();
  const { setPreLoginData } = usePreLogin();

  const onContinueWithNotificationClick = () => {
    setQuestionsFlowHandler({ compensationOffer: false });
    setPreLoginData({ damageNotification: false });
    history.push(PRE_LOGIN_ROUTES.chooseInsuranceCompany);
  };

  const onDamageNotificationClick = () => {
    closeModal();
    setQuestionsFlowHandler({ type: STATEMENT_TYPES.NOTIFICATION });
    history.push(generatePath(PRE_LOGIN_ROUTES.insuranceType));

    setPreLoginData({ damageNotification: true });
  };

  return (
    <>
      <Wrapper display='flex' flexDirection='column' height='100%'>
        <Wrapper alignItems='left' display='flex' flexDirection='column' justifyContent='flex-start'>
          <Typography commonStyles={{ padding: '0 15px' }} extendedStyles={{ lineHeight: $lineHeightXL }} fontWeight='700' variant='h3'>
            {t('preLoginQuestions.youMustKnow')}
          </Typography>
          <Typography
            align='left'
            color='#8e8e8e'
            commonStyles={{ padding: '18px 15px' }}
            extendedStyles={{ fontSize: '16px', lineHeight: '22px' }}
            variant='body'
          >
            {t('preLoginQuestions.youMustDo')}
          </Typography>
        </Wrapper>

        <Wrapper display='flex' flexDirection='column' justifyContent='flex-start' width='100%'>
          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            width='100%'
            onClick={onDamageNotificationClick}
          >
            {t('observationNote.damageNotification')}
          </Button>
          <Button margin='20px 0' mode='link' onClick={onContinueWithNotificationClick}>
            <Typography color='#512AD8' variant='h5'>
              {t('observationNote.continueWithoutNotification')}
            </Typography>
          </Button>
        </Wrapper>
      </Wrapper>
    </>
  );
};
