import styled from 'styled-components';

export type ITabs = 'utils' | 'profile' | 'evaluations' | 'home';

export interface IProps {
  selected?: ITabs;
  onSelect?: (tab: ITabs) => void;
}

export const StyledBottomContainer = styled.div`
  height: 62px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 2.66667px rgba(0, 0, 0, 0.12), 0 2.66667px 2.66667px rgba(0, 0, 0, 0.24);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 799;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StyledIconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: none;
  cursor: pointer;
`;

export const StyledButtonLabel = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: 4px;
  color: ${({ selected }: { selected: boolean }) => (selected ? '#5F2EEA' : '#8E8D8D')};
  font-weight: ${({ selected }: { selected: boolean }) => (selected ? '700' : '500')};
`;
