import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { fetchDamageByDamageId, postDamagesApi, postDamagesMediaApi } from '../store/statement/currentStatement.action';
import { IPhotoProofs } from '../@types/damage/damageType';

const useDamages = () => {
  const dispatch = useDispatch();

  const getDamagesByDamageId = useCallback(
    (id: number) => {
      dispatch(fetchDamageByDamageId(id));
    },
    [dispatch],
  );

  const postDamages = useCallback(
    (
      payload: {
        description: string | undefined;
        zone: string | undefined;
        statementId: number | null;
        personId: number | null;
      },
      onSuccess?: () => void,
    ) => {
      console.log('Before Callback dispatch damages: ', payload);
      dispatch(postDamagesApi(payload, onSuccess));
      console.log('After Callback dispatch damages: ', payload);
    },
    [dispatch],
  );

  const postDamagesMedia = useCallback(
    (payload: FormData, type: boolean, onSuccess?: (payload: IPhotoProofs) => void) => {
      dispatch(postDamagesMediaApi(payload, type, onSuccess));
    },
    [dispatch],
  );

  return { getDamagesByDamageId, postDamages, postDamagesMedia };
};

export { useDamages };
