import { AppDispatch, AppThunk } from '../store';

import { setAuthUser } from './auth.slice';
import { IAuthUserModel } from './auth.model';

export const logUserAction = (payload: IAuthUserModel, onSuccess?: (payload: IAuthUserModel) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  try {
    dispatch(setAuthUser(payload));

    if (onSuccess) {
      onSuccess(payload);
    }
  } catch (e) {
    console.log('Error: ', e.message);
  }
};
