import { TestFunction } from 'yup';

const isCNPValid = (pCnp: string) => {
  let year: number;
  let hashResult = 0;
  const cnp = [];
  const hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];

  if (pCnp?.length !== 13) return false;

  for (let i = 0; i < 13; i += 1) {
    cnp[i] = parseInt(pCnp.charAt(i), 10);

    if (parseFloat(cnp[i].toString()) !== +cnp[i]) return false;

    if (i < 12) hashResult += cnp[i] * hashTable[i];
  }
  hashResult %= 11;

  if (hashResult === 10) {
    hashResult = 1;
  }
  year = cnp[1] * 10 + cnp[2];

  if (parseInt(`${cnp[3]}${cnp[4]}`) < 1 || parseInt(`${cnp[3]}${cnp[4]}`) > 12) {
    return false;
  }

  if (parseInt(`${cnp[5]}${cnp[6]}`) < 1 || parseInt(`${cnp[5]}${cnp[6]}`) > 31) {
    return false;
  }

  switch (cnp[0]) {
    case 1:
    case 2:
      year += 1900;
      break;
    case 3:
    case 4:
      year += 1800;
      break;
    case 5:
    case 6:
      year += 2000;
      break;
    case 7:
    case 8:
    case 9:
      year += 2000;

      if (year > parseInt(new Date().getFullYear().toString(), 10) - 14) {
        year -= 100;
      }
      break;
    default: {
      return false;
    }
  }

  if (year < 1800 || year > 2099) {
    return false;
  }

  return cnp[12] === hashResult;
};

const isCUIValid = (cui: string) => {
  let cif = cui;
  let cifNumber: number;

  if (!parseFloat(cui as string) || !(Math.abs(parseFloat(cui as string)) !== Number.POSITIVE_INFINITY)) {
    cif = cif?.toUpperCase();

    if (cif?.indexOf('RO') === 0) {
      cif = cif.replace('RO', '');
    } else {
      return false;
    }
    cifNumber = parseInt(cif.trim(), 10);
    cif = cif.trim();
  } else {
    cifNumber = parseInt(cif as string, 10);
  }

  if (cif && (cif.length > 10 || cif.toString().length < 6)) return false;

  let v = 753217532;

  const c1 = cifNumber % 10;
  cifNumber /= 10;

  let t = 0;

  while (cifNumber > 0) {
    // eslint-disable-next-line no-bitwise
    t += (cifNumber % 10 | 0) * (v % 10 | 0);
    cifNumber /= 10;
    v /= 10;
  }

  let c2 = (t * 10) % 11;

  if (c2 === 10) {
    c2 = 0;
  }

  return c1 === c2;
};

const validateCnp: TestFunction<string | null | undefined> = (pCnp: any): boolean => {
  return !pCnp || isCNPValid(pCnp);
};

const validateCui: TestFunction<string | null | undefined> = cui => {
  return cui ? isCUIValid(cui) : false;
};

const validateCuiOrCNP: TestFunction<string | null | undefined> = cui => {
  return cui ? isCNPValid(cui) || isCUIValid(cui) : false;
};

export { validateCnp, validateCui, validateCuiOrCNP };
