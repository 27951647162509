import { useCallback, useEffect, useState } from 'react';
import * as H from 'history';
import { useSelector } from 'react-redux';

import { USER_SITUATION } from '../services/router/routeHandles';
import { ChooseInsuranceHelpers } from '../helpers/insurance';
import { PreLoginInsuranceData, PreLoginInsurances } from '../@types/pre-login/preLoginTypes';
import { RulesTypes } from '../screens/component-props.model';
import { ChooseInsuranceConstants } from '../constants/dataConstants/ChooseInsuranceConstants';
import { RootState } from '../store/rootReducer';
import { preLoginInitialState } from '../store/pre-login/initialState';
import { PROTECTED_ROUTES } from '../services/router/app-routes/routes-constants';

import { usePreLogin } from './use-preLogin';

const useInsurance = (flowHandle: USER_SITUATION, history: H.History<any>, nextRoute: RulesTypes<string>) => {
  const { initialInsuranceState } = ChooseInsuranceConstants();
  const { setPreLoginData } = usePreLogin();
  const { convertInsuranceData, changeUserScreen } = ChooseInsuranceHelpers();
  const [personInsurer, setPersonInsurer] = useState<PreLoginInsurances>(initialInsuranceState);
  const [showModalDirectSettlement, setShowModalDirectSettlement] = useState<boolean>(false);
  const loggedUser = useSelector((state: RootState) => state.authUser.data);
  const [handleNextRoute, setHandleNextRoute] = useState<RulesTypes<string> | string>('');
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    setRender(prevState => prevState + 1);
  }, []);

  const convertInsuranceToStore = useCallback(() => {
    setPreLoginData({
      insurances: {
        injuredInsurance: convertInsuranceData(personInsurer?.injuredInsurance),
        guiltyInsurance: convertInsuranceData(personInsurer?.guiltyInsurance),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personInsurer]);

  useEffect(() => {
    convertInsuranceToStore();
  }, [convertInsuranceToStore]);

  const navigateToNextScreen = () => {
    if (loggedUser) {
      setPreLoginData(preLoginInitialState);
      history.push(PROTECTED_ROUTES.ownerStatus);

      return;
    }
    history.push(nextRoute.toString());
  };

  const handleScreenChange = useCallback(() => {
    if ((personInsurer.injuredInsurance.insurerID === null || personInsurer.guiltyInsurance.insurerID === null) && render !== 0) {
      changeUserScreen(history, flowHandle);
    } else if (!!handleNextRoute) {
      navigateToNextScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personInsurer]);

  useEffect(() => {
    handleScreenChange();
  }, [handleScreenChange]);

  const handleInjurerSituation = (modalResponse: boolean, insurance: PreLoginInsuranceData) => {
    if (modalResponse) {
      setPersonInsurer({ ...personInsurer, injuredInsurance: { ...insurance, directSettlement: true } });
    } else {
      setPersonInsurer({ ...personInsurer, injuredInsurance: insurance });
    }
  };

  const handleDirectSettlement = useCallback(
    (modalResponse: boolean, insurance: PreLoginInsuranceData) => {
      setShowModalDirectSettlement(false);
      handleInjurerSituation(modalResponse, insurance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [personInsurer],
  );

  const handleGuiltySituation = useCallback((userSituation: USER_SITUATION, insurance: PreLoginInsuranceData) => {
    if (userSituation === USER_SITUATION.GUILTY_STATUS) {
      setPersonInsurer(prevState => ({ ...prevState, guiltyInsurance: { ...insurance, directSettlement: false } }));
    } else {
      setShowModalDirectSettlement(true);
    }
  }, []);

  const selectInsurance = useCallback(
    (insurance: PreLoginInsuranceData) => {
      handleGuiltySituation(flowHandle, insurance);
    },
    [handleGuiltySituation, flowHandle],
  );

  return {
    selectInsurance,
    personInsurer,
    showModalDirectSettlement,
    setShowModalDirectSettlement,
    handleDirectSettlement,
    setHandleNextRoute,
  };
};

export { useInsurance };
