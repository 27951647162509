import React from 'react';

import { IIconProps } from './icon.model';

export const GoogleIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 20} viewBox='0 0 22 20' width={width || 22} {...otherProps}>
      <path
        d='M10.932 8.239v3.821h5.951c-.778 2.315-2.893 3.972-5.951 3.972-3.642 0-6.594-2.701-6.594-6.032s2.952-6.032 6.594-6.032c1.637 0 3.132.549 4.285 1.453l3.076-2.814C16.351.988 13.767 0 10.932 0 4.894 0 0 4.477 0 10s4.894 10 10.932 10c9.177 0 11.202-7.85 10.302-11.748l-10.302-.013z'
        fill={fill || '#fff'}
      />
    </svg>
  );
};
