import React from 'react';
import styled from 'styled-components';

export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  padding?: string;
  margin?: string;
  width?: string;
  borderRadius?: string;
  name?: string;
  checkboxColor?: string;
  checkboxBackground?: string;
  labelText?: string;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  cursor: pointer;
  margin: ${(props: ICheckboxProps) => props.margin || '10px 0'};
  position: relative;
  & span {
    padding-top: 1px;
  }
`;

const Icon = styled.svg`
  fill: none;
  stroke-width: 3px;
  width: 100%;
  position: absolute;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  height: 100%;
`;
//TODO fix background when is checked and had checkboxBackground prop
const StyledCheckbox = styled.div`
  display: inline-block;
  min-width: ${(props: ICheckboxProps) => props.width || '20px'};
  height: ${(props: ICheckboxProps) => props.width || '20px'};
  border-radius: 3px;
  position: relative;
  margin-right: 17px;
  background: ${({ checked, checkboxBackground }: ICheckboxProps) => (checked ? checkboxBackground || '#512AD8' : '#fff')};
  border: 2px solid ${({ checked, checkboxBackground }: ICheckboxProps) => (checked ? checkboxBackground || '#512AD8' : '#B6B6B6')};
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const Checkbox = ({ className, checked, onChange, ...props }: ICheckboxProps) => (
  <label htmlFor={props.name}>
    <CheckboxContainer className={className} {...props}>
      <HiddenCheckbox checked={checked} value={props.value} onChange={onChange} />
      <StyledCheckbox checked={checked} name={props.name}>
        <Icon stroke={props.checkboxColor || 'white'} viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </StyledCheckbox>
      <span>{props.labelText || props.children}</span>
    </CheckboxContainer>
  </label>
);
