import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import * as H from 'history';

import { Typography } from '../../styled-components';
import { Button } from '../button';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';

interface ITermsModal {
  setShowTerms?: (showTerms: boolean) => void;
  setShowBankInfo?: (showBankInfo: boolean) => void;
  setAcceptTerms?: (acceptTerms: boolean) => void;
  setModalActive?: (modalActive: boolean) => void;
  history: H.History<any>;
}

export const TermsModal = ({ setShowTerms, setShowBankInfo, setAcceptTerms, setModalActive, history }: ITermsModal): JSX.Element => {
  const { t } = useTranslation();
  const { currentStatement } = useCurrentStatement();
  const ITERATOR = [1, 2, 3, 4];
  const PARAGRAPH_TEXT = t('payPactTerms.terms');

  const handleModals = () => {
    if (setShowTerms) {
      setShowTerms(false);
    }

    if (setShowBankInfo) {
      setShowBankInfo(true);
    } else {
      if (currentStatement.statementID !== null) {
        history.push(generatePath(PROTECTED_ROUTES.statementWarrant, { currentStatementId: currentStatement.statementID }));
      }
    }

    if (setAcceptTerms) {
      setAcceptTerms(true);
    }

    if (setModalActive) {
      setModalActive(false);
    }

    if (currentStatement.statementID && currentStatement.insuredId) {
      history.push(generatePath(PROTECTED_ROUTES.statementWarrant, { currentStatementId: currentStatement.statementID.toString() }));
    }
  };

  return (
    <>
      <Typography align='center' commonStyles={{ margin: '15px 0 15px 0' }} fontWeight='700' variant='h4'>
        {t('payPactTerms.title')}
      </Typography>
      <div style={{ overflow: 'scroll', height: '100%' }}>
        {ITERATOR.map((index: number) => (
          <Typography key={`paragraph-${index - 1}`} color='#8e8e8e' commonStyles={{ margin: '0 0 20px 0' }} fontSize='12px' variant='body'>
            {PARAGRAPH_TEXT}
          </Typography>
        ))}

        <Button mode='primary' type='button' onClick={handleModals}>
          {t('commonText.accept')}
        </Button>
      </div>
    </>
  );
};
