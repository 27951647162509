import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Switch, Wrapper, Typography } from '../../styled-components';
import { WitnessAddEditForm } from '../../components';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS } from '../../translations/ro/home';
import { IWitness } from '../../@types/witnesses/witnessesTypes';
import { useWitnesses } from '../../hooks/useWitnesses';
import { witnessInitialState } from '../../store/statement/initialState';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

export const WitnessAddEdit = ({ history, state }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const data = state?.currentStatement || ({} as any);
  const [hasWitnesses, setHasWitnesses] = useState(false);

  const { setWitnesses, setWitnessByID } = useWitnesses();

  const [witness, setWitness] = useState<IWitness>(witnessInitialState);

  useEffect(() => {
    if (history.location.state.addWitnesses) {
      setHasWitnesses(true);
    }

    if (history.location.state.witness) {
      setWitness(history.location.state.witness);
    }
  }, [history.location.state.addWitnesses, history.location.state.witness, witness]);

  const onSwitchForm = (): void => {
    setHasWitnesses(!hasWitnesses);
  };

  const putOnState = (formData: IWitness | null): void => {
    const payload = {
      injured: {
        ...data.injured,
        witnesses: [...data.injured.witnesses, formData],
      },
    };
    setWitnesses(payload.injured.witnesses);
  };

  const onSkip = () => {
    setWitnesses(null);
    history.replace(
      generatePath(`${PROTECTED_ROUTES.statementWitnessesList}`, {
        currentStatementId: String(data.statementID),
      }),
    );
  };

  const onSubmit = (formData: IWitness) => {
    // comming from edit
    if (window.location.pathname.includes('/edit')) {
      setWitnessByID(formData.id, formData);
      history.push(PROTECTED_ROUTES.statement$IdWitnesses);
    } else {
      // comming from add new witness
      formData.id = Math.floor(Math.random() * 100);
      formData.statementId = data.statementID;
      putOnState(formData);
      history.replace(
        generatePath(`${PROTECTED_ROUTES.statement$IdWitnesses}`, {
          currentStatementId: String(data.statementID),
        }),
      );
    }
  };

  return (
    <>
      <ScreenContainer headerProps={{ hideLogo: true, title: HOME_TEXTS.witnesses }}>
        <Wrapper style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          {!history.location.state.addWitnesses && (
            <>
              <Wrapper width='100%'>
                <Typography fontWeight='700' variant='h5'>
                  {t('commonText.noWitnesses')}
                </Typography>
                <Switch defaultChecked={!hasWitnesses} onChange={onSwitchForm} />
              </Wrapper>
            </>
          )}
          <WitnessAddEditForm hasWitnesses={hasWitnesses} initialValues={witness} onSkip={onSkip} onSubmit={onSubmit} />
        </Wrapper>
      </ScreenContainer>
    </>
  );
};
