import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { DefaultTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Column, Container, Image } from '../index';
import { RootState } from '../../store/rootReducer';
import DefaultAvatar from '../../assets/images/DefaultAvatar.png';

export interface IHeaderProps {
  backgroundColor?: string;
  gradient?: string;
  title?: string;
  height?: string;
  position?: string;
  marginBottom?: string;
  showProfilePicture?: boolean;
  className?: string;
  children?: JSX.Element | JSX.Element[] | ReactNode;
  theme?: DefaultTheme;
}

interface IThemedHeader extends IHeaderProps {
  theme: DefaultTheme;
}

const Header = styled.header`
  transform: rotate(-180deg);
  z-index: 2;
  width: 100%;
  background-color: ${({ backgroundColor, theme }: IThemedHeader) => backgroundColor || theme.primaryColor};
  background-image: ${({ gradient }: IHeaderProps) => gradient || 'none'};
  height: ${({ height }: IHeaderProps) => height || '322px'};
  position: ${({ position }: IHeaderProps) => position || 'relative'};
  margin-bottom: ${({ marginBottom }: IHeaderProps) => marginBottom || '60px'};
`;

export const WelcomeHeader = (props: IHeaderProps): JSX.Element => {
  const authData = useSelector((state: RootState) => state.authUser);
  const profilePictureUrl = `${process.env.REACT_APP_ATTACHMENTS_DOWNLOAD_PATH}/${authData?.data?.picture}?isAvatar=true`;

  // handle broken image scenario
  document.querySelectorAll('img').forEach(img => {
    img.onerror = function () {
      this.src = DefaultAvatar;
    };
  });

  return (
    <Header backgroundColor={props.backgroundColor} gradient={props.gradient} height={props.height}>
      <Container height='100%' padding='43px 0 38px 0' style={{ transform: 'rotate(-180deg)' }}>
        {props.showProfilePicture && (
          <Column paddingBottom='0' paddingTop='0' position='relative' textAlign='center'>
            <div
              style={{
                backgroundImage: 'linear-gradient(to left, #563add , #8c51df)',
                width: '100px',
                height: '100px',
                borderRadius: 50,
                overflow: 'hidden',
                left: 0,
                right: 0,
                position: 'absolute',
                margin: '0 auto',
                top: '25px',
              }}
            >
              <Image alt='profile image' height='100px' id='img1' src={profilePictureUrl || DefaultAvatar} />
            </div>
          </Column>
        )}
      </Container>
    </Header>
  );
};

WelcomeHeader.defaultProps = {
  showProfilePicture: false,
};
