import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextField } from '../text_field';
import { Button } from '../button';
import { Typography } from '../../styled-components';
import { PHONE_REGEX } from '../../shared/constants';
import { IContactDetails } from '../../@types/current-statement/currentStatementTypes';
import { AutocompleteInput } from '../autocomplete';
import { useLocationsData } from '../../hooks/useLocationsData';
import { validateCui } from '../../utils/form-validators';

interface IContactDataProps {
  initialValues: Omit<IContactDetails, 'type'>;
  onSubmit: (value: Omit<IContactDetails, 'type'>) => void;
  multipleContactInformation?: boolean;
  legalPerson?: boolean;
}

interface ICityComparison {
  name: string;
}

export const ContactDetailsForm = ({
  initialValues,
  onSubmit,
  multipleContactInformation,
  legalPerson,
}: IContactDataProps): JSX.Element => {
  const { t } = useTranslation();
  const { counties, cities, loadCities } = useLocationsData();

  useMemo(() => {
    if (cities.length > 0) {
      cities.sort(function (a: ICityComparison, b: ICityComparison) {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });
    }

    return;
  }, [cities]);

  const ValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(new RegExp(PHONE_REGEX), t('contactDetails.invalidPhoneMessage'))
      .required(t('contactDetails.invalidPhoneMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    email: Yup.string()
      .email(t('contactDetails.invalidEmailMessage'))
      .required(t('contactDetails.invalidEmailMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
  });

  const MultipleValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(new RegExp(PHONE_REGEX), t('contactDetails.invalidPhoneMessage'))
      .required(t('contactDetails.invalidPhoneMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    email: Yup.string()
      .email(t('contactDetails.invalidEmailMessage'))
      .required(t('contactDetails.invalidEmailMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
    insuredPhone: Yup.string()
      .matches(new RegExp(PHONE_REGEX), t('contactDetails.invalidPhoneMessage'))
      .required(t('contactDetails.invalidPhoneMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    insuredEmail: Yup.string()
      .email(t('contactDetails.invalidEmailMessage'))
      .required(t('contactDetails.invalidEmailMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
  });

  const LegalPersonValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(t('contactDetails.invalidPhoneMessage'))
      .matches(new RegExp(PHONE_REGEX), t('contactDetails.invalidPhoneMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    email: Yup.string()
      .required(t('contactDetails.invalidEmailMessage'))
      .email(t('contactDetails.invalidEmailMessage'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
    companyName: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
    cui: Yup.string().test('validate-cnp', t('commonText.invalidFormat'), validateCui).required(t('commonText.requiredFieldError')),
    address: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : false)),
    county: Yup.string().required(t('commonText.requiredFieldError')),
    city: Yup.string().required(t('commonText.requiredFieldError')),
  });

  const handleValidationTypes = () => {
    if (multipleContactInformation) return MultipleValidationSchema;

    if (legalPerson) return LegalPersonValidationSchema;

    return ValidationSchema;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={handleValidationTypes()} enableReinitialize validateOnMount onSubmit={onSubmit}>
      {({ handleChange, handleBlur, isValid, values, errors, setFieldValue }) => (
        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <div>
            <Typography commonStyles={{ margin: '0 auto 0 0' }} fontWeight='700' variant='h4'>
              {t('contactDetails.injuredDetails')}
            </Typography>

            <TextField
              errorMessage={errors.phone}
              inputMode='tel'
              label={t('contactDetails.injuredPhoneNo')}
              name='phone'
              value={values.phone}
              variant='rounded'
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <TextField
              autoCapitalize='off'
              errorMessage={errors.email}
              inputMode='email'
              label={t('contactDetails.injuredEmail')}
              name='email'
              value={values.email}
              variant='rounded'
              onBlur={handleBlur}
              onChange={handleChange}
            />

            {multipleContactInformation && (
              <>
                <Typography commonStyles={{ margin: '10px auto 0 0' }} fontWeight='700' variant='h4'>
                  {t('contactDetails.insuredDetails')}
                </Typography>

                <TextField
                  errorMessage={errors.insuredPhone}
                  inputMode='tel'
                  label={t('contactDetails.insuredPhone')}
                  name='insuredPhone'
                  value={values.insuredPhone}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <TextField
                  errorMessage={errors.insuredEmail}
                  inputMode='email'
                  label={t('contactDetails.insuredEmail')}
                  name='insuredEmail'
                  value={values.insuredEmail}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </>
            )}

            {legalPerson && (
              <>
                <TextField
                  errorMessage={errors.companyName}
                  label={t('contactDetails.companyName')}
                  name='companyName'
                  value={values.companyName}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <TextField
                  errorMessage={errors.cui}
                  label={t('contactDetails.cui')}
                  name='cui'
                  value={values.cui}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <AutocompleteInput
                  data={counties}
                  defaultValue={values.county}
                  errorMessage={errors.county}
                  label={t('contactDetails.county')}
                  onBlur={handleBlur}
                  onChange={(e: any, option: any) => {
                    const selectedOption = option && option.value ? option.value : '';
                    setFieldValue('county', selectedOption);
                    loadCities(selectedOption, true);

                    if (!selectedOption) {
                      setFieldValue('city', null);
                    }
                  }}
                />

                <AutocompleteInput
                  data={cities}
                  defaultValue={values.city}
                  errorMessage={errors.city}
                  label={t('contactDetails.city')}
                  onBlur={handleBlur}
                  onChange={(e: any, option: any) => {
                    setFieldValue('city', option && option.value ? option.value : '');
                  }}
                />

                <TextField
                  errorMessage={errors.address}
                  label={t('contactDetails.address')}
                  name='address'
                  value={values.address}
                  variant='rounded'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </>
            )}
          </div>

          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            border='none'
            borderRadius='25px'
            color='#FFFF'
            disabled={!isValid}
            disabledColor='#DADADA'
            margin='10px 0 0 0'
            type='submit'
          >
            {t('commonText.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
