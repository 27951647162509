/**
 * Styled element background color switch
 * Examples:
 * 	<Wrapper backgroundColor='primary' />
 * 	<Wrapper backgroundColor='#534453' />
 * @param props
 */
export const backgroundColor = (props: any): string => {
  if (!props.backgroundColor) {
    return 'transparent';
  }

  switch (props.backgroundColor) {
    case 'primary':
      return props.theme.primaryColor;
    case 'secondary':
      return props.theme.secondaryColor;
    default:
      return props.backgroundColor;
  }
};

/**
 * Styled element text color switch
 * Examples:
 * 	<Wrapper color='primary' />
 * 	<Wrapper color='#534453' />
 * @param props
 */
export const textColor = (props: any): string => {
  if (!props.color) {
    return props.theme.textPrimaryColor || '#20344C';
  }

  switch (props.color) {
    case 'primary':
      return props.theme.primaryColor;
    case 'secondary':
      return props.theme.secondaryColor;
    default:
      return props.color;
  }
};
