import React from 'react';

import { IIconProps } from './icon.model';

export const LinkedinIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 20} viewBox='0 0 20 20' width={width || 20} {...otherProps}>
      <path
        d='M19.9945 20V19.9991H19.9995V12.6642C19.9995 9.07582 19.227 6.31166 15.0321 6.31166C13.0154 6.31166 11.6621 7.41833 11.1096 8.46749H11.0512V6.64666H7.07373V19.9991H11.2154V13.3875C11.2154 11.6467 11.5454 9.96332 13.7012 9.96332C15.8254 9.96332 15.8571 11.95 15.8571 13.4992V20H19.9945Z'
        fill={fill || '#fff'}
      />
      <path d='M0.330078 6.64752H4.47674V20H0.330078V6.64752Z' fill='white' />
      <path
        d='M2.40166 0C1.07583 0 0 1.07583 0 2.40166C0 3.7275 1.07583 4.82583 2.40166 4.82583C3.72749 4.82583 4.80333 3.7275 4.80333 2.40166C4.80249 1.07583 3.72666 0 2.40166 0V0Z'
        fill={fill || '#fff'}
      />
    </svg>
  );
};
