import { ICurrentPerson } from '../@types/current-statement/currentStatementTypes';

export type UserSituationType = 'guilty' | 'injured';

export type StatusType = 'danger' | 'success' | 'warning';

export type CarOwnerType = 'MYSELF' | 'OTHER_INDIVIDUAL' | 'LEGAL_PERSON' | 'LEASING_COMPANY';

export enum STATEMENT_TYPES {
  NOTIFICATION = 'NOTIFICATION',
  EVALUATION = 'EVALUATION',
  STATEMENT = 'STATEMENT',
}

export type RoleTypeProp = { [K in UserSituationType]: ICurrentPerson };

export interface IStatementFile {
  title: string;
  image: string | null;
}

/*
 *  Circumstances
 * */
export interface ICircumstances {
  accidentDate: string;
  accidentTime: string;
  accidentLocation: string;
}
