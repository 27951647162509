import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { LEASING_COMPANY, LEGAL_PERSON, MYSELF, OTHER_INDIVIDUAL, USER_SITUATION } from '../../services/router/routeHandles';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { $lineHeightXL } from '../../styles/variables';
import { usePreLogin } from '../../hooks/use-preLogin';
import { CarOwnerType } from '../../store/types';
import { RootState } from '../../store/rootReducer';

export const CheckOwnerStatus = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { setPreLoginData } = usePreLogin();
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);

  const handleOwnerType = (carOwner: CarOwnerType) => {
    setPreLoginData({ carOwner });

    switch (carOwner) {
      case MYSELF:
        return history.push(nextRoute['individual'].toString(), { flowHandle: USER_SITUATION.INJURED_STATUS, myself: true });
      case OTHER_INDIVIDUAL:
        return history.push(nextRoute['individual'].toString(), {
          flowHandle: USER_SITUATION.INJURED_STATUS,
          multipleContactInformation: true,
        });
      case LEGAL_PERSON:
        return history.push(nextRoute['company'], { flowHandle: carOwner, legalPerson: true });
      case LEASING_COMPANY:
        return history.push(nextRoute['company'], { flowHandle: carOwner, legalPerson: true });
      default:
        return;
    }
  };

  return (
    <>
      <ScreenContainer
        backgroundColor='#F0F2FC'
        headerProps={{ hideLogo: true, title: t('preLoginVehicleOwner.title') }}
        showBottomNavbar={isLoggedIn}
      >
        <Wrapper display='flex' flex='1' flexDirection='column' width='100%'>
          <Typography extendedStyles={{ lineHeight: $lineHeightXL }} fontWeight='700' variant='h3'>
            {t('preLoginVehicleOwner.whoOwns')}
          </Typography>
          <Wrapper display='flex' flexDirection='column' width='100%'>
            <Button borderRadius='25px' color='#512AD8' margin='15px 0 0 0' onClick={() => handleOwnerType(MYSELF)}>
              {t('preLoginVehicleOwner.myself')}
            </Button>
            <Button borderRadius='25px' color='#512AD8' margin='15px 0 0 0' onClick={() => handleOwnerType(OTHER_INDIVIDUAL)}>
              {t('preLoginVehicleOwner.otherIndividual')}
            </Button>
            <Button borderRadius='25px' color='#512AD8' margin='15px 0 0 0' width='100%' onClick={() => handleOwnerType(LEGAL_PERSON)}>
              {t('preLoginVehicleOwner.legalPersonCompany')}
            </Button>
            <Button borderRadius='25px' color='#512AD8' margin='15px 0 0 0' onClick={() => handleOwnerType(LEASING_COMPANY)}>
              {t('preLoginVehicleOwner.leasingCompany')}
            </Button>
          </Wrapper>
        </Wrapper>
      </ScreenContainer>
    </>
  );
};
