import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { UseInitialInformationScreen } from '../../constants/dataConstants/initialInformationScreenConstants';

export const ImportantInformationsScreen = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { ImportantInformationData } = UseInitialInformationScreen();
  const goNext = () => {
    history.push(nextRoute.toString());
  };

  const getInformationModal = () => {
    return (
      <>
        <Wrapper alignItems='center' display='flex' flexDirection='column' height='100%' padding='0px' width='100%'>
          <Wrapper display='block' height='100%' margin='0 0 20px 0' overflowY='scroll'>
            {ImportantInformationData.map((importantInformation: any) => {
              return (
                <Wrapper key={importantInformation.id} display='block' margin='15px 0' width='100%'>
                  <Typography
                    align='left'
                    color='black'
                    commonStyles={{ margin: '5px 5px' }}
                    fontWeight='500'
                    lineHeight='18px'
                    variant='h5'
                  >
                    {importantInformation.title}
                  </Typography>
                  <Typography align='left' color='#8E8E8E' commonStyles={{ margin: '0 5px' }} variant='body-xs'>
                    {importantInformation.subtitle}
                  </Typography>
                </Wrapper>
              );
            })}
          </Wrapper>

          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            marginTop='auto'
            width='100%'
            onClick={() => goNext()}
          >
            {t('commonText.continue')}
          </Button>
        </Wrapper>
      </>
    );
  };

  return (
    <>
      <ScreenContainer
        backgroundColor='#F0F2FC'
        headerType='none'
        modal={getInformationModal()}
        modalProps={{
          show: true,
          showCloseButton: false,
          scroll: 'none',
          border: '1px solid #DADADA',
          height: '90%',
          margin: 'auto 25px',
          showTitle: true,
          backgroundColor: 'white',
          title: t('welcome.importantInformations'),
        }}
        showBottomNavbar={false}
        isModalOpen
      />
    </>
  );
};
