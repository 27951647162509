import React from 'react';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2% 5%',
      background: 'linear-gradient(270deg, #813EDA 0%, #4123D7 100%)',
      borderRadius: '25px',
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 0,
    },
    labelTypography: {
      color: 'white !important',
    },
  }),
);

export const CustomTimePickerToolbar = (props: ToolbarComponentProps): JSX.Element => {
  const classes = useStyles();

  const { date, isLandscape, openView, setOpenView } = props;

  const handleChangeViewClick = (view: any) => () => {
    setOpenView(view);
  };

  return (
    <PickerToolbar className={classes.toolbar} isLandscape={isLandscape}>
      <div className={classes.content}>
        <ToolbarButton
          label={date ? date.format('HH') : ''}
          selected={openView === 'hours'}
          style={{ cursor: 'pointer' }}
          typographyClassName={classes.labelTypography}
          variant='h6'
          onClick={handleChangeViewClick('hours')}
        />
        <Typography style={{ color: 'white', margin: '0 15%' }} variant='h6'>
          :
        </Typography>
        <ToolbarButton
          label={date ? date.format('mm') : ''}
          selected={openView === 'minutes'}
          style={{ cursor: 'pointer' }}
          typographyClassName={classes.labelTypography}
          variant='h6'
          onClick={handleChangeViewClick('minutes')}
        />
      </div>
    </PickerToolbar>
  );
};
