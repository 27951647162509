import { Omit } from 'react-redux';

import { AppDispatch, AppThunk } from '../store';
import { UserSituationType } from '../types';
import { IWitness } from '../../@types/witnesses/witnessesTypes';
import {
  IContactDetails,
  ICurrentStatement,
  ICurrentPerson,
  IPayment,
  IPaymentDTO,
  IStatementBackResponse,
  IStatementDbFormat,
  ICurrentStatementStatus,
} from '../../@types/current-statement/currentStatementTypes';
import { IDocuments } from '../../@types/document/documentTypes';
import { IPhotoProofs, IStatementDamage } from '../../@types/damage/damageType';
import { USER_SITUATION } from '../../services/router/routeHandles';
import { ICircumstancesProp } from '../../screens/statement/interfaces/statement.model';
import { IAuthUserModel } from '../auth-user/auth.model';
import { getUserByIdApi, postUserPhoneNumberApi } from '../auth-user/auth.api';
import { setAuthUser } from '../auth-user/auth.slice';
import { setErrorStatement } from '../app-flow/flow.slice';
import { getPersonsByPersonId, updateStatement } from '../../services/api/createStatement/createStatement.api';

import {
  setInjuredWitnesses,
  setInjuredDetails,
  setGuiltyDetails,
  setCurrentStatementData,
  fetchStart,
  fetchError,
  fetchSuccess,
} from './statement.slice';
import {
  getWitnessesByStatementId,
  postWitnessesByStatementId,
  postDamages,
  getDamagesById,
  postDamagesMedia,
  putDocuments,
  putPaymentDetails,
  postSignature,
  putStatementDocuments,
  getStatementByIdRequest,
  insuredAssignAndRespond,
  getSignature,
  postOcrDataCall,
} from './currentStatement.api';
import { IInsuredAssign } from './statement.model';

export const setStatementAction = (payload: ICurrentStatement): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setCurrentStatementData(payload));
};

export const setCircumstancesAction = (userSituation: UserSituationType, payload: ICircumstancesProp): AppThunk => async (
  dispatch: AppDispatch,
) => {
  if (userSituation === 'injured') dispatch(setInjuredDetails({ circumstances: payload }));
  else dispatch(setGuiltyDetails({ circumstances: payload }));
};

export const setWitnessesAction = (payload: IWitness[] | null): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setInjuredWitnesses({ witnesses: payload }));
};

export const setPaymentDetailsAction = (payload: IPayment): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setCurrentStatementData({ paymentDetails: payload }));
};

export const setDocumentsAction = (userSituation: UserSituationType, payload: IDocuments): AppThunk => async (dispatch: AppDispatch) => {
  if (userSituation === 'injured') dispatch(setInjuredDetails({ documents: payload }));
  else dispatch(setGuiltyDetails({ documents: payload }));
};

export const setDamageAction = (userSituation: UserSituationType, payload: Partial<IStatementDamage>): AppThunk => async (
  dispatch: AppDispatch,
) => {
  // console.log('payload')
  // console.log(payload)
  if (userSituation === 'injured') dispatch(setInjuredDetails({ damage: payload }));
  else dispatch(setGuiltyDetails({ damage: payload }));
};

export const setAuthUserAction = (payload: IAuthUserModel): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setAuthUser(payload));
};

export const fetchWitnessesByStatementId = (payload: number, onSuccess?: (payload: Partial<ICurrentPerson>) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    const statement = await getWitnessesByStatementId(payload);
    dispatch(setInjuredWitnesses({ witnesses: statement.data }));
    dispatch(fetchSuccess());
    onSuccess && onSuccess({ witnesses: statement.data });
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const postWitnessesApi = (
  payload: IWitness[] | Omit<IWitness, 'id'>[],
  onSuccess?: (payload: Partial<ICurrentPerson>) => void,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    const statement = await postWitnessesByStatementId(payload);
    dispatch(fetchSuccess());
    onSuccess && onSuccess({ witnesses: statement.data });
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const postDamagesApi = (
  payload: {
    description: string | undefined;
    zone: string | undefined;
    statementId: number | null;
    personId: number | null;
  },
  onSuccess?: (payload: Partial<IStatementBackResponse>) => void,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    console.log('BEFORE AWAIT POST DAMAGE: ', payload);
    const statement = await postDamages(payload);
    console.log('AFTER AWAIT POST DAMAGE: ', payload);
    dispatch(fetchSuccess());
    onSuccess && onSuccess({ injured: statement.data.injured });
  } catch (e) {
    console.log('ERROR ON POST DAMAGE: ', e);
    dispatch(fetchError(e.message));
  }
};

export const postDamagesMediaApi = (payload: FormData, type: boolean, onSuccess?: (payload: IPhotoProofs) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  try {
    const statement = await postDamagesMedia(payload, type);
    onSuccess && onSuccess(statement.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const fetchDamageByDamageId = (payload: number, onSuccess?: (payload: IStatementDamage) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    const damage = await getDamagesById(payload);
    dispatch(setDamageAction(USER_SITUATION.INJURED_STATUS, damage.data));
    dispatch(fetchSuccess());
    onSuccess && onSuccess(damage.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const putDocumentsApi = (payload: FormData, onSuccess?: (payload: Partial<IStatementBackResponse>) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    const statement = await putDocuments(payload);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(statement.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const postOcrDataApi = (
  payload: FormData,
  document: string,
  onSuccess?: (payload: any) => void,
  onErrorResponse?: () => void,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    const response = await postOcrDataCall(payload, document);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(response.data);
  } catch (e) {
    dispatch(fetchError(e.message));
    dispatch(setErrorStatement(null));
    onErrorResponse && onErrorResponse();
  }
};

export const putStatementDocumentsApi = (
  payload: FormData,
  onSuccess?: (payload: Partial<IStatementBackResponse>) => void,
  onErrorResponse?: () => void,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    const statement = await putStatementDocuments(payload);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(statement.data);
  } catch (e) {
    dispatch(fetchError(e.message));
    onErrorResponse && onErrorResponse();
  }
};

export const putPaymentDetailsApi = (payload: IPaymentDTO, onSuccess?: (payload: IPayment) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    const paymentDetails = await putPaymentDetails(payload);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(paymentDetails.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const postSignatureApi = (
  payload: FormData,
  id: number,
  onSuccess?: (payload: Partial<IStatementBackResponse>) => void,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    const statement = await postSignature(payload, id);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(statement.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const getSignatureApi = (signatureId: number, onSuccess?: (payload: string) => void): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    const signatureData = await getSignature(signatureId);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(signatureData.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const getPersonByIdApi = (personId: number, onSuccess?: (payload: IContactDetails) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    const personData = await getPersonsByPersonId(personId);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(personData.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const getStatementByIdApi = (id: number, onSuccess?: (payload: IStatementDbFormat) => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    const statement = await getStatementByIdRequest(id);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(statement.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const postUserPhoneNumber = (
  payload: { phone: string | undefined },
  onSuccess?: (payload: Partial<IAuthUserModel>) => void,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    const response = await postUserPhoneNumberApi(payload);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(response.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const getUserById = (id: number, onSuccess?: (payload: IAuthUserModel) => void): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchStart());

  try {
    const response = await getUserByIdApi(id);
    dispatch(fetchSuccess());
    onSuccess && onSuccess(response.data);
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const insuredAssignAndRespondAction = (payload: IInsuredAssign, onSuccess?: () => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    await insuredAssignAndRespond(payload);
    dispatch(fetchSuccess());

    if (onSuccess) onSuccess();
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};

export const updateStatementStatus = (payload: ICurrentStatementStatus, onSuccess?: () => void): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(fetchStart());

  try {
    await updateStatement(payload);
    dispatch(fetchSuccess());

    if (onSuccess) onSuccess();
  } catch (e) {
    dispatch(fetchError(e.message));
  }
};
