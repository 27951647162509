export const INFO_TAB = {
  utils: [
    {
      items: [
        {
          title: 'Ce fac dupa un accident?',
          list: [
            {
              title: 'PAGUBIT',
              text:
                'In primul rând asigurați-vă că toate persoanele implicate sunt în siguranță și că daunele produse autovehiculului nu pun în pericol sănătatea acestora mai departe (risc de incendiu, scurgeri, margini care pot tăia, etc). În cazul în care sunt persoane rănite, sunați la 112. <br/><br/> În cazul în care nu sunt daune și pagube în afara celor suferite de cele două autovehicule implicate, aveți posibilitatea încheierii unei constatări amiabile de către ambele părți. În cazul în care nu există un acord privind vinovăția, este necesar să contactați poliția  pentru realizarea unui proces verbal. Pregătiți toate documentele necesare. ',
            },
            {
              title: 'VINOVAT',
              text:
                'În primul rând asigurați-vă că toate persoanele implicate sunt în siguranță și că daunele produse autovehiculului nu pun în pericol sănătatea acestora mai departe (risc de incendiu, scurgeri, margini care pot tăia, etc). În cazul în care sunt persoane rănite, sunați la 112. <br/><br/> În cazul în care nu sunt daune și pagube în afara celor suferite de cele două autovehicule implicate, aveți posibilitatea încheierii unei constatări amiabile de către ambele părți, prin care sunteți de acord să vă recunoașteți vinovăția. Asistați partea păgubită cu documentele și informațiile necesare – talon, permis de conducere, buletin, poliță de asigurare, date de contact.',
            },
            {
              title: 'DOCUMENTE PAGUBIT CONSTATARE AMIABILA',
              text:
                '<ul style="list-style:disc"><li>În primul rând, verificați să aveți ITP-ul la zi.</li> <li> Asigurare RCA valabilă.</li> <li>Talon.</li> <li>Permis de conducere. </li> <li>Act de identitate șofer.</li><ul>',
            },
            {
              title: 'DOCUMENTE VINOVAT CONSTATARE AMIABILA	',
              text:
                '<ul style="list-style:disc"><li>În primul rând verificați dacă vinovatul are ITP-ul la zi.</li> <li>Polița de asigurare valabilă.</li> <li>Talonul mașinii.</li> <li>Permis de conducere. </li><li>(Opțional) Cartea de identitate. </li><li>Datele de contact ale asiguratului, dacă acesta este diferit de șofer.</li>',
            },
          ],
        },
        {
          title: 'Constatare amiabila',
          list: [
            {
              title: 'CAND SE COMPLETEAZA',
              text:
                'În cazul în care accidentul are loc între două mașini care se deplasează pe un drum public, cu ITP și asigurare RCA valabilă. Cele două părți implicate trebuie să fie de acord privind vinovăția și întocmirea acestui document, fără intervenția poliției. Dacă în cadrul accidentului sunt implicate mai mult de două vehicule, dacă sunt răniți sau au fost și alte pagube (garduri, stâlpi, etc), atunci nu se poate întocmi constatarea amiabilă și este necesar să se apeleze la 112.',
            },
            {
              title: 'CUM SE COMPLETEAZA',
              text:
                'În prezent constatarea amiabilă se completează direct pe formularul autocopiativ primit de la compania de asigurări sau brokerul de asigurare. Formularul se completează o singură dată cu pixul, cu majuscule, pe rând, de ambele părți pe aceeași filă. Hârtia fiind autocopiativă, se creeaza automat doua formulare cu valoare de original – unul rămâne la păgubit, celălalt la vinovat. Nu se completează două formulare diferite! Odată separate cele două pagini, nu mai adăugați nimic pe niciuna dintre ele.',
            },
            {
              title: 'UNDE O GASESC',
              text:
                'Ar trebui să primești un formular de la asiguratorul sau brokerul tău în momentul încheierii poliței de asigurare. Dacă ai cumpărat polița de asigurare online, poți să te adresezi oricând asiguratorului sau brokerului pentru un exemplar . În cazuri de urgență, poți ruga alt participant la trafic să îți ofere unul, dar recomandăm să te asiguri că ai un formular oricând pleci la drum.',
            },
            {
              title: 'CUM FAC SCHITA',
              text:
                'Se face o singură schiță, ideal de către o singură persoană. Indiferent dacă e realizată de vinovat sau păgubit, este extrem de important ca ambii șoferi să fie de acord cu forma sa finală. Nu este necesar talentul la desen, mașinile pot fi reprezentate simplu sub forma de dreptunghiuri semnalizate clar (A sau B), iar străzile pot fi reprezentate prin linii simple. Este important să se poată observa ușor direcția de deplasare, semnele de circulație, străzile și orice alt element relevant.',
            },
            {
              title: 'CUM COMPLETEZ MARTORII',
              text:
                'Completarea datelor martorilor nu este obligatorie. Dacă există martori, datele lor se completează în căsuța 5 (dreapta sus), însă atenție, nu se consideră martori rudele sau cunoștințele oricăruia dintre șoferi!',
            },
            {
              title: 'IN CAT TIMP SE TRIMITE LA ASIGURATOR',
              text:
                'Conform legii se trimite în 5 zile lucrătoare, dar se recomandă notificarea daunei și implicit transmiterea constatării amiabile în maximum 24 de ore de la producerea accidentului.',
            },
          ],
        },
        {
          title: 'CityInsurance',
          list: [
            {
              title: 'CE FAC DACA SUNT ASIGURAT CITY INSURANCE',
              text: `Poți alege să îți păstrezi asigurarea sau să o denunți. Dacă alegi să o păstrezi, ea rămâne valabilă încă 90 de zile de la data pronunțării hotărârii de deschidere a procedurii falimentului sau până la data expirării dacă acesta este anterioară datei pronunțării. De asemenea, polița mai poate fi denunțată de lichidatorul judiciar numit de instanță odată cu deschiderea în instanță a procedurii falimentului, dar în acest caz vei fi anunțat în prealabil pentru a putea încheia altă poliță. <br/><br/>
              În cazul în care decizi să denunți asigurarea pentru a achiziționa alta, procedura este simplă – completezi <a href="https://www.cityinsurance.ro/wp-content/uploads/2021/09/822-01-00.01F-Rev.5-Cerere-de-denuntare-30.09.2021_editabil.pdf" target="_blank"> cererea de denunțare </a> și o trimiți la <a href="mailto:denuntare@cityinsurance.ro">denuntare@cityinsurance.ro</a>. În funcție de cât de tare te grăbești, poți aștepta un răspuns, dar un print-screen cu dovada trimiterii cererii de denunțare poate fi suficient pentru pasul următor.<br/><br/>
              Pentru restituirea primei, trebuie să trimiți la adresa <a href="mailto:cerere.plata@fgaromania.ro">cerere.plata@fgaromania.ro</a> următoarele documente:<br/><br/>
              <ul style="list-style:disc"><li>Dovada trimiterii cererii de denunțare către City Insurance.</li>
              <li>Copie act identitate și poliță de asigurare</li>
              <li>În cazul în care nu ești deținătorul poliței, o împuternicire din partea acestuia</li>
              <li>Dovada plății primei în cazul polițelor achiziționate în rate</li>
              <li><a href="https://www.cityinsurance.ro/wp-content/uploads/2021/09/822-01-00.01F-Rev.5-Cerere-de-denuntare-30.09.2021_editabil.pdf" target="_blank">Această cerere </a> completată</li></ul>
              Plățile se realizează doar pentru lunile complete rămase de a denunțarea poliței, în ordinea sosirii cererilor. Ținând cont de numărul mare de denunțări, este posibil să aștepți puțin până la transferul banilor.
              <br/><br/>Polița de asigurare rămâne valabilă încă 20 de zile de la data denunțării, dar recomandăm încheierea unei noi polițe cu altă companie de asigurare cât de repede. Este în regulă dacă ai două polițe valabile în acest timp. Ținând cont de posibilele întârzieri, cel mai important aspect este să nu rămâi neasigurat, atât pentru siguranța ta cât și a celorlalți participanți la trafic.
              `,
            },
            {
              title: 'PROCEDURA DESPAGUBIRE FGA',
              text: `Odată cu retragerea autorizației de funcționare a companiei City Insurance, cazurile de despăgubire sunt preluate de Fondul de Garantare a Asiguraților. Procedura este farte similară cu cea de despăgubire direct de către asigurator.
  <br/><br/>În primul rând, asigură-te că ai colectat toate documentele necesare la locul accidentului – proces verbal sau constatare amiabilă, fotografii detaliate ale vehiculului și daunei, copia poliței de asigurare a părții vinovate (ideal și o fotografie a permisului de conducere a acestuia), talonul vehiculului avariat, permisul personal de conducere, actul de identitate, precum și împuternicirea sau procura dacă nu ești titularul asigurării (mașina este a altei persoane sau a unei persoane juridice).
  <br/><br/>Având toate aceste documente la îndemână, completează <a href="https://www.fgaromania.ro/wp-content/uploads/2021/10/ANEXA-nr7.pdf" target="_blank">Anexa 7</a>, cerere de deschidere a dosarului de daună și trimite copia scanată la această adresă <a href="mailto:constatari.daune@fgaromania.ro">constatari.daune@fgaromania.ro</a> împreună cu documentele necesare menționate în Anexă. Ca alternativă, se poate completa direct online acest <a href="https://www.fgaromania.ro/formular-daune/" target="_blank">formular</a>.
  <br/><br/>În urma trimiterii informației, urmează să fii contactat direct de FGA. Până atunci, completează <a href="https://www.fgaromania.ro/wp-content/uploads/2021/09/Anexa-8-1-2.pdf" target="_blank">Anexa 8 – Declarație privind evenimentul</a>.
  <br/><br/>Constatarea daunei se va face de către un expert FGA – încercați să nu mai deplasați și să nu modificați în niciun fel autovehiculul până în acest punct, pentru a te asigura că daunele sunt evaluate și identificate corect pentru despăgubire.
  <br/><br/>În cazul în care dorești o reconstatare, cererea ta sau a unității reparatoare se trimite tot la <a href="mailto:constatari.daune@fgaromania.ro">constatari.daune@fgaromania.ro</a>
  <br/><br/>Odată deschis dosarul și evaluată dauna, ultimul pas este să trimiți <a href="https://www.fgaromania.ro/wp-content/uploads/2021/09/anexa-10-1.pdf" target="_blank">Anexa 10  – cerere de plată</a>.
  <br/><br/>Pentru mai multe informații și date de contact, poți accesa <a href="https://www.fgaromania.ro/demersuri-privind-deschiderea-dosarului-de-dauna/" target="_blank">site-ul FGA</a>.
              `,
            },
          ],
        },
      ],
    },
  ],
  legislation: [
    {
      items: [
        {
          title: 'Ordonanţa de urgenţă nr. 189/2005',
          text:
            '<a href="https://asfromania.ro/uploads/articole/attachments/60783e86c7f80938287115.pdf" target="_blank">Ordonanţa de urgenţă nr. 189/2005</a> pentru stabilirea unor măsuri privind vehiculele rutiere înmatriculate',
        },
        {
          title: 'Legea nr. 237/2015',
          text:
            '<a href="https://asfromania.ro/uploads/articole/attachments/60783ed4105a9486533449.pdf" target="_blank">Legea nr. 237/2015</a> privind autorizarea şi supravegherea activităţii de asigurare şi reasigurare, modificată prin <a href="https://asfromania.ro/uploads/articole/attachments/6076e0bc2eccd020752446.pdf" target="_blank">L 236/2018</a>, <a href="https://asfromania.ro/uploads/articole/attachments/6076e23d60532770051915.pdf" target="_blank">OUG 111/2020</a> si <a href="https://asfromania.ro/uploads/articole/attachments/6076e38355c4d351270437.pdf" target="_blank">L 158/2020</a>',
        },
        {
          title: 'Legea nr. 132/2017',
          text:
            '<a href="https://asfromania.ro/uploads/articole/attachments/60783dd282565278623817.pdf" target="_blank">Legea nr. 132/2017</a> privind asigurarea obligatorie de răspundere civilă auto pentru prejudicii produse terţilor prin accidente de vehicule şi tramvaie',
        },
        {
          title: 'Norma ASF nr. 20/2017',
          text:
            '<a href="https://asfromania.ro/uploads/articole/attachments/60782e77d3c1f489847957.pdf" target="_blank">Norma ASF nr. 20/2017</a> privind asigurările auto din România modificată prin <a href="https://asfromania.ro/uploads/articole/attachments/6077d619dcac6844007660.pdf" target="_blank">Norma ASF nr. 7/2018</a> si <a href="https://asfromania.ro/uploads/articole/attachments/60783406436c3456121092.pdf" target="_blank">Norma ASF nr. 36/2020</a>',
        },
        {
          title: 'Norma ASF nr. 22/2017',
          text:
            '<a href="https://asfromania.ro/uploads/articole/attachments/607842553f32b816763662.pdf" target="_blank">Norma ASF nr. 22/2017</a> privind metodologia de calcul al tarifelor de referinţă pentru asigurarea obligatorie de răspundere civilă auto pentru prejudicii produse terţilor prin accidente de vehicule şi de tramvaie',
        },
      ],
    },
  ],
};
