import React from 'react';

import { IIconProps } from './icon.model';

export const InfoIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 23} viewBox='0 0 9 23' width={width || 9} {...otherProps}>
      <path
        d='M3.738 4.884c-.672 0-1.204-.196-1.596-.588-.392-.392-.588-.896-.588-1.512 0-.71.224-1.297.672-1.764.448-.467 1.046-.7 1.792-.7.747 0 1.316.205 1.708.616.392.392.588.887.588 1.484 0 .747-.224 1.344-.672 1.792-.448.448-1.073.672-1.876.672h-.028zm-1.484 5.208L.266 9.56V7.684l5.124-.616h.056l.784.56v13.468l1.876.224V23H.294v-1.68l1.96-.252V10.092z'
        fill={fill || '#6c5ce7'}
      />
    </svg>
  );
};
