import React from 'react';

import { Typography } from '../index';
import { HOME_TEXTS } from '../../translations/ro/home';

import { StyledTextArea } from './textarea.styled';
import { ITextAreaProps } from './types';

export const TextArea = (props: ITextAreaProps): JSX.Element => {
  return (
    <>
      <StyledTextArea {...props} />
      {props.error && (
        <Typography align='left' color='#C54554' commonStyles={{ margin: '10px', width: '100%' }} fontStyle='italic' variant='caption'>
          {HOME_TEXTS.describeDamageError}
        </Typography>
      )}
    </>
  );
};
