import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { putPaymentDetailsApi, setPaymentDetailsAction } from '../store/statement/currentStatement.action';
import { IPayment, IPaymentDTO } from '../@types/current-statement/currentStatementTypes';

const usePaymentDetails = () => {
  const dispatch = useDispatch();
  const setPaymentDetails = useCallback(
    (newData: IPayment) => {
      dispatch(setPaymentDetailsAction(newData));
    },
    [dispatch],
  );

  const putPaymentDetails = useCallback(
    (payload: IPaymentDTO, onSuccess?: (payload: IPayment) => void) => {
      dispatch(putPaymentDetailsApi(payload, onSuccess));
    },
    [dispatch],
  );

  return { setPaymentDetails, putPaymentDetails };
};

export { usePaymentDetails };
