import React from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowLeftIcon, LogoPayPact, MenuIcon } from '../../assets/icons';
import { Container, ContainerFixed, Typography, Wrapper } from '../../styled-components';
import { Button } from '../button';

import { ITab } from './TabsNav';

type IHeaderIconButton = 'back' | 'menu';
type ITitleSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface INavCreateProps {
  title?: string;
  titleColor?: string;
  titleSize?: ITitleSize;
  titleWeight?: string;
  iconButton?: IHeaderIconButton; //icon inside nav button
  arrowLeftColor?: string;
  backUrl?: string;
  showBack?: boolean;
  showMenu?: boolean;
  hideLogo?: boolean;
  showSaveLink?: boolean;
  saveAction?: () => void;
  customParentBack?: boolean;
  onCustomParentBack?: () => void;
  bannerBackground?: string;
  background?: string;
  backgroundColor?: string;
  boxShadow?: string;
  showButton?: boolean;
  style?: React.CSSProperties;
  tabs?: ITab[];
  onTabClick?: (tabNumber: number) => void;
  selectedTab?: number;
  heightTab?: string;
  borderRadiusTab?: string;
  activeTabBgColor?: string;
  activeTabColor?: string;
  tabColor?: string;
  tabsContainerPadding?: string;
  fontSize?: string;
}

interface IHeaderIconButtonProps {
  type: INavCreateProps['iconButton'];
  stroke: string;
}

const HeaderIconButton = (props: IHeaderIconButtonProps): JSX.Element | null => {
  const { type, stroke } = props;

  switch (type) {
    case 'back':
      return <ArrowLeftIcon fill={stroke || '#3F414E'} width='17px' />;
    case 'menu':
      return <MenuIcon />;
    default:
      return null;
  }
};

export const PageTopNav = (props: INavCreateProps): JSX.Element => {
  const history = useHistory();
  const goBack = () => {
    props.backUrl ? history.push(props.backUrl) : history.goBack();
  };

  return (
    <Wrapper
      alignItems='center'
      backgroundColor={props.backgroundColor || '#F0F2FC'}
      height='77px'
      justifyContent='space-between'
      padding='0 0 0.2rem 0'
      position='relative'
      style={props.style}
    >
      <Wrapper backgroundColor='#FFFFFF' borderRadius='0 0 26px 26px' boxShadow='0px 0px 5px lightgrey' height='100%' width='100%'>
        {props.showButton && (
          <Container
            alignItems='center'
            backgroundColor='#E6E7F2'
            borderRadius='38px'
            display='flex'
            height='50px'
            justifyContent='center'
            margin='0 0 0 10px'
            width='50px'
            onClick={props.customParentBack ? () => props.onCustomParentBack && props.onCustomParentBack() : () => goBack()}
          >
            <Button display='flex' mode='link'>
              <HeaderIconButton stroke={props.arrowLeftColor || '#3F414E'} type={props.iconButton} />
            </Button>
          </Container>
        )}

        <Wrapper backgroundColor='transparent' height='100%' justifyContent='center' padding='0' width='100%'>
          {!props.hideLogo && <LogoPayPact />}
          <Typography
            align='center'
            color={props.titleColor || '#512AD8'}
            commonStyles={{ margin: props.showButton ? '0 50px 0 0' : '0' }}
            fontWeight={props.titleWeight || '700'}
            variant={props.titleSize || 'h5'}
          >
            {props.title}
          </Typography>
        </Wrapper>

        <ContainerFixed backgroundColor='transparent' position='absolute' right='0' width='auto' onClick={props.saveAction}>
          {props.showSaveLink && (
            <Button color='#fff' display='flex' mode='link' textTransform='uppercase'>
              Salveaza
            </Button>
          )}
        </ContainerFixed>
      </Wrapper>
    </Wrapper>
  );
};

PageTopNav.defaultProps = {
  showButton: true,
  iconButton: 'back',
};
