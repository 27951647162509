import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { postOcrDataApi, putDocumentsApi, putStatementDocumentsApi } from '../store/statement/currentStatement.action';
import { IStatementBackResponse } from '../@types/current-statement/currentStatementTypes';

export interface ITempDocumentsData {
  title?: string;
  defaultPicture?: string;
  formType?: string;
  flowHandle?: string;
}

const useDocumentsTempData = () => {
  const setTempDocumentsDataFromMainScreen = (tempData: ITempDocumentsData) => {
    localStorage.setItem('tempDocumentsData', JSON.stringify(tempData));
  };

  const documentsData = localStorage.getItem('tempDocumentsData');
  const dispatch = useDispatch();

  const convertJSON = () => {
    if (documentsData !== null && documentsData !== undefined) return JSON.parse(documentsData);

    return {
      title: '',
      defaultPicture: '',
      formType: '',
      flowHandle: '',
    };
  };

  const putDocuments = useCallback(
    (payload: FormData, onSuccess?: (data: Partial<IStatementBackResponse>) => void) => {
      dispatch(putDocumentsApi(payload, onSuccess));
    },
    [dispatch],
  );

  const postOcrData = useCallback(
    (payload: FormData, document: string, onSuccess?: (response: any) => void, onErrorResponse?: () => void) => {
      dispatch(postOcrDataApi(payload, document, onSuccess, onErrorResponse));
    },
    [dispatch],
  );

  const putStatementDocuments = useCallback(
    (payload: FormData, onSuccess?: (data: Partial<IStatementBackResponse>) => void, onErrorResponse?: () => void) => {
      console.log('hook log');

      dispatch(putStatementDocumentsApi(payload, onSuccess, onErrorResponse));
    },
    [dispatch],
  );

  const { title, defaultPicture, formType, flowHandle } = convertJSON();

  return {
    getTempDocumentsDataFromMainScreen: setTempDocumentsDataFromMainScreen,
    title,
    defaultPicture,
    formType,
    flowHandle,
    putDocuments,
    putStatementDocuments,
    postOcrData,
  };
};

export { useDocumentsTempData };
