import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../styled-components';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Button } from '../../components/button';
import { Wrapper } from '../../styled-components';
import { TextField } from '../../components/text_field';
import { PHONE_REGEX } from '../../shared/constants';

export const AscertainmentInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string().matches(new RegExp(PHONE_REGEX)).required(),
    }),
    onSubmit: values => {
      console.log(values);
    },
  });
  const sendLinkModal = (
    <>
      <Wrapper alignItems='center' display='flex' flexDirection='column' height='calc(100% - 70px)' justifyContent='flex-start'>
        <Typography commonStyles={{ margin: '15px 0 15px 0' }} fontWeight='700' variant='h4'>
          {t('contactDetails.verifyNumberGuilty')}
        </Typography>
        <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
          <TextField
            error={!!formik.errors.phone}
            errorMessage={formik.errors.phone}
            name='phone'
            value={formik.values.phone}
            variant='rounded'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </form>
      </Wrapper>

      <Wrapper display='flex' flexDirection='column' justifyContent='flex-start'>
        <Button mode='primary' onClick={() => formik.submitForm()}>
          {t('contactDetails.sendLink')}
        </Button>
      </Wrapper>
    </>
  );

  return (
    <>
      <ScreenContainer
        bodyProps={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}
        headerProps={{
          title: t('contactDetails.contact'),
          hideLogo: true,
          titleSize: 'h5',
        }}
        isModalOpen={showModal}
        modal={sendLinkModal}
        modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
      >
        <Typography fontWeight='700' variant='h4'>
          {t('contactDetails.whoCompleteAscertainment')}
        </Typography>

        <Wrapper display='flex' flexDirection='column' width='100%'>
          <Button background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)' border='none' borderRadius='25px' color='#FFFF'>
            {t('contactDetails.injured')}
          </Button>
          <Button margin='15px 0 0 0' mode='link' onClick={() => setShowModal(true)}>
            <Typography color='#512AD8' fontWeight='700' variant='h5'>
              {t('contactDetails.guiltyLink')}
            </Typography>
          </Button>
        </Wrapper>
      </ScreenContainer>
    </>
  );
};
