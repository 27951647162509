import { useTranslation } from 'react-i18next';

import { useStepsStatusFunctions } from '../../hooks/useStepsStatusFunctions';
import { CARD_TYPE, StatementOrEvaluationStep, STATUS_CARD } from '../../@types/statement-evaluation-cards/statementOrEvaluationCardsTypes';
import { PATHS, PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { STATEMENT_TYPES } from '../../store/types';

const useProgressEvaluationAndStatement = () => {
  const {
    isEvaluationDocumentsReady,
    STEPS_CONSTANTS,
    handleDamage,
    handleCircumstances,
    handleWitnesses,
    handleGuiltyDocuments,
    handleIdentityCheck,
  } = useStepsStatusFunctions();
  const { t } = useTranslation();
  const handleStatusUpdateReusableReturn = (stepType: boolean) => {
    return !stepType ? STATUS_CARD.COMPLETE : STATUS_CARD.INCOMPLETE;
  };

  const handleStepsStatus = (label: string): STATUS_CARD => {
    switch (label) {
      case STEPS_CONSTANTS.DOCUMENTS:
        return isEvaluationDocumentsReady() ? STATUS_CARD.COMPLETE : STATUS_CARD.INCOMPLETE;
      case STEPS_CONSTANTS.DAMAGE:
        return handleStatusUpdateReusableReturn(!handleDamage());
      case STEPS_CONSTANTS.GUILTY_DOCUMENTS:
        return handleStatusUpdateReusableReturn(!handleGuiltyDocuments());
      case STEPS_CONSTANTS.CIRCUMSTANCES:
        return handleStatusUpdateReusableReturn(!handleCircumstances());
      case STEPS_CONSTANTS.WITNESSES:
        return handleStatusUpdateReusableReturn(!handleWitnesses());
      case STEPS_CONSTANTS.CHECK_IDENTITY:
        return handleStatusUpdateReusableReturn(!handleIdentityCheck());

      default:
        return STATUS_CARD.INCOMPLETE;
    }
  };

  const EVALUATION_STEPS: StatementOrEvaluationStep[] = [
    {
      id: 1,
      status: handleStepsStatus(t('statements.documents')),
      title: t('statements.documents'),
      type: CARD_TYPE.INJURED_DOCUMENTS,
      navigateTo: {
        new: PATHS.chooseDocuments,
        list: '/documents/list',
      },
    },
    {
      id: 2,
      status: handleStepsStatus(t('statements.damage')),
      title: t('statements.damage'),
      type: CARD_TYPE.DAMAGE,
      navigateTo: {
        new: '/damage',
        list: '/damage/list',
      },
    },
    {
      id: 3,
      status: handleStepsStatus(t('statements.circumstances')),
      title: t('statements.circumstances'),
      type: CARD_TYPE.CIRCUMSTANCES,
      navigateTo: {
        new: '/circumstances',
        list: '/circumstances/list',
      },
    },
    {
      id: 4,
      status: handleStepsStatus(t('statements.witnesses')),
      title: t('statements.witnesses'),
      type: CARD_TYPE.WITNESSES,
      navigateTo: {
        new: '/witnesses',
        list: '/witnesses/list',
      },
    },
    {
      id: 5,
      status: handleStepsStatus(t('statements.guiltyDocuments')),
      title: t('statements.guiltyDocuments'),
      type: CARD_TYPE.GUILTY_DOCUMENTS,
      navigateTo: {
        new: '/documents',
        list: '/documents/list',
      },
    },
    {
      id: 6,
      status: handleStepsStatus(t('statements.checkIdentity')),
      title: t('statements.checkIdentity'),
      type: CARD_TYPE.CHECK_IDENTITY,
      navigateTo: {
        new: `${PROTECTED_ROUTES.faceRecognition}`,
      },
    },
  ];

  const getStepsByType = (type: STATEMENT_TYPES) => {
    const stepsByType = {
      [STATEMENT_TYPES.NOTIFICATION]: [EVALUATION_STEPS[0], EVALUATION_STEPS[2], EVALUATION_STEPS[1]],
      [STATEMENT_TYPES.EVALUATION]: [EVALUATION_STEPS[0], EVALUATION_STEPS[1]],
      [STATEMENT_TYPES.STATEMENT]: [...EVALUATION_STEPS],
    };

    return stepsByType[type];
  };

  return { EVALUATION_STEPS, getStepsByType };
};

export { useProgressEvaluationAndStatement };
