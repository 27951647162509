import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardRow, ElevatedCard, StyledCard } from '../../styled-components/cards/ElevatedCard';
import { Wrapper } from '../../styled-components';
import { Button } from '../button';
import { useCardManagement } from '../../hooks/useCardManagement';
import { EVALUATION_STATUS, NOTIFICATION_STATUS, STATEMENT_STATUS } from '../../constants/typeConstants/statementStatusType';

import { CardsCommonContent } from './CardsCommonContent';

interface PropsI {
  currentPlace: number | null;
  onContinue: () => void;
  createdAt: string;
  insuranceNo?: string | null;
  registrationNumber?: string | null;
  status: STATEMENT_STATUS | EVALUATION_STATUS | NOTIFICATION_STATUS;
}

export const StatementCardTemplate = ({
  registrationNumber,
  insuranceNo,
  currentPlace,
  onContinue,
  createdAt,
  status,
}: PropsI): JSX.Element => {
  const { t } = useTranslation();
  const { getLabel, IconStatus, getMainColor } = useCardManagement(status);

  return (
    <>
      <StyledCard style={{ padding: 0 }}>
        <Wrapper height='62px' style={{ borderTopLeftRadius: '18px', borderTopRightRadius: '18px' }} width='100%'>
          <CardRow>
            <ElevatedCard color='black' header={t('statementStatus.statement')} label={`${currentPlace}`} />

            <span>{IconStatus()}</span>
            <span style={{ color: getMainColor(), padding: '15px', whiteSpace: 'nowrap' }}>{getLabel()}</span>
          </CardRow>
        </Wrapper>
        <Wrapper style={{ padding: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
          <CardsCommonContent createdAt={createdAt} insuranceNo={insuranceNo} registrationNumber={registrationNumber} />
          <Button mode='primary' onClick={onContinue}>
            {t('commonText.continue')}
          </Button>
        </Wrapper>
      </StyledCard>
    </>
  );
};
