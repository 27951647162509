export const $fontSizeXS = '0.75rem'; //12px
export const $fontSizeS = '0.875rem'; // 14px
export const $fontSizeMS = '1rem'; // 16px
export const $fontSizeM = '1.125rem'; // 18px
export const $fontSizeL = '1.25rem'; // 20px
export const $fontSizeXL = '1.5rem'; // 24px
export const $fontSizeXXL = '1.875rem'; // 30px

export const $fontWeightLight = '300';
export const $fontWeightBook = '400';
export const $fontWeightMedium = '500';
export const $fontWeightBase = '600';
export const $fontWeightBold = '700';

export const $lineHeightS = '1.063rem'; //17px
export const $lineHeightM = '1.125rem'; //18px
export const $lineHeightL = '1.5rem'; // 24px
export const $lineHeightXL = '1.875rem'; // 30px

// Colors
export const $darkRed = '#DB4437';
