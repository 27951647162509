import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICurrentStatement } from '../../@types/current-statement/currentStatementTypes';

export type IFilter = 'all' | 'inProgress' | 'finished';

type IStatements = {
  data: ICurrentStatement[];
  isLoading: boolean;
  error: string | null;
  filter: IFilter;
};

const initialState: IStatements = {
  data: [],
  isLoading: false,
  error: null,
  filter: 'all',
};

const statementInsuranceSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    setStatement(state: IStatements, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    setFilter(state: IStatements, action: PayloadAction<IFilter>) {
      state.filter = action.payload;
    },
    addStatement(state: IStatements, action: PayloadAction<ICurrentStatement>) {
      state.data.push(action.payload);
    },
    getStatements(state: IStatements): void {
      state.data = [];
      localStorage.setItem('statements', JSON.stringify([...state.data]));
    },
  },
});

export default statementInsuranceSlice.reducer;

export const { getStatements, setStatement, addStatement, setFilter } = statementInsuranceSlice.actions;
