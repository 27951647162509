import styled, { css } from 'styled-components';

import { IModalProps } from './types';

const useStyles = () => ({
  closeButton: {
    backgroundColor: '#e6e7f2',
    width: 35,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    padding: 0,
    minHeight: 35,
  },
});

const StyledModal = styled.div<IModalProps>`
  height: ${({ height }) => height || '90%'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  margin: ${({ margin }) => margin || '0 20px'};
  border: ${({ border }) => border || 'unset'};
  position: ${({ position }) => position || 'absolute'};
  left: ${({ left }) => left || '0'};
  right: ${({ right }) => right || '0'};
  z-index: 20;
  padding: 16px;
  box-shadow: 0px 2px 15px rgba(134, 112, 251, 0.2);
  border-radius: 18px;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};
`;

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

export { useStyles, StyledModalHeader, StyledModal };
