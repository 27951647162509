import React from 'react';
import styled from 'styled-components';

import { Button } from '../../components/button';
import { DeleteIcon, PencilIcon } from '../../assets/icons';
import { IWitness } from '../../@types/witnesses/witnessesTypes';
import { Wrapper } from '../containers';

import { StyledCard, ColProperty, ColValue } from './ElevatedCard';

export interface ICardProps {
  fields: IWitness;
  onEdit?: () => void;
  onDelete?: () => void;
  showCrudButton?: boolean;
  icon?: JSX.Element;
}

const CardTitle = styled.h6`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #1e1e29;
  margin: 0;
`;

interface ICardRowProps {
  margin?: string;
}

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }: ICardRowProps) => margin || '0'}; ;
`;

export const EditableCard: React.FC<ICardProps> = ({ fields, onEdit, onDelete, showCrudButton, icon }) => {
  return (
    <StyledCard>
      <CardRow margin='0 0 10px'>
        <CardTitle>{fields.name}</CardTitle>
      </CardRow>
      <CardRow>
        <ColProperty>{fields.address}</ColProperty>
      </CardRow>
      <CardRow>
        <ColProperty>{fields.phone}</ColProperty>
      </CardRow>
      <Wrapper style={{ display: 'flex', justifyContent: 'space-between', marginTop: showCrudButton ? '-10px' : '0' }}>
        <CardRow>
          <ColProperty>{fields.email}</ColProperty>
        </CardRow>
        {showCrudButton && (
          <ColValue>
            <Button mode='link' onClick={onEdit}>
              {icon || <PencilIcon />}
            </Button>
            <Button mode='link' onClick={onDelete}>
              {icon || <DeleteIcon />}
            </Button>
          </ColValue>
        )}
      </Wrapper>
    </StyledCard>
  );
};
