import React from 'react';

import { IIconProps } from './icon.model';

export const Close = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg height={height || 17.545} width={width || 16.546} {...otherProps}>
      <path
        d='M9.187 8.773l7.169-7.6a.715.715 0 000-.969.621.621 0 00-.914 0L8.273 7.799 1.1.199a.621.621 0 00-.911 0 .715.715 0 000 .969l7.169 7.6-7.169 7.6a.715.715 0 000 .969.621.621 0 00.914 0l7.169-7.6 7.169 7.6a.621.621 0 00.914 0 .715.715 0 000-.969z'
        fill={fill || '#B6B6B6'}
        stroke={fill || '#B6B6B6'}
      />
    </svg>
  );
};
