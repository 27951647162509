import React from 'react';

export const style: { [key: string]: React.CSSProperties } = {
  modalWrapper: {
    width: '100%',
    opacity: '1',
    top: '0',
    bottom: '0',
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    zIndex: 999,
  },
};
