import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../../styled-components';
import { Typography } from '../../styled-components';
import { Button } from '../../components/button';
import { PreLoginInsuranceData } from '../../@types/pre-login/preLoginTypes';
import { USER_SITUATION } from '../../services/router/routeHandles';

export interface PropsI {
  handleDirectSettlement: (modalAnswer: boolean, insurance: PreLoginInsuranceData) => void;
  insurance: PreLoginInsuranceData;
  setFlowHandle: (flowHandle: USER_SITUATION) => void;
}

export const DirectSettlement = ({ handleDirectSettlement, insurance, setFlowHandle }: PropsI) => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper display='flex' flexDirection='column' height='100%' width='100%'>
        <Wrapper alignItems='center' display='flex' justifyContent='flex-start'>
          <Typography align='left' commonStyles={{ padding: '15px' }} fontWeight='700' variant='h4'>
            {t('chooseInsurance.doYouHaveDirectSettlement')}
          </Typography>
        </Wrapper>

        <Wrapper display='flex' flexDirection='column' justifyContent='flex-end' width='100%'>
          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            borderRadius='25px'
            color='#FFFFFF'
            width='100%'
            onClick={() => {
              setFlowHandle(USER_SITUATION.GUILTY_STATUS);
              handleDirectSettlement(false, insurance);
            }}
          >
            {t('commonText.no')}
          </Button>

          <Button
            margin='20px 0'
            mode='link'
            onClick={() => {
              setFlowHandle(USER_SITUATION.GUILTY_STATUS);
              handleDirectSettlement(true, insurance);
            }}
          >
            <Typography color='#512AD8' variant='h5'>
              {t('commonText.yes')}
            </Typography>
          </Button>
        </Wrapper>
      </Wrapper>
    </>
  );
};
