import { IPreLogin, PreLoginInsuranceData } from '../../@types/pre-login/preLoginTypes';
import { MYSELF } from '../../services/router/routeHandles';

export const preLoginInsuranceInitialState: PreLoginInsuranceData = {
  insurerID: null,
  insurerName: null,
  image: '',
  available: null,
};

export const preLoginInitialState: IPreLogin = {
  carOwner: null,
  insurances: {
    injuredInsurance: preLoginInsuranceInitialState,
    guiltyInsurance: preLoginInsuranceInitialState,
  },
  contactData: {
    email: '',
    phone: '',
    type: MYSELF,
  },
  damageNotification: null,
};

/*

export const questionsFlowHandleInitialState: QuestionsFlowHandler = {
  compensationOffer: null,
  completedDocumentsAfterAccident: null,
  damageTypeQuestion: '',
  observationNote: null,
};
*/
