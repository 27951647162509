import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled, { css } from 'styled-components';

import { InputLabel } from '../text_field';
import { Image } from '../../styled-components';
import ExclamationSvg from '../../assets/svgs/exclamation-mark.svg';
import { Wrapper } from '../../styled-components';

const SelectInputCss = styled.div`
  & {
    margin: 5px 0;
  }
  .MuiFormControl-root {
    width: 100%;
    border: 0;
    .MuiInputBase-input {
      padding: 0;
    }
  }
  .MuiAutocomplete-root,
  .MuiSelect-select:focus {
    padding: 6px 16px;
    width: 100%;
    background-color: white;
    border-radius: 25px;
    border: ${(props: any) =>
      props.errorMessage ? `1px solid ${props.theme.palette.red.dark}` : props.border ? props.border : '1px solid #dcdcdc'};
    ${({ margin }: any) =>
      margin &&
      css`
			margin ${margin};
		`}
  }
  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
`;

export const AutocompleteInput = ({ data, label, value, ...restProps }: any): JSX.Element => {
  return (
    <SelectInputCss {...restProps}>
      <InputLabel>{label}</InputLabel>
      <FormControl style={{ marginTop: '5px' }}>
        <Autocomplete
          {...restProps}
          defaultValue={value}
          getOptionLabel={(option: any) => option.name}
          options={data}
          renderInput={params => <TextField {...params} />}
          autoHighlight
        />
        {restProps.errorMessage && (
          <Wrapper
            alignItems='center'
            background='#DB4437'
            borderRadius='23px'
            display='flex'
            height='24px'
            justifyContent='center'
            position='absolute'
            right='12px'
            top='10px'
            width='24px'
          >
            <Image src={ExclamationSvg} />
          </Wrapper>
        )}
      </FormControl>
    </SelectInputCss>
  );
};
