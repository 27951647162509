import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import { IScreenComponentProps } from '../component-props.model';
import { Image, Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { setCurrentStatementData } from '../../store/statement/statement.slice';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { TextArea } from '../../styled-components/textarea';
import ExclamationSvg from '../../assets/svgs/exclamation-mark.svg';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { useStatements } from '../../hooks/useStatements';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { ConfirmNavigationModal } from '../../components/confirm-navigation-modal';
import { CoordsSetI } from '../../@types/damage/damageType';
import { useDamages } from '../../hooks/useDamages';

export const DamageDescription = ({ nextRoute, history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentStatement } = useCurrentStatement();
  const [description, setDescription] = useState<string>('');
  const { currentStatementId } = useParams<{ currentStatementId: string | undefined }>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { statementsState } = useStatements();
  const { postDamages } = useDamages();

  useEffect(() => {
    if (currentStatementId) {
      statementsState.forEach(statement => {
        if (
          statement?.injured?.damage?.photoProofs &&
          statement.statementID === Number(currentStatementId) &&
          statement?.injured?.damage?.photoProofs.length >= 10
        ) {
          return history.push(generatePath(`${PROTECTED_ROUTES.evaluations}`));
        }
      });
    }

    if (currentStatement?.injured.damage && currentStatement?.injured.damage?.description) {
      const { description } = currentStatement?.injured.damage;
      setDescription(description);
    }
  }, []);

  const getParts = (damageParts: any) => {
    if (damageParts) {
      const partsArray: string[] = [];

      const left: CoordsSetI[] = damageParts.leftSide;
      const right: CoordsSetI[] = damageParts.rightSide;

      if (left && left.length > 0) {
        left.forEach(index => {
          if (index.part != null) {
            partsArray.push(index.part);
          }
        });
      }

      if (right && right.length > 0) {
        right.forEach(index => {
          if (index.part != null) {
            partsArray.push(index.part);
          }
        });
      }

      return partsArray;
    }
  };

  const onContinue = (): void => {
    const updateStatement = {
      ...currentStatement,
      injured: { ...currentStatement?.injured, damage: { ...currentStatement?.injured.damage, description } },
    };

    console.log('Update statement: ', updateStatement);

    const dataToSend: {
      description: string | undefined;
      zone: string | undefined;
      statementId: number | null;
      personId: number | null;
    } = {
      description: updateStatement.injured.damage.description,
      zone: JSON.stringify(getParts(updateStatement.injured.damage.zone)),
      statementId: currentStatement.statementID,
      personId: currentStatement.injured.id,
    };
    console.log('Before post damages: ', dataToSend);
    postDamages(dataToSend);
    console.log('After post damages: ', dataToSend);

    console.log('Before dispatch: ', updateStatement);
    dispatch(setCurrentStatementData(updateStatement));
    console.log('After dispatch: ', updateStatement);

    if (currentStatement.statementID !== null) {
      console.log('Change route: ', currentStatement);
      history.push(generatePath(nextRoute.toString(), { currentStatementId: currentStatement.statementID }));
    }
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <ScreenContainer
      headerProps={{
        title: t('carDamage.title'),
        hideLogo: true,
        customParentBack: true,
        onCustomParentBack: () => {
          if (description.length > 20) setShowModal(true);
          else {
            history.goBack();
          }
        },
      }}
      isModalOpen={showModal}
      modal={<ConfirmNavigationModal history={history} setShowModal={setShowModal} />}
      modalProps={{ show: showModal, onClose: () => setShowModal(false) }}
    >
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {t('carDamage.mentionAllVisibleDamage')}
      </Typography>
      <div style={{ position: 'relative', width: '100%' }}>
        {description.length < 20 && (
          <div style={{ position: 'absolute', bottom: 65, right: 12 }}>
            <Wrapper
              alignItems='center'
              backgroundColor='#DB4437'
              borderRadius='23px'
              display='flex'
              height='24px'
              justifyContent='center'
              width='24px'
            >
              <Image src={ExclamationSvg} />
            </Wrapper>
          </div>
        )}
        <TextArea
          borderColor={description.length < 20 ? '#C54554' : '#E7E7E7'}
          defaultValue={description}
          error={description.length < 20}
          name='damageDescription'
          placeholder={t('carDamage.describeDamage')}
          rows={7}
          onChange={onChangeDescription}
        />
      </div>
      <Button disabled={description.length < 20} margin='auto 0 0 0' mode='primary' onClick={onContinue}>
        {t('commonText.continue')}
      </Button>
    </ScreenContainer>
  );
};
