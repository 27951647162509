import React from 'react';
import styled, { css } from 'styled-components';
import { DefaultTheme } from 'styled-components';

export interface IParagraphProps {
  width?: string;
  maxWidth?: string;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
  textAlign?: string;
  margin?: string;
  padding?: string;
  textTransform?: string;
  fontWeight?: string;
  lineHeight?: string;
  className?: string;
  children: React.ReactNode;
}

interface IThemedParagraph extends IParagraphProps {
  theme: DefaultTheme;
}

const StyledParahraph: any = styled.p`
  padding: ${({ padding }: IParagraphProps) => padding || '0'};
  width: ${({ width }: IParagraphProps) => width || '100%'};
  margin: ${({ margin }: IParagraphProps) => margin || '0'};
  font-size: ${({ fontSize, theme }: IThemedParagraph) => fontSize || theme.typography.textFontSize};
  font-family: ${({ fontFamily, theme }: IThemedParagraph) => fontFamily || theme.typography.fontFamily};
  color: ${({ color, theme }: IThemedParagraph) => color || theme.typography.textPrimaryColor};
  text-align: ${({ textAlign }: IParagraphProps) => textAlign || 'left'};
  font-weight: ${({ fontWeight }: IParagraphProps) => fontWeight || 'normal'};
  line-height: ${({ lineHeight }: IParagraphProps) => lineHeight || '19.5px'};
  ${({ padding }: IThemedParagraph) =>
    padding &&
    css`
      padding: ${padding};
    `}
  ${({ textTransform }: IThemedParagraph) =>
    textTransform &&
    css`
      text-transform: ${textTransform};
    `}
`;

export const Paragraph = (props: IParagraphProps): JSX.Element => {
  return <StyledParahraph {...props}> {props.children} </StyledParahraph>;
};

const StyledTitle: any = styled(StyledParahraph)`
  color: ${({ color, theme }: IThemedParagraph) => color || theme.typography.titleColor};
  font-family: Montserrat, sans-serif;
  font-size: ${({ fontSize, theme }: IThemedParagraph) => fontSize || theme.typography.title};
  line-height: ${({ lineHeight }: IParagraphProps) => lineHeight || '24.38px'};
  text-transform: ${({ textTransform }: IParagraphProps) => textTransform || 'uppercase'};
  text-align: ${({ textAlign }: IParagraphProps) => textAlign || 'center'};
  font-weight: ${({ fontWeight }: IParagraphProps) => fontWeight || '600'};
  ${({ maxWidth }: IParagraphProps) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
`;

export const Title = (props: IParagraphProps): JSX.Element => {
  return <StyledTitle {...props}> {props.children} </StyledTitle>;
};

const StyledH3: any = styled(StyledParahraph)`
  font-size: ${({ fontSize, theme }: IThemedParagraph) => fontSize || theme.typography.h3FontSize};
`;

export const H3 = (props: IParagraphProps): JSX.Element => {
  return <StyledH3 {...props}> {props.children} </StyledH3>;
};

const StyledHeaderTitle: any = styled(StyledParahraph)`
  font-family: Montserrat, Lato, serif;
  font-size: ${({ fontSize }: IThemedParagraph) => fontSize || '24px'};
  text-align: ${({ textAlign }: IParagraphProps) => textAlign || 'center'};
  font-weight: ${({ fontWeight }: IParagraphProps) => fontWeight || '600'};
  color: ${({ color }: IParagraphProps) => color || '#fff'};
  line-height: ${({ lineHeight }: IParagraphProps) => lineHeight || '29.26px'};
`;

export const HeaderTitle = (props: IParagraphProps): JSX.Element => {
  return <StyledHeaderTitle {...props}> {props.children} </StyledHeaderTitle>;
};
