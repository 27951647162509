export const convertImage = (image: File): string => {
  const binaryData = [];
  binaryData.push(image);

  return URL?.createObjectURL(new Blob(binaryData));
};

export const convertFileToBase64 = (file: File): Promise<FileReader['result']> => {
  return new Promise((resolve, reject) => {
    let baseURL: FileReader['result'] = '';
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load something...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };

    reader.onerror = () => {
      reject(null);
    };
  });
};

export const createFileFromBase64 = (base64String: string, type: string): Promise<File> => {
  return fetch(base64String)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], type, { type: 'image/jpeg' });
    });
};
