import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';

import { TextField } from '../text_field';
import { Button } from '../button';
import { IIdentityCard } from '../../@types/document/documentTypes';
import { Column, Typography, Wrapper } from '../../styled-components';
import { momentDateFormat } from '../../shared/helpers';
import { CustomDatePickerToolbar } from '../date-picker/custom-date-picker-toolbar';
import { validateCuiOrCNP } from '../../utils/form-validators';
import { DIGITS_REGEX } from '../../shared/constants';

interface IDetailsFormProps {
  initialValues: IIdentityCard['data'];
  onSubmit: (value: IIdentityCard['data']) => void;
  history: H.History<any>;
  parentRoute: string;
}

export const IdCardDetailsForm = ({ onSubmit, initialValues }: IDetailsFormProps): JSX.Element => {
  const { t } = useTranslation();

  //TODO add validation
  const validationSchema = Yup.object().shape({
    nameSurname: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
    address: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : false)),
    dateOfBirth: Yup.date()
      .required(t('commonText.requiredFieldError'))
      .typeError(t('commonText.invalidDateFormat', { date: initialValues?.dateOfBirth }))
      .nullable(),
    issuedOn: Yup.date()
      .required(t('commonText.requiredFieldError'))
      .typeError(t('commonText.invalidDateFormat', { date: initialValues?.issuedOn }))
      .nullable(),
    expiresOn: Yup.date()
      .required(t('commonText.requiredFieldError'))
      .min(Yup.ref('issuedOn'), t('commonText.endDateError'))
      .typeError(t('commonText.invalidDateFormat', { date: initialValues?.expiresOn }))
      .nullable()
      .default(null),
    cnp: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 14 : false))
      .test('validate-cnp', t('commonText.invalidCnpOrCuiError'), validateCuiOrCNP),
    serial: Yup.string()
      .length(2, t('commonText.invalidFormat'))
      .required(t('commonText.requiredFieldError'))
      .nullable()
      .matches(/^[A-Za-z]+$/, t('ocrForms.invalidFieldOnlyLetters')),
    serialNumber: Yup.string()
      .min(6, t('commonText.invalidFormat'))
      .max(6, t('commonText.invalidMaxLimit'))
      .required(t('commonText.requiredFieldError'))
      .matches(DIGITS_REGEX, t('commonText.invalidFormat'))
      .nullable(),
    placeOfBirth: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : false)),
    issuedBy: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : false)),
  });

  const formatDateForIdCard = (date: string | null) => {
    if (!date) return null;

    return moment(date, 'DD-MM-YYYY');
  };

  const eighteenYearsAgo = () => {
    const d = new Date();

    return d.setFullYear(d.getFullYear() - 18);
  };

  const formattedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      dateOfBirth: formatDateForIdCard(initialValues?.dateOfBirth)?.format('YYYY/MM/DD') ?? null,
      issuedOn: formatDateForIdCard(initialValues?.issuedOn)?.format('YYYY/MM/DD') ?? null,
      expiresOn: formatDateForIdCard(initialValues?.expiresOn)?.format('YYYY/MM/DD') ?? null,
    };
  }, [initialValues]);

  return (
    <Formik initialValues={formattedInitialValues || {}} validationSchema={validationSchema} validateOnMount onSubmit={onSubmit}>
      {({ handleChange, handleBlur, isValid, values, errors, setFieldValue }: FormikProps<typeof initialValues>) => (
        <Form style={{ width: '100%' }}>
          <TextField
            errorMessage={errors.nameSurname}
            label={t('ocrForms.nameSurname')}
            name='nameSurname'
            value={values?.nameSurname}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.cnp}
            label={t('ocrForms.cnp')}
            name='cnp'
            value={values?.cnp}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <Wrapper justifyContent='space-between'>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <TextField
                errorMessage={errors.serial}
                label={t('ocrForms.series')}
                name='serial'
                value={values?.serial}
                variant='rounded'
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
              />
            </Column>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <TextField
                errorMessage={errors.serialNumber}
                label={t('ocrForms.seriesNumber')}
                name='serialNumber'
                value={values?.serialNumber}
                variant='rounded'
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
              />
            </Column>
          </Wrapper>

          <TextField
            errorMessage={errors.placeOfBirth}
            label={t('ocrForms.birthLocation')}
            name='placeOfBirth'
            value={values?.placeOfBirth}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.issuedBy}
            label={t('ocrForms.issuedBy')}
            name='issuedBy'
            value={values?.issuedBy}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.address}
            label={t('ocrForms.address')}
            name='address'
            value={values?.address}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Typography color='#9a9a9a' variant='body-xs'>
            {t('ocrForms.dateOfBirth')}
          </Typography>
          <DatePicker
            ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
            cancelLabel={t('commonText.cancel')}
            className='date-picker'
            error={Boolean(errors.dateOfBirth)}
            format='DD.MM.YYYY'
            helperText={errors.dateOfBirth}
            maxDate={eighteenYearsAgo()}
            name='dateOfBirth'
            okLabel={t('commonText.save')}
            value={values?.dateOfBirth || null}
            onBlur={handleBlur}
            onChange={value => setFieldValue('dateOfBirth', momentDateFormat(value))}
          />
          <Typography color='#9a9a9a' variant='body-xs'>
            {t('ocrForms.identityCardAvailability')}
          </Typography>
          <Wrapper alignItems='flex-start' justifyContent='space-between'>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <DatePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
                cancelLabel={t('commonText.cancel')}
                className='date-picker'
                error={Boolean(errors.issuedOn)}
                format='DD.MM.YYYY'
                helperText={errors.issuedOn}
                initialFocusedDate={values?.issuedOn ? moment(values?.issuedOn).format('DD.MM.YYYY') : null}
                maxDate={new Date()}
                name='issuedOn'
                okLabel={t('commonText.save')}
                value={values?.issuedOn || null}
                onBlur={handleBlur}
                onChange={value => {
                  setFieldValue('issuedOn', momentDateFormat(value));
                }}
              />
            </Column>
            <Column maxWidth='4%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='24px' textAlign='center'>
              -
            </Column>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <DatePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
                cancelLabel={t('commonText.cancel')}
                className='date-picker'
                error={Boolean(errors.expiresOn)}
                format='DD.MM.YYYY'
                helperText={errors.expiresOn}
                minDate={new Date()}
                name='expiresOn'
                okLabel={t('commonText.save')}
                value={values?.expiresOn || null}
                onBlur={handleBlur}
                onChange={value => setFieldValue('expiresOn', momentDateFormat(value))}
              />
            </Column>
          </Wrapper>
          {/*TODO button present in multiple forms, create reusable component*/}
          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            border='none'
            borderRadius='25px'
            color='#FFFF'
            disabled={!isValid}
            disabledColor='#DADADA'
            margin='15px 0 10px 0'
            type='submit'
            onClick={() => onSubmit(values)}
          >
            {t('commonText.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
