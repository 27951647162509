import React from 'react';

import { AddSquareIcon, ArrowLeftIcon, Close } from '../../assets/icons';
import { Column, Paragraph, Wrapper } from '../../styled-components';
import { Button } from '../button';

export const ScreenBottomMenu = (): JSX.Element => {
  const menu = [
    {
      title: 'Statements',
      icon: AddSquareIcon,
      path: '/statements',
    },
    {
      title: 'Assistance',
      icon: ArrowLeftIcon,
      path: '/login',
    },
    {
      title: 'Account',
      icon: Close,
      path: '/login',
    },
    {
      title: 'Notifications',
      icon: AddSquareIcon,
      path: '/statements',
    },
  ];

  return (
    <Column borderTop='1px solid lightgray' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
      <Wrapper justifyContent='space-between' margin='0 auto' maxWidth='500px'>
        {menu.map((item, index) => (
          <Column key={index} maxWidth='auto' textAlign='center'>
            <Button mode='link'>
              <item.icon fill='#999999' />
              <Paragraph margin='0'>{item.title}</Paragraph>
            </Button>
          </Column>
        ))}
      </Wrapper>
    </Column>
  );
};
