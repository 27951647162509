import React from 'react';

import { LocationPinIcon } from '../assets/icons';

// Cluj-Napoca Center.
export const DEFAULT_MAP_CENTER = {
  lat: 46.7657678,
  lng: 23.6067858,
};

export const DEFAULT_MAP_ZOOM = 13;

export const LocationPin = (): JSX.Element => (
  <div>
    <LocationPinIcon />
  </div>
);
