import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { MediaModal } from '../../components/media-modal';
import { DamagePicturesI, useStatementDamageConstants } from '../../hooks/useStatementDamageConstants';
import { IPhotoProofs } from '../../@types/damage/damageType';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { useDamages } from '../../hooks/useDamages';
import AddPictureBackground from '../../assets/images/add-picture.png';
import { updateStatement } from '../../services/api/createStatement/createStatement.api';
import { EVALUATION_STATUS } from '../../constants/typeConstants/statementStatusType';
import { StatementOrEvaluationStep, STATUS_CARD } from '../../@types/statement-evaluation-cards/statementOrEvaluationCardsTypes';
import { useProgressEvaluationAndStatement } from '../../constants/dataConstants/evaluationSteps';
import { STATEMENT_TYPES } from '../../store/types';
import { useDocumentsTempData } from '../../hooks/useDocumentsTempData';
import { setLoading } from '../../store/app/app.slice';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { IScreenComponentProps } from '../component-props.model';

import { ModalDataI } from './AddPictureContainer';
import { AddPictureContainer } from './AddPictureContainer1';

export const DamageDetailsView = ({ history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { DAMAGE_PICTURES } = useStatementDamageConstants();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalDataI | null>(null);
  const dispatch = useDispatch();

  const { currentStatement, setCurrentStatementData } = useCurrentStatement();
  const { injured } = currentStatement;
  const { getTempDocumentsDataFromMainScreen } = useDocumentsTempData();
  const { damage } = injured;

  const imagesList = DAMAGE_PICTURES.data;

  const { getStepsByType } = useProgressEvaluationAndStatement();

  const { postDamagesMedia } = useDamages();

  const handleNextStatementStatus = async () => {
    if (currentStatement?.statementID) {
      const evaluationStatus = getStepsByType(currentStatement.type).find((evaluation: StatementOrEvaluationStep): boolean => {
        return evaluation.status === STATUS_CARD.COMPLETE;
      });

      if (evaluationStatus?.status === STATUS_CARD.COMPLETE) {
        await updateStatement({
          id: currentStatement.statementID,
          status: EVALUATION_STATUS.EVALUATION_AWAITING_USER_PAYMENT,
        });
      }
    }
  };

  const uploadImage = async (file: File, data: ModalDataI | null) => {
    if (!currentStatement?.statementID && !currentStatement?.injured?.id) return;

    const { injured } = currentStatement;

    const type = data?.label?.replace('* ', '');

    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', type as string);
    formData.append('statementId', (currentStatement.statementID as number).toString());
    formData.append('personId', injured.id.toString());

    try {
      dispatch(setLoading(true));

      postDamagesMedia(formData, false, (data: IPhotoProofs) => {
        const currentPhotoProfs = injured?.damage?.photoProofs || [];

        const newPhotoProfs = [...currentPhotoProfs, data];

        const newDamageData = {
          damage: {
            ...damage,
            photoProofs: newPhotoProfs,
          },
        };

        setCurrentStatementData({
          injured: {
            ...injured,
            ...newDamageData,
          },
        });
        dispatch(setLoading(false));
      });

      dispatch(setLoading(false));

      if (currentStatement?.type === STATEMENT_TYPES.EVALUATION) await handleNextStatementStatus();
    } catch (e) {
      dispatch(setLoading(false));

      //Todo change with notification alert
      // dispatch(fetchError(e.message));
      // console.log('Error on delete: ', e);
    }
  };

  const handleContinueDisabled = () => {
    const requiredImages = imagesList.filter((image: DamagePicturesI) => image.label.includes('*'));
    const validImages = requiredImages.filter((image: DamagePicturesI) => image.src !== AddPictureBackground);

    return validImages?.length < requiredImages?.length;
  };

  const onContinue = (): void => {
    if (!handleContinueDisabled()) {
      history.push(
        generatePath(`${PROTECTED_ROUTES.statement$IdDetails}`, {
          currentStatementId: currentStatement.statementID as number,
        }),
        {
          currentStatementId: currentStatement.statementID,
        },
      );
    }
  };

  const handleAddPhoto = (photo: DamagePicturesI) => {
    getTempDocumentsDataFromMainScreen({ title: photo.label });

    const content = {
      id: photo?.id,
      label: photo?.label,
      src: photo?.src,
      modalLabel: photo?.modalLabel,
      type: photo?.type,
    } as ModalDataI;

    // If the image is default (placeholder) then don't pass any initialImage to the modal (MediaModal)
    if (photo.src === AddPictureBackground) content.src = '';
    setModalData(content);

    setShowModal(true);
  };

  const handleDeletePhoto = async (photo: DamagePicturesI) => {
    try {
      dispatch(setLoading(true));

      await photo.handleOnDelete();

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      //Todo change with notification alert
      console.log('Error on delete: ', e);
    }
  };

  const pictureModal = (
    <MediaModal
      CAMERA_TYPE_ACTION={t('cameraText.uploadPicture')}
      CAMERA_TYPE_TAKE={t('cameraText.takePicture')}
      RETAKE_CAMERA_TYPE={t('cameraText.retakePicture')}
      data={modalData}
      setPicture={uploadImage}
      setShowModal={setShowModal}
    />
  );

  const videoModal = (
    <MediaModal
      CAMERA_TYPE_ACTION={t('cameraText.uploadVideo')}
      CAMERA_TYPE_TAKE={t('cameraText.filming')}
      RETAKE_CAMERA_TYPE={t('cameraText.retakeVideo')}
      data={modalData}
      moveOn={t('cameraText.moveOn')}
      setPicture={uploadImage}
      setShowModal={setShowModal}
    />
  );

  return (
    <ScreenContainer
      headerProps={{ title: t('affectedZones.title'), titleSize: 'h5', hideLogo: true }}
      isModalOpen={showModal}
      modal={modalData?.type === 'video' ? videoModal : pictureModal}
      modalProps={{
        show: showModal,
        onClose: () => {
          setShowModal(false);
        },
      }}
    >
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {t('carDamage.affectedSpots')}
      </Typography>
      <Wrapper flexWrap='wrap' width='100%'>
        {imagesList.map((item: DamagePicturesI) => {
          return (
            <AddPictureContainer
              key={item.id}
              image={item?.src}
              isDeletable={item?.src !== AddPictureBackground}
              label={item.label}
              onAddDocument={() => handleAddPhoto(item)}
              onDelete={() => handleDeletePhoto(item)}
            />
          );
        })}
        <Button disabled={handleContinueDisabled()} margin='35px 0 0 0' mode='primary' onClick={onContinue}>
          {t('commonText.continue')}
        </Button>
      </Wrapper>
    </ScreenContainer>
  );
};
