import { Action, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage: storageSession,
  // Keep in mind that here we can blacklist or whitelist certain reducers
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(routerMiddleware(history)),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    store.replaceReducer(persistedReducer);
  });
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
