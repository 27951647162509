import { useTranslation } from 'react-i18next';

import { USER_SITUATION } from '../services/router/routeHandles';
import DrivingLicenseTutorial from '../assets/images-tutorial/docs/permis.png';
import InsuranceTutorial from '../assets/images-tutorial/docs/polita.png';
import CarIdentityTutorial from '../assets/images-tutorial/docs/talon.png';
import IdentityCardTutorial from '../assets/images-tutorial/docs/buletin.png';
import AmicableFindingTutorial from '../assets/images-tutorial/docs/constatare-amiabila.png';
import PoliceReportTutorial from '../assets/images-tutorial/docs/proces-verbal.png';
import { INPUT_TYPE } from '../constants/typeConstants/cameraType';
import { DOCUMENTS_FORM_TYPE } from '../constants/typeConstants/documentsFormType';
import { PICTURE_CONTAINER_TYPE } from '../constants/pictureContainerType';
import { COMPLETED_DOCUMENTS_TYPE } from '../@types/questions-flow-handler/questionsFlowHandler';
import AddPictureBackground from '../assets/images/add-picture.png';
import { STATEMENT_TYPES } from '../store/types';

import { useCurrentStatement } from './useCurrentStatement';
import { useQuestionsFlowHandler } from './use-questionsFlowHandler';

export interface SpecialFormType {
  AMICABLE_FINDING: string;
  POLICE_REPORT: string;
}

export interface StatementDocumentData {
  id: number;
  label: string;
  modalLabel?: string;
  src: string;
  type: string | SpecialFormType;
  formType: DOCUMENTS_FORM_TYPE;
  userSituation?: string;
  defaultTutorialPicture?: string;
  documentID?: number | null;
  handleOnDelete: () => void;
}

export interface StatementDocumentsI {
  type: string;
  data: StatementDocumentData[];
}

const useStatementDocuments = () => {
  const { t } = useTranslation();
  const { currentStatement, setCurrentStatementData } = useCurrentStatement();
  const { questionsFlowHandlerState } = useQuestionsFlowHandler();
  const { completedDocumentsAfterAccident } = questionsFlowHandlerState;
  const { injured, guilty } = currentStatement;

  const handleImageSource = (id: number | null): string => {
    if (id) return `${process.env.REACT_APP_API_URL}/attachments/file/${id}?token=${localStorage.getItem('access_token')}` as string;

    return AddPictureBackground;
  };

  const STATEMENT_DOCUMENTS: StatementDocumentsI = {
    type: PICTURE_CONTAINER_TYPE.DOCUMENTS,
    data: [
      {
        id: 1,
        label: t('documents.injuredInsurance'),
        src: handleImageSource(injured.insuranceId),
        defaultTutorialPicture: InsuranceTutorial,
        formType: DOCUMENTS_FORM_TYPE.INSURANCE,
        userSituation: USER_SITUATION.INJURED_STATUS,
        type: INPUT_TYPE.IMAGE,
        documentID: injured.insuranceId,
        handleOnDelete: () => setCurrentStatementData({ injured: { ...injured, insuranceId: null } }),
      },
      {
        id: 2,
        label: t('documents.guiltyInsurance'),
        src: handleImageSource(guilty.insuranceId),
        formType: DOCUMENTS_FORM_TYPE.INSURANCE,
        userSituation: USER_SITUATION.GUILTY_STATUS,
        defaultTutorialPicture: InsuranceTutorial,
        type: INPUT_TYPE.IMAGE,
        documentID: guilty.insuranceId,
        handleOnDelete: () => setCurrentStatementData({ guilty: { ...guilty, insuranceId: null } }),
      },
      {
        id: 3,
        label: t('documents.drivingLicense'),
        defaultTutorialPicture: DrivingLicenseTutorial,
        formType: DOCUMENTS_FORM_TYPE.DRIVING_LICENSE,
        userSituation: USER_SITUATION.INJURED_STATUS,
        src: handleImageSource(injured.drivingLicenseId),
        type: INPUT_TYPE.IMAGE,
        documentID: injured.drivingLicenseId,
        handleOnDelete: () => setCurrentStatementData({ injured: { ...injured, drivingLicenseId: null } }),
      },
      {
        id: 4,
        label: t('documents.carIdentityDocument'),
        defaultTutorialPicture: CarIdentityTutorial,
        formType: DOCUMENTS_FORM_TYPE.CAR_IDENTITY,
        userSituation: USER_SITUATION.INJURED_STATUS,
        src: handleImageSource(injured.carIdentityId),
        type: INPUT_TYPE.IMAGE,
        documentID: injured.carIdentityId,
        handleOnDelete: () => setCurrentStatementData({ injured: { ...injured, carIdentityId: null } }),
      },
      {
        id: 5,
        label: t('documents.idCard'),
        src: handleImageSource(injured.identityCardId),
        formType: DOCUMENTS_FORM_TYPE.ID_CARD,
        userSituation: USER_SITUATION.INJURED_STATUS,
        defaultTutorialPicture: IdentityCardTutorial,
        type: INPUT_TYPE.IMAGE,
        documentID: injured.identityCardId,
        handleOnDelete: () => setCurrentStatementData({ injured: { ...injured, identityCardId: null } }),
      },
      {
        id: 6,
        label: t('documents.amicableFinding'),
        defaultTutorialPicture: AmicableFindingTutorial,
        formType: DOCUMENTS_FORM_TYPE.AMICABLE_FINDING,
        src: handleImageSource(currentStatement.amicableFindingId),
        type: INPUT_TYPE.IMAGE,
        documentID: currentStatement.amicableFindingId,
        handleOnDelete: () => setCurrentStatementData({ amicableFindingId: null }),
      },
      {
        id: 7,
        label: t('documents.policeReport'),
        defaultTutorialPicture: PoliceReportTutorial,
        formType: DOCUMENTS_FORM_TYPE.POLICE_REPORT,
        src: handleImageSource(currentStatement.policeReportId),
        type: INPUT_TYPE.IMAGE,
        documentID: currentStatement.policeReportId,
        handleOnDelete: () => setCurrentStatementData({ policeReportId: null }),
      },
      {
        id: 8,
        label: t('documents.observationNote'),
        defaultTutorialPicture: AmicableFindingTutorial,
        formType: DOCUMENTS_FORM_TYPE.OBSERVATION_NOTE,
        src: handleImageSource(currentStatement.observationNoteId),
        type: INPUT_TYPE.IMAGE,
        documentID: currentStatement.observationNoteId,
        handleOnDelete: () => setCurrentStatementData({ observationNoteId: null }),
      },
      {
        id: 9,
        label: t('documents.compensationOffer'),
        defaultTutorialPicture: AmicableFindingTutorial,
        formType: DOCUMENTS_FORM_TYPE.COMPENSATION_OFFER,
        src: handleImageSource(currentStatement.compensationOfferId),
        type: INPUT_TYPE.IMAGE,
        documentID: currentStatement.compensationOfferId,
        handleOnDelete: () => setCurrentStatementData({ compensationOfferId: null }),
      },
    ],
  };

  const getStatementDocumentsListByFlowCondition = () => {
    const excludedAccidentDocument =
      completedDocumentsAfterAccident === COMPLETED_DOCUMENTS_TYPE.AMICABLE_FINDING
        ? DOCUMENTS_FORM_TYPE.POLICE_REPORT
        : DOCUMENTS_FORM_TYPE.AMICABLE_FINDING;

    const excludeCompensationOffer = !currentStatement.compensationOffer;
    const excludeObservationNote = !currentStatement.observationNote;

    const isDocumentValid = (statementDocument: StatementDocumentData) => {
      let condition = statementDocument.formType !== excludedAccidentDocument;

      if (currentStatement.type === STATEMENT_TYPES.NOTIFICATION) {
        condition =
          condition &&
          statementDocument.formType !== DOCUMENTS_FORM_TYPE.COMPENSATION_OFFER &&
          statementDocument.formType !== DOCUMENTS_FORM_TYPE.OBSERVATION_NOTE;
      } else {
        if (excludeCompensationOffer) condition = condition && statementDocument.formType !== DOCUMENTS_FORM_TYPE.COMPENSATION_OFFER;

        if (excludeObservationNote) condition = condition && statementDocument.formType !== DOCUMENTS_FORM_TYPE.OBSERVATION_NOTE;
      }

      return condition;
    };

    return STATEMENT_DOCUMENTS.data.filter((index: StatementDocumentData) => isDocumentValid(index));
  };

  /**
   *
   * @returns {number}
   */
  const getRequiredStatementsDocuments = () => {
    const excludedAccidentDocument =
      completedDocumentsAfterAccident === COMPLETED_DOCUMENTS_TYPE.AMICABLE_FINDING
        ? DOCUMENTS_FORM_TYPE.POLICE_REPORT
        : DOCUMENTS_FORM_TYPE.AMICABLE_FINDING;

    const isDocumentValid = (statementDocument: StatementDocumentData) => {
      return (
        statementDocument.formType !== excludedAccidentDocument &&
        statementDocument.formType !== DOCUMENTS_FORM_TYPE.COMPENSATION_OFFER &&
        statementDocument.formType !== DOCUMENTS_FORM_TYPE.OBSERVATION_NOTE
      );
    };

    return STATEMENT_DOCUMENTS.data.filter((index: StatementDocumentData) => isDocumentValid(index)).length;
  };

  /**
   *
   * @param {string} base64String
   * @param {string} type
   * @returns {Promise<File>}
   */
  const createFileFromBase64 = (base64String: string, type: string): Promise<File> => {
    return fetch(base64String)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], type, { type: 'image/jpeg' });
      });
  };

  return { STATEMENT_DOCUMENTS, getStatementDocumentsListByFlowCondition, getRequiredStatementsDocuments, createFileFromBase64 };
};

export { useStatementDocuments };
