import { PayloadAction } from '@reduxjs/toolkit';

import { createGenericSlice, GenericState, initializeState } from '../store.constants';
import { AppThunk } from '../store';
import { IPreLogin } from '../../@types/pre-login/preLoginTypes';

import { preLoginInitialState } from './initialState';

type IPreLoginState = GenericState<IPreLogin>;

const initialState: IPreLoginState = initializeState(preLoginInitialState);

const preLoginSlice = createGenericSlice({
  name: 'preLogin',
  initialState,
  reducers: {
    setPreLogin(state: IPreLoginState, action: PayloadAction<IPreLogin>) {
      state.data = action.payload;
    },
  },
});

export default preLoginSlice.reducer;

export const { setPreLogin } = preLoginSlice.actions;

export const setPreLoginDataAction = (payload: IPreLogin): AppThunk => async (dispatch: (arg0: any) => void) => {
  dispatch(setPreLogin(payload));
};
