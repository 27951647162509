import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { RootState } from '../store/store';
import { ICurrentStatement, ICurrentStatementStatus } from '../@types/current-statement/currentStatementTypes';
import {
  setCircumstancesAction,
  setDamageAction,
  setDocumentsAction,
  setStatementAction,
  updateStatementStatus,
} from '../store/statement/currentStatement.action';
import { USER_SITUATION } from '../services/router/routeHandles';
import { IStatementDamage } from '../@types/damage/damageType';

import { useStatements } from './useStatements';

const useCurrentStatement = () => {
  const dispatch = useDispatch();
  const urlParams = useParams<{ currentStatementId?: string }>();
  const currentStatementsData = useSelector((state: RootState) => state.statements.data);
  const _currentStatement = useSelector((state: RootState) => state.currentStatement.data);
  const currentStatementIsLoading = useSelector((state: RootState) => state.currentStatement.isLoading);
  const currentStatementError = useSelector((state: RootState) => state.currentStatement.error);

  const { statementsState } = useStatements();

  const currentStatement = { ..._currentStatement };

  // update currentStatement on state, if the id in the url has changed
  if (!currentStatement?.statementID && urlParams?.currentStatementId) {
    const currentStatementHasChanged = currentStatement?.statementID !== Number(urlParams?.currentStatementId);

    if (currentStatementHasChanged) {
      currentStatement.statementID = Number(urlParams.currentStatementId);
      const newCurrentStatement = statementsState.find(item => item.statementID === currentStatement.statementID);

      dispatch(setStatementAction({ ...currentStatement, ...newCurrentStatement }));
    }
  }

  const setCurrentStatementData = useCallback(
    (newData: Partial<ICurrentStatement>) => {
      dispatch(setStatementAction({ ...currentStatement, ...newData }));
    },
    [currentStatement, dispatch],
  );

  const getCurrentStatementCircumstances = useCallback(
    (userSituation: any, newCircumstances: any) => {
      dispatch(setCircumstancesAction(userSituation, newCircumstances));
    },
    [dispatch],
  );

  const getDocuments = useCallback(
    (userSituation: USER_SITUATION, newDocuments: any) => {
      dispatch(setDocumentsAction(userSituation, { ...currentStatement[userSituation]?.documents, ...newDocuments }));
    },
    [currentStatement, dispatch],
  );

  const getDamage = useCallback(
    (userSituation: USER_SITUATION, newDamage: IStatementDamage) => {
      dispatch(setDamageAction(userSituation, newDamage));
    },
    [dispatch],
  );

  const updateStatementStatusToPayment = useCallback(
    (payload: ICurrentStatementStatus, onSuccess?: () => void) => {
      dispatch(updateStatementStatus(payload, onSuccess));
    },
    [dispatch],
  );

  return {
    setCurrentStatementData,
    currentStatement,
    getCurrentStatementCircumstances,
    getDocuments,
    getDamage,
    currentStatementIsLoading,
    currentStatementsData,
    currentStatementError,
    updateStatementStatusToPayment,
  };
};

export { useCurrentStatement };
