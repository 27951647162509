import React, { ReactNode } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import CloseIcon from '../../assets/icons/CloseIcon.svg';
import { textColor } from '../../utils/elementColorSwitch';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  disabledColor?: string;
  border?: string;
  backgroundColor?: string;
  background?: string;
  display?: string;
  width?: string;
  height?: string;
  margin?: string;
  float?: string;
  textTransform?: string;
  maxWidth?: string;
  borderRadius?: string;
  marginTop?: string;
  paddingTop?: string;
  boxShadow?: string;
  children: string | JSX.Element | ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  size?: string;
  mode?: ButtonTypes;
  disabled?: boolean;
}

type ButtonTypes = 'default' | 'danger' | 'success' | 'link' | 'primary' | 'icon' | 'close';

interface IThemedButton extends IButtonProps {
  theme: DefaultTheme;
}

const StyledButton = styled.button`
  cursor: pointer;
  color: ${(props: IThemedButton) => props.color || '#20344c'};
  box-shadow: ${({ boxShadow }: IThemedButton) => boxShadow || '0 1px 2px #0000002e'};
  min-height: ${(props: IThemedButton) => props.height || props.theme.buttonSizes.height};
  background-color: ${(props: IThemedButton) => props.backgroundColor || '#FFFF'};
  background: ${(props: IThemedButton) => (props.disabled ? props.disabledColor : props.background || '#FFFF')};
  border: ${(props: IThemedButton) => (props.border ? props.border : 'none')};
  border-radius: ${(props: IThemedButton) => props.borderRadius || '3px'};
  text-transform: ${(props: IThemedButton) => props.textTransform || 'none'};
  font-size: 16px;
  margin-top: auto;
  line-height: 19.5px;
  font-weight: 700;
  outline: 0;
  :active {
    opacity: 0.5;
  }
  opacity: ${(props: IThemedButton) => (props.disabled ? 0.8 : 1)};
  ${({ maxWidth }: IThemedButton) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  ${({ display }: IThemedButton) =>
    display &&
    css`
      display: ${display};
      align-items: center;
      justify-content: center;
    `}
	${({ float }: IThemedButton) =>
    float &&
    css`
      float: ${float};
    `}
	${({ margin }: IThemedButton) =>
    margin &&
    css`
      margin: ${margin};
    `}
		${({ size, theme, width }) => {
    switch (size) {
      case 'sm':
        return `max-width: ${theme.buttonSizes.small}`;
      case 'md':
        return `width: ${theme.buttonSizes.medium}`;
      case 'lg':
        return `width: ${theme.buttonSizes.large}`;
      default:
        return `width: ${width || '100%'}`;
    }
  }}
`;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${(props: IThemedButton) => textColor(props)};
  width: ${({ width }: IThemedButton) => width || 'auto'};
  ${({ display }: IThemedButton) =>
    display &&
    css`
      display: ${display};
      align-items: center;
    `}
  ${({ maxWidth }: IThemedButton) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
	${({ textTransform }: IThemedButton) =>
    textTransform &&
    css`
      text-transform: ${textTransform};
    `}
	${({ float }: IThemedButton) =>
    float &&
    css`
      float: ${float};
    `}
		${({ margin }: IThemedButton) =>
    margin &&
    css`
      margin: ${margin};
    `}
	&:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

interface IButtonIcon {
  left?: string;
  right?: string;
  children: React.ReactNode;
}

const StyledButtonIcon = styled(StyledButton)`
  position: absolute;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  ${({ left }: IButtonIcon) =>
    left &&
    css`
      left: ${left};
    `}
  ${({ right }: IButtonIcon) =>
    right &&
    css`
      right: ${right};
    `}
`;

export const Label = (props: IButtonIcon): JSX.Element => <StyledButtonIcon {...props}> {props.children} </StyledButtonIcon>;

const PrimaryButton = styled(StyledButton)`
  color: #ffff;
  background: linear-gradient(270deg, #813eda 0%, #4123d7 100%);
  border-radius: 50px;
  border: 0;
  &:disabled {
    background: rgba(218, 218, 218, 0.72);
    opacity: 0.5;
  }
  &.outlined {
    background-color: ${(props: IThemedButton) => props.backgroundColor || '#fff'};
    color: #0066ff;
    border: 1px solid #0066ff;
  }
`;

const SuccessButton = styled(StyledButton)`
  color: #ffff;
  background-color: ${(props: IThemedButton) => props.theme.palette.green.light};
  border: 1px solid ${(props: IThemedButton) => props.theme.palette.green.dark};
`;

const DangerButton = styled(StyledButton)`
  color: #ffff;
  background-color: ${(props: IThemedButton) => props.theme.palette.red.light};
  border: 1px solid ${(props: IThemedButton) => props.theme.palette.red.dark};
`;

const DefaultButton = styled(StyledButton)`
  color: ${(props: IThemedButton) => props.color || '#20344c'};
  background-color: #ffff;
  border: 1px solid #ffff;
`;

interface ICloseButtonProps {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  zIndex?: number;
  width?: string;
  height?: string;
}

export const CloseButton = styled.button<ICloseButtonProps>`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${CloseIcon});
  background-color: transparent;
  width: ${({ width }) => width || '34px'};
  height: ${({ height }) => height || '34px'};
  border: none;
  position: absolute;
  z-index: ${({ zIndex }) => zIndex || 'unset'};
  bottom: ${({ bottom }) => bottom || '130px'};
  right: ${({ right }) => right || '-10px'};
  top: ${({ top }) => top || 'initial'};
  left: ${({ left }) => left || 'initial'};
`;

const getButton = (props: IButtonProps): JSX.Element => {
  const { mode } = props;

  switch (mode) {
    case 'success':
      return <SuccessButton {...props} />;
    case 'primary':
      return <PrimaryButton {...props} />;
    case 'danger':
      return <DangerButton {...props} />;
    case 'default':
      return <DefaultButton {...props} />;
    case 'link':
      return <LinkButton {...props} />;
    case 'icon':
      return <StyledButtonIcon {...props} />;
    default:
      return <StyledButton {...props} />;
  }
};

const renderButton = (props: IButtonProps) => {
  const { mode } = props;

  return mode ? getButton(props) : <StyledButton {...props} />;
};

export const Button = (props: IButtonProps): JSX.Element => {
  return renderButton(props);
};
