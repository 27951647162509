import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS } from '../../translations/ro/home';
import { Typography } from '../../styled-components';
import { IScreenComponentProps } from '../component-props.model';
import { useWitnesses } from '../../hooks/useWitnesses';
import { IWitness } from '../../@types/witnesses/witnessesTypes';

export const WitnessList = ({ state }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { witnesses } = state?.currentStatement.data.injured || {};
  const { getWitnessesByStatementId } = useWitnesses();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.currentStatement?.data?.statementID) getWitnessesByStatementId(state?.currentStatement?.data?.statementID);
  }, [dispatch, getWitnessesByStatementId, state?.currentStatement?.data?.statementID]);

  return (
    <ScreenContainer headerProps={{ title: HOME_TEXTS.witnesses, hideLogo: true }}>
      {witnesses && witnesses.length > 0 && witnesses[0]['name'] !== '' ? (
        witnesses.map((witness: IWitness) => (
          <div
            key={`witness-${witness.id}`}
            style={{ background: '#fff', borderRadius: 10, padding: '20px 18px', width: '100%', marginBottom: 20 }}
          >
            <Typography extendedStyles={{ fontSize: '16px', lineHeight: '30px' }} fontWeight='700' variant='h5'>
              {witness.name}
            </Typography>

            <Typography color='#9a9a9a' extendedStyles={{ lineHeight: '20px' }} variant='body'>
              {witness.address}
            </Typography>
            <Typography color='#9a9a9a' extendedStyles={{ lineHeight: '20px' }} variant='body'>
              {witness.phone}
            </Typography>
            <Typography color='#9a9a9a' extendedStyles={{ lineHeight: '20px' }} variant='body'>
              {witness.email}
            </Typography>
          </div>
        ))
      ) : (
        <div style={{ background: '#fff', borderRadius: 10, padding: '20px 18px', width: '100%', marginBottom: 20 }}>
          <Typography color='#9a9a9a' extendedStyles={{ lineHeight: '20px' }} variant='body'>
            {t('statements.noWitnesses')}
          </Typography>
        </div>
      )}
    </ScreenContainer>
  );
};
