import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { StatementDocumentData, useStatementDocuments } from '../../hooks/useStatementDocuments';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import AddPictureBackground from '../../assets/images/add-picture.png';
import { StatementOrEvaluationStep, STATUS_CARD } from '../../@types/statement-evaluation-cards/statementOrEvaluationCardsTypes';
import { EVALUATION_STATUS } from '../../constants/typeConstants/statementStatusType';
import { useProgressEvaluationAndStatement } from '../../constants/dataConstants/evaluationSteps';
import { STATEMENT_TYPES } from '../../store/types';
import { AddPictureContainer } from '../statement-damage/AddPictureContainer1';
import { deleteDocumentApi } from '../../services/api/attachments/attachments.api';
import { setLoading } from '../../store/app/app.slice';
import { useDocumentsTempData } from '../../hooks/useDocumentsTempData';

/**
 *
 * @param {IScreenComponentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const DocumentsDetailsView = (props: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { history, nextRoute, parentRoute } = props;
  const { STATEMENT_DOCUMENTS, getStatementDocumentsListByFlowCondition, getRequiredStatementsDocuments } = useStatementDocuments();

  const documentsList = STATEMENT_DOCUMENTS.data;

  const { currentStatement, updateStatementStatusToPayment } = useCurrentStatement();

  const { getTempDocumentsDataFromMainScreen } = useDocumentsTempData();

  const { getStepsByType } = useProgressEvaluationAndStatement();

  const handleButtonDisable = () => {
    const numberOfRequiredDocs = getRequiredStatementsDocuments();
    const numberOfUploadedDocs = documentsList.filter(image => image.src !== AddPictureBackground).length;

    return numberOfUploadedDocs < numberOfRequiredDocs;
  };

  const handleNextStatementStatus = (callback: () => void) => {
    if (currentStatement?.type === STATEMENT_TYPES.EVALUATION && currentStatement?.statementID) {
      const evaluationStatus = getStepsByType(currentStatement.type).find((evaluation: StatementOrEvaluationStep): boolean => {
        return evaluation.status === STATUS_CARD.COMPLETE;
      });

      if (evaluationStatus?.status === STATUS_CARD.COMPLETE) {
        updateStatementStatusToPayment(
          {
            id: currentStatement.statementID,
            status: EVALUATION_STATUS.EVALUATION_AWAITING_USER_PAYMENT,
          },
          callback,
        );
      }
    }

    callback();
  };

  const handleAddDocument = (document: StatementDocumentData) => {
    getTempDocumentsDataFromMainScreen({
      title: document.label,
      defaultPicture: document.defaultTutorialPicture,
      formType: document.formType,
      flowHandle: document.userSituation,
    });

    const alreadyUpload = document?.src !== AddPictureBackground;

    /** Pass uploaded img to modal */
    const img = alreadyUpload && { image: document.src };

    history.push(`${parentRoute}${nextRoute['photo'].toString()}`, img);
  };

  const handleDeleteDocument = async (document: StatementDocumentData) => {
    const documentID = document?.documentID;

    if (documentID) {
      try {
        dispatch(setLoading(true));

        await deleteDocumentApi(documentID);

        document.handleOnDelete();

        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setLoading(false));
        //Todo change with notification alert
        console.log('Error on delete: ', e);
      }
    }
  };

  const onSubmit = (): void => {
    const callback = () => {
      if (currentStatement.statementID) {
        history.push(generatePath(`${PROTECTED_ROUTES.statement$IdDetails}`, { currentStatementId: currentStatement.statementID }));
      }
    };

    handleNextStatementStatus(callback);
  };

  return (
    <ScreenContainer
      bodyProps={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}
      headerProps={{ title: t('documents.title'), titleSize: 'h5', hideLogo: true, backUrl: PROTECTED_ROUTES.statement$IdDetails }}
    >
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {t('documents.title')}
      </Typography>
      <Wrapper flexWrap='wrap' width='100%'>
        {getStatementDocumentsListByFlowCondition().map((document: StatementDocumentData) => {
          return (
            <AddPictureContainer
              key={document.id}
              image={document?.src}
              isDeletable={document?.src !== AddPictureBackground}
              label={document.label}
              onAddDocument={() => handleAddDocument(document)}
              onDelete={() => handleDeleteDocument(document)}
            />
          );
        })}
      </Wrapper>

      <Button disabled={handleButtonDisable()} margin='35px 0 0 0' mode='primary' onClick={() => onSubmit()}>
        {t('commonText.continue')}
      </Button>
    </ScreenContainer>
  );
};
