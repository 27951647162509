import React from 'react';

import { IIconProps } from './icon.model';

export const ContactPayPactIcon = ({ width, height, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 23} viewBox='0 0 23 23' width={width || 23} {...otherProps}>
      <path
        d='M13.938 5a5 5 0 013.95 3.95M13.938 1a9 9 0 017.95 7.94m-1 7.98v3a2 2 0 01-2.18 2 19.791 19.791 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 012.998 2h3a2 2 0 012 1.72c.127.96.362 1.903.7 2.81a2 2 0 01-.45 2.11l-1.27 1.27a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45c.907.339 1.85.573 2.81.7a2 2 0 011.72 2.03z'
        stroke='#6C5CE7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
    </svg>
  );
};
