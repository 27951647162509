import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UtilsIcon, PersonIcon, AssessmentIcon, StatementIcon } from '../../assets/icons';
import { ITabs, StyledBottomContainer, StyledButtonLabel, StyledIconButton } from '../../styled-components/bottom-nav';
import { Typography, Wrapper } from '../../styled-components';
import { useStatements } from '../../hooks/useStatements';
import { BottomNavigationPathUtilities, PRE_LOGIN_ROUTES, PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';

export interface IProps {
  onSelect?: (tab: ITabs) => void;
  setShowModal?: (showModal: boolean) => void;
  confirmNavigation?: boolean;
  setToEvaluations?: (evaluations: boolean) => void;
  setToStatements?: (statements: boolean) => void;
  setNavigation?: (path: string) => void;
}

export const BottomNavigation: React.FC<IProps> = ({ setShowModal, setNavigation }) => {
  const history = useHistory();
  const { t } = useTranslation(); //h
  const { getActiveStatementsCount } = useStatements();
  const [selectedTab, setSelectedTab] = useState<BottomNavigationPathUtilities | null>(null);
  const currentLocation = history.location.pathname.toString();
  const activeStatementsCount = getActiveStatementsCount();
  const location = history.location.pathname.replace(/^\/([^\/]*).*$/, '$1');

  const isHomeRoute = !!Object.values(PRE_LOGIN_ROUTES).find(
    item =>
      item.includes(location) || PROTECTED_ROUTES.ownerStatus.includes(location) || PROTECTED_ROUTES.checkContactDetails.includes(location),
  );

  useEffect(() => {
    // eslint-disable-next-line no-useless-escape
    if (isHomeRoute) {
      setSelectedTab(BottomNavigationPathUtilities.home);
    } else {
      switch (location) {
        case BottomNavigationPathUtilities.profile:
          setSelectedTab(BottomNavigationPathUtilities.profile);
          break;
        case BottomNavigationPathUtilities.utils:
          setSelectedTab(BottomNavigationPathUtilities.utils);
          break;
        case BottomNavigationPathUtilities.evaluations:
          setSelectedTab(BottomNavigationPathUtilities.evaluations);
          break;
        case 'payment':
          setSelectedTab(BottomNavigationPathUtilities.evaluations);
          break;
        default:
          setSelectedTab(BottomNavigationPathUtilities.evaluations);
          break;
      }
    }
  }, [history.location.pathname, isHomeRoute, location]);

  const onTabClick = (tab: BottomNavigationPathUtilities) => {
    switch (tab) {
      case BottomNavigationPathUtilities.home:
        history.push(PRE_LOGIN_ROUTES.root);
        break;
      case BottomNavigationPathUtilities.utils:
        history.push(`/${BottomNavigationPathUtilities.utils}`);
        break;
      case BottomNavigationPathUtilities.profile:
        history.push(`/${BottomNavigationPathUtilities.profile}`);
        break;
      case BottomNavigationPathUtilities.evaluations:
        history.push(`/${BottomNavigationPathUtilities.evaluations}`);
        break;
      default:
        history.push('/');

        break;
    }
  };

  return (
    <StyledBottomContainer>
      <StyledIconButton onClick={() => onTabClick(BottomNavigationPathUtilities.home)}>
        <Wrapper>
          <StatementIcon selected={BottomNavigationPathUtilities.home === selectedTab} />
        </Wrapper>
        <StyledButtonLabel selected={BottomNavigationPathUtilities.home === selectedTab}>
          {t('bottomNavigationLabels.home')}
        </StyledButtonLabel>
      </StyledIconButton>

      <StyledIconButton onClick={() => onTabClick(BottomNavigationPathUtilities.evaluations)}>
        <Wrapper>
          {!!activeStatementsCount && (
            <Wrapper
              backgroundColor='red'
              borderRadius='50%'
              bottom='18px'
              display='inline-block'
              height='17px'
              position='absolute'
              right='-7px'
              width='17px'
            >
              <Typography align='center' color='white' variant='caption'>
                {activeStatementsCount < 1 ? '' : activeStatementsCount}
              </Typography>
            </Wrapper>
          )}
          <AssessmentIcon selected={BottomNavigationPathUtilities.evaluations === selectedTab} />
        </Wrapper>
        <StyledButtonLabel selected={BottomNavigationPathUtilities.evaluations === selectedTab}>
          {t('bottomNavigationLabels.services')}
        </StyledButtonLabel>
      </StyledIconButton>
      <StyledIconButton onClick={() => onTabClick(BottomNavigationPathUtilities.utils)}>
        <UtilsIcon selected={selectedTab === BottomNavigationPathUtilities.utils} />
        <StyledButtonLabel selected={selectedTab === BottomNavigationPathUtilities.utils}>
          {t('bottomNavigationLabels.utils')}
        </StyledButtonLabel>
      </StyledIconButton>
      <StyledIconButton onClick={() => onTabClick(BottomNavigationPathUtilities.profile)}>
        <PersonIcon />
        <StyledButtonLabel selected={selectedTab === BottomNavigationPathUtilities.profile}>
          {t('bottomNavigationLabels.profile')}
        </StyledButtonLabel>
      </StyledIconButton>
    </StyledBottomContainer>
  );
};
