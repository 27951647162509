import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { useStatements } from '../../hooks/useStatements';
import { currentStatementInitialState } from '../../store/statement/initialState';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { RootState } from '../../store/rootReducer';
import DashboardHomeNav from '../../components/layout/DashboardHomeNav';
import { Typography } from '../../styled-components';

export const Statements = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { setCurrentStatementData } = useCurrentStatement();
  const authData = useSelector((state: RootState) => state.authUser.data);
  const { fetchStatements, getStatementsByStatus } = useStatements();

  const reFetch = () => {
    fetchStatements().catch(e => e);
  };

  useEffect(() => {
    fetchStatements();
    setCurrentStatementData(currentStatementInitialState);
  }, [currentStatementInitialState]);

  return (
    <ScreenContainer header={<DashboardHomeNav history={history} />}>
      <Typography variant='h4'>{t('bottomNavigationLabels.soon')}</Typography>
    </ScreenContainer>
  );
};
