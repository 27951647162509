import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Checkbox, Typography } from '../../styled-components';
import { Button } from '../button';
import { TextField } from '../text_field';
import { HOME_TEXTS } from '../../translations/ro/home';
import { IEvaluationContent } from '../../@types/current-statement/currentStatementTypes';

interface IBankModalProps {
  initialValues: IEvaluationContent;
  onSubmit: (formData: IEvaluationContent) => void;
  setShowTerms: (showTerms: boolean) => void;
  setShowBankInfo: (showBankInfo: boolean) => void;
  setShowContinue: (showContinue: boolean) => void;
  acceptTerms: boolean;
}

const ValidationSchema = Yup.object().shape({
  injuredBrokerName: Yup.string().required(HOME_TEXTS.provideInjuredBrokerName),
  bankName: Yup.string().required(HOME_TEXTS.provideInjuredBankName),
  iban: Yup.string().min(16, HOME_TEXTS.ibanMinLength).required(HOME_TEXTS.provideInjuredIBAN),
});

export const BankModal = ({
  initialValues,
  onSubmit,
  setShowTerms,
  setShowBankInfo,
  acceptTerms,
  setShowContinue,
}: IBankModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [acceptCheckbox, setAcceptCheckbox] = useState<boolean>(false);

  useEffect(() => {
    setAcceptCheckbox(acceptTerms);
  }, [acceptTerms]);

  return (
    <>
      <Typography align='center' commonStyles={{ margin: '0 0 15px 0' }} fontWeight='700' variant='h4'>
        {t('payPactTerms.provideYourBankInfo')}
      </Typography>
      <Formik initialValues={initialValues} validationSchema={ValidationSchema} validateOnMount onSubmit={onSubmit}>
        {({ handleChange, handleBlur, isValid, errors, touched }) => (
          <Form>
            <TextField
              error={Boolean(errors.injuredBrokerName) && Boolean(touched.injuredBrokerName)}
              errorMessage={errors.injuredBrokerName}
              label={HOME_TEXTS.injuredBrokerName}
              margin='40px 0 20px'
              name='injuredBrokerName'
              variant='rounded'
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <TextField
              error={Boolean(errors.bankName) && Boolean(touched.bankName)}
              errorMessage={errors.bankName}
              label={HOME_TEXTS.bankName}
              margin='40px 0 20px'
              name='bankName'
              variant='rounded'
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <TextField
              error={Boolean(errors.iban) && Boolean(touched.iban)}
              errorMessage={errors.iban}
              label={HOME_TEXTS.IBAN}
              margin='40px 0 20px'
              name='iban'
              variant='rounded'
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <Checkbox
              checked={acceptCheckbox}
              margin='35px 0'
              name='terms'
              onBlur={handleBlur}
              onChange={handleChange}
              onClick={() => {
                setAcceptCheckbox(!acceptCheckbox);
              }}
            >
              {`${HOME_TEXTS.agreeWith} `}
              <Button
                mode='link'
                style={{ padding: 0, fontSize: 16, color: '#0066ff', textDecoration: 'underline', display: 'contents' }}
                type='button'
                onClick={() => {
                  setShowTerms(true);
                  setShowBankInfo(false);
                  setShowContinue(false);
                }}
              >
                {HOME_TEXTS.termsAndConditions}
              </Button>
              {` ${HOME_TEXTS.ofPayment}`}
            </Checkbox>
            <Button disabled={!isValid || !acceptCheckbox} mode='primary' type='submit'>
              {t('commonText.save')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
