import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { ICurrentStatement, IStatementDbFormat } from '../@types/current-statement/currentStatementTypes';
import { addStatement, setStatement } from '../store/statements/statements.slice';
import { RootState } from '../store/rootReducer';
import { IStatement } from '../screens/statement/interfaces/statement.model';
import { EVALUATION_STATUS, NOTIFICATION_STATUS, STATEMENT_STATUS } from '../constants/typeConstants/statementStatusType';
import { accidentDocumentsInitialState, documentsInitialState } from '../store/statement/initialState';
import { getStatementByIdApi, setStatementAction } from '../store/statement/currentStatement.action';
import { COMPLETED_DOCUMENTS_TYPE } from '../@types/questions-flow-handler/questionsFlowHandler';
import { deleteEvaluation, getStatements } from '../services/api/createStatement/createStatement.api';
import { STATEMENT_FILTERS } from '../store/ui/ui.model';
import { STATEMENT_TYPES } from '../store/types';
import { fetchError, fetchStart, fetchSuccess } from '../store/statement/statement.slice';

import { useQuestionsFlowHandler } from './use-questionsFlowHandler';

const useStatements = () => {
  const dispatch = useDispatch();
  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();

  const transformFormatDbToFront = (data: IStatementDbFormat): IStatement => {
    const getTime = (val: string) => {
      const match = val.split('T')[1].match(/\d{2}:\d{2}/);

      return match ? match[0] : null;
    };

    return {
      statementID: data.id,
      insuredId: data.insuredId ? data.insuredId : null,
      statementOnSameDevice: true,
      digitalStatement: true,
      userSituation: 'injured',
      createdAt: data?.createdAt.split('T')[0],
      type: data.type,
      status: data?.status ? data?.status : EVALUATION_STATUS.EVALUATION_INCOMPLETE,
      accidentDate: data?.dateTime ? data?.dateTime.split('T')[0] : null,
      accidentTime: data?.dateTime ? getTime(data?.dateTime) : null,
      accidentLocation: data?.location ? data?.location : null,
      weatherCondition: data?.weatherCondition ? data?.weatherCondition : null,
      roadCondition: data?.roadCondition ? data?.roadCondition : null,
      description: data?.description ? data?.description : null,
      accidentDocuments: accidentDocumentsInitialState,
      carOwner: data?.injured.type,
      amicableFindingId: data.amicableFindingId ? data.amicableFindingId : null,
      policeReportId: data.policeReportId ? data.policeReportId : null,
      observationNoteId: data.observationNoteId ? data.observationNoteId : null,
      compensationOfferId: data.compensationOfferId ? data.compensationOfferId : null,
      evaluationDocumentId: data.evaluationDocumentId ? data.evaluationDocumentId : null,
      evaluationDocument: data.evaluationDocument ? data.evaluationDocument : null,
      paymentDetails: {
        statementId: data.id,
        amount: data?.paymentDetails?.amount ? data?.paymentDetails.amount : null,
        name: data?.paymentDetails?.name ? data?.paymentDetails.name : '',
        iban: data?.paymentDetails?.iban ? data?.paymentDetails.iban : '',
        bankName: data?.paymentDetails?.bankName ? data?.paymentDetails.bankName : '',
      },
      paymentOrder: data.paymentOrder,
      damageTypeQuestion: data?.damageTypeQuestion ? data?.damageTypeQuestion : '',
      observationNote: data?.observationNoteBool ? data?.observationNoteBool : null,
      compensationOffer: data?.compensationOfferBool ? data?.compensationOfferBool : null,
      guilty: {
        id: data.guiltyId,
        identityCardId: data?.guilty?.identityCardId ? data?.guilty?.identityCardId : null,
        insuranceId: data?.guilty?.insuranceId ? data?.guilty?.insuranceId : null,
        drivingLicenseId: data?.guilty?.driverLicenseId ? data?.guilty?.driverLicenseId : null,
        carIdentityId: data?.guilty?.vehicleRegistrationCertificateId ? data?.guilty?.vehicleRegistrationCertificateId : null,
        documents: documentsInitialState,
        damage: {
          zones: data.guilty?.damages ? data.guilty?.damages?.zone : '',
          description: data.guilty?.damages ? data?.guilty?.damages?.description : '',
          photoProofs: data.guilty?.photoProofs || '',
        },
        witnesses: null,
        personType: data?.guilty?.type ? data?.guilty?.type : null,
        userSignature: data?.guilty?.signatureId ? data?.guilty?.signatureId?.toString() : null,
        phoneNumber: data?.guilty?.phone ? data?.guilty?.phone : null,
        email: data?.guilty?.email ? data?.guilty?.email : null,
        companyName: data?.guilty?.companyName ? data?.guilty?.companyName : null,
        cui: data?.guilty?.cui ? data?.guilty?.cui : null,
        address: data?.guilty?.address ? data?.guilty?.address : null,
        insuredPhone: data?.guilty?.insuredPhone ? data?.guilty?.insuredPhone : null,
        insuredEmail: data?.guilty?.insuredEmail ? data?.guilty?.insuredEmail : null,
        county: data?.guilty?.county ? data?.guilty?.county : null,
        city: data?.guilty?.city ? data?.guilty?.city : null,
      },
      injured: {
        id: data.injuredId,
        identityCardId: data?.injured?.identityCardId ? data?.injured?.identityCardId : null,
        insuranceId: data?.injured?.insuranceId ? data?.injured?.insuranceId : null,
        drivingLicenseId: data?.injured?.driverLicenseId ? data?.injured?.driverLicenseId : null,
        carIdentityId: data?.injured?.vehicleRegistrationCertificateId ? data?.injured?.vehicleRegistrationCertificateId : null,
        documents: documentsInitialState,
        damage: {
          zones: data.injured?.damages ? data.injured?.damages?.zone : '',
          description: data?.injured?.damages ? data.injured?.damages?.description : '',
          photoProofs: data?.injured?.photoProofs || '',
        },
        witnesses: data?.witnesses ? data?.witnesses : null,
        personType: data?.injured?.type ? data?.injured?.type : null,
        userSignature: data?.injured?.signatureId ? data?.injured?.signatureId?.toString() : null,
        phoneNumber: data?.injured?.phone ? data?.injured?.phone : null,
        email: data?.injured?.email ? data?.injured?.email : null,
        companyName: data?.injured?.companyName ? data?.injured?.companyName : null,
        cui: data?.injured?.cui ? data?.injured?.cui : null,
        address: data?.injured?.address ? data?.injured?.address : null,
        insuredPhone: data?.injured?.insuredPhone ? data?.injured?.insuredPhone : null,
        insuredEmail: data?.injured?.insuredEmail ? data?.injured?.insuredEmail : null,
        county: data?.injured?.county ? data?.injured?.county : null,
        city: data?.injured?.city ? data?.injured?.city : null,
      },
      finalSignatureId: data?.injured?.finalSignatureId ?? null,
      verifiedIdentity: data?.injured?.verifiedIdentity ?? null,
    };
  };

  const fetchStatements = useCallback(
    async (callbackFunction?: () => void) => {
      try {
        dispatch(fetchStart());
        const response = await getStatements();
        const states = response?.data.map(item => transformFormatDbToFront(item));
        dispatch(setStatement(states));
        callbackFunction && callbackFunction();
        dispatch(fetchSuccess());
      } catch (e) {
        console.log(e);
        dispatch(fetchError(e.message));
      }
    },
    [dispatch],
  );

  const statementsState = useSelector((state: RootState) => {
    return state.statements.data;
  });

  const statementsFilter = useSelector((state: RootState) => {
    return state.ui.statements.filter;
  });

  const addNewStatement = useCallback(
    (currentStatement: ICurrentStatement) => {
      dispatch(addStatement(currentStatement));
    },
    [dispatch, statementsState],
  );

  const updateExistingStatement = (currentStatement: ICurrentStatement) => {
    statementsState.filter((statement: ICurrentStatement) => {
      return statement.statementID === currentStatement.statementID;
    });
  };

  const getStatementsByType = (type?: STATEMENT_TYPES) => {
    return statementsState.filter(getStatementsByFilter(type));
  };

  const getStatementsByFilter = (type?: STATEMENT_TYPES) => (statement: ICurrentStatement) => {
    const isMatchingType = statement.type === type;

    if (!isMatchingType) {
      return false;
    }

    const inProgressStatuses = [
      EVALUATION_STATUS.EVALUATION_INCOMPLETE,
      EVALUATION_STATUS.EVALUATION_AWAITING_USER_PAYMENT,
      EVALUATION_STATUS.EVALUATION_AWAITING_ADMIN_REVIEW,

      NOTIFICATION_STATUS.NOTIFICATION_INCOMPLETE,
      NOTIFICATION_STATUS.NOTIFICATION_AWAITING_ADMIN,

      STATEMENT_STATUS.STATEMENT_INCOMPLETE,
      STATEMENT_STATUS.STATEMENT_AWAITING_ADMIN_PAYMENT,
    ];

    const finishStatuses = [
      NOTIFICATION_STATUS.NOTIFICATION_FINISHED,
      EVALUATION_STATUS.EVALUATION_FINISHED,
      STATEMENT_STATUS.STATEMENT_FINISHED,
    ];

    switch (statementsFilter) {
      case STATEMENT_FILTERS.ALL:
        return isMatchingType;
      case STATEMENT_FILTERS.IN_PROGRESS:
        return isMatchingType && inProgressStatuses.includes(statement.status);
      case STATEMENT_FILTERS.FINISHED:
        return isMatchingType && finishStatuses.includes(statement.status);
    }

    return false;
  };

  const getStatementsByStatus = () => {
    return statementsState.map((item, index) => ({ ...item, index })).filter(getStatementsByFilter());
  };

  const getActiveStatementsCount = () =>
    getStatementsByStatus().filter((statement: ICurrentStatement) => statement.status === STATEMENT_STATUS.STATEMENT_INCOMPLETE).length;

  const deleteEvaluationAction = async (id: number, onSuccess?: () => void, onError?: () => void) => {
    try {
      await deleteEvaluation(id);

      if (onSuccess) onSuccess();
    } catch (e) {
      if (onError) onError();
    }

    await fetchStatements();
  };

  const getStatementById = useCallback(
    (id: number) => {
      dispatch(
        getStatementByIdApi(id, statement => {
          const statementData = transformFormatDbToFront(statement);

          if (statement.amicableFindingId) {
            setQuestionsFlowHandler({ completedDocumentsAfterAccident: COMPLETED_DOCUMENTS_TYPE.AMICABLE_FINDING });
          } else if (statement.policeReportId) {
            setQuestionsFlowHandler({ completedDocumentsAfterAccident: COMPLETED_DOCUMENTS_TYPE.POLICE_REPORT });
          }
          dispatch(setStatementAction(statementData));
        }),
      );
    },
    [dispatch],
  );

  return {
    getStatementsByStatus,
    getStatementsByType,
    statementsState,
    addNewStatement,
    updateExistingStatement,
    transformFormatDbToFront,
    fetchStatements,
    getStatementById,
    getActiveStatementsCount,
    deleteEvaluationAction,
  };
};
export { useStatements };
