import React, { ChangeEvent } from 'react';
import { createStyles, InputAdornment, makeStyles, TextField } from '@material-ui/core';

import { SearchIcon } from '../../assets/icons';
import { GoogleMapSearchProps } from '../../@types/google-map/GoogleMap.types';

const useStyles = makeStyles(() =>
  createStyles({
    search: {
      backgroundColor: 'white',
      padding: 3,
    },
    searchInput: {
      padding: 10,
      '&::placeholder': {
        fontStyle: 'oblique',
      },
    },
    searchField: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#F0F2FC !important',
          borderRadius: '0 !important',
          borderWidth: '1px !important',
        },
      },
    },
  }),
);

export const GoogleMapSearch = (props: GoogleMapSearchProps): JSX.Element => {
  const classes = useStyles();

  const { value, onChange, placeholder } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    onChange(value);
  };

  return (
    <TextField
      InputLabelProps={{ shrink: false }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>
        ),
        className: classes.search,
        classes: {
          input: classes.searchInput,
        },
      }}
      className={classes.searchField}
      placeholder={placeholder}
      value={value}
      variant='outlined'
      fullWidth
      onChange={handleChange}
    />
  );
};
