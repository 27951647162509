import React from 'react';

import { IIconProps } from './icon.model';

export const ArrowLeftIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 16} viewBox='0 0 16 16' width={width || 16} {...otherProps}>
      <path
        d='M14.5825 6.5925H3.4125L8.2925 1.7125C8.6825 1.3225 8.6825 0.6825 8.2925 0.2925C7.9025 -0.0975 7.2725 -0.0975 6.8825 0.2925L0.2925 6.8825C-0.0975 7.2725 -0.0975 7.9025 0.2925 8.2925L6.8825 14.8825C7.2725 15.2725 7.9025 15.2725 8.2925 14.8825C8.6825 14.4925 8.6825 13.8625 8.2925 13.4725L3.4125 8.5925H14.5825C15.1325 8.5925 15.5825 8.1425 15.5825 7.5925C15.5825 7.0425 15.1325 6.5925 14.5825 6.5925Z'
        fill={fill || '#512AD8'}
      />
    </svg>
  );
};
