import styled from 'styled-components';
import React from 'react';

import { IThemedTypography } from './types';

const StyledTextBase = styled.p`
  font-family: 'Roboto';
`;
type IParagraphProps = IThemedTypography & React.HTMLProps<typeof StyledTextBase>;

const StyledHeading = styled.p<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  color: ${({ color }) => color || '#000'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  text-align: ${({ align }) => align || 'left'};
`;

const StyledText = styled.p<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  font-style: ${({ fontStyle }) => fontStyle || 'regular'};
  color: ${({ color }) => color || '#000'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  font-style: ${({ extendedStyles }) => extendedStyles?.fontStyle || 'normal'};
  text-align: ${({ align }) => align || 'left'};
`;

const Heading1 = styled.h1<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
  color: ${({ color }) => color || '#000'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  text-align: ${({ align }) => align || 'left'};
  font-size: 30px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '36px'};
  position: ${({ position }) => position || 'static'};
`;

const Heading2 = styled.h2<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
  color: ${({ color }) => color || '#000'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  text-align: ${({ align }) => align || 'left'};
  font-size: 26px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '29px'};
  position: ${({ position }) => position || 'static'};
`;

const Heading3 = styled.h3<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  color: ${({ color }) => color || '#000'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  text-align: ${({ align }) => align || 'left'};
  font-size: 24px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '24px'};
  position: ${({ position }) => position || 'static'};
`;

const Heading4 = styled.h4<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  color: ${({ color }) => color || '#000'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  text-align: ${({ align }) => align || 'left'};
  font-size: ${({ fontSize }) => fontSize || '20px'};
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '24px'};
  letter-spacing: ${({ extendedStyles }) => extendedStyles?.letterSpacing || '0.02em'};
  position: ${({ position }) => position || 'static'};
`;

const Heading5 = styled.h5<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  color: ${({ color }) => color || '#000'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  text-align: ${({ align }) => align || 'left'};
  font-size: 15px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '18px'};
  position: ${({ position }) => position || 'static'};
`;

const Heading6 = styled.h6<IParagraphProps>`
  font-family: 'Roboto';
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
  width: ${({ commonStyles }) => commonStyles?.width || 'auto'};
  color: ${({ color }) => color || '#000'};
  margin: ${({ commonStyles }) => commonStyles?.margin || '0'};
  padding: ${({ commonStyles }) => commonStyles?.padding || '0'};
  text-align: ${({ align }) => align || 'left'};
  font-size: 13px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '21px'};
  position: ${({ position }) => position || 'static'};
`;

const TBody = styled(StyledText)`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-wrap'};
  font-style: ${({ fontStyle }) => fontStyle || 'unset'};
  font-family: 'Roboto';
  font-weight: ${({ fontWeight }: IParagraphProps) => fontWeight || 'unset'};
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '17px'};
  letter-spacing: ${({ extendedStyles }) => extendedStyles?.letterSpacing || '0.02em'};
  color: ${({ color }) => color || '#000'};
  position: ${({ position }) => position || 'static'};
`;

const TBodySmall = styled(StyledText)`
  font-size: 13px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '18px'};
  position: ${({ position }) => position || 'static'};
`;

const TBodyXS = styled(StyledText)`
  font-size: 12px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '18px'};
  position: ${({ position }) => position || 'static'};
`;

const Caption = styled(StyledText)`
  font-size: 12px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '16px'};
  position: ${({ position }) => position || 'static'};
`;

const Button = styled(StyledHeading)`
  font-size: 15px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '17.78px'};
  position: ${({ position }) => position || 'static'};
`;

const ButtonRegular = styled(StyledText)`
  font-size: 15px;
  line-height: 17.78px;
  position: ${({ position }) => position || 'static'};
`;

const Tab = styled(StyledText)`
  font-size: 16px;
  line-height: ${({ extendedStyles }) => extendedStyles?.lineHeight || '19px'};
  position: ${({ position }) => position || 'static'};
`;

const Subtitle = styled(StyledText)`
  font-size: 16px;
  line-height: 26px;
  color: #181d1e99;
  position: ${({ position }) => position || 'static'};
`;

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  TBody,
  TBodySmall,
  TBodyXS,
  Caption,
  Button,
  Tab,
  Subtitle,
  ButtonRegular,
};
