import React from 'react';

import { IIconProps } from './icon.model';

export const AssessmentIcon = ({ width, height, selected, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg height={height || 30} width={width || 30} {...otherProps}>
      <rect fill={selected ? '#4123D7' : '#FFF'} height={30} rx={15} width={30} />
      <path
        d='M21.082 25H9.695c-1.048 0-1.898-.895-1.898-2V7c0-1.105.85-2 1.898-2h6.643c.01 0 .02.002.03.006l.028.006a.92.92 0 01.246.051l.027.009a.935.935 0 01.34.228l5.694 6a.99.99 0 01.19.293c.01.022.016.045.024.068l.008.026c.027.083.042.17.047.258.001.01.003.018.006.027.002.01.003.019.002.028v11c0 1.105-.85 2-1.898 2zM9.695 7v16h11.387V13h-4.744c-.525 0-.95-.448-.95-1V7H9.696zm7.591 1.414V11h2.455l-2.454-2.586z'
        fill={selected ? '#FFF' : '#8E8D8D'}
      />
    </svg>
  );
};
