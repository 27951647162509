import React from 'react';
import { useTranslation } from 'react-i18next';

import { AddPictureContainer } from '../AddPictureContainer';
import { ScreenContainer } from '../../../components/containers/ScreenContainer';
import { Typography, Wrapper } from '../../../styled-components';
import { HOME_TEXTS } from '../../../translations/ro/home';
import { useCurrentStatement } from '../../../hooks/useCurrentStatement';
import { IPhotoProofs } from '../../../@types/damage/damageType';

export const DamagePictureList = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentStatement } = useCurrentStatement();

  return (
    <ScreenContainer headerProps={{ title: t('affectedZones.title'), titleSize: 'h5', hideLogo: true }}>
      <Typography commonStyles={{ margin: '0 auto 25px 0' }} fontWeight='700' variant='h4'>
        {HOME_TEXTS.affectedZones}
      </Typography>

      <Wrapper flexWrap='wrap' width='100%'>
        {currentStatement.injured?.damage?.photoProofs &&
          currentStatement.injured.damage.photoProofs.map((index: IPhotoProofs) => {
            const image = `${process.env.REACT_APP_API_URL}/attachments/file/${index.attachmentId}?token=${localStorage.getItem(
              'access_token',
            )}`;

            return (
              <AddPictureContainer
                key={index.id}
                id={index.id}
                image={image}
                label={index.type}
                modalLabel='list'
                pictureContainerType='damage'
                type={index.type === 'Filmare de ansamblu' ? 'video' : 'image'}
              />
            );
          })}
      </Wrapper>
    </ScreenContainer>
  );
};
