import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import authSlice from './auth-user/auth.slice';
import statementsSlice from './statements/statements.slice';
import statementSlice from './statement/statement.slice';
import authenticationSlice from './app/app.slice';
import preLoginSlice from './pre-login/preLogin.slice';
import lastRoutesSlice from './last-routes/last-routes.slice';
import questionsFlowHandlerSlice from './questions-flow-handler/questions-flow-handler.slice';
import uiSlice from './ui/ui.slice';

const reducers = {
  authUser: authSlice,
  statements: statementsSlice,
  currentStatement: statementSlice,
  appStatus: authenticationSlice,
  preLogin: preLoginSlice,
  lastRoutes: lastRoutesSlice,
  questionsFlowHandler: questionsFlowHandlerSlice,
  ui: uiSlice,
};

const rootReducer = (history: History) =>
  combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
