import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

export interface IProgressBarProps {
  value: number;
}

interface IThemedProgressBar extends IProgressBarProps {
  theme: DefaultTheme;
}

const StyledProgressBar = styled.div`
  width: 100%;
  height: 6px;
  background-color: #bebebe;
  position: relative;
`;

const ProgressBarFill = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #ffcd07;
  width: ${({ value }: IThemedProgressBar) => value}%;
`;

export const ProgressBar = (props: IProgressBarProps): JSX.Element => (
  <StyledProgressBar>
    <ProgressBarFill {...props} />
  </StyledProgressBar>
);
