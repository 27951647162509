import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { ObjectSchema } from 'yup';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import moment from 'moment';

import { TextField } from '../text_field';
import { Button } from '../button';
import { Column, Wrapper } from '../../styled-components';
import { HOME_TEXTS } from '../../translations/ro/home';
import { Typography } from '../../styled-components';
import { ICurrentStatementInsurance } from '../../@types/current-statement/currentStatementTypes';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { MYSELF } from '../../services/router/routeHandles';
import { momentDateFormat } from '../../shared/helpers';
import { CustomDatePickerToolbar } from '../date-picker/custom-date-picker-toolbar';
import { INSURANCE_SERIES_REGEX, LETTERS_AND_NUMBERS_REGEX, PLATE_NUMBER_REGEX } from '../../shared/constants';
import { validateCnp, validateCuiOrCNP } from '../../utils/form-validators';

interface IDetailsFormProps {
  initialValues: ICurrentStatementInsurance['data'];
  onSubmit: (value: ICurrentStatementInsurance['data']) => void;
  history: H.History<any>;
  parentRoute: string;
}

export const InsuranceDetailsForm = ({ onSubmit, initialValues }: IDetailsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentStatement } = useCurrentStatement();
  const [carDriverFullName, setCarDriverFullName] = useState<string>('');
  let validationSchema: ObjectSchema<any> = Yup.object().shape({
    insuranceNo: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false)),
    insuranceSeries: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 101 : false))
      .matches(INSURANCE_SERIES_REGEX, t('ocrForms.invalidInsuranceSeries')),
    insuranceOwnerName: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : false)),
    cnp: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 14 : false))
      .test('validate-cnp', t('commonText.invalidCnpOrCuiError'), validateCuiOrCNP),
    insuranceOwnerAddress: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : false)),
    carMakeAndModel: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .matches(LETTERS_AND_NUMBERS_REGEX, t('ocrForms.invalidFieldOnlyLettersAndDigits'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    carPlate: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .matches(PLATE_NUMBER_REGEX, t('ocrForms.invalidFieldCarPlate'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false)),
    registrationNumber: Yup.string()
      .min(17, t('commonText.invalidFormat'))
      .max(17, t('commonText.invalidMaxLimit'))
      .required(t('commonText.requiredFieldError'))
      .matches(LETTERS_AND_NUMBERS_REGEX, t('ocrForms.invalidFieldOnlyLettersAndDigits')),
    insuranceCreatedAt: Yup.date()
      .required(t('commonText.requiredFieldError'))
      .typeError(t('commonText.invalidDateFormat', { date: initialValues.insuranceDateTo }))
      .test('validate-date', t('commonText.invalidInsuranceCreatedDate'), value => {
        const date = moment(value);

        return date && date >= moment().startOf('day').subtract(13, 'months') && date <= moment().startOf('day').subtract(1, 'day');
      })
      .nullable(),
    insuranceDateFrom: Yup.date()
      .required(t('commonText.requiredFieldError'))
      .typeError(t('commonText.invalidDateFormat', { date: initialValues.insuranceDateTo }))
      .test('validate-date', t('commonText.invalidInsuranceDateFrom'), value => {
        const date = moment(value);

        return date && date >= moment().startOf('day').subtract(12, 'months') && date <= moment().startOf('day').subtract(1, 'day');
      })
      .nullable(),
    insuranceDateTo: Yup.date()
      .required(t('commonText.requiredFieldError'))
      .min(Yup.ref('insuranceDateFrom'), t('commonText.endDateError'))
      .typeError(t('commonText.invalidDateFormat', { date: initialValues.insuranceDateTo }))
      .nullable()
      .test('validate-date', t('commonText.invalidInsuranceDateTo'), value => {
        const date = moment(value);

        return date && date >= moment().startOf('day');
      })
      .default(null),
    seats: Yup.number()
      .required(t('commonText.requiredFieldError'))
      .min(1, t('ocrForms.invalidSeatsNumber'))
      .max(100, t('ocrForms.invalidSeatsNumber')),
    engineCapacity: Yup.number()
      .typeError(t('commonText.requiredFieldError'))
      .required(t('commonText.requiredFieldError'))
      .min(1, t('ocrForms.invalidCubicCapacity'))
      .max(10000, t('ocrForms.invalidCubicCapacity')),
    civNumber: Yup.string()
      .required(t('commonText.requiredFieldError'))
      .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 51 : false))
      .matches(LETTERS_AND_NUMBERS_REGEX, t('ocrForms.invalidFieldOnlyLettersAndDigits')),
  });

  if (currentStatement.carOwner !== MYSELF && carDriverFullName.length < 5) {
    validationSchema = Yup.object().shape({
      ...validationSchema.fields,
      carDriverFullName: Yup.string().test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : true)),
      carDriverCnp: Yup.string()
        .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 14 : true))
        .test('validate-cnp', t('commonText.invalidCnpError'), validateCnp),
    });
  } else if (currentStatement.carOwner !== MYSELF && carDriverFullName.length > 5) {
    validationSchema = Yup.object().shape({
      ...validationSchema.fields,
      carDriverFullName: Yup.string().test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 251 : true)),
      carDriverCnp: Yup.string()
        .test('len', t('commonText.invalidMaxLimit'), val => (val ? val.length < 14 : true))
        .test('validate-cnp', t('commonText.invalidCnpError'), validateCnp),
    });
  }

  return (
    <Formik initialValues={initialValues || {}} validationSchema={validationSchema} enableReinitialize validateOnMount onSubmit={onSubmit}>
      {({ handleChange, handleBlur, isValid, values, errors, setFieldValue }) => (
        <Form>
          <TextField
            errorMessage={errors.insuranceSeries}
            label={t('ocrForms.insuranceSeries')}
            name='insuranceSeries'
            position='relative'
            value={values?.insuranceSeries}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextField
            errorMessage={errors.insuranceNo}
            label={t('ocrForms.insuranceNo')}
            name='insuranceNo'
            value={values?.insuranceNo}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <TextField
            errorMessage={errors.insuranceOwnerName}
            label={t('ocrForms.insuranceOwnerName')}
            name='insuranceOwnerName'
            value={values?.insuranceOwnerName}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.cnp}
            label={t('ocrForms.cnp')}
            name='cnp'
            type='number'
            value={values?.cnp}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <TextField
            errorMessage={errors.insuranceOwnerAddress}
            label={t('ocrForms.insuranceOwnerAddress')}
            name='insuranceOwnerAddress'
            value={values?.insuranceOwnerAddress}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.carMakeAndModel}
            label={t('ocrForms.carMakeAndModel')}
            name='carMakeAndModel'
            value={values?.carMakeAndModel}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextField
            errorMessage={errors.carPlate}
            label={t('ocrForms.carPlate')}
            name='carPlate'
            value={values?.carPlate}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <TextField
            errorMessage={errors.registrationNumber}
            label={t('ocrForms.registrationNumber')}
            name='registrationNumber'
            value={values?.registrationNumber}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />
          <TextField
            errorMessage={errors.civNumber}
            label={t('ocrForms.civNumber')}
            name='civNumber'
            value={values?.civNumber ?? ''}
            variant='rounded'
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
          />

          <Wrapper justifyContent='space-between'>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <TextField
                errorMessage={errors.engineCapacity}
                label={t('ocrForms.engineCapacity')}
                name='engineCapacity'
                type='number'
                value={values?.engineCapacity}
                variant='rounded'
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
              />
            </Column>
            <Column maxWidth='4%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0' />
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <TextField
                errorMessage={errors.seats}
                label={t('ocrForms.seats')}
                name='seats'
                type='number'
                value={values?.seats}
                variant='rounded'
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
              />
            </Column>
          </Wrapper>

          <Typography color='#9a9a9a' variant='body-xs'>
            {t('ocrForms.insuranceDateTo')}
          </Typography>
          <DatePicker
            ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
            cancelLabel={t('commonText.cancel')}
            className='date-picker'
            error={Boolean(errors.insuranceCreatedAt)}
            format='DD.MM.YYYY'
            helperText={errors.insuranceCreatedAt}
            maxDate={moment().subtract(1, 'day')}
            minDate={moment().subtract(13, 'months')}
            name='insuranceCreatedAt'
            okLabel={t('commonText.save')}
            value={values?.insuranceCreatedAt || null}
            onBlur={handleBlur}
            onChange={value => setFieldValue('insuranceCreatedAt', momentDateFormat(value))}
          />
          <Typography color='#9a9a9a' variant='body-xs'>
            {HOME_TEXTS.insuranceValidity}
          </Typography>
          <Wrapper alignItems='flex-start' justifyContent='space-between'>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <DatePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
                cancelLabel={t('commonText.cancel')}
                className='date-picker'
                error={Boolean(errors.insuranceDateFrom)}
                format='DD.MM.YYYY'
                helperText={errors.insuranceDateFrom}
                maxDate={moment().subtract(1, 'day')}
                minDate={moment(values?.insuranceCreatedAt).add(1, 'day') && moment().subtract(12, 'month')}
                name='insuranceDateFrom'
                okLabel={t('commonText.save')}
                value={values?.insuranceDateFrom || null}
                onBlur={handleBlur}
                onChange={value => setFieldValue('insuranceDateFrom', momentDateFormat(value))}
              />
            </Column>
            <Column maxWidth='4%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='24px' textAlign='center'>
              -
            </Column>
            <Column maxWidth='48%' paddingBottom='0' paddingLeft='0' paddingRight='0' paddingTop='0'>
              <DatePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
                cancelLabel={t('commonText.cancel')}
                className='date-picker'
                error={Boolean(errors.insuranceDateTo)}
                format='DD.MM.YYYY'
                helperText={errors.insuranceDateTo}
                maxDate={moment(values?.insuranceDateFrom).add(12, 'months') || ''}
                minDate={
                  moment(values?.insuranceDateFrom) < moment().subtract(1, 'month')
                    ? moment()
                    : moment(values?.insuranceDateFrom).add(1, 'month')
                }
                name='insuranceDateTo'
                okLabel={t('commonText.save')}
                value={values?.insuranceDateTo || null}
                onBlur={handleBlur}
                onChange={value => setFieldValue('insuranceDateTo', momentDateFormat(value))}
              />
            </Column>
          </Wrapper>
          {currentStatement.carOwner !== MYSELF && (
            <>
              <Typography color='#9a9a9a' commonStyles={{ margin: '10px 0 0 0' }} variant='body-small'>
                {t('ocrForms.carDriver')}
              </Typography>
              <TextField
                error={Boolean(errors.carDriverFullName)}
                errorMessage={errors.carDriverFullName}
                label={t('ocrForms.carDriverFullName')}
                name='carDriverFullName'
                value={values?.carDriverFullName}
                variant='rounded'
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);
                  setCarDriverFullName(e.target.value);
                }}
              />
              <TextField
                disabled={!values.carDriverFullName || values?.carDriverFullName?.length < 5 ? true : false}
                errorMessage={errors.carDriverCnp}
                label={t('ocrForms.carDriverCnp')}
                name='carDriverCnp'
                type='number'
                value={values?.carDriverCnp}
                variant='rounded'
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={evt => evt.key === '-' && evt.preventDefault()}
              />
            </>
          )}

          <Button
            background='linear-gradient(270deg, #813EDA 0%, #4123D7 100%)'
            border='none'
            borderRadius='25px'
            color='#FFFF'
            disabled={!isValid}
            disabledColor='#DADADA'
            margin='15px 0 10px 0'
            type='submit'
            onClick={() => onSubmit(values)}
          >
            {t('commonText.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
