import React from 'react';

import { IIconProps } from './icon.model';

export const MinusIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 10} viewBox='0 0 18 3' width={width || 20} xmlns='http://www.w3.org/2000/svg' {...otherProps}>
      <path d='M0 2.5V0.7H18V2.5H0Z' fill='#0066FF' />
    </svg>
  );
};
