import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { $lineHeightXL } from '../../styles/variables';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { IScreenComponentProps } from '../component-props.model';

export const InsurancePrompt = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const pageTitle = t('insurancePrompt.insurancePicture');
  const subtitle = t('insurancePrompt.continueWithInsurancePicture');

  return (
    <ScreenContainer
      backgroundColor='#F0F2FC'
      headerProps={{ hideLogo: true, showButton: false, title: pageTitle, titleSize: 'h5' }}
      headerType='light'
      showBottomNavbar
    >
      <Wrapper display='flex' flexDirection='column' height='100%' justifyContent='space-between'>
        <Typography
          align='left'
          commonStyles={{ width: '100%' }}
          extendedStyles={{ lineHeight: $lineHeightXL }}
          fontWeight='700'
          variant='h3'
        >
          {subtitle}
        </Typography>
        <Button borderRadius='25px' height='50px' mode='primary' width='100%' onClick={() => history.push(nextRoute)}>
          {t('commonText.continue')}
        </Button>
      </Wrapper>
    </ScreenContainer>
  );
};
