import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import { IAuthUserModel } from '../../store/auth-user/auth.model';
import { logUserAction } from '../../store/auth-user/auth.action';
import { logInAction } from '../../store/app/app.action';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Wrapper } from '../../styled-components';
import { AuthRoutes } from '../../services/router/routeAccessRights';
import { insuredAssignAndRespondAction } from '../../store/statement/currentStatement.action';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { usePreLogin } from '../../hooks/use-preLogin';
import { RootState } from '../../store/rootReducer';

export const AuthSuccess = ({ location, history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);
  const questionsFlowHandlerData = useSelector((state: RootState) => state.questionsFlowHandler.data);
  const dispatch = useDispatch();
  const { preLoginState } = usePreLogin();

  const attachInsuredToStatement = (statementId: string, userAcceptsOffer: boolean) => {
    dispatch(
      insuredAssignAndRespondAction({ id: parseInt(statementId), response: userAcceptsOffer }, () => {
        localStorage.removeItem('statement');
        localStorage.removeItem('response');
        history.push(AuthRoutes.evaluations);
      }),
    );
  };

  useEffect(() => {
    const sessionToken = query.get('token');

    if (sessionToken) {
      const authData: IAuthUserModel = jwt_decode(sessionToken);
      dispatch(logUserAction(authData));
      dispatch(logInAction(sessionToken));
    }
  }, [dispatch, nextRoute, query, preLoginState, isLoggedIn]);

  const handleRedirectAfterLogin = () => {
    if (!isLoggedIn) {
      return;
    }

    const questionnaireStarted = Object.keys(questionsFlowHandlerData).length;
    const preLoginOwnerStatus = preLoginState.carOwner;
    const isInTheMiddleOfProcess = questionnaireStarted && !preLoginOwnerStatus;

    if (isInTheMiddleOfProcess) {
      return history.push(PROTECTED_ROUTES.ownerStatus);
    }

    return history.push(AuthRoutes.evaluations);
  };

  useEffect(handleRedirectAfterLogin, [isLoggedIn]);

  return (
    <ScreenContainer
      backgroundColor='#F0F2FC'
      headerProps={{ hideLogo: true, showButton: false, title: 'Logging in...', titleSize: 'h5' }}
      headerType='light'
      showBottomNavbar
    >
      <Wrapper display='flex' flexDirection='column' height='100%' justifyContent='space-between' />
    </ScreenContainer>
  );
};
