import { STATEMENT_TYPES } from '../../store/types';

export enum EVALUATION_STATUS {
  EVALUATION_INCOMPLETE = 'EVALUATION_INCOMPLETE',
  EVALUATION_AWAITING_USER_PAYMENT = 'EVALUATION_AWAITING_USER_PAYMENT',
  EVALUATION_AWAITING_ADMIN_REVIEW = 'EVALUATION_AWAITING_ADMIN_REVIEW',
  EVALUATION_FINISHED = 'EVALUATION_FINISHED',
}

export enum STATEMENT_STATUS {
  STATEMENT_INCOMPLETE = 'STATEMENT_INCOMPLETE',
  STATEMENT_AWAITING_ADMIN_PAYMENT = 'STATEMENT_AWAITING_ADMIN_PAYMENT',
  STATEMENT_FINISHED = 'STATEMENT_FINISHED',
}

export enum NOTIFICATION_STATUS {
  NOTIFICATION_INCOMPLETE = 'NOTIFICATION_INCOMPLETE',
  NOTIFICATION_AWAITING_ADMIN = 'NOTIFICATION_AWAITING_ADMIN',
  NOTIFICATION_FINISHED = 'NOTIFICATION_FINISHED',
}

const FINISH_STATUS_BY_TYPE = {
  [STATEMENT_TYPES.STATEMENT]: STATEMENT_STATUS.STATEMENT_FINISHED,
  [STATEMENT_TYPES.NOTIFICATION]: NOTIFICATION_STATUS.NOTIFICATION_FINISHED,
  [STATEMENT_TYPES.EVALUATION]: EVALUATION_STATUS.EVALUATION_FINISHED,
};
