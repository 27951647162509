import React from 'react';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Wrapper } from '../../styled-components';
import EvaluationsTabs from '../../screens/evaluations/EvaluationsTabs';
import { RootState } from '../../store/rootReducer';
import { STATEMENT_FILTERS } from '../../store/ui/ui.model';
import { setStatementFilter } from '../../store/ui/ui.slice';

interface IDashboardHomeNav {
  history: H.History;
}

const DashboardHomeNav = ({ history }: IDashboardHomeNav): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterValue = useSelector((state: RootState) => state.ui.statements.filter);

  const changeRoute = (route: string) => {
    history.push(route);
  };

  const menu = [
    { name: t('bottomNavigationLabels.notifications'), url: '/notifications', onClick: () => changeRoute('/notifications') },
    { name: t('bottomNavigationLabels.evaluations'), url: '/evaluations', onClick: () => changeRoute('/evaluations') },
  ];

  const isActive = (path: string) => {
    return window.location.href.includes(path);
  };

  const changeFilter = (filter: STATEMENT_FILTERS) => {
    dispatch(setStatementFilter(filter));
  };

  return (
    <>
      <Wrapper alignItems='center' backgroundColor='#8875e6' height='77px' justifyContent='space-around' position='relative' width='100%'>
        <Wrapper borderRadius='0' boxShadow='0px 0px 5px lightgrey' height='100%' width='100%'>
          <Wrapper height='100%' justifyContent='space-around' padding='0' width='100%'>
            {menu.map(menu => (
              <Wrapper key={menu.url} onClick={menu.onClick}>
                <Typography
                  align='center'
                  color={isActive(menu.url) ? '#512AD8' : '#FFF'}
                  commonStyles={{ margin: '0 25px' }}
                  fontSize='20px'
                  fontWeight='700'
                  variant='h4'
                >
                  {menu.name}
                </Typography>
              </Wrapper>
            ))}
          </Wrapper>
        </Wrapper>
      </Wrapper>
      <EvaluationsTabs
        menu={[
          { name: t('filters.all'), active: filterValue === STATEMENT_FILTERS.ALL, onClick: () => changeFilter(STATEMENT_FILTERS.ALL) },
          {
            name: t('filters.inProgress'),
            active: filterValue === STATEMENT_FILTERS.IN_PROGRESS,
            onClick: () => changeFilter(STATEMENT_FILTERS.IN_PROGRESS),
          },
          {
            name: t('filters.finished'),
            active: filterValue === STATEMENT_FILTERS.FINISHED,
            onClick: () => changeFilter(STATEMENT_FILTERS.FINISHED),
          },
        ]}
      />
    </>
  );
};

export default DashboardHomeNav;
