import React from 'react';

import { IIconProps } from './icon.model';

export const IdBadgeIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg height={height || 24.45} width={width || 18.337} {...otherProps}>
      <path
        d='M16.045 0H2.292A2.293 2.293 0 000 2.292v19.866a2.293 2.293 0 002.292 2.292h13.753a2.293 2.293 0 002.292-2.292V2.292A2.293 2.293 0 0016.045 0zM6.877 1.528h4.584a.764.764 0 010 1.528H6.877a.764.764 0 110-1.528zm2.292 6.113A3.056 3.056 0 116.112 10.7a3.059 3.059 0 013.057-3.059zm5.348 11.308a1 1 0 01-1.07.917H4.89a1 1 0 01-1.07-.917v-.917a3.007 3.007 0 013.209-2.751h.239a4.919 4.919 0 003.8 0h.239a3.007 3.007 0 013.209 2.751z'
        fill={fill || '#fc0'}
      />
    </svg>
  );
};
