/*
  Palette
*/

export type PaletteTypes = 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink';

export type IPaletteTypes<T> = {
  [k in PaletteTypes]: {
    light: T;
    dark: T;
  };
};

export const palette: IPaletteTypes<string> = {
  red: {
    light: '#FF3B30',
    dark: '#FF453A',
  },
  orange: {
    light: '#FF9500',
    dark: '#FF9F0A',
  },
  yellow: {
    light: '#FFCC00',
    dark: '#f6c604',
  },
  green: {
    light: '#34C759',
    dark: '#32D74B',
  },
  teal: {
    light: '#5AC8FA',
    dark: '#64D2FF',
  },
  blue: {
    light: '#007AFF',
    dark: '#0A84FF',
  },
  indigo: {
    light: '#5856D6',
    dark: '#5E5CE6',
  },
  purple: {
    light: '#AF52DE',
    dark: '#BF5AF2',
  },
  pink: {
    light: '#FF2D55',
    dark: '#FF375F',
  },
};
