import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { STATEMENT_TYPES } from '../../store/types';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { RootState } from '../../store/store';
import { IQuestionScreenType, useQuestionsContent } from '../../constants/dataConstants/questionsContent';
import { IScreenComponentProps } from '../component-props.model';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';
import { SecondAmicableFindingModal } from '../../components/QuestionModals/SecondAmicableFindingModal';
import { ThirdAmicableFindingModal } from '../../components/QuestionModals/ThirdAmicableFindingModal';
import { ShowNotificationModal } from '../../components/QuestionModals/ShowNotificationModal';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { SwitchToEvaluationModal } from '../../components/QuestionModals/SwitchToEvaluationModal';
import { INJURED_STATUS } from '../../services/router/routeHandles';

import { QuestionsTemplate } from './QuestionsTemplate';

enum ModalContentTypes {
  COMPENSATION = 'COMPENSATION',
  INFORMATION = 'INFORMATION',
  NOTIFICATION = 'NOTIFICATION',
  SWITCH_TO_EVALUATION = 'SWITCH_TO_EVALUATION',
}

export const AmicableFindings: React.FC<IScreenComponentProps> = ({ history, nextRoute, location }: IScreenComponentProps) => {
  const statementType = useSelector((state: RootState) => state.questionsFlowHandler.data.type);
  const URL_PARAM_ID = location?.state?.URL_PARAM_ID;
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);

  const [modalContent, setModalContent] = useState<ModalContentTypes | null>(null);

  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();
  const { QUESTIONS_SCREENS_CONSTANTS } = useQuestionsContent();
  const { AMICABLE_FINDING } = QUESTIONS_SCREENS_CONSTANTS;

  const MATCHED_QUESTION_SCREEN_DATA = AMICABLE_FINDING.find((index: IQuestionScreenType) => index.id === URL_PARAM_ID);

  const handleSecondQuestion = (answer: boolean) => {
    setQuestionsFlowHandler({ compensationOffer: answer });

    switch (statementType) {
      case STATEMENT_TYPES.EVALUATION:
        history.push(PRE_LOGIN_ROUTES.chooseInsuranceCompany, { flowHandle: INJURED_STATUS });

        break;
      case STATEMENT_TYPES.NOTIFICATION:
        return;
      default:
        history.push(PRE_LOGIN_ROUTES.root);
    }
  };

  // Dosar asigurari deschis
  const handleFirstQuestion = (answer: boolean): void => {
    setQuestionsFlowHandler({ observationNote: answer });

    switch (statementType) {
      case STATEMENT_TYPES.EVALUATION:
        if (answer) {
          history.push(generatePath(PRE_LOGIN_ROUTES.amicableFindings, { id: 2 }), { URL_PARAM_ID: 2 });

          return;
        }
        setModalContent(ModalContentTypes.INFORMATION);
        break;
      case STATEMENT_TYPES.NOTIFICATION:
        if (answer) {
          setModalContent(ModalContentTypes.SWITCH_TO_EVALUATION);

          return;
        }

        history.push(PRE_LOGIN_ROUTES.chooseInsuranceCompany, { flowHandle: INJURED_STATUS });

        return;
      default:
        history.push(PRE_LOGIN_ROUTES.root);
    }

    if (answer) return;
  };

  const onCloseModalClick = () => {
    setModalContent(null);
  };

  const chooseModal = useCallback((): JSX.Element | null => {
    switch (modalContent) {
      case ModalContentTypes.COMPENSATION:
        return <SecondAmicableFindingModal closeModal={onCloseModalClick} />;
      case ModalContentTypes.INFORMATION:
        return <ThirdAmicableFindingModal closeModal={onCloseModalClick} />;
      case ModalContentTypes.NOTIFICATION:
        return <ShowNotificationModal closeModal={onCloseModalClick} />;
      case ModalContentTypes.SWITCH_TO_EVALUATION:
        return <SwitchToEvaluationModal closeModal={onCloseModalClick} />;
      default:
        return null;
    }
  }, [modalContent]);

  if (!MATCHED_QUESTION_SCREEN_DATA) {
    history.push(PRE_LOGIN_ROUTES.root);

    return null;
  }

  return (
    <ScreenContainer
      backgroundColor='linear-gradient(30deg, #D0E1FB 0%, #FFFFFF 100%), #FBE6DA'
      headerProps={{ title: MATCHED_QUESTION_SCREEN_DATA.pageTitle, hideLogo: true }}
      isModalOpen={!!modalContent}
      modal={chooseModal()}
      modalProps={{
        show: !!modalContent,
        onClose: onCloseModalClick,
      }}
      showBottomNavbar={isLoggedIn}
    >
      <QuestionsTemplate
        key={MATCHED_QUESTION_SCREEN_DATA.id}
        answers={MATCHED_QUESTION_SCREEN_DATA.answers}
        history={history}
        nextRoute={nextRoute}
        question={MATCHED_QUESTION_SCREEN_DATA.question}
        onAnswerClick={URL_PARAM_ID === 1 ? handleFirstQuestion : handleSecondQuestion}
      />
    </ScreenContainer>
  );
};
