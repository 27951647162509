import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContactPayPactIcon, MessageIcon, InfoIcon } from '../../assets/icons';
import { Title, Paragraph, Typography } from '../../styled-components';
import { $fontSizeL, $fontWeightBold, $lineHeightL } from '../../styles/variables';

export const PaypactContact = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <Title
        color='#1E1E29'
        fontSize={$fontSizeL}
        fontWeight={$fontWeightBold}
        lineHeight={$lineHeightL}
        padding='20px 0 0 0'
        textAlign='left'
        textTransform='initial'
      >
        {t('commonText.support')}
      </Title>
      <div style={{ width: '100%', marginTop: '35px' }}>
        <a href='tel:+40744778890' style={{ display: 'flex', marginTop: '30px', color: '#1E1E29', textDecoration: 'none' }}>
          <ContactPayPactIcon />
          <span style={{ marginLeft: '30px' }}>+40 744 778 890</span>
        </a>
        <a href='mailto:daune@paypact.ro' style={{ display: 'flex', marginTop: '30px', color: '#1E1E29', textDecoration: 'none' }}>
          <MessageIcon />
          <span style={{ marginLeft: '30px' }}>daune@paypact.ro</span>
        </a>
        <a
          href='https://www.paypact.ro'
          style={{ display: 'flex', marginTop: '30px', color: '#1E1E29', textDecoration: 'none' }}
          target='_blank'
        >
          <InfoIcon />
          <span style={{ marginLeft: '42px' }}>www.paypact.ro</span>
        </a>
      </div>
      <Typography align='left' color='#1E1E29' commonStyles={{ padding: '40px 0 0 0' }} fontWeight={$fontWeightBold} variant='h5'>
        BEWELL TECHNOLOGIES SRL
      </Typography>
      <Paragraph color='#1E1E29' fontSize='17px' margin='0'>
        CUI: 37273506
      </Paragraph>
      <Paragraph color='#1E1E29' fontSize='17px' margin='0'>
        STR IALOMIȚA NR. 11
      </Paragraph>
      <Paragraph color='#1E1E29' fontSize='17px' margin='0'>
        CLUJ-NAPOCA, JUD. CLUJ
      </Paragraph>
    </div>
  );
};
