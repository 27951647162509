import { useTranslation } from 'react-i18next';

export interface InitialInformationI {
  id: number;
  title: string;
  paragraph?: string;
}

const UseInitialInformationScreen = () => {
  const { t } = useTranslation();
  const InitialInformationData = [
    {
      id: 1,
      title: t('welcome.prepareDocuments'),
    },
    {
      id: 2,
      title: t('welcome.makeSureYouHavePhotos'),
    },
    {
      id: 3,
      title: t('welcome.takeTenMinutes'),
    },
    {
      id: 4,
      title: t('welcome.lightningMessage'),
    },
  ];

  const TransferInformationData = [
    {
      id: 1,
      title: t('welcome.theWarrant'),
      paragraph: t('commonText.warrantParagraph'),
    },
    {
      id: 2,
      title: t('welcome.receivableAssignment'),
      paragraph: t('commonText.assignmentParagraph'),
    },
  ];

  const ImportantInformationData = [
    {
      id: 1,
      title: t('welcome.whoDoesTheEvaluation'),
      subtitle: t('welcome.whoDoesTheEvaluationSubtitle'),
    },
    {
      id: 2,
      title: t('welcome.whatsThePrice'),
      subtitle: t('welcome.whatsThePriceSubtitle'),
    },
    {
      id: 3,
      title: t('welcome.whatGuaranteeDoIHave'),
      subtitle: t('welcome.whatGuaranteeDoIHaveSubtitle'),
    },
    {
      id: 4,
      title: t('welcome.whereDoIUseTheGuarantee'),
      subtitle: t('welcome.whatGuaranteeDoIHaveSubtitleTwo'),
    },
  ];

  return { InitialInformationData, ImportantInformationData, TransferInformationData };
};

export { UseInitialInformationScreen };
