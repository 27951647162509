import React from 'react';
import styled from 'styled-components';

import { Paragraph } from '../index';

export interface IRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  padding?: string;
  radioSize?: string;
  margin?: string;
  width?: string;
  color?: string;
  borderRadius?: string;
  borderColor?: string;
  backgroundColor?: string;
  borderActive?: string;
  fillColor?: string;
  checked?: boolean;
  value?: string | number;
  labelText?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  className?: string;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: ${(props: IRadioProps) => props.width || '100%'};
  margin: ${(props: IRadioProps) => props.margin || '10px 0'};
  position: relative;
`;

const Root = styled.div`
  margin-right: 17px;
  cursor: pointer;
  width: ${(props: IRadioProps) => (props.radioSize ? props.radioSize : '20px')};
  height: ${props => (props.radioSize ? props.radioSize : '20px')};
  position: relative;
  label {
    margin-left: 20px;
  }
  &::before {
    content: '';
    border-radius: 100%;
    border: ${(props: IRadioProps) => (props.checked ? 'none' : '1px solid #B6B6B6')};
    background: ${(props: IRadioProps) => props.backgroundColor || '#FAFAFA'};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled.div`
  background: ${(props: IRadioProps) => props.fillColor || '#512AD8'};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    opacity: 0;
    width: calc(23px - 2px);
    position: absolute;
    height: calc(23px - 2px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid ${(props: IRadioProps) => (props.borderActive ? props.borderActive : '#512AD8')};
    border-radius: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Root} label ${Fill} {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      transition: width 0.2s ease-out, height 0.2s ease-out;
      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

export const Radio = (props: IRadioProps) => {
  return (
    <Container margin={props.margin} padding={props.padding} width={props.width}>
      <Input
        aria-checked={props.checked}
        checked={props.checked}
        name={props.name}
        type='radio'
        value={props.value}
        onChange={props.onChange}
      />
      <Root>
        <label>
          <Fill />
        </label>
      </Root>
      <Paragraph color={props.color || 'inherit'} margin='0'>
        {props.labelText || props.children}
      </Paragraph>
    </Container>
  );
};
