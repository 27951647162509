import { AxiosResponse } from 'axios';

import { authorizedRequest } from '../index';
import { IContactDetails, IStatementBackResponse, IStatementDbFormat } from '../../../@types/current-statement/currentStatementTypes';

export function createStatement(payload: any): Promise<AxiosResponse<IStatementBackResponse>> {
  return authorizedRequest().post('/statements', payload);
}

export function getStatements(): Promise<AxiosResponse<IStatementDbFormat[]>> {
  return authorizedRequest().get('/statements/user');
}

export function deleteEvaluation(id: number): Promise<AxiosResponse<IStatementDbFormat>> {
  return authorizedRequest().delete(`/statements/${id}`);
}

// TODO: follow REST API
// TODO: define any
export function updateStatement(payload: any): Promise<AxiosResponse<IStatementDbFormat>> {
  return authorizedRequest().put('/statements', payload);
}

export function getPersons(): Promise<AxiosResponse<IContactDetails[]>> {
  return authorizedRequest().get('/persons');
}

export function getPersonsByPersonId(id: number): Promise<AxiosResponse<IContactDetails>> {
  return authorizedRequest().get(`/persons/${id}`);
}

export function userFinishNotificationRequest(id: number): Promise<AxiosResponse<any>> {
  return authorizedRequest().post(`/statements/${id}/user/finish/notification`);
}
