import React from 'react';

import { Container, Typography, Wrapper } from '../../styled-components';
import { AuthentificationMethods } from '../../components/auth_methods';
import { Button } from '../../components/button';
import { HOME_TEXTS } from '../../translations/ro/home';
import { IScreenCommonProps } from '../component-props.model';
import { AUTH_TYPE } from '../../constants/typeConstants/authType';
import { PRE_LOGIN_ROUTES } from '../../services/router/app-routes/routes-constants';

interface PropsI extends IScreenCommonProps {
  toggleModal: (value: boolean, method: string) => void;
  pageTitle: string;
  modalPrompt: {
    question: string;
    buttonName: string;
  };
  isLogin?: boolean;
  showModal: boolean;
}

export const AuthBody = ({ toggleModal, history, pageTitle, modalPrompt, isLogin }: PropsI): JSX.Element => {
  const AUTH = {
    LOGIN: PRE_LOGIN_ROUTES.login,
    REGISTER: PRE_LOGIN_ROUTES.register,
  };

  return (
    <Wrapper display='block' height='100%' padding='0' width='100%'>
      <Typography align='center' commonStyles={{ padding: '0 20px 0 20px' }} fontWeight='700' variant='h4'>
        {pageTitle}
      </Typography>
      <Typography align='center' color='#8E8E89' commonStyles={{ padding: '6px' }} variant='body'>
        {HOME_TEXTS.dataIsSaved}
      </Typography>
      <Container position='relative' style={{ padding: '22px 0', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <AuthentificationMethods login={isLogin} methodType={AUTH_TYPE.FACEBOOK} toggleModal={toggleModal} />
        <AuthentificationMethods login={isLogin} methodType={AUTH_TYPE.GOOGLE} toggleModal={toggleModal} />
        <AuthentificationMethods login={isLogin} methodType={AUTH_TYPE.LINKED_IN} toggleModal={toggleModal} />
        <Wrapper alignItems='center' justifyContent='center' margin='35px'>
          <Typography align='center' color='#8E8E8E' commonStyles={{ padding: '6px' }} variant='body'>
            {modalPrompt?.question}
          </Typography>
          <Button
            boxShadow='none'
            color='#0066FF'
            mode='default'
            style={{
              border: 'none',
              background: 'transparent',
              fontFamily: 'Roboto',
              fontSize: '14px',
              lineHeight: '17px',
              padding: 0,
            }}
            width='auto'
            onClick={() => (isLogin ? history?.push(AUTH.REGISTER) : history?.push(AUTH.LOGIN))}
          >
            {modalPrompt?.buttonName}
          </Button>
        </Wrapper>
      </Container>
    </Wrapper>
  );
};
