import { authorizedRequest } from '../index';

export const getDocumentsDataApi = (id: number | null): any => {
  return authorizedRequest().get(`/attachments/${id}`);
};

export const getDocumentsPhotoApi = (id: number | null): any => {
  return authorizedRequest().get(`/attachments/file/${id}`);
};

export function deleteDocumentApi(id: number): Promise<any> {
  return authorizedRequest().delete(`/attachments/${id}`);
}
