import {
  ICurrentStatement,
  ICurrentPerson,
  ICurrentStatementInsurance,
  IPayment,
} from '../../@types/current-statement/currentStatementTypes';
import { USER_SITUATION } from '../../services/router/routeHandles';
import { IWitness } from '../../@types/witnesses/witnessesTypes';
import AddPictureBackground from '../../assets/images/add-picture.png';
import { ICarIdentity, IDocuments, IDrivingLicense, IIdentityCard } from '../../@types/document/documentTypes';
import { IStatementDamage } from '../../@types/damage/damageType';
import { EVALUATION_STATUS, NOTIFICATION_STATUS } from '../../constants/typeConstants/statementStatusType';
import { ICircumstancesProp } from '../../screens/statement/interfaces/statement.model';
import { STATEMENT_TYPES } from '../types';

export const currentStatementInsuranceInitialState: ICurrentStatementInsurance = {
  image: AddPictureBackground,
  type: null,
  data: {
    insurerID: null,
    insurerName: null,
    insuranceSeries: null,
    insuranceNo: null,
    carPlate: null,
    insuranceOwnerName: null,
    carMakeAndModel: null,
    cnp: null,
    insuranceOwnerAddress: null,
    registrationNumber: null,
    insuranceCreatedAt: null,
    insuranceDateFrom: null,
    insuranceDateTo: null,
    civNumber: null,
    seats: null,
    engineCapacity: null,
    carDriverCnp: null,
    carDriverFullName: null,
  },
};

export const drivingLicenseInitialState: IDrivingLicense = {
  image: AddPictureBackground,
  type: null,
  data: {
    nameSurname: null,
    birthLocation: null,
    dateOfBirth: null,
    cnp: null,
    availabilityStart: null,
    availabilityEnd: null,
    categories: null,
    number: null,
  },
};

export const carIdentityInitialState: ICarIdentity = {
  image: AddPictureBackground,
  type: null,
  data: {
    carType: null,
    carBrand: null,
    carModel: null,
    matriculationNumber: null,
    firstMatriculationYear: null,
    ownerName: null,
    registrationNumber: null,
    insuranceCompletedDate: null,
    availabilityEnd: null,
    availabilityStart: null,
    itpValidity: null,
  },
};

export const identityCardInitialState: IIdentityCard = {
  image: AddPictureBackground,
  type: null,
  data: {
    nameSurname: null,
    cnp: null,
    serial: null,
    serialNumber: null,
    dateOfBirth: null,
    placeOfBirth: null,
    address: null,
    issuedBy: null,
    issuedOn: null,
    expiresOn: null,
  },
};

export const documentsInitialState: IDocuments = {
  insurance: currentStatementInsuranceInitialState,
  drivingLicense: drivingLicenseInitialState,
  carIdentity: carIdentityInitialState,
  identityCard: identityCardInitialState,
};

export const damagePartsInitialState = {
  leftSide: [],
  rightSide: [],
};

export const imagesInitialState = [];

export const damageInitialState: IStatementDamage = {
  zones: '',
  zone: damagePartsInitialState,
  images: imagesInitialState,
  description: '',
};

export const ICircumstanceInitialState: ICircumstancesProp = {
  accidentDate: '',
  accidentTime: '',
  accidentLocation: '',
};

export const IPaymentInitialState: IPayment = {
  statementId: 0,
  amount: null,
  name: '',
  iban: '',
  bankName: '',
};

export const statementDetailsInitialState: ICurrentPerson = {
  carIdentityId: null,
  drivingLicenseId: null,
  identityCardId: null,
  insuranceId: null,
  id: 0,
  circumstances: ICircumstanceInitialState,
  documents: documentsInitialState,
  damage: damageInitialState,
  witnesses: null,
  userSignature: null, //TODO update type
};

export const accidentDocumentsInitialState = {
  amicableFinding: {
    image: AddPictureBackground,
    type: null,
    injuredVehicle: null,
  },
  policeReport: {
    image: AddPictureBackground,
    type: null,
  },
  observationNote: {
    image: AddPictureBackground,
    type: null,
  },
  compensationOffer: {
    image: AddPictureBackground,
    type: null,
  },
};

export const currentStatementInitialState: ICurrentStatement = {
  statementID: null,
  statementOnSameDevice: true,
  paymentDetails: IPaymentInitialState,
  digitalStatement: null,
  accidentDocuments: accidentDocumentsInitialState,
  createdAt: new Date().toLocaleDateString(),
  accidentDate: null,
  accidentLocation: null,
  accidentTime: null,
  weatherCondition: null,
  roadCondition: null,
  description: null,
  amicableFindingId: null,
  compensationOfferId: null,
  evaluationDocumentId: null,
  evaluationDocument: null,
  observationNoteId: null,
  policeReportId: null,
  damageTypeQuestion: '',
  observationNote: null,
  compensationOffer: null,
  type: STATEMENT_TYPES.EVALUATION,
  status: EVALUATION_STATUS.EVALUATION_INCOMPLETE,
  carOwner: null,
  userSituation: USER_SITUATION.INJURED_STATUS,
  guilty: statementDetailsInitialState,
  injured: statementDetailsInitialState,
};

export const currentNotificationInitialState: ICurrentStatement = {
  ...currentStatementInitialState,
  status: NOTIFICATION_STATUS.NOTIFICATION_INCOMPLETE as NOTIFICATION_STATUS,
  type: STATEMENT_TYPES.NOTIFICATION,
};

export const contactDetailsInitialState = {
  phone: '',
  email: '',
  insuredPhone: '',
  insuredEmail: '',
  companyName: '',
  cui: '',
  address: '',
  county: '',
  city: '',
};

export const witnessInitialState: IWitness = {
  id: 0,
  name: '',
  address: '',
  phone: '',
  email: '',
};
