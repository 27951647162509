import React from 'react';

import { $lineHeightXL } from '../../styles/variables';
import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';

interface PropsI extends Omit<IScreenComponentProps, 'parentRoute'> {
  question: string;
  answers: {
    firstAnswer: string;
    secondAnswer: string;
    secondAnswerProps?: Record<string, string | boolean>;
  };
  onAnswerClick: (answer: boolean) => void;
}

export const QuestionsTemplate: React.FC<PropsI> = ({ question, answers, onAnswerClick }) => {
  return (
    <Wrapper display='flex' flex='1' flexDirection='column' width='100%'>
      <Typography
        align='left'
        commonStyles={{ width: '100%' }}
        extendedStyles={{ lineHeight: $lineHeightXL }}
        fontWeight='700'
        variant='h3'
      >
        {question}
      </Typography>
      <Wrapper display='flex' flexDirection='column' margin='0 0 30px 0' width='100%'>
        <Button borderRadius='25px' color='#512AD8' margin='15px 0 0 0' onClick={() => onAnswerClick(true)}>
          {answers.firstAnswer}
        </Button>
        <Button
          borderRadius='25px'
          color='#512AD8'
          margin='15px 0 0 0'
          width='100%'
          onClick={() => onAnswerClick(false)}
          {...answers.secondAnswerProps}
        >
          {answers.secondAnswer}
        </Button>
      </Wrapper>
    </Wrapper>
  );
};
