import React from 'react';

import { Column, H3, Paragraph, Wrapper } from '../../styled-components';
import { Button } from '../button';
import { ArrowLeftIcon, Close } from '../../assets/icons';

interface INavCreateProps {
  title: string;
  backUrl?: string;
  showBack?: boolean;
  showClose?: boolean;
  onClose?: () => void;
  goBack?: () => void;
}

export const NavClose = (props: INavCreateProps): JSX.Element => {
  const onCloseClick = () => {
    //
  };

  return (
    <Wrapper backgroundColor='primary' justifyContent='space-between' padding='0.2rem 0'>
      <Column maxWidth='20%'>
        {props.showBack !== false && (
          <Button display='flex' mode='link' onClick={props.goBack}>
            <ArrowLeftIcon />
            <Paragraph color='#fff' fontSize='17px' margin='0 0 0 5px'>
              Back
            </Paragraph>
          </Button>
        )}
      </Column>
      <Column maxWidth='60%'>
        <H3 color='#fff' fontWeight='400' margin='0' textAlign='center'>
          {props.title}
        </H3>
      </Column>
      <Column maxWidth='15%'>
        {props.showClose !== false && (
          <Button display='flex' float='right' mode='link' onClick={props.onClose ? props.onClose : onCloseClick}>
            <Close />
          </Button>
        )}
      </Column>
    </Wrapper>
  );
};
