import React from 'react';

import { IIconProps } from './icon.model';

function FaceRecognition({ width, height, color }: IIconProps): JSX.Element {
  return (
    <svg
      fill={color ? color : 'none'}
      height={height ? height : '32'}
      viewBox='0 0 32 32'
      width={width ? width : '32'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 11a1 1 0 001-1V5h5a1 1 0 100-2H5a2 2 0 00-2 2v5a1 1 0 001 1zm23-8h-5a1 1 0 100 2h5v5a1 1 0 002 0V5a2 2 0 00-2-2zm1 18a1 1 0 00-1 1v5h-5a1 1 0 000 2h5a2 2 0 002-2v-5a1 1 0 00-1-1zm-18 6H5v-5a1 1 0 10-2 0v5a2 2 0 002 2h5a1 1 0 000-2zm0-20a1 1 0 00-1 1v2a1 1 0 102 0V8a1 1 0 00-1-1zm12 4a1 1 0 001-1V8a1 1 0 00-2 0v2a1 1 0 001 1zm-8 8h1a2 2 0 002-2V8a1 1 0 00-2 0v9h-1a1 1 0 000 2z'
        fill={color ? color : 'white'}
      />
      <path d='M24 20a1 1 0 00-2 0c0 1.42-2.46 3-6 3s-6-1.58-6-3a1 1 0 10-2 0c0 2.8 3.51 5 8 5s8-2.2 8-5z' fill={color ? color : 'white'} />
    </svg>
  );
}

export default FaceRecognition;
