import { STATEMENT_TYPES } from '../../store/types';

export enum COMPLETED_DOCUMENTS_TYPE {
  AMICABLE_FINDING = 'AMICABLE_FINDING',
  POLICE_REPORT = 'POLICE_REPORT',
}

export interface QuestionsFlowHandler {
  damageTypeQuestion: string;
  observationNote: boolean | null;
  compensationOffer: boolean | null;
  completedDocumentsAfterAccident?: COMPLETED_DOCUMENTS_TYPE | null;
  type: STATEMENT_TYPES;
}
