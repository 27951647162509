import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store/rootReducer';
import { addNewRoute, ILastRoutes, setLastRoute } from '../store/last-routes/last-routes.slice';
import { ILastRoute } from '../@types/last-routes/lastRoutesTypes';

const useLastLocation = () => {
  const dispatch = useDispatch();
  const lastRoutesState = useSelector((state: RootState) => state.lastRoutes.data);

  const setLastLocationByStatementID = (statementId: number | null, lastRoute: string) => {
    if (statementId !== null && lastRoute.includes('/' + statementId)) dispatch(addNewRoute({ statementId, lastRoute }));
  };

  const getLastLocationByStatementID = (statementId: number | null) => {
    return lastRoutesState
      .slice()
      .reverse()
      .find((lastRoute: ILastRoute) => lastRoute.statementId === statementId)?.lastRoute;
  };

  const resetRoutes = (routes: ILastRoutes) => {
    dispatch(setLastRoute(routes));
  };

  return { setLastLocationByStatementID, getLastLocationByStatementID, resetRoutes, lastRoutesState };
};

export { useLastLocation };
