import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';

import { Button } from '../../button';
import { VALIDATION_TEXTS } from '../../../translations/ro/home';
import { Typography } from '../../../styled-components';
import { TextField } from '../../text_field';
import { AccidentEnvironment } from '../../../@types/circumstances/circumstancesTypes';
import { CustomDatePickerToolbar } from '../../date-picker/custom-date-picker-toolbar';
import { CustomTimePickerToolbar } from '../../date-picker/custom-time-picker-toolbar';

interface IFormProps {
  onSubmit: () => void;
  formValues: AccidentEnvironment;
  hideDateTime?: boolean;
  onChangeValue: (value: string, key: string) => void;
  setAccidentLocation?: () => void;
}

export const AddCircumstancesForm = ({
  onSubmit,
  hideDateTime = false,
  formValues,
  onChangeValue,
  setAccidentLocation,
}: IFormProps): JSX.Element => {
  const { t } = useTranslation();

  const ValidationSchema = Yup.object().shape({
    accidentDate: Yup.string().required(VALIDATION_TEXTS.accidentDateRequired),
    accidentTime: Yup.string().required(VALIDATION_TEXTS.accidentTimeRequired),
    accidentLocation: Yup.string().required(VALIDATION_TEXTS.accidentLocationRequired).nullable(),
  });

  const renderAccidentTimeValue = (value: string | null) => (value ? moment(value, 'HH:mm').toDate() : '');
  const oneMonthAgo = () => moment().subtract(1, 'month');

  return (
    <Formik initialValues={formValues} validationSchema={ValidationSchema} enableReinitialize validateOnMount onSubmit={onSubmit}>
      {({ handleBlur, isValid, values, errors, touched }) => (
        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
          {!hideDateTime && (
            <>
              <Typography color='#00160A' fontSize='16px' fontWeight='700' variant='h4'>
                {t('circumstances.accidentDate')}
              </Typography>
              <DatePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomDatePickerToolbar {...props} />}
                cancelLabel={false}
                className='date-picker'
                format='DD/MM/YYYY'
                helperText={null}
                minDate={oneMonthAgo()}
                name='accidentDate'
                okLabel={t('commonText.save')}
                todayLabel={<div id='goToCurrentDateTime' />}
                value={values.accidentDate}
                disableFuture
                showTodayButton
                onChange={value => onChangeValue(moment(value).format('YYYY-MM-DD'), 'accidentDate')}
              />
              <Typography color='#00160A' fontSize='16px' fontWeight='700' variant='h4'>
                {t('circumstances.accidentTime')}
              </Typography>
              <TimePicker
                ToolbarComponent={(props: ToolbarComponentProps) => <CustomTimePickerToolbar {...props} />}
                ampm={false}
                cancelLabel={false}
                className='date-picker'
                format='HH:mm'
                helperText={null}
                name='accidentTime'
                okLabel={t('commonText.save')}
                value={renderAccidentTimeValue(values?.accidentTime)}
                showTodayButton
                onChange={value => onChangeValue(moment(value).format('HH:mm'), 'accidentTime')}
              />
              <Typography color='#00160A' fontSize='16px' fontWeight='700' variant='h4'>
                {t('circumstances.accidentLocation')}
              </Typography>
              <TextField
                error={Boolean(touched.accidentLocation) && Boolean(errors.accidentLocation)}
                errorMessage={errors.accidentLocation}
                fontSize='14px'
                name='accidentLocation'
                value={values.accidentLocation !== null ? values.accidentLocation : ''}
                variant='rounded'
                readOnly
                onBlur={handleBlur}
                onClick={setAccidentLocation}
              />
              <Button disabled={!isValid} mode='primary' style={{ marginTop: 'auto' }} type='submit'>
                {t('commonText.continue')}
              </Button>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
