import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { IScreenComponentProps } from '../../screens/component-props.model';
import { ICurrentStatement } from '../../@types/current-statement/currentStatementTypes';
import { IDocuments } from '../../@types/document/documentTypes';
import { useCardManagement } from '../../hooks/useCardManagement';
import { useStatements } from '../../hooks/useStatements';
import { getDocumentsDataApi } from '../../services/api/attachments/attachments.api';
import { STATEMENT_TYPES } from '../../store/types';

export interface IStatusCardProps extends Partial<IScreenComponentProps> {
  currentPlace: number | null;
  currentData: ICurrentStatement;
  insuranceId: number | null;
  isEvaluation?: boolean;
  type: STATEMENT_TYPES;
}

export const StatusCard: React.FC<IStatusCardProps> = ({
  isEvaluation,
  insuranceId,
  history,
  nextRoute,
  currentData,
  currentPlace,
  type,
  ...props
}) => {
  const { statementsState } = useStatements();
  const { statementID, createdAt, status } = currentData;
  const { handleStatusCardTemplatesByStatus } = useCardManagement(status);
  const [documentsData, setDocumentsData] = useState<Partial<IDocuments['insurance']['data']> | null>(null);

  const onContinue = () => {
    const nextRoutePath = generatePath(`${nextRoute}`, { currentStatementId: Number(statementID) });
    history?.push(nextRoutePath);
  };

  const getDocumentsData = (id: number | null) => {
    if (id)
      getDocumentsDataApi(id)
        .then((data: { data: { data: string }[] }) => {
          setDocumentsData(JSON.parse(data.data[0].data));
        })
        .catch((err: string) => err);

    return true;
  };

  useEffect(() => {
    getDocumentsData(insuranceId);
  }, [statementsState]);

  return handleStatusCardTemplatesByStatus(
    statementID as number,
    createdAt,
    currentPlace,
    insuranceId,
    documentsData,
    onContinue,
    isEvaluation as boolean,
  );
};

StatusCard.defaultProps = {
  isEvaluation: false,
};
