import React, { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IScreenComponentProps } from '../component-props.model';
import { useQuestionsContent } from '../../constants/dataConstants/questionsContent';
import { useQuestionsFlowHandler } from '../../hooks/use-questionsFlowHandler';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { RootState } from '../../store/rootReducer';
import { setPreLoginDataAction } from '../../store/pre-login/preLogin.slice';
import { preLoginInitialState } from '../../store/pre-login/initialState';

import { QuestionsTemplate } from './QuestionsTemplate';

export const CheckInsuranceType = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { QUESTIONS_SCREENS_CONSTANTS } = useQuestionsContent();
  const { DAMAGE_TYPE } = QUESTIONS_SCREENS_CONSTANTS;
  const { setQuestionsFlowHandler } = useQuestionsFlowHandler();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);

  useEffect(() => {
    // reset preLoginData since it's starting a new process of adding a statement
    dispatch(setPreLoginDataAction(preLoginInitialState));
  }, []);

  const handleAnswer = (answer: boolean): void => {
    const damageTypeQuestion = answer ? DAMAGE_TYPE.answers.firstAnswer : DAMAGE_TYPE.answers.secondAnswer;
    setQuestionsFlowHandler({ damageTypeQuestion });
    history.push(generatePath(nextRoute.toString(), { id: 1 }), { URL_PARAM_ID: 1 });
  };

  return (
    <ScreenContainer
      backgroundColor='linear-gradient(30deg, #D0E1FB 0%, #FFFFFF 100%), #FBE6DA'
      headerProps={{ title: DAMAGE_TYPE.pageTitle, hideLogo: true }}
      showBottomNavbar={isLoggedIn}
    >
      <QuestionsTemplate
        answers={DAMAGE_TYPE.answers}
        history={history}
        nextRoute={nextRoute}
        question={DAMAGE_TYPE.question}
        onAnswerClick={handleAnswer}
      />
    </ScreenContainer>
  );
};
