import React from 'react';
import styled from 'styled-components';

import { IScreenComponentProps } from '../../screens/component-props.model';
import { Wrapper } from '../index';

export interface IStatusCardProps extends Partial<IScreenComponentProps> {
  title: string;
  description: string;
  icon: any;
  onClick?: () => void;
  normalPrice: string;
  discountPrice: string;
}

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-left: 5px;
`;

const Description = styled.p`
  color: #5b5675;
  font-size: 12px;
`;

const PriceWrapper = styled.div`
  background: linear-gradient(#7433ff, #ffa3fd);
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Price = styled.div`
  color: white;
  margin: 5px;
  font-weight: bold;
  font-size: 18px;
  position: relative;
`;

const Line = styled.span`
  position: absolute;
  width: 80%;
  height: 2px;
  background-color: white;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(137.54deg);
  top: calc(50% - 1px);
`;

export const ButtonCard: React.FC<IStatusCardProps> = ({ title, description, icon, onClick, normalPrice, discountPrice }) => {
  return (
    <Wrapper
      alignItems='stretch'
      backgroundColor='white'
      borderRadius='16px'
      display='flex'
      flexDirection='row'
      marginBottom='10px'
      marginTop='10px'
      overflowY='hidden'
      onClick={onClick}
    >
      <Wrapper flexDirection='column' padding='16px'>
        <Wrapper alignItems='center' display='flex' justifyContent='flex-start' width='100%'>
          {icon}
          <Title>{title}</Title>
        </Wrapper>
        <Description>{description}</Description>
      </Wrapper>
      <PriceWrapper>
        <Price>
          {normalPrice}
          <Line />
        </Price>
        <Price>{discountPrice}</Price>
      </PriceWrapper>
    </Wrapper>
  );
};

ButtonCard.defaultProps = {
  title: '',
  description: '',
  icon: '',
};
