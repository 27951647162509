import { IRoute } from '../interfaces/route.model';
import { CarDamageSelect } from '../../../screens/statement-damage/CarDamageSelect';
import { CircumstancesAdd } from '../../../screens/statement/CircumstancesAdd';
import { DamageDescription } from '../../../screens/statement-damage/DamageDescription';
import { DamageDetailsView } from '../../../screens/statement-damage/DamageDetailsView';
import { DamagePictureList } from '../../../screens/statement-damage/DamagePictureList';
import { StatementAndEvaluationsDetails } from '../../../screens/statement/StatementAndEvaluationsDetails';
import { Statements } from '../../../screens/statements/Statements';
import { WitnessesHome } from '../../../screens/witnesses/Home';
import { ViewCircumstances } from '../../../components/statements/circumstances/ViewCircumstances';
import { StatementOverview } from '../../../screens/statement-overview/StatementOverview';
import { DocumentsPhoto } from '../../../screens/documentsOCR/documentsPhoto';
import { DocumentsList } from '../../../screens/statement/DocumentsList';
import { DocumentsOcrDataForm } from '../../../screens/documentsOCR/documentsOcrDataForm';
import { Signature } from '../../../screens/statement/Signature';
import { InjuredRecognition } from '../../../screens';
import { FacialRecognitionCamera } from '../../../screens/facialRecognition/FacialRecognitionCamera';
import { Congratulations } from '../../../screens/congratulations/Congratulations';
import { FinalSignature } from '../../../screens/statement/FinalSignature';
import { InformationSendSuccess } from '../../../screens/statement/InformationSendSuccess';
import { DocumentsDetailsView } from '../../../screens/statement-documents/DocumentsDetailsView1';

import { PATHS, PROTECTED_ROUTES } from './routes-constants';

export const statementsRoutes: IRoute = {
  baseUrl: PROTECTED_ROUTES.statements,
  children: [
    {
      path: PROTECTED_ROUTES.statements,
      component: Statements,
      nextRoute: PROTECTED_ROUTES.statement,
      exact: true,
    },
  ],
};

export const statementDetailsRoutes: IRoute = {
  baseUrl: PROTECTED_ROUTES.statement$IdDetails,
  children: [
    {
      path: PROTECTED_ROUTES.statement$IdDetails,
      component: StatementAndEvaluationsDetails,
      exact: true,
      nextRoute: PROTECTED_ROUTES.statementView,
    },
    {
      path: PROTECTED_ROUTES.statement$IdCircumstances,
      component: CircumstancesAdd,
      nextRoute: {
        guilty: '/',
        injured: PROTECTED_ROUTES.statementSketch,
      },
      exact: true,
    },
    {
      path: PROTECTED_ROUTES.statementCircumstanceList,
      component: ViewCircumstances,
    },
    {
      // Has children
      path: PROTECTED_ROUTES.statementWitnesses,
      component: WitnessesHome,
    },
    {
      path: PROTECTED_ROUTES.statementSignature,
      component: Signature,
      nextRoute: PROTECTED_ROUTES.statementGuilty,
    },
    {
      path: PROTECTED_ROUTES.statementDocuments,
      component: DocumentsDetailsView, //EVALUATION DOCUMENTS MAIN SCREEN
      exact: true,
      nextRoute: {
        photo: PATHS.photo, //FIRST SCREEN TO INFORM ABOUT THE OCR
        documentsList: PATHS.list, //AFTER EVERY DOCUMENT IS COMPLETED YOU SEE THE LIST OF DOCUMENTS
      },
    },
    {
      path: PROTECTED_ROUTES.documentsPhotoStatements, //TODO HANDLE BETTER AFTER DEMO
      component: DocumentsPhoto, //INFORMATIONAL SCREEN ABOUT THE OCR
      nextRoute: PATHS.documentsForm, //THE SCREEN WHERE YOU UPLOAD DOCUMENTS
      exact: true,
    },
    // {
    //   path: PROTECTED_ROUTES.documentsCameraStatements, //TODO HANDLE BETTER AFTER DEMO
    //   component: DocumentsCamera, //THE SCREEN WHERE YOU UPLOAD DOCUMENTS
    //   nextRoute: PATHS.documentsForm, //THE SCREEN THAT DISPLAYS THE OCR DATA ***YOU CAN MODIFY THE FORM DATA
    //   exact: true,
    // },
    {
      path: PROTECTED_ROUTES.documentsFormStatements, //TODO HANDLE BETTER AFTER DEMO
      component: DocumentsOcrDataForm, //THE SCREEN THAT DISPLAYS THE OCR DATA
      nextRoute: PATHS.documents, //TAKES YOU BACK TO THE DOCUMENTS MAIN SCREEN
    },
    {
      path: PROTECTED_ROUTES.currentStatementDocumentsList, //AFTER YOU COMPLETE THE DOCUMENTS THIS SCREEN WILL BE SHOWN CONTAINING ALL THE DOCUMENTS PICTURE
      component: DocumentsList,
      //DOESN'T HAVE A NEXT ROUTE IT ONLY DISPLAYS THE DOCUMENTS LIST
    },
    {
      path: PROTECTED_ROUTES.statementView,
      component: StatementOverview,
    },
    {
      path: PROTECTED_ROUTES.faceRecognition,
      component: InjuredRecognition,
      nextRoute: PATHS.facialRecognitionCamera,
      exact: true,
    },
    {
      path: PROTECTED_ROUTES.faceRecognitionCamera,
      component: FacialRecognitionCamera,
      nextRoute: PROTECTED_ROUTES.statementFinalSignature,
    },
    {
      path: PROTECTED_ROUTES.statementFinalSignature, //SIGNATURE AFTER FACE RECOGNITION
      exact: true,
      component: FinalSignature,
      nextRoute: PROTECTED_ROUTES.congratulations,
    },
    {
      path: PROTECTED_ROUTES.congratulations,
      component: Congratulations,
      nextRoute: PATHS.currentStatement,
    },
    {
      path: PROTECTED_ROUTES.informationSendSuccessfully,
      component: InformationSendSuccess,
    },
  ],
};

export const evaluationDetailsDamageRoutes: IRoute = {
  children: [
    {
      path: PROTECTED_ROUTES.statementDamage,
      component: CarDamageSelect,
      exact: true,
      nextRoute: PROTECTED_ROUTES.statementDamageDescription,
    },
    {
      path: PROTECTED_ROUTES.statementDamageList,
      component: DamagePictureList,
    },
    {
      path: PROTECTED_ROUTES.statementDamageDescription,
      component: DamageDescription,
      nextRoute: PROTECTED_ROUTES.statementDamageDetails,
    },
    {
      path: PROTECTED_ROUTES.statementDamageDetails,
      component: DamageDetailsView,
      nextRoute: PROTECTED_ROUTES.statement$IdDetails,
    },
  ],
};
