import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';

import { IStatementFlowStatus } from './flow.model';

export type IStatementFlow = {
  data: IStatementFlowStatus | null;
  isLoading: boolean;
  error: string | null;
};

const getStatementFlowState = (): IStatementFlowStatus | null => {
  const flowStatus = localStorage.getItem('flowStatus');

  if (flowStatus) return JSON.parse(flowStatus);

  return null;
};

const initialState: IStatementFlow = {
  data: getStatementFlowState(),
  isLoading: false,
  error: null,
};

const statementFlowSlice = createSlice({
  name: 'statementFlow',
  initialState,
  reducers: {
    setFlowStatus(state: IStatementFlow, action: PayloadAction<IStatementFlowStatus>) {
      state.data = { ...state.data, ...action.payload };
      localStorage.setItem('flowStatus', JSON.stringify({ ...state.data, ...action.payload }));
    },
    setErrorStatement(state: IStatementFlow, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export default statementFlowSlice.reducer;

export const { setFlowStatus, setErrorStatement } = statementFlowSlice.actions;

export const setStatus = (payload: IStatementFlowStatus): AppThunk => async (dispatch: (arg0: any) => void) => {
  dispatch(setFlowStatus({ ...initialState.data, ...payload }));
};
