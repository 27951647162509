export const CAR_PARTS = {
  LEFT: 'Left',
  RIGHT: 'Right',
  FRONT: 'front',
  FRONT_DOOR: 'frontDoor',
  BACK_DOOR: 'backDoor',
  BACK: 'back',
};

export const CONVERTED_CAR_PARTS = {
  FRONT_LEFT: 'frontLeft',
  FRONT_DOOR_LEFT: 'frontDoorLeft',
  BACK_DOOR_LEFT: 'backDoorLeft',
  BACK_LEFT: 'backLeft',
  FRONT_RIGHT: 'frontRight',
  FRONT_DOOR_RIGHT: 'frontDoorRight',
  BACK_DOOR_RIGHT: 'backDoorRight',
  BACK_RIGHT: 'backRight',
};
