import { Route, Switch, Redirect, useLocation, matchPath } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store/rootReducer';
import { checkUrl, removeLeadingSlash, removeTrailingSlash } from '../../shared/helpers';
import { UrlNotFound } from '../../screens';
import { getUserByIdApi } from '../../store/auth-user/auth.api';
import { logOutAction } from '../../store/app/app.action';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { StyledLoader } from '../../styled-components/spinner/styled';

import { BottomNavigationPathUtilities, PRE_LOGIN_ROUTES } from './app-routes/routes-constants';
import { IComponentRoute, mainRoutes } from './appRoutes';
import { AuthRoutes } from './routeAccessRights';

interface PathChildrenProps {
  childArray: IComponentRoute[];
  url: string;
  state?: RootState;
}

export const PathChildren = ({ childArray, url, state }: PathChildrenProps): JSX.Element => {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state: RootState) => state.appStatus);

  const hasAccessRights = useCallback(
    (path: string) => {
      if (path.indexOf(PRE_LOGIN_ROUTES.loginSuccess) > -1) return true;

      if (!isLoggedIn) {
        return !!Object.values(PRE_LOGIN_ROUTES).find(item => {
          return matchPath(path, {
            path: item,
            exact: true,
          });
        });
      }

      return true;
    },
    [isLoggedIn],
  );

  const handleNonexistentRoutes = () => {
    const routesSet = new Set();
    const regex = /\d/;
    const hasNumber = regex.test(location.pathname as string);
    childArray.map((index: IComponentRoute) => {
      routesSet.add(index.path);
    });

    if (!hasNumber && !routesSet.has(location.pathname)) {
      return <Route component={UrlNotFound} />;
    }

    return null;
  };

  return (
    <Switch>
      {checkUrl(location.pathname) ? (
        <Redirect from='/:url*(/+)' to={{ pathname: removeTrailingSlash(location.pathname), state: location.state }} />
      ) : (
        handleNonexistentRoutes()
      )}

      {childArray.map((child: IComponentRoute, index) => {
        const path = removeLeadingSlash(`${url}${child.path}`);

        return (
          <Route
            key={index.toString()}
            exact={child.exact || false}
            path={path}
            render={({ match: { url }, ...props }) =>
              hasAccessRights(location.pathname) ? (
                <child.component {...props} flowHandle={child.handle} nextRoute={child.nextRoute} parentRoute={url} state={state}>
                  {child.children}
                </child.component>
              ) : (
                <Redirect to={PRE_LOGIN_ROUTES.login} />
              )
            }
          />
        );
      })}
    </Switch>
  );
};

export const pathChildrenMap = (childArray: IComponentRoute[], url: string, state?: RootState): JSX.Element => {
  return <PathChildren childArray={childArray} state={state} url={url} />;
};

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

// TODO: disable the socket functionality for now. Not needed.
// const SocketContext = createContext({
//   socket: io(`${WEBSOCKET_URL}`, { path: `${API_PREFIX}/socket/statements` }).connect(),
// });

// export const useSocket = () => useContext(SocketContext);

export const AppRouter: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const authData = useSelector((state: RootState) => state.authUser.data);
  const appIsLoading = useSelector((state: RootState) => state.appStatus.isLoading);

  const { currentStatementIsLoading } = useCurrentStatement();

  const checkIfAuthenticated = () => {
    if (window.location.pathname.startsWith(PRE_LOGIN_ROUTES.loginSuccess)) {
      return;
    }

    if (!authData) {
      dispatch(logOutAction());

      return;
    }

    // Validate token
    getUserByIdApi(authData.id)
      .then(() => {
        // token is valid
      })
      .catch(e => {
        // token is not valid
        if (e?.request?.status === 401) {
          dispatch(logOutAction());
        }
      });
  };

  // redirect to login if not authenticated
  useEffect(() => {
    checkIfAuthenticated();
  }, [authData]);

  return (
    <StyledLoader
      active={currentStatementIsLoading || appIsLoading}
      classNamePrefix='MyLoader_'
      text='Va rugam asteptati, preluam datele...'
      spinner
    >
      <Route
        path={mainRoutes.baseUrl}
        render={({ match: { url }, ...props }) => <PathChildren {...props} childArray={mainRoutes.children} state={state} url={url} />}
      />
      <Route
        path={[
          AuthRoutes.statements,
          BottomNavigationPathUtilities.utils,
          BottomNavigationPathUtilities.profile,
          BottomNavigationPathUtilities.evaluations,
          BottomNavigationPathUtilities.home,
        ]}
      />
    </StyledLoader>
  );
};
