import React, { ReactNode } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

import { backgroundColor } from '../../utils/elementColorSwitch';

export interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
  background?: string;
  height?: string;
  minHeight?: string;
  width?: string;
  maxWidth?: string;
  maxHeight?: string;
  display?: string;
  overflowY?: string;
  flexWrap?: string;
  position?: string;
  padding?: string;
  bottom?: string;
  zIndex?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  margin?: string;
  marginRight?: string;
  marginLeft?: string;
  marginTop?: string;
  top?: string;
  right?: string;
  marginBottom?: string;
  borderRadius?: string;
  border?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  justifyContent?: string;
  flexDirection?: string;
  alignSelf?: string;
  nowrap?: boolean;
  alignItems?: string;
  color?: string;
  textAlign?: string;
  boxShadow?: string;
  className?: string;
  flex?: string;
  onClick?: () => void;
  shake?: boolean;
  gridTemplateColumns?: string;
  children?: JSX.Element | JSX.Element[] | ReactNode;
}
export interface IContainerFixedProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string;
  height?: string;
  maxWidth?: string;
  margin?: string;
  textAlign?: string;
  border?: string;
  borderRadius?: string;
  padding?: string;
  position?: string;
  backgroundColor?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  zIndex?: string;
  boxShadow?: string;
  children?: JSX.Element | JSX.Element[] | ReactNode;
}
export interface IColumnProps {
  position?: string;
  flex?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  borderRadius?: string;
  border?: string;
  textAlign?: string;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  children?: JSX.Element | JSX.Element[] | ReactNode;
}

interface IThemedWrapper extends IContainerProps {
  theme: DefaultTheme;
}

interface IThemedColumn extends IContainerProps {
  theme: DefaultTheme;
}

export const StyledWrapper = styled.div`
  width: ${({ width }: IContainerProps) => width || 'auto'};
  bottom: ${({ bottom }: IContainerProps) => bottom || 'unset'};
  z-index: ${({ zIndex }: IContainerProps) => zIndex || 1};
  height: ${({ height }: IContainerProps) => height || 'auto'};
  display: ${({ display }: IContainerProps) => display || 'flex'};
  flex-direction: ${({ flexDirection }: IContainerProps) => flexDirection || 'row'};
  align-self: ${({ alignSelf }: IContainerProps) => alignSelf || 'unset'};
  flex-wrap: ${({ flexWrap }: IContainerProps) => flexWrap || 'unset'};
  color: ${({ color }: IThemedWrapper) => color || 'inherit'};
  position: ${({ position }: IContainerProps) => position || 'relative'};
  justify-content: ${({ justifyContent }: IContainerProps) => justifyContent || 'space-between'};
  align-items: ${({ alignItems }: IContainerProps) => alignItems || 'center'};
  background-color: ${(props: IThemedWrapper) => backgroundColor(props)};
  background: ${(props: IThemedWrapper) => props.background};
  overflow-y: ${({ overflowY }: IThemedWrapper) => overflowY || 'unset'};
  animation: ${({ shake }) => (shake ? 'shake 0.8s' : '')};
  margin-bottom: ${({ marginBottom }: IContainerProps) => marginBottom || '0'};
  margin-top: ${({ marginTop }: IContainerProps) => marginTop || '0'};
  margin-right: ${({ marginRight }: IContainerProps) => marginRight || '0'};
  margin-left: ${({ marginLeft }: IContainerProps) => marginLeft || '0'};
  padding-top: ${({ paddingTop }: IContainerProps) => paddingTop || '0'};
  padding-bottom: ${({ paddingBottom }: IContainerProps) => paddingBottom || '0'};
  padding-right: ${({ paddingRight }: IContainerProps) => paddingRight || '0'};
  animation-iteration-count: infinite;
  opacity: ${({ shake }) => (shake ? '0.2' : '1')};
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(0px, -1px) rotate(-1deg);
    }
    20% {
      transform: translate(-1px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(2px, 1px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(0px, 1px) rotate(-1deg);
    }
    60% {
      transform: translate(-1px, 2px) rotate(0deg);
    }
    70% {
      transform: translate(1px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(0px, 1px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -1px) rotate(-1deg);
    }
  }

  ${({ padding }: IContainerProps) =>
    padding &&
    css`
      padding: ${padding};
    `}
  ${({ borderRight }: IContainerProps) =>
    borderRight &&
    css`
      border-right: ${borderRight};
    `}
	${({ borderLeft }: IContainerProps) =>
    borderLeft &&
    css`
      border-left: ${borderLeft};
    `}
	${({ borderTop }: IContainerProps) =>
    borderTop &&
    css`
      border-top: ${borderTop};
    `}
	${({ borderBottom }: IContainerProps) =>
    borderBottom &&
    css`
      border-top: ${borderBottom};
    `}
  ${(props: IContainerFixedProps) =>
    props.top &&
    css`
      top: ${props.top};
    `}
  ${(props: IContainerFixedProps) =>
    props.right &&
    css`
      right: ${props.right};
    `}
	${({ margin }: IContainerProps) =>
    margin &&
    css`
      margin: ${margin};
    `}
	${({ maxWidth }: IContainerProps) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  ${({ maxHeight }: IContainerProps) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
	${({ boxShadow }: IContainerProps) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
	${({ border }: IContainerProps) =>
    border &&
    css`
      border: ${border};
    `}
	${({ borderRadius }: IContainerProps) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}
  ${({ flex }: IContainerProps) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

export const Wrapper = (props: IContainerProps): JSX.Element => {
  return <StyledWrapper {...props}> {props.children} </StyledWrapper>;
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  width: ${({ width }: IContainerProps) => width || '100%'};
  height: ${({ height }: IContainerProps) => height || 'auto'};
  padding-left: ${({ paddingLeft }: IContainerProps) => paddingLeft || '20px'};
  padding-right: ${({ paddingRight }: IContainerProps) => paddingRight || '20px'};
  margin: ${({ margin }: IContainerProps) => margin || '0 auto'};
  background-color: ${({ backgroundColor }: IContainerProps) => backgroundColor || 'transparent'};
  position: ${({ position }: IContainerProps) => position || 'relative'};
  display: ${({ display }: IContainerProps) => display || 'block'};
  flex-direction: ${({ flexDirection }: IContainerProps) => flexDirection || 'initial'};
  align-items: ${({ alignItems }: IContainerProps) => alignItems || 'initial'};
  justify-content: ${({ justifyContent }: IContainerProps) => justifyContent || 'initial'};
  overflow-y: ${({ overflowY }: IContainerProps) => overflowY || 'unset'};
  ${({ boxShadow }: IContainerProps) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
  ${({ padding }: IContainerProps) =>
    padding &&
    css`
      padding: ${padding};
    `}
	${({ boxShadow }: IContainerProps) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
	${({ maxWidth }: IContainerProps) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
	${({ border }: IContainerProps) =>
    border &&
    css`
      border: ${border};
    `}
	${({ borderRadius }: IContainerProps) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}
		${({ minHeight }: IContainerProps) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}
		${({ textAlign }: IContainerProps) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
  ${({ flex }: IContainerProps) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

export const Container = (props: IContainerProps): JSX.Element => {
  return <StyledContainer {...props}> {props.children} </StyledContainer>;
};

const StyledContainerFixed = styled.div`
  width: ${({ width }: IContainerFixedProps) => width || '100%'};
  height: ${({ height }: IContainerFixedProps) => height || 'auto'};
  position: ${({ position }: IContainerFixedProps) => position || 'fixed'};
  padding: ${({ padding }: IContainerFixedProps) => padding || '0 20px'};
  background-color: ${({ backgroundColor }: IContainerFixedProps) => backgroundColor || '#fff'};
  z-index: ${({ zIndex }: IContainerFixedProps) => zIndex || '1'};
  ${(props: IContainerFixedProps) =>
    props.bottom &&
    css`
      bottom: ${props.bottom};
    `}
  ${(props: IContainerFixedProps) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
	${(props: IContainerFixedProps) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
	${(props: IContainerFixedProps) =>
    props.top &&
    css`
      top: ${props.top};
    `}
	${(props: IContainerFixedProps) =>
    props.right &&
    css`
      right: ${props.right};
    `}
	${({ boxShadow }: IContainerFixedProps) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
	${({ maxWidth }: IContainerFixedProps) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
	${({ border }: IContainerFixedProps) =>
    border &&
    css`
      border: ${border};
    `}
   ${({ borderRadius }: IContainerFixedProps) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}
`;

export const ContainerFixed = (props: IContainerFixedProps): JSX.Element => {
  return <StyledContainerFixed {...props}> {props.children} </StyledContainerFixed>;
};

const StyledRow = styled.div`
  box-sizing: border-box;
  display: ${({ display }: IContainerProps) => display || 'flex'};
  flex-wrap: ${({ nowrap }: IContainerProps) => (nowrap ? 'nowrap' : 'wrap')};
  margin: ${({ margin }: IContainerProps) => margin || '0'};
  margin-top: ${({ marginTop }: IContainerProps) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }: IContainerProps) => marginBottom || '0'};
  margin-left: ${({ marginLeft }: IContainerProps) => marginLeft || '-20px'};
  margin-right: ${({ marginRight }: IContainerProps) => marginRight || '-20px'};
  justify-content: ${({ justifyContent }: IContainerProps) => justifyContent || 'flex-start'};
  background-color: ${({ backgroundColor }: IContainerProps) => backgroundColor || 'transparent'};
  ${({ boxShadow }: IContainerProps) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow};
    `}
  ${({ gridTemplateColumns }: IContainerProps) =>
    gridTemplateColumns &&
    css`
      grid-template-columns: ${gridTemplateColumns};
    `}
  ${({ border }: IContainerProps) =>
    border &&
    css`
      border: ${border};
    `}
`;

export const Row = (props: IContainerProps): JSX.Element => {
  return <StyledRow {...props}> {props.children} </StyledRow>;
};

const StyledColumn = styled.div`
  box-sizing: border-box;
  flex: ${({ maxWidth }: IColumnProps) => (maxWidth ? `0 0 ${maxWidth}` : '0 0 100%')};
  position: ${({ position }: IColumnProps) => (position ? position : 'static')};
  max-width: ${({ maxWidth }: IColumnProps) => maxWidth || '100%'};
  height: ${({ height }: IColumnProps) => height || '100%'};
  text-align: ${({ textAlign }: IColumnProps) => textAlign || 'left'};
  color: ${({ color, theme }: IThemedColumn) => color || theme.typography.textPrimaryColor};
  background-color: ${({ backgroundColor }: IColumnProps) => backgroundColor || 'transparent'};
  padding-left: ${({ paddingLeft }: IColumnProps) => paddingLeft || '20px'};
  padding-right: ${({ paddingRight }: IColumnProps) => paddingRight || '20px'};
  padding-top: ${({ paddingTop }: IColumnProps) => paddingTop || '.75rem'};
  padding-bottom: ${({ paddingBottom }: IColumnProps) => paddingBottom || '.75rem'};
  border-right: ${({ borderRight }: IColumnProps) => borderRight || 'none'};
  border-left: ${({ borderLeft }: IColumnProps) => borderLeft || 'none'};
  border-top: ${({ borderTop }: IColumnProps) => borderTop || 'none'};
  border-bottom: ${({ borderBottom }: IColumnProps) => borderBottom || 'none'};
  text-align: ${({ textAlign }: IColumnProps) => textAlign || 'left'};
  ${({ width }: IColumnProps) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ border }: IColumnProps) =>
    border &&
    css`
      border: ${border};
    `}
	${({ borderRadius }: IColumnProps) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}
`;

export const Column = (props: IColumnProps): JSX.Element => {
  return <StyledColumn {...props}> {props.children} </StyledColumn>;
};

export const StyledScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ backgroundColor }: { backgroundColor?: string }) => backgroundColor || '#F0F2FC'};
  background: ${({ backgroundColor }: { backgroundColor?: string }) => backgroundColor || '#F0F2FC'};
`;
