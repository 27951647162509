import { PayloadAction } from '@reduxjs/toolkit';

import { createGenericSlice } from '../store.constants';
import { ILastRoute } from '../../@types/last-routes/lastRoutesTypes';

export type ILastRoutes = {
  data: ILastRoute[];
  isLoading: boolean;
  error: string | null;
};

const initialState: ILastRoutes = {
  data: [],
  isLoading: false,
  error: null,
};

const lastRoutesSlice = createGenericSlice({
  name: 'lastRoutesSlice',
  initialState,
  reducers: {
    setLastRoute(state: ILastRoutes, action: PayloadAction<ILastRoutes>) {
      state.data = action.payload.data;
    },
    addNewRoute(state: ILastRoutes, action: PayloadAction<ILastRoute>) {
      state.data.push(action.payload);
    },
  },
});

export default lastRoutesSlice.reducer;

export const { addNewRoute, setLastRoute } = lastRoutesSlice.actions;
