import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Typography, Wrapper } from '../../styled-components';
import { Button } from '../../components/button';
import FaceRecognition from '../../assets/icons/faceRecognition';
import { IScreenComponentProps } from '../component-props.model';
import { handleNextRouteLastPathConversion } from '../../helpers/routes-helpers';

export const InjuredRecognition = ({ history, nextRoute, parentRoute }: IScreenComponentProps) => {
  const { t } = useTranslation();

  return (
    <ScreenContainer headerProps={{ title: t('facialRecognition.title'), hideLogo: true }}>
      <Typography align='left' commonStyles={{ width: '100%' }} fontWeight='bold' variant='h4'>
        {t('facialRecognition.faceScan')}
      </Typography>
      <Typography align='left' color='#8E8E8E' commonStyles={{ width: '100%', margin: '10px 0 0 0' }} variant='caption'>
        {t('facialRecognition.preparePhone')}
      </Typography>

      <Wrapper border='2px solid #C4C4C4' height='200px' margin='70px 0 ' width='60%'>
        <FaceRecognition color='black' height='150' width='200' />
      </Wrapper>
      <Button
        margin='auto 0 0 0'
        mode='primary'
        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
        onClick={() => history.push(handleNextRouteLastPathConversion(parentRoute, nextRoute))}
      >
        <Wrapper justifyContent='flex-start' style={{ gap: '30px' }}>
          <FaceRecognition color='white' height='40' width='40' />
          <Typography color='white' variant='h5'>
            {t('facialRecognition.startScan')}
          </Typography>
        </Wrapper>
      </Button>
    </ScreenContainer>
  );
};
