import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from '../../components/button';
import { CoordsSetI } from '../../@types/damage/damageType';

interface PropsI {
  x?: number;
  y?: number;
  part?: string;
  leftSideCoordsSet: Set<CoordsSetI>;
  rightSideCoordsSet: Set<CoordsSetI>;
  isLeftSide: () => boolean;
  damagePartsSet: Set<string>;
}

export const DamageIndicator = ({ damagePartsSet, isLeftSide, x, y, part, rightSideCoordsSet, leftSideCoordsSet }: PropsI): JSX.Element => {
  const { t } = useTranslation();
  const [showClose, setShowClose] = useState<boolean>(false);

  const matchAndDeleteIndicator = (coordSet: Set<CoordsSetI>, carPart: string) => {
    const coordsArr = Array.from(coordSet).filter((element: CoordsSetI) => {
      return element.part === carPart;
    });

    coordSet.delete(coordsArr[0]);

    setTimeout(function () {
      damagePartsSet.delete(carPart);
    }, 1000);
  };

  const deleteDamagedPart = (carPart: string) => {
    isLeftSide() ? matchAndDeleteIndicator(leftSideCoordsSet, carPart) : matchAndDeleteIndicator(rightSideCoordsSet, carPart);
  };

  return (
    <div
      style={{
        width: '30px',
        position: 'absolute',
        zIndex: 10,
        left: `calc(${x}px - 20px)`,
        top: `calc(${y}px - 80px)`,
      }}
      onClick={e => {
        e.preventDefault();
        setShowClose(prevState => !prevState);
      }}
      onTouchEnd={e => {
        e.preventDefault();
        setShowClose(prevState => !prevState);
      }}
    >
      <span
        style={{
          width: '30px',
          height: '30px',
          borderRadius: '100%',
          display: 'block',
          position: 'relative',
          backgroundColor: '#f0cb48',
        }}
      >
        {' '}
      </span>
      {showClose && (
        <CloseButton
          bottom='0'
          height='38px'
          left='-4px'
          right='0'
          top='0'
          width='38px'
          zIndex={3}
          onClick={() => {
            part && deleteDamagedPart(part);
          }}
          onTouchEnd={e => {
            e.preventDefault();
            part && deleteDamagedPart(part);
          }}
        />
      )}
    </div>
  );
};
