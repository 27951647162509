import React, { useEffect, useState } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { CircumstancesSelectionForm } from '../../components/statements/circumstances/CircumstancesSelectionForm';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { USER_SITUATION } from '../../services/router/routeHandles';
import { circumstancesData, ICheckOption } from '../../constants/dataConstants/circumstances';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { useStatements } from '../../hooks/useStatements';
import { ICurrentStatement } from '../../@types/current-statement/currentStatementTypes';
import { updateStatement } from '../../services/api/createStatement/createStatement.api';

export type SelectionType = 'checkbox' | 'radio' | 'text';

export const CircumstancesSelection = ({ history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { fetchStatements, statementsState } = useStatements();
  const { getCurrentStatementCircumstances, currentStatement } = useCurrentStatement();
  const { id, currentStatementId } = useParams<{ currentStatementId: string | undefined; id: string | undefined }>();
  circumstancesData[0]?.values.forEach((value: ICheckOption) => {
    value.checked = false;
  });
  const [formValues, setFormValues] = useState(circumstancesData[0]?.values);
  const [title, setTitle] = useState(circumstancesData[0]?.title);
  const [formType, setFormType] = useState(circumstancesData[0]?.type);

  const verifyAlreadyCompleted = (statement: ICurrentStatement) => {
    return !!(
      statement?.accidentDate &&
      statement?.accidentTime &&
      statement?.accidentLocation &&
      statement?.weatherCondition &&
      statement?.roadCondition &&
      statement?.description
    );
  };

  useEffect(() => {
    fetchStatements();
    statementsState.forEach(statement => {
      if (statement.statementID === Number(currentStatementId) && verifyAlreadyCompleted(statement)) {
        return history.push(generatePath(`${PROTECTED_ROUTES.statements}`));
      }
    });

    setFormValues(circumstancesData[Number(id) - 1]?.values);
    setTitle(circumstancesData[Number(id) - 1]?.title);
    setFormType(circumstancesData[Number(id) - 1]?.type);
  }, [id, formValues, title, formType]);

  const saveCircumstancesSelection = async (values: ICheckOption[]) => {
    const circumstancesValues = values.filter((circumstance: ICheckOption) => circumstance.checked === true);
    const convertedCircumstances: Array<any> = circumstancesValues.map((circumstance: ICheckOption) => {
      return circumstance.hasChild ? circumstance?.childValue : circumstance.value;
    });

    switch (id) {
      case '1':
        getCurrentStatementCircumstances(USER_SITUATION.INJURED_STATUS, convertedCircumstances);
        await updateStatement({
          id: currentStatement.statementID,
          description: JSON.stringify(convertedCircumstances),
        });
        await fetchStatements();
        break;
      case '2':
        await updateStatement({
          id: currentStatement.statementID,
          weatherCondition: convertedCircumstances[0],
        });
        await fetchStatements();
        break;
      case '3':
        await updateStatement({
          id: currentStatement.statementID,
          roadCondition: convertedCircumstances[0],
        });
        await fetchStatements();
        break;
      default:
        break;
    }

    const nextId = Number(id) + 1;

    if (nextId < 4) {
      history.push(
        generatePath(`${PROTECTED_ROUTES.circumstances$Id}`, {
          currentStatementId: String(currentStatement.statementID),
          id: String(nextId),
        }),
      );
    } else {
      history.push(
        generatePath(`${PROTECTED_ROUTES.statement$IdDetails}`, {
          currentStatementId: String(currentStatement.statementID),
        }),
      );
    }
  };

  const onChangeBox = (el: ICheckOption, value: boolean, idx: number) => {
    // TODO remove this function
    // TODO: this will be refactored/removed as soon as redux store is in place
    const temp = [...circumstancesData];
    temp[0].values[idx].checked = value;
    setFormValues(temp[0].values);
  };

  const onChangeChildValue = (el: ICheckOption, value: string, idx: number) => {
    //TODO remove this function
    const temp = [...circumstancesData];

    temp[0].values[idx].childValue = value;
    setFormValues(temp[0].values);
  };

  return (
    <ScreenContainer headerProps={{ title: t('circumstances.title'), hideLogo: true, titleSize: 'h5' }}>
      <CircumstancesSelectionForm
        formValues={formValues}
        title={title}
        type={formType}
        onChangeCheckbox={onChangeBox}
        onChangeChildValue={onChangeChildValue}
        onSubmit={saveCircumstancesSelection}
      />
    </ScreenContainer>
  );
};
