import { useEffect, useState } from 'react';

import { getCitiesByCounty, getLocations } from '../store/locations/locations.api';
//hh
const useLocationsData = () => {
  const [counties, setCounty] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    getLocations().then(response => {
      const newData: [] = [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.keys(response.data).map((key: string) => newData.push({ name: response.data[key], value: response.data[key] }));
      setCounty(newData);
    });
  }, []);

  const loadCities = (county: string, countyRequired?: boolean) => {
    if (!county && countyRequired) {
      setCities([]);

      return { counties, cities, loadCities };
    }
    getCitiesByCounty(county).then(response => {
      const newData: [] = [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.keys(response.data).map((key: string) => newData.push({ name: response.data[key], value: response.data[key] }));
      setCities(newData);
    });
  };

  return { counties, cities, loadCities };
};

export { useLocationsData };
