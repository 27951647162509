import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { createStyles, makeStyles } from '@material-ui/core';

import { AddCircumstancesForm } from '../../components';
import { IScreenComponentProps } from '../component-props.model';
import { GUILTY_STATUS } from '../../services/router/routeHandles';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { PROTECTED_ROUTES } from '../../services/router/app-routes/routes-constants';
import { updateStatement } from '../../services/api/createStatement/createStatement.api';
import { AccidentEnvironment } from '../../@types/circumstances/circumstancesTypes';
import { GoogleMap } from '../settings/google-map';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  }),
);

export const CircumstancesAdd = ({ history }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { currentStatement, setCurrentStatementData } = useCurrentStatement();
  const flowHandle = history?.location?.state?.flowHandle;

  const [data, setData] = useState<AccidentEnvironment>({
    accidentDate: currentStatement.accidentDate || moment(new Date()).format('YYYY-MM-DD'),
    accidentTime: currentStatement.accidentTime || moment(new Date()).format('HH:mm'),
    accidentLocation: currentStatement?.accidentLocation || null,
  });

  const [isMap, setMap] = useState<boolean>(false);

  const onSave = () => {
    const { accidentDate, accidentTime, accidentLocation } = data;

    setCurrentStatementData({
      accidentDate,
      accidentTime,
      accidentLocation,
    });

    if (accidentDate && accidentTime) {
      const transformedDateTime = moment(accidentDate + ' ' + accidentTime).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      const payload = {
        id: currentStatement.statementID,
        dateTime: transformedDateTime,
        location: accidentLocation,
      };

      updateStatement(payload).then(() => {
        history.push(
          generatePath(PROTECTED_ROUTES.circumstances$Id, {
            currentStatementId: Number(currentStatement.statementID),
            id: 1,
          }),
        );
      });
    }
  };

  const renderMap = () => (
    <div className={classes.content}>
      <GoogleMap
        address={data.accidentLocation || ''}
        onSave={address => {
          setData(prevState => ({ ...prevState, accidentLocation: address || '' }));
          setMap(false);
        }}
      />
    </div>
  );

  const renderForm = () => (
    <AddCircumstancesForm
      formValues={data}
      hideDateTime={flowHandle === GUILTY_STATUS}
      setAccidentLocation={() => setMap(true)}
      onChangeValue={(value: string, key: string) => setData(prevState => ({ ...prevState, [key]: value }))}
      onSubmit={() => onSave()}
    />
  );

  const renderContent = () => {
    if (isMap) return renderMap();

    return renderForm();
  };

  return (
    <ScreenContainer
      bodyProps={isMap ? { padding: '0' } : {}}
      headerProps={{
        hideLogo: true,
        title: t('circumstances.title'),
      }}
      headerType={isMap ? 'none' : 'light'}
    >
      {renderContent()}
    </ScreenContainer>
  );
};
