import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Image } from '../../styled-components';
import { Typography } from '../../styled-components';
import { CloseButton } from '../../components/button';

interface PropsI {
  label: string;
  image?: string;
  onAddDocument: () => void;
  onDelete: () => void;
  isDeletable: boolean;
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '150px',
    margin: '16px auto 0 auto',
    alignSelf: 'flex-end',
  },
});

/**
 *
 * @param {PropsI} props
 * @returns {JSX.Element}
 * @constructor
 */
export const AddPictureContainer = (props: PropsI): JSX.Element => {
  const classes = useStyles();

  const { label, image, onAddDocument, onDelete, isDeletable } = props;

  const renderDeleteAction = () => {
    if (!isDeletable) return <></>;

    return <CloseButton onClick={() => onDelete()} />;
  };

  return (
    <div className={classes.container}>
      <Typography color='#3F414E' commonStyles={{ margin: '0 0 7px 0', width: '95%' }} fontWeight='normal' variant='body'>
        {label}
      </Typography>
      <Image
        backgroundColor='white'
        borderRadius='10px'
        src={image}
        style={{
          objectFit: 'cover',
          width: 145,
          height: 145,
        }}
        onClick={() => onAddDocument()}
      />
      {renderDeleteAction()}
    </div>
  );
};
