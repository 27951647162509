import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';

import { IComponentRoute } from '../services/router/appRoutes';

export const checkUrl = (path: string): boolean => {
  const length = path.length;

  return path[length - 1] === '/';
};

export const removeTrailingSlash = (path: string): string => {
  const length = path.length;

  return path.substring(0, length - 1);
};

export const removeLeadingSlash = (path?: string): string => {
  return (path || '').replace(/^\/\/+/, '/'); // replace // to /
};

export const removeParamsFromUrl = (path: string): string => {
  const patchSlices = path.split('/');

  return patchSlices.filter(param => param.indexOf(':') !== 0).join('/');
};

export const cleanupUrlString = (path: IComponentRoute['nextRoute']): IComponentRoute['nextRoute'] => {
  if (typeof path === 'object') {
    return {
      guilty: removeParamsFromUrl(path.guilty || ''),
      injured: removeParamsFromUrl(path.injured || ''),
    };
  }

  return removeParamsFromUrl(path || '');
};

export const momentDateFormat = (date: MaterialUiPickersDate, format = 'YYYY/MM/DD'): string => {
  return moment(date).format(format);
};
