import React from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export const StyledCard = styled.div`
  border-radius: 18px;
  box-shadow: 0 2px 15px #dadada;
  background-color: #fff;
  padding: 16px;
  margin: 0 0 20px 0;
  width: 100%;
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const CardHeaderWrapper = styled(CardSection)`
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 18px 12px 5px 12px;
`;

export const CardContentWrapper = styled(CardSection)`
  padding: 15px;
`;

export const ContinueBtn = styled.button`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  border: none;
  border-top: 1px solid ${({ separatorColor }: { separatorColor?: string }) => separatorColor || 'white'};
  background: white;
  width: 100%;
  padding: 15px;
  font-size: 15px;
  color: ${({ color }: { color?: string }) => color || 'black'};
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.64px;
`;

/*export const CardTitle = styled.h6`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: black;
  margin: 0 0 11px 0;
`;*/

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  width: 100%;
`;

export const ColProperty = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #9a9a9a;
  margin-right: 5px;
`;

export const ColValue = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 23px;
  color: #1e1e29;
`;

export const CardActionWrapper = styled.div`
  margin-top: 9px;
  width: 100%;
`;

interface IStatusItem {
  label?: string;
  header: string;
  color: string;
}

const StatusText = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: ${({ color }: { color?: string }) => color || 'white'};
`;

const CardTitle = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: ${({ color }: { color?: string }) => color || 'white'};
`;

const CardStatusText = styled.p`
  color: ${({ color }: { color?: string }) => color || 'black'};
  text-align: left;
  width: 100%;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.75px;
  margin: 0;
  padding: 8px 0 0;
`;

export const CardHeader: React.FC<IStatusItem> = ({ label, header, color, children }) => (
  <CardHeaderWrapper>
    <CardRow>
      <CardTitle color='black'>{`${header}`}</CardTitle>
      {children}
    </CardRow>

    {label && (
      <CardRow>
        <CardStatusText color={color}>{label}</CardStatusText>
      </CardRow>
    )}
  </CardHeaderWrapper>
);

type ICardCardContent = React.HTMLAttributes<HTMLDivElement>;

export const CardContent: React.FC<ICardCardContent> = ({ children, ...props }) => (
  <CardContentWrapper {...props}>{children}</CardContentWrapper>
);

interface ICardActionButtonProps {
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  color?: string;
  label: string;
  separatorColor?: string;
}

export const CardActionButton: React.FC<ICardActionButtonProps> = ({ onClick, color = 'black', label, separatorColor }) => (
  <ContinueBtn color={color} separatorColor={separatorColor} onClick={onClick}>
    {label}
    <ArrowForwardIcon fontSize='small' style={{ color }} />
  </ContinueBtn>
);

export const ElevatedCard: React.FC<IStatusItem> = ({ label, header, color }) => (
  <CardRow>
    <StatusText color={color}>{`${header} ${label}`}</StatusText>
  </CardRow>
);
