import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from './styles/globalStyles';
import { AppRouter } from './services/router/AppRouter';
import { darkTheme } from './styles/theme';
import './App.css';
import { history } from './store/store';

function App(): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyle />
      <ConnectedRouter history={history}>
        <Router>
          <AppRouter />
        </Router>
      </ConnectedRouter>
    </ThemeProvider>
  );
}

export default App;
