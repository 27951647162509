import React from 'react';

import { IIconProps } from './icon.model';

export const MessageIcon = ({ width, height, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 24} viewBox='0 0 24 24' width={width || 24} {...otherProps}>
      <path
        d='M23 5.632H1v16.923h22V5.632z'
        stroke='url(#prefix__paint0_linear)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
      <path
        d='M1.844 6.479l8.461 7.7c.931.93 2.454.93 3.47 0l8.123-7.362'
        stroke='url(#prefix__paint1_linear)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
      <path
        d='M1.844 6.479l8.884-5.162a2.697 2.697 0 012.539 0l8.546 4.823'
        stroke='url(#prefix__paint2_linear)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
      <path
        d='M14.536 14.095l7.277 7.277m-19.97.338l7.616-7.615-7.615 7.615z'
        stroke='url(#prefix__paint3_linear)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
      <path d='M5.23 9.018H18.77' stroke='url(#prefix__paint4_linear)' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} />
      <defs>
        <linearGradient gradientUnits='userSpaceOnUse' id='prefix__paint0_linear' x1={13.1} x2={13.835} y1={2.968} y2={32.959}>
          <stop offset={1} stopColor='#6C5CE7' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='prefix__paint1_linear' x1={12.873} x2={13.072} y1={5.157} y2={20.046}>
          <stop offset={1} stopColor='#6C5CE7' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='prefix__paint2_linear' x1={12.827} x2={12.912} y1={0.138} y2={9.852}>
          <stop offset={1} stopColor='#6C5CE7' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='prefix__paint3_linear' x1={12.827} x2={12.991} y1={12.896} y2={26.398}>
          <stop offset={1} stopColor='#6C5CE7' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='prefix__paint4_linear' x1={12.677} x2={12.681} y1={8.86} y2={10.633}>
          <stop offset={1} stopColor='#6C5CE7' />
        </linearGradient>
      </defs>
    </svg>
  );
};
