import React, { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Wrapper } from '../../styled-components';
import { FileUpload } from '../../components';
import { Button } from '../button';
import { ModalDataI } from '../../screens/statement-damage/AddPictureContainer';
import { FILE_MODE, FILE_TYPE, INPUT_TYPE } from '../../constants/typeConstants/cameraType';
import AddPictureBackground from '../../assets/images/add-picture.png';
import CarFrontImageTutorial from '../../assets/images-tutorial/damages/fata.png';
import CarBackImageTutorial from '../../assets/images-tutorial/damages/spate.png';
import LateralLeftImageTutorial from '../../assets/images-tutorial/damages/lateral-stanga.png';
import LateralRightImageTutorial from '../../assets/images-tutorial/damages/lateral-dreapta.png';
import MileageImageTutorial from '../../assets/images-tutorial/damages/kilometraj.png';
import ChassisImageTutorial from '../../assets/images-tutorial/damages/serie-sasiu.png';
import DamageDetailImageTutorial from '../../assets/images-tutorial/damages/detaliu-dauna.png';
import OverallCallImageTutorial from '../../assets/images-tutorial/damages/ansamblu-dauna.png';

interface PropsI {
  data: ModalDataI | null;
  setShowModal?: (arg0: boolean) => void;
  setPicture?: (file: File, data: ModalDataI | null) => void;
  CAMERA_TYPE_ACTION: string;
  CAMERA_TYPE_TAKE: string;
  RETAKE_CAMERA_TYPE: string;
  onlyPicture?: boolean;
  moveOn?: string;
}

export const MediaModal = ({
  RETAKE_CAMERA_TYPE,
  CAMERA_TYPE_ACTION,
  CAMERA_TYPE_TAKE,
  setPicture,
  setShowModal,
  onlyPicture,
  moveOn,
  data,
}: PropsI): JSX.Element => {
  const imageRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const [changeButtons, setChangeButtons] = useState<boolean>(false);
  const [fileUploadMode, setFileUploadMode] = useState<string | null>(null);
  const [currentFile, setCurrentFile] = useState<File>();

  const onUpload = () => {
    setFileUploadMode(FILE_MODE.UPLOAD);
    setTimeout(() => {
      imageRef.current?.click();
    }, 300);
  };

  const onCamera = () => {
    setFileUploadMode(FILE_MODE.CAMERA);
    setTimeout(() => {
      imageRef.current?.click();
    }, 300);
  };

  const onFileChange = useCallback((file?: File | null) => {
    if (file) {
      if (file && setPicture) {
        setPicture(file, data);
      }

      if (setShowModal) {
        setShowModal(false);
      }
    } else {
      setChangeButtons(false);
    }
  }, []);

  const getDefaultImage = () => {
    switch (data?.modalLabel) {
      case t('affectedZones.modal_label.carBack'):
        return CarBackImageTutorial;
      case t('affectedZones.modal_label.carFront'):
        return CarFrontImageTutorial;
      case t('affectedZones.modal_label.lateralLeft'):
        return LateralLeftImageTutorial;
      case t('affectedZones.modal_label.lateralRight'):
        return LateralRightImageTutorial;
      case t('affectedZones.modal_label.boardMileage'):
        return MileageImageTutorial;
      case t('affectedZones.modal_label.chassisSeries'):
        return ChassisImageTutorial;
      case t('affectedZones.modal_label.firstOverallPicture'):
        return OverallCallImageTutorial;
      case t('affectedZones.modal_label.secondOverallPicture'):
        return OverallCallImageTutorial;
      case t('affectedZones.modal_label.firstDamageDetail'):
        return DamageDetailImageTutorial;
      case t('affectedZones.modal_label.secondDamageDetail'):
        return DamageDetailImageTutorial;
      default:
        return AddPictureBackground;
    }
  };

  return (
    <Wrapper flexDirection='column' height='100%'>
      <Wrapper display='block' width='100%'>
        <Typography commonStyles={{ margin: '0 auto 5px 0' }} variant='h4' whiteSpace='unset'>
          {data?.modalLabel}
        </Typography>
        {data?.label?.includes('*') ? (
          <Typography align='left' color='#DB4437' commonStyles={{ width: '100%' }} fontWeight='400' variant='caption'>
            {t('affectedZones.mandatoryPicture')}
          </Typography>
        ) : (
          <Typography align='left' color='#9A9A9A' commonStyles={{ width: '100%' }} fontWeight='400' variant='caption'>
            {data?.type === INPUT_TYPE.VIDEO ? t('cameraText.notRequired') : ''}
          </Typography>
        )}
      </Wrapper>
      <Wrapper display='flex' flexDirection='column' height='100%' style={{ overflowY: 'scroll', marginTop: '20px' }} width='100%'>
        <FileUpload
          ref={imageRef}
          initialImageB64={data?.src ? data.src : getDefaultImage()}
          mode={fileUploadMode}
          type={data?.type === INPUT_TYPE.IMAGE ? INPUT_TYPE.IMAGE : INPUT_TYPE.VIDEO}
          hideControls
          preview
          onFileChange={onFileChange}
        />
        {changeButtons ? (
          <Wrapper display='flex' flexDirection='column' margin='50px 0 0 0' width='100%'>
            <Button
              boxShadow='unset'
              color='#512AD8'
              mode='primary'
              onClick={() => {
                if (currentFile)
                  if (setPicture) {
                    setPicture(currentFile, data);
                  }

                if (setShowModal) {
                  setShowModal(false);
                }
              }}
            >
              {t('commonText.continue')}
            </Button>
            <Button boxShadow='unset' color='#512AD8' mode='primary' onClick={() => onCamera()}>
              {RETAKE_CAMERA_TYPE}
            </Button>
          </Wrapper>
        ) : (
          <Wrapper display='flex' flexDirection='column' width='100%'>
            <Button color='#512AD8' mode='primary' onClick={() => onCamera()}>
              {CAMERA_TYPE_TAKE}
            </Button>

            {!onlyPicture && (
              <Button boxShadow='unset' color='#512AD8' mode='default' onClick={() => onUpload()}>
                {CAMERA_TYPE_ACTION}
              </Button>
            )}

            {data?.type === FILE_TYPE.VIDEO && (
              <Button color='#8E8E8E' mode='default' onClick={() => (setShowModal ? setShowModal(false) : true)}>
                {moveOn}
              </Button>
            )}
          </Wrapper>
        )}
      </Wrapper>
    </Wrapper>
  );
};
