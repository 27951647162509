import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

import { ScreenContainer } from '../../containers/ScreenContainer';
import { Wrapper } from '../../../styled-components';
import { useCurrentStatement } from '../../../hooks/useCurrentStatement';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: 'Roboto',
      whiteSpace: 'pre-wrap',
      color: '#00160A',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    value: {
      fontFamily: 'Roboto',
      whiteSpace: 'pre-wrap',
      color: '#8E8D8D',
      fontSize: '15px',
      fontWeight: 'bold',
      lineHeight: '18px',
      padding: '2% 0',
      wordBreak: 'break-all',
    },
  }),
);

export const ViewCircumstances = (): JSX.Element => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { currentStatement } = useCurrentStatement();

  const getCircumstanceDescription = () => {
    if (typeof currentStatement?.description === 'string') {
      return currentStatement?.description ? JSON.parse(currentStatement?.description).toString() : 'N/A';
    }

    return currentStatement?.description ? currentStatement.description?.join() : 'N/A';
  };

  return (
    <ScreenContainer headerProps={{ title: t('circumstances.circumstances'), titleSize: 'h5', hideLogo: true }}>
      <Wrapper
        alignItems='stretch'
        backgroundColor='white'
        borderRadius='16px'
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        overflowY='scroll'
        padding='24px'
      >
        <Typography className={classes.title}>{t('circumstances.accidentDate')}</Typography>
        <Typography className={classes.value}>{currentStatement?.accidentDate}</Typography>
        <Typography className={classes.title}>{t('circumstances.accidentTime')}</Typography>
        <Typography className={classes.value}>{currentStatement?.accidentTime}</Typography>
        <Typography className={classes.title}>{t('circumstances.accidentLocation')}</Typography>
        <Typography className={classes.value}>{currentStatement?.accidentLocation}</Typography>
        <Typography className={classes.title} style={{ padding: '2% 0 0 0' }}>
          {t('circumstances.accidentCircumstances')}:
        </Typography>
        <Typography className={classes.value}>{getCircumstanceDescription()}</Typography>
        <Typography className={classes.title}>{t('circumstances.weatherCondition')}:</Typography>
        <Typography className={classes.value}>{currentStatement?.weatherCondition}</Typography>
        <Typography className={classes.title}>{t('circumstances.roadCondition')}:</Typography>
        <Typography className={classes.value}>{currentStatement?.roadCondition}</Typography>
      </Wrapper>
    </ScreenContainer>
  );
};
