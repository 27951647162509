import React from 'react';

import { Typography, Wrapper } from '../../styled-components';

interface IEvaluationsTabs {
  menu: Array<any>;
}

const EvaluationsTabs = (props: IEvaluationsTabs): JSX.Element | null => {
  return (
    <Wrapper backgroundColor='#8875e6' display='flex' flexDirection='row' justifyContent='space-between' padding='0px 20px 20px'>
      {props.menu.map(menu => (
        <Wrapper
          key={menu.name}
          backgroundColor={menu.active ? '#4123d7' : 'none'}
          borderRadius='50px'
          padding='5px 5px'
          onClick={menu.onClick}
        >
          <Typography align='center' color='#FFF' commonStyles={{ margin: '0 25px' }} fontSize='14px' variant='h4' whiteSpace='pre'>
            <strong>{menu.name}</strong>
          </Typography>
        </Wrapper>
      ))}
    </Wrapper>
  );
};

export default EvaluationsTabs;
