import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardRow, ColProperty, ColValue } from '../../styled-components/cards/ElevatedCard';

interface PropsI {
  createdAt: string;
  insuranceNo?: string | null;
  registrationNumber?: string | null;
}

export const CardsCommonContent = ({ createdAt, insuranceNo, registrationNumber }: PropsI): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <CardRow style={{ justifyContent: 'flex-start' }}>
        <ColProperty>{t('commonText.statementDateOfIssue')}:</ColProperty>
        <ColValue style={{ marginLeft: 'auto' }}>{createdAt}</ColValue>
      </CardRow>

      <CardRow style={{ justifyContent: 'flex-start' }}>
        <ColProperty>{t('statementStatus.registrationNo')}</ColProperty>
        <ColValue style={{ marginLeft: 'auto' }}>{registrationNumber}</ColValue>
      </CardRow>

      <CardRow style={{ justifyContent: 'flex-start' }}>
        <ColProperty>{t('statementStatus.insuranceNo')}</ColProperty>
        <ColValue style={{ marginLeft: 'auto' }}>{insuranceNo}</ColValue>
      </CardRow>
    </>
  );
};
