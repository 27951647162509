import React from 'react';

import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  TBody,
  TBodySmall,
  Caption,
  Button,
  Tab,
  Subtitle,
  ButtonRegular,
  TBodyXS,
} from './typography.styled';
import { ITypographyProps } from './types';

const getTypographyVariant = (props: ITypographyProps): JSX.Element => {
  const { variant } = props;

  switch (variant) {
    case 'h1':
      return <Heading1 {...props} />;
    case 'h2':
      return <Heading2 {...props} />;
    case 'h3':
      return <Heading3 {...props} />;
    case 'h4':
      return <Heading4 {...props} />;
    case 'h5':
      return <Heading5 {...props} />;
    case 'h6':
      return <Heading6 {...props} />;
    case 'body':
      return <TBody {...props} />;
    case 'body-small':
      return <TBodySmall {...props} />;
    case 'body-xs':
      return <TBodyXS {...props} />;
    case 'caption':
      return <Caption {...props} />;
    case 'button':
      return <Button {...props} />;
    case 'button-regular':
      return <ButtonRegular {...props} />;
    case 'tab':
      return <Tab {...props} />;
    case 'subtitle':
      return <Subtitle {...props} />;
    default:
      return <TBody {...props} />;
  }
};

export const Typography: React.FC<ITypographyProps> = props => getTypographyVariant(props);
