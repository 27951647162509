import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Container, Typography } from '../../styled-components';
import { ASSESSMENTS_TEXT } from '../../translations/ro/home';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';

export const ReFinding = ({ history, nextRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ScreenContainer headerType='none'>
      <Container
        style={{
          backgroundColor: '#fff',
          borderRadius: 10,
          padding: '20px 15px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 2px 15px rgba(134, 112, 251, 0.2)',
          marginBottom: 35,
        }}
      >
        <Typography commonStyles={{ margin: '0 0 20px 0' }} fontWeight='700' variant='h4'>
          {ASSESSMENTS_TEXT.iWantMoneyInMyAccount}
        </Typography>

        <Typography color='#8e8e8e' commonStyles={{ margin: '0 0 20px 0' }} fontSize='12px' variant='body'>
          {ASSESSMENTS_TEXT.iWantMoneyInMyAccountNotice}
        </Typography>

        <Button mode='primary' onClick={() => history.push(nextRoute)}>
          {t('commonText.continue')}
        </Button>
      </Container>

      <Container
        style={{
          backgroundColor: '#fff',
          borderRadius: 10,
          padding: '20px 15px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 2px 15px rgba(134, 112, 251, 0.2)',
        }}
      >
        <Typography commonStyles={{ margin: '0 0 20px 0' }} fontWeight='700' variant='h4'>
          {ASSESSMENTS_TEXT.fixMyCarInService}
        </Typography>

        <Typography color='#8e8e8e' commonStyles={{ margin: '0 0 20px 0' }} fontSize='12px' variant='body'>
          {ASSESSMENTS_TEXT.fixMyCarInServiceNotice}
        </Typography>

        <Button mode='link' style={{ color: '#512ad8', fontWeight: 700, fontSize: 15 }}>
          {t('commonText.continue')}
        </Button>
      </Container>
    </ScreenContainer>
  );
};
