import React from 'react';

import { IIconProps } from './icon.model';

export const PersonIcon = ({ width, height, selected, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 30} width={width || 30} {...otherProps}>
      <rect fill={selected ? '#4123d7' : '#fff'} height={height || 30} rx={15} width={height || 30} />
      <path
        d='M15 25C9.477 25 5 20.523 5 15S9.477 5 15 5s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10zm0-18a8 8 0 108 8 8.01 8.01 0 00-8-8zm0 14a4.837 4.837 0 01-4-2 6.3 6.3 0 01-1-2h10v.008A6.422 6.422 0 0119 19a4.838 4.838 0 01-4 2zm-3.5-6a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6.993-.014a1.493 1.493 0 110-2.986 1.493 1.493 0 010 2.986z'
        fill={selected ? '#fff' : '#8e8d8d'}
      />
    </svg>
  );
};
