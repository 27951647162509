import React from 'react';

import { IIconProps } from './icon.model';

export const CurvedArrowRightIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 9} viewBox='0 0 21 9' width={width || 21} {...otherProps}>
      <path
        d='M16.86 3.6C15.01 1.99 12.61 1 9.96 1C5.31 1 1.38 4.03 0 8.22L2.36 9C3.41 5.81 6.41 3.5 9.96 3.5C11.91 3.5 13.69 4.22 15.08 5.38L11.46 9H20.46V0L16.86 3.6Z'
        fill={fill || '#1e1e29'}
      />
    </svg>
  );
};
