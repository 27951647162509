import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { Typography } from '../../styled-components';
import { IScreenComponentProps } from '../component-props.model';
import { HandleOcrFormsData } from '../../helpers/handleOcrFormsData/handleOcrFormsData';
import { useDocumentsTempData } from '../../hooks/useDocumentsTempData';
import { handleNextRouteLastPathConversion } from '../../helpers/routes-helpers';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { updateDocuments } from '../../helpers/handle-documents-update';
import { IInsuranceData } from '../../constants/mockConstants/mockInsuranceDetails';
import { ICarIdentity, IDrivingLicense, IIdentityCard } from '../../@types/document/documentTypes';
import { createFileFromBase64 } from '../../helpers/convert-image';
import { IStatementBackResponse } from '../../@types/current-statement/currentStatementTypes';
import { DOCUMENTS_FORM_TYPE } from '../../constants/typeConstants/documentsFormType';

export type IFormOcrMocks = IInsuranceData | IDrivingLicense['data'] | ICarIdentity['data'] | IIdentityCard['data'];

export const DocumentsOcrDataForm = ({ history, parentRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { handleFormTypesForOcr } = HandleOcrFormsData();
  const { putDocuments } = useDocumentsTempData();
  const { formType, title, flowHandle } = useDocumentsTempData();
  const ocrData = history?.location?.state?.ocrData;
  const documentImage = history?.location?.state?.image;
  const { currentStatement, setCurrentStatementData } = useCurrentStatement();

  const { guilty, injured } = currentStatement;

  //TODO Delete this on fix backend response object key names
  const mapBackendDocumentIDs = (data: Partial<IStatementBackResponse>) => {
    switch (formType) {
      case DOCUMENTS_FORM_TYPE.DRIVING_LICENSE:
        if (data.hasOwnProperty('driverLicenseId')) {
          const drivingLicenseIdKey = 'driverLicenseId' as keyof IStatementBackResponse;
          const drivingLicenseId = data?.[drivingLicenseIdKey];

          delete data?.[drivingLicenseIdKey];

          return { ...data, drivingLicenseId };
        }

        return data;
      case DOCUMENTS_FORM_TYPE.CAR_IDENTITY:
        if (data.hasOwnProperty('vehicleRegistrationCertificateId')) {
          const carIdentityIdKey = 'vehicleRegistrationCertificateId' as keyof IStatementBackResponse;
          const carIdentityId = data?.[carIdentityIdKey];

          delete data?.[carIdentityIdKey];

          return { ...data, carIdentityId };
        }

        return data;
      default:
        return data;
    }
  };

  const uploadDocument = (formValue: IFormOcrMocks) => {
    const payload = updateDocuments(formValue, formType, documentImage);

    if (payload && documentImage && documentImage.includes('data:image')) {
      const content = Object.values(payload)?.[0];

      const person = flowHandle === 'injured' ? injured : guilty;

      const file: Promise<File> = createFileFromBase64(documentImage, content.type);

      const formData = new FormData();

      file.then((data: File) => {
        formData.append('file', data);
        formData.append('personId', person?.id as any);
        formData.append('type', content.type as any);
        formData.append('data', JSON.stringify(content?.data));

        putDocuments(formData, (data: Partial<IStatementBackResponse>) => {
          const newData = mapBackendDocumentIDs(data);

          const newPersonData = { ...person, ...newData };
          setCurrentStatementData({ [flowHandle]: newPersonData });
        });
      });
    }
  };

  const onSubmitValues = (formValue: IFormOcrMocks) => {
    uploadDocument(formValue);
    history.push(handleNextRouteLastPathConversion(parentRoute));
  };

  return (
    <>
      <ScreenContainer
        headerProps={{
          title,
          hideLogo: true,
          titleSize: 'h5',
        }}
      >
        <Typography commonStyles={{ margin: '0 auto 15px 0' }} fontWeight='700' variant='h4'>
          {t('ocrForms.insuranceDetailsTitle')}
        </Typography>
        {handleFormTypesForOcr(formType, history, ocrData, parentRoute, onSubmitValues)}
      </ScreenContainer>
    </>
  );
};
