import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../styled-components';
import { Button } from '../../components/button';
import { IScreenComponentProps } from '../component-props.model';
import { ScreenContainer } from '../../components/containers/ScreenContainer';
import { HOME_TEXTS } from '../../translations/ro/home';
import { useCurrentStatement } from '../../hooks/useCurrentStatement';
import { handleNextRouteLastPathConversion } from '../../helpers/routes-helpers';
import { DOCUMENTS_TYPES } from '../../helpers/handle-documents-update';
import { IStatementBackResponse } from '../../@types/current-statement/currentStatementTypes';
import { useDocumentsTempData } from '../../hooks/useDocumentsTempData';

export const VehicleSelection = ({ history, parentRoute }: IScreenComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentStatement, setCurrentStatementData } = useCurrentStatement();
  const { putStatementDocuments } = useDocumentsTempData();

  const image = history?.location?.state?.newImage;

  const VEHICLE_A = 'A';
  const VEHICLE_B = 'B';

  const onClick = (injuredVehicle: string) => {
    if (image) {
      console.log('spsp');

      const formData = new FormData();

      formData.append('file', image);
      formData.append('id', currentStatement?.statementID as any);
      formData.append('type', DOCUMENTS_TYPES.AMICABLE_FINDING);
      formData.append('injuredVehicle', injuredVehicle);
      putStatementDocuments(
        formData,
        (data: Partial<IStatementBackResponse>) => {
          const documentID = data?.['amicableFindingId' as keyof Partial<IStatementBackResponse>] as number;
          setCurrentStatementData({ amicableFindingId: documentID });
          history.push(handleNextRouteLastPathConversion(parentRoute));
        },
        () => console.log('error'),
      );
    }
  };

  return (
    <ScreenContainer backgroundColor='#fff' headerProps={{ hideLogo: true, title: HOME_TEXTS.chooseVehicle }}>
      <Typography extendedStyles={{ lineHeight: '30px' }} fontWeight='700' variant='h3'>
        {t('documents.whichCarIsOwnerByInjured')}
      </Typography>
      {/* <p> this is the error p </p> */}
      <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button background='rgba(0, 102, 255, 0.2)' borderRadius='26px' color='#512ad8' width='47%' onClick={() => onClick(VEHICLE_A)}>
          {t('documents.aVehicle')}
        </Button>
        <Button background='rgba(255, 205, 7, 0.2)' borderRadius='26px' color='#512ad8' width='47%' onClick={() => onClick(VEHICLE_B)}>
          {t('documents.bVehicle')}
        </Button>
      </div>
    </ScreenContainer>
  );
};
