export const DATA_PRIVACY = `<p>PayPact este un produs dezvoltat de SC Bewell Technologies SRL, cu sediul în România, Cluj-Napoca , str. Ialomita , nr. 11 , bl. , sc. , ap. 5 , Județul Cluj, nr. de înregistrare la Registrul Comerțului J12/1166/2017, cod de identificare fiscală 37273506. Această politică de confidențialitate explică modul în care organizația noastră folosește datele cu caracter personal atunci când accesezi websiteul nostru.</p>
<p><b>POLITICA DE CONFIDENŢIALITATE</b></p>
<p><b>Ultima actualizare: 13.04.2022</b></p>
<p><span style="font-weight: 400;">Politica de confidențialitate stabileşte termenii și condițiile în care societatea Bewell Technologies SRL în calitate de operator de date cu caracter personal, procesează și prelucrează datele personale (ca parte componentă și indispensabilă realizării activității) pe care ni le furnizați atunci când utilizați aplicaţia noastră şi toate funcţionalităţile puse la dispoziţie prin intermediul acesteia, </span></p>
<p><span style="font-weight: 400;">Cu ocazia utilizării aplicaţiei ”PayPact” ne furnizați sau este necesar să accesăm informații care, datorită caracteristicilor lor, ne permit să vă identificăm, cum ar fi numele, prenumele, adresa de e-mail, adresa, numărul de telefon, face id etc. (date cu caracter personal). În situaţia prezentată, vi se va aplica această Politică de confidențialitate. Dorim să vă explicăm cât mai clar posibil ce date colectăm, cum le folosim și ce drepturi aveți legate de acestea. Dacă aveţi întrebări, sugestii şi/sau critici/reclamaţii legate de protecţia datelor cu caracter personal sau la practicile noastre referitoare la datele dumneavoastră personale, vă rugăm să ne contactați la daune@paypact.ro. </span></p>
<p><span style="font-weight: 400;">Aplicaţia noastră utilizează informaţia stocată, şi anume datele furnizate de dumneavoastră sau cele extrase de aplicaţie din documentele ataşate de Dvs., în scopul de a întocmi notificarea daunei către societatea de asigurare, respectiv pentru a da posibilitatea evaluării daunei, prin trasmiterea acestor date cu caracter personal către o unitate de service autorizată. </span></p>
<p><b>Vă rugăm să citiți această politică cu atenție deoarece vă va ajuta să luați decizii informate cu privire la  transmiterea datelor dvs. personale către noi.</b><span style="font-weight: 400;"> </span></p>
<p><b>CUPRINS: </b></p>
<ol>
   <li><b> Tipurile de date cu caracter personal pe care le prelucrăm. Scopurile specifice ale acestor prelucrări de date cu caracter personal.</b></li>
   <li><b> Temeiul legal al prelucrării datelor cu caracter personal.</b></li>
   <li><b> Drepturile dumneavoastră în calitate de utilizator al aplicaţiei.</b></li>
   <li><b> Categorii de destinatari ai datelor cu caracter personal prelucrate.</b></li>
   <li><b> Colectarea datelor de la minori.</b></li>
   <li><b> Perioada de stocare a datelor cu caracter personal.</b></li>
   <li><b> Asigurarea securităţii datelor.</b></li>
   <li><b> Modificări ale politicii de confidenţialitate.</b></li>
</ol>
<p><b> CARE SUNT TIPURILE DE DATE CU CARACTER PERSONAL PE CARE LE PRELUCRĂM PRIN INTERMEDIUL APLICAŢIEI PUSE LA DISPOZIŢIA DUMNEAVOASTRĂ? CARE SUNT SCOPURILE SPECIFICE ALE ACESTOR PRELUCRĂRI DE DATE CU CARACTER PERSONAL?</b></p>
<p><b>1.1. </b><b>Date furnizate în mod voluntar de către dumneavoastră în calitate de utilizator al aplicaţiei</b><span style="font-weight: 400;">, şi anume: </span><span style="font-weight: 400;">nume, prenume, CNP sau cod de identificare fiscală, adresă, sexul, adresa de mail, număr de telefon, informaţii extrase de pe copii ale documentelor de identitate (BI/CI/Pașaport), informaţii extrase de pe copii ale altor documente, precum permis de conducere, certificat de înmatriculare, cartea de identitate a vehiculului, informații privind contul bancar – IBAN, semnătură (olografă sau în format digital), date biometrice (face ID).</span></p>
<p><b>1.2. </b><b>Date colectate în mod automat de către aplicaţie:  </b></p>
<p><span style="font-weight: 400;">Atunci când accesaţi o aplicaţie dezvăluiţi anumite informaţii despre dumneavoastră, ca ora vizitei, locul de unde s-a realizat accesarea aplicaţiei. </span></p>
<p><span style="font-weight: 400;">Aceste date nu vă  divulgă identitatea specifică (cum ar fi numele sau datele de contact) dar pot include date despre  dispozitiv și informații de utilizare, precum caracteristici ale dispozitivului, sistem de operare, preferințe legate de limbă, numele dispozitivului, țară, locație, date despre cum și când folosiți aplicația noastră și alte date tehnice. Aceste date sunt necesare în primul rând  pentru a menține securitatea și funcționarea aplicaţiei noastre și pentru analizele noastre interne și în scopuri de raportare.</span></p>
<p><b>1.3.</b><b> Care sunt scopurile prelucrării datelor cu caracter personal?</b></p>
<p><span style="font-weight: 400;">Prelucrăm date cu caracter personal numai pe baza consimţământului expres al utilizatorilor şi numai după informarea acestora cu privire la scopurile în care aceste date vor fi prelucrate. </span></p>
<p><span style="font-weight: 400;">Utilizatorul are dreptul de a-şi da consimţământul pentru fiecare scop al prelucrării datelor cu caracter personal în parte, aplicaţia subscrisei putând prelucra datele cu caracter personal numai în scopul pentru care utilizatorul şi-a exprimat în mod expres consimţământul. </span></p>
<p><span style="font-weight: 400;">Utilizatorul are dreptul de a-şi retrage în orice moment consimţământul. Retragerea consimţământului nu afectează legalitatea prelucrării efectuate pe baza consimţământului înainte de retragerea acestuia.</span></p>
<p><span style="font-weight: 400;">Scopurile prelucrării:</span></p>
<ul>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru a se întocmi notificarea privind dauna şi comunicarea acesteia către societatea de asigurări,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru transmiterea documentelor necesar a fi anexate notificării de la punctul anterior, </span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru transmiterea solicitării de evaluare a pagubei către unitatea de service cu care colaborăm</span><span style="font-weight: 400;">, </span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru transmiterea către unitatea de a service a documentelor necesare în vederea evaluării pagubei, respectiv întocmirea devizului de reparaţii,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru încărcarea informaţiilor necesare în softul autorizat de evaluare a daunelor, AUDATEX.</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru a obține informația conform căreia metoda de autentificare a fost validată de telefonul/dispozitivul dumneavoastră (în cazul recunoașterii faciale &#8211; face ID)</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru îndeplinirea și gestionarea cererilor dumneavoastră,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru a răspunde la mesaje, reclamaţii şi sugestii, pe care ni le trimiteţi, respectiv pentru a procesa cele enumerate,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Pentru a aplica termenii, condițiile și politicile noastre.</span></li>
</ul>
<p><span style="font-weight: 400;">În măsura în care, după citirea prezentei politici, nu veți dori să ne furnizați anumite informații obligatorii, se poate ajunge în situația în care nu veţi putea trece la pasul următor în aplicaţie. De exemplu pentru formularea notificării de daună, trebuie să încărcaţi diverse documente (ca de ex. copie după cartea de identitate, copie după poliţa de asigurare). Însă, în măsura în care refuzaţi furnizarea datelor cu caracter personal solicitate și fără de care noi nu ne-am putea îndeplini obligațiile, dumneavoastră vă asumați prin citirea prezentei că serviciile solicitate (transmiterea notificării de daună către societatea de asigurare, evaluarea pagubei) nu pot fi efectuate.  </span></p>
<ol start="2">
   <li><b> CARE ESTE TEMEIUL LEGAL AL PRELUCRĂRII DATELOR CU CARACTER PERSONAL?</b></li>
</ol>
<p><span style="font-weight: 400;">Temeiul legal pentru colectarea şi procesarea datelor cu caracter personal depinde de contextul specific în care sunt colectate.</span></p>
<p><span style="font-weight: 400;">De regulă, vom prelucra datele dumneavoastră cu caracter personal în baza consimţământului exprimat în acest sens, (art. 6, par. 1, lit. a din Regulamentul european privind prelucrarea datelor cu caracter personal – RGPD), dacă avem nevoie de date cu caracter personal pentru îndeplinirea unor obligaţii asumate de noi ca urmare a solicitărilor Dvs., (trasmiterea notificării de daună, prestarea de servicii constând în furnizarea unui deviz de reparaţii) sau dacă respectivele date cu caracter personal colectate sunt necesare în vederea utilizării anumitor funcţionalităţi puse la dispoziţie prin intermediul aplicaţiei noastre. (art. 6, par. 1, lit. b din RGPD).</span></p>
<p><span style="font-weight: 400;">În unele cazuri prelucrarea datelor dumneavoastră cu caracter personal poate fi necesară pentru îndeplinirea unei obligaţii legale care se referă la noi (art. 6, par. 1, lit. c din RGPD), cum ar fi obligaţiile legale de păstrare a datelor, sau pentru protejarea intereselor dumneavoastră esenţiale sau a intereselor esenţiale ale unei alte persoane (art. 6, par. 1, lit. d din RGPD).</span></p>
<p><span style="font-weight: 400;">În cazul în care prelucrăm datele dumneavoastră cu caracter personal pe baza altor interese, nemenţionate mai sus (sau interesele terţilor), vă vom informa despre aceste interese întemeiate la momentul oportun.</span></p>
<ol start="3">
   <li><b> CARE SUNT DREPTURILE DUMNEAVOASTRĂ ÎN CALITATE DE UTILIZATOR AL APLICAŢIEI?</b></li>
</ol>
<p><span style="font-weight: 400;">Facem eforturi pentru a menține un nivel ridicat de transparență în legătură cu prelucrările de date cu caracter personal pe care le efectuăm. </span></p>
<p><span style="font-weight: 400;">În acest sens, în calitate de operator de date cu caracter personal ne angajăm să respectăm confidențialitatea datelor colectate și vă garantăm că vă puteți exercita drepturile recunoscute de legislația în vigoare cu privire la acestea. Vă puteți exercita dreptul de a fi informat, de a accesa, de a rectifica, de a anula datele personale, de opoziție, dreptul de a nu fi supus unei decizii individuale, prin trimiterea unui e-mail la adresa de e-mail </span><span style="font-weight: 400;">daune@paypact.ro.</span><span style="font-weight: 400;"> </span></p>
<p><span style="font-weight: 400;">Aşadar, în privința datelor cu caracter personal pe care le prelucrăm conform celor descrise în această politică, aveți următoarele drepturi:</span></p>
<p><b>3.1. Dreptul de acces la datele cu caracter personal prelucrate</b><span style="font-weight: 400;">:</span></p>
<p><span style="font-weight: 400;">Utilizatorul aplicaţiei noastre are dreptul de a obţine din partea noastră o confirmare  că se prelucrează sau nu date cu caracter personal care îl privesc şi, în caz afirmativ, are dreptul de acces la datele respective şi la următoarele informaţii: scopurile prelucrării, categoriile de date cu caracter personal vizate, destinatarii cărora datele cu caracter personal le-au fost sau urmează să le fie divulgate, perioada pentru care se preconizează că respectivele date cu caracter personal vor fi stocate acolo unde este posibil, în cazul în care datele cu caracter personal nu sunt colectate direct de la utilizator, orice informaţii disponibile privind sursa acestora şi cu privire la care deținem informații. </span></p>
<p><b>3.2. Dreptul la rectificare:</b></p>
<p><span style="font-weight: 400;">Utilizatorul are dreptul de a obţine din partea noastră fără întârzieri nejustificate, rectificarea datelor cu caracter personal inexacte care îl privesc. Ţinându-se seama de scopurile în care datele cu caracter personal au fost prelucrate, utilizatorul are dreptul de a obţine completarea datelor cu caracter personal care sunt incomplete. </span></p>
<p><b>3.3. Dreptul la restricţionarea prelucrării</b></p>
<p><span style="font-weight: 400;">Utilizatorul are dreptul de a obţine din partea noastră, restricţionarea prelucrării în cazul în care: </span></p>
<ul>
   <li style="list-style-type: none;">
      <ul>
         <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">exactitatea datelor cu caracter personal este contestată, pentru o perioadă care îi permite operatorului să verifice exactitatea datelor respective. </span></li>
         <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">prelucrarea este ilegală, iar utilizatorul se opune ştergerii datelor colectate, solicitând în schimb restricţionarea utilizării acestora. </span></li>
         <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">în cazul în care nu mai avem nevoie de datele cu caracter personal în scopul prelucrării dar utilizatorul i le solicită pentru constatarea, exercitarea sau apărarea unui drept în instanţă. </span></li>
         <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">utilizatorul s-a opus prelucrării, pentru intervalul de timp în care se verifică dacă drepturile legitime ale operatorului prevalează asupra celor ale utilizatorului.</span></li>
      </ul>
   </li>
</ul>
<p><b>3.4. Dreptul la opoziţie </b></p>
<p><span style="font-weight: 400;">În orice moment utilizatorul are dreptul de a se opune, din motive legate de situaţia particulară în care se află, prelucrării datelor cu caracter personal. Nu vom mai prelucra de la acel moment datele cu caracter personal, cu excepţia cazului în care această prelucrare este impusă de motive temeinice, legitime şi imperioase care justifică prelucrarea şi care prevalează asupra intereselor, drepturilor şi libertăţilor persoanei vizate, fie că este vorba despre apărarea unui interes legitim sau drept al său, fie că este vorba despre o obligaţie legală care impune prelucrarea datelor cu caracter personal în continuare. </span></p>
<p><b>3.5. Dreptul la ştergerea datelor (dreptul de a fi uitat)</b></p>
<p><span style="font-weight: 400;">Utilizatorul are dreptul de a obţine din partea noastră ştergerea datelor cu caracter personal care îl privesc, fără întârzieri nejustificate. Ne asumăm obligaţia de a şterge respectivele date cu caracter personal, fără întârzieri nejustificate, în cazul în care:</span></p>
<p><span style="font-weight: 400;">&#8211; prelucrarea nu mai este necesară pentru îndeplinirea scopurilor pentru care au fost furnizate/colectate (exceptând situaţia în care există o obligaţie legală care impune prelucrarea datelor cu caracter personal în continuare); </span></p>
<p><span style="font-weight: 400;">&#8211; utilizatorul şi-a retras consimţământul pe baza căruia prelucrarea a avut loc; </span></p>
<p><span style="font-weight: 400;">&#8211; datele cu caracter personal trebuie şterse pentru respectarea unei obligaţii legale care revine operatorului în temeiul dreptului Uniunii Europene sau al dreptului sub incidenţa căruia se află acesta. </span></p>
<p><b>3.6. Dreptul de a depune o plângere la o autoritate de supraveghere </b></p>
<p><span style="font-weight: 400;">Dacă utilizatorul este de părere că prelucrăm datele cu caracter personal într-un mod incorect, acesta ne poate contacta. De asemenea, utilizatorii au dreptul de a adresa o plângere autorităţii de supraveghere competente în domeniul prelucrării datelor cu caracter personal. </span></p>
<ol start="4">
   <li><b> CĂTRE CINE VOM TRANSMITE DATELE DVS. CU CARACTER PERSONAL? </b></li>
</ol>
<p><span style="font-weight: 400;">Este posibil să procesăm sau să transmitem date  în următoarele baze legale: </span></p>
<p><b>4.1. Consimțământ:</b><span style="font-weight: 400;"> Vă putem procesa datele dacă ne-ați dat consimțământul specific de a utiliza datele dumneavoastră în scopuri anumite, respectiv transmiterea către: </span></p>
<ul>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Societatea de asigurări,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Unitatea service care va efectua evaluarea pagubei, respectiv va întocmi devizul de reparaţii,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Încărcarea în softul autorizat de evaluare a daunelor,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Furnizorii implicați în mod direct/ indirect în procesul de asigurare</span><span style="font-weight: 400;">,</span></li>
</ul>
<p><b>4.2. Obligații legale:</b><span style="font-weight: 400;"> Este posibil să  divulgăm datele dumneavoastră atunci când este legal necesar pentru a respecta legea aplicabilă, cererile guvernamentale, o procedură juridică, ordin judecătoresc sau proces legal, sens în care vom transmite datele cu caracter personal către:</span></p>
<ul>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Autoritățile publice,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Parchet, </span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Poliţie,</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Instanţe de judecată şi altor organe abilitate ale statului,</span></li>
</ul>
<p><b>4.3. Interese legitime şi comerciale:</b><span style="font-weight: 400;"> Vă putem procesa datele atunci când este rezonabil necesar să ne îndeplinim interesele comerciale, prin divulgarea acestora altor companii cu care ne aflăm în relaţii de parteneriat, dar numai în temeiul unui angajament de confidenţialitate din partea acestora, ca de exemplu:</span></p>
<p><span style="font-weight: 400;">&#8211; Furnizorul de servicii IT – pentru a ne menține infrastructura IT,</span></p>
<p><span style="font-weight: 400;">&#8211; Furnizori de servicii de marketing, </span></p>
<p><span style="font-weight: 400;">&#8211; Curierat, </span></p>
<p><span style="font-weight: 400;">&#8211; Servicii de plată/bancare, </span></p>
<p><span style="font-weight: 400;">&#8211; Servicii de contabilitate,</span></p>
<p><span style="font-weight: 400;">&#8211; Auditori externi.</span></p>
<p><span style="font-weight: 400;">Subliniem faptul că împărtășim cu aceștia doar datele personale necesare realizării activităților specifice pe care le încredințăm acestora.</span></p>
<ol start="5">
   <li><b> COLECTĂM DATE DE LA MINORI?</b></li>
</ol>
<p><span style="font-weight: 400;">Pentru utilizarea aplicaţiei noastre este necesar să aveţi 18 ani. Aşadar, nu solicităm cu bună-ştiinţă date confidenţiale despre minori. Dacă luați cunoștință despre orice date pe care le-am colectat  de la minori, vă rugăm să ne contactați la adresa de email daune@paypact.ro. </span></p>
<p><span style="font-weight: 400;">Dacă nu ați împlinit vârsta de 18 ani, vă rugăm să nu ne furnizați datele dumneavoastră personale. În cazul în care constatăm că o persoană cu vârsta sub 18 ani a furnizat date personale prin intermediul aplicaţiei noastre, vom depune toate eforturile pentru a elimina aceste informații din bazele noastre de date.</span><span style="font-weight: 400;"> </span></p>
<ol start="6">
   <li><b> CÂT TIMP PĂSTRĂM DATELE DUMNEAVOASTRĂ? </b></li>
</ol>
<p><span style="font-weight: 400;">Intenționăm să prelucrăm date cu caracter personal corecte și actualizate. Nu dorim să stocăm și nici nu vom stoca datele Dvs. cu caracter personal mai mult decât este necesar pentru scopurile relevante ce țin de utilizarea aplicaţiei noastre. Perioada de stocare necesară depinde de scopurile în care au fost colectate datele și, prin urmare, nu există o perioadă de stocare standard. Astfel, perioada de stocare poate varia în funcție de circumstanțe sau de situația aplicabilă. Pentru alte motive de ordin juridic, spre exemplu în contextul unor litigii în instanță sau dispute, este posibil să fie necesar să stocăm datele cu caracter personal peste termenul de stocare declarat inițial. În orice caz, în aceste situații nu vom mai prelucra activ datele cu caracter personal în scopurile în care le-am colectat inițial.</span></p>
<p><span style="font-weight: 400;">Aşadar, perioada de păstrare a datelor depinde în principal de următoarele:</span></p>
<ul>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">pentru ce perioadă avem nevoie de datele Dvs. pentru a vă oferi serviciile solicitate și a ne îndeplini obligațiile față de Dvs. și pentru scopurile menționate mai sus în prezenta Politică;</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">dacă v-aţi exprimat consimțământul pentru prelucrarea datelor pentru o perioadă mai mare, vom menține datele pe toată această perioadă, cu excepția cazului în care vă retrageţi între timp consimțământul;</span></li>
   <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">obligațiile legale sau contractuale ne impun păstrarea datelor Dvs. pentru o anumită perioadă de timp.</span></li>
</ul>
<p><span style="font-weight: 400;">În cazul în care ne veţi solicita ştergerea datelor cu character personal, acestea vor fi șterse sau anonimizate astfel încât să nu mai puteţi fi identificat în sistemele noastre de evidență a datelor, exceptând cazul în care suntem obligați să păstrăm datele Dvs. pentru o perioadă mai lungă, în baza legii sau interesului nostru legitim.</span></p>
<p><span style="font-weight: 400;">  </span></p>
<ol start="7">
   <li><b> CUM ASIGURĂM SECURITATEA DATELOR DVS.?</b></li>
</ol>
<p><span style="font-weight: 400;">Asigurăm măsurile tehnice și organizatorice necesare pentru colectarea, prelucrarea și păstrarea datelor în siguranță, inclusiv împotriva accesului neautorizat, al utilizării neautorizate a datelor, sau distrugerii, pierderii sau alterării datelor. Ne angajăm să păstrăm datele Dvs. personale în siguranță și luăm toate măsurile de protecție rezonabile pentru a face acest lucru.</span></p>
<p><span style="font-weight: 400;">În plus, depunem toate eforturile rezonabile pentru a ne asigura (inclusiv prin contractele încheiate cu aceștia) că și parteneri noștri de încredere gestionează măsuri tehnice și organizatorice adecvate pentru prelucrarea datelor pe care le împărtășim cu aceștia.</span></p>
<p><span style="font-weight: 400;">Însă, în ciuda faptului că luăm măsuri de siguranță rezonabile din punct de vedere comercial pentru asigurarea unui nivel de securitate corespunzător confidențialității datelor personale colectate de noi, nu putem garanta securitatea informațiilor furnizate prin intermediul internetului. </span></p>
<p><b> </b></p>
<ol start="8">
   <li><b> MODIFICĂM ACEASTĂ POLITICĂ?</b></li>
</ol>
<p><span style="font-weight: 400;">Dacă vom considera că este necesară o schimbare a regulilor de confidentialitate, vom publica respectivele modificări, pentru a vă informa cu privire la tipurile de informaţii pe care le colectăm şi la modul în care le utilizăm. Menţionăm că, versiunea actualizată va intra în vigoare imediat ce este accesibilă. </span></p>
<p style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal;"> </p>
</div>`;
