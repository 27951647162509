export const FILE_TYPE = {
  VIDEO: 'video',
  PICTURE: 'picture',
};

export const INPUT_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const FILE_MODE = {
  UPLOAD: 'upload',
  CAMERA: 'camera',
};
