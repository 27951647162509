import { useTranslation } from 'react-i18next';

import AddPictureBackground from '../assets/images/add-picture.png';
import { PICTURE_CONTAINER_TYPE } from '../constants/pictureContainerType';
import { INPUT_TYPE } from '../constants/typeConstants/cameraType';
import { deleteDamagesMedia } from '../store/statement/currentStatement.api';

import { useCurrentStatement } from './useCurrentStatement';
import { SpecialFormType } from './useStatementDocuments';

export interface DamagePicturesI {
  id: number;
  label: string;
  src: string;
  modalLabel?: string;
  type: string | SpecialFormType;
  handleOnDelete: () => void;
}

const useStatementDamageConstants = () => {
  const { t } = useTranslation();
  const { currentStatement, setCurrentStatementData } = useCurrentStatement();
  const { injured } = currentStatement;
  const { damage } = injured || {};

  const getImageSrcByLabel = (label: string) => {
    const currentPhotoProfs = damage?.photoProofs;

    const type = label?.replace('* ', '');
    const damageImage = currentPhotoProfs?.find(image => image.type === type);

    if (damageImage?.attachmentId) {
      return `${process.env.REACT_APP_API_URL}/attachments/file/${damageImage.attachmentId}?token=${localStorage.getItem('access_token')}`;
    }

    return AddPictureBackground;
  };

  const handleDeletePhotoProof = async (label: string) => {
    const currentPhotoProfs = damage?.photoProofs;

    const type = label?.replace('* ', '');
    const damageImage = currentPhotoProfs?.find(image => image.type === type);
    const damagePhotoID = damageImage?.id;

    if (damagePhotoID) {
      await deleteDamagesMedia(damagePhotoID);

      const newPhotoProfs = currentPhotoProfs?.filter(image => image?.id !== damagePhotoID);

      const newDamageData = {
        damage: {
          ...damage,
          photoProofs: newPhotoProfs,
        },
      };

      setCurrentStatementData({
        injured: {
          ...injured,
          ...newDamageData,
        },
      });
    }
  };

  const DAMAGE_PICTURES = {
    type: PICTURE_CONTAINER_TYPE.DAMAGE,
    data: [
      {
        id: 1,
        label: t('affectedZones.carFront'),
        modalLabel: t('affectedZones.modal_label.carFront'),
        src: getImageSrcByLabel(t('affectedZones.carFront')),
        type: INPUT_TYPE.IMAGE,
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.carFront')),
      },
      {
        id: 2,
        label: t('affectedZones.carBack'),
        modalLabel: t('affectedZones.modal_label.carBack'),
        src: getImageSrcByLabel(t('affectedZones.carBack')),
        type: INPUT_TYPE.IMAGE,
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.carBack')),
      },
      {
        id: 3,
        label: t('affectedZones.lateralLeft'),
        modalLabel: t('affectedZones.modal_label.lateralLeft'),
        src: getImageSrcByLabel(t('affectedZones.lateralLeft')),
        type: INPUT_TYPE.IMAGE,
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.lateralLeft')),
      },
      {
        id: 4,
        label: t('affectedZones.lateralRight'),
        modalLabel: t('affectedZones.modal_label.lateralRight'),
        src: getImageSrcByLabel(t('affectedZones.lateralRight')),
        type: INPUT_TYPE.IMAGE,
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.lateralRight')),
      },
      {
        id: 5,
        label: t('affectedZones.chassisSeries'),
        modalLabel: t('affectedZones.modal_label.chassisSeries'),
        src: getImageSrcByLabel(t('affectedZones.chassisSeries')),
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.chassisSeries')),
        type: INPUT_TYPE.IMAGE,
      },
      {
        id: 6,
        label: t('affectedZones.boardMileage'),
        modalLabel: t('affectedZones.modal_label.boardMileage'),
        src: getImageSrcByLabel(t('affectedZones.boardMileage')),
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.boardMileage')),
        type: INPUT_TYPE.IMAGE,
      },
      {
        id: 7,
        label: t('affectedZones.firstDamageDetail'),
        modalLabel: t('affectedZones.modal_label.firstDamageDetail'),
        src: getImageSrcByLabel(t('affectedZones.firstDamageDetail')),
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.firstDamageDetail')),
        type: INPUT_TYPE.IMAGE,
      },
      {
        id: 8,
        label: t('affectedZones.secondDamageDetail'),
        modalLabel: t('affectedZones.modal_label.secondDamageDetail'),
        src: getImageSrcByLabel(t('affectedZones.secondDamageDetail')),
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.secondDamageDetail')),
        type: INPUT_TYPE.IMAGE,
      },
      {
        id: 9,
        label: t('affectedZones.firstOverallPicture'),
        modalLabel: t('affectedZones.modal_label.firstOverallPicture'),
        src: getImageSrcByLabel(t('affectedZones.firstOverallPicture')),
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.firstOverallPicture')),
        type: INPUT_TYPE.IMAGE,
      },
      {
        id: 10,
        label: t('affectedZones.secondOverallPicture'),
        modalLabel: t('affectedZones.modal_label.secondOverallPicture'),
        src: getImageSrcByLabel(t('affectedZones.secondOverallPicture')),
        handleOnDelete: async () => await handleDeletePhotoProof(t('affectedZones.secondOverallPicture')),
        type: INPUT_TYPE.IMAGE,
      },
    ],
  };

  return { DAMAGE_PICTURES };
};

export { useStatementDamageConstants };
