import React from 'react';

import { IIconProps } from './icon.model';

export const CloseIcon = ({ width, height, fill, ...otherProps }: IIconProps): JSX.Element => {
  return (
    <svg fill='none' height={height || 18} viewBox='0 0 18 18' width={width || 18} {...otherProps}>
      <path
        d='M17.6007 0.41297C17.0683 -0.119454 16.2082 -0.119454 15.6758 0.41297L9 7.07509L2.32423 0.399317C1.79181 -0.133106 0.931741 -0.133106 0.399317 0.399317C-0.133106 0.931741 -0.133106 1.79181 0.399317 2.32423L7.07509 9L0.399317 15.6758C-0.133106 16.2082 -0.133106 17.0683 0.399317 17.6007C0.931741 18.1331 1.79181 18.1331 2.32423 17.6007L9 10.9249L15.6758 17.6007C16.2082 18.1331 17.0683 18.1331 17.6007 17.6007C18.1331 17.0683 18.1331 16.2082 17.6007 15.6758L10.9249 9L17.6007 2.32423C18.1195 1.80546 18.1195 0.931741 17.6007 0.41297Z'
        fill={fill || '#9a9a9a'}
      />
    </svg>
  );
};
